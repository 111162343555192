import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  editVariant,
  getVariants,
} from "../../../redux/actions/variant-actions";
import MenuActions, {
  Action,
} from "../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../constants/navigation-constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import {
  Column,
  Columns,
} from "../../../components/pagination-table/table-types";
import { Variant } from "../../../types/variant-types";
import PaginationTable from "../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../../components/confirmation-dialog/confirmation-dialog";
import { Typography, useTheme } from "@mui/material";
import Section from "../../../components/section/section";
import VariantFilter from "./variant-filter/variant-filter";
import { getAllFillings } from "../../../redux/actions/filling-actions";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { ELLIPSIS_STYLES } from "../../../components/pagination-table/table-row/table-row";
import {
  formatInteger,
  formatPrice as priceFormatter,
} from "../../../utils/formatter-utils";

interface VariantsListProps extends WithPageLoaderProps {
  modelId: number;
}

const VariantsList = ({ modelId, finishLoading }: VariantsListProps) => {
  const reducer = useSelector((state: Store) => state.variant);
  const { loading, filters, content: variants, size, sort } = reducer;
  const { allFillings } = useSelector((state: Store) => state.filling);

  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const isActive = filters.active;

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await Promise.all([dispatch(getAllFillings({}))]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      await dispatch(editVariant({ id: pickedId, active: !isActive }));
      dispatch(
        getVariants({
          params: { size, page: 0, sort, filters },
          reload: true,
        })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (entityId) => {
        navigate(`${Route.VariantEdit}/${modelId}/${entityId}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderActionsCell = (variant: Variant) => (
    <MenuActions
      key={`${variant.id}-actions-cell`}
      actions={actions}
      entityId={variant.id}
    />
  );

  const formatPrice = (price?: string) =>
    price ? `${priceFormatter(Number(price))} €` : "-";

  const renderPriceCell = (variant: Variant, fillingId: number) => {
    const priceObject = variant.price?.find(
      (row) => row.fillingId === fillingId
    );

    const color =
      priceObject && !priceObject.active
        ? theme.palette.grey["500"]
        : theme.palette.common.black;

    return (
      <Typography
        key={`${variant.id}-${fillingId}`}
        sx={{
          ...ELLIPSIS_STYLES,
          color,
        }}
      >
        {formatPrice(priceObject?.price.toString())}
      </Typography>
    );
  };

  const getColumns = (): Columns<Variant> => {
    const allUsedFillings = variants
      ? variants
          .flatMap((variant) => variant.price)
          .filter((price) => price.active)
          .map((price) => price.fillingId)
          .filter((id, index, array) => array.indexOf(id) === index)
          .map((id) => {
            const filling = allFillings?.find((filling) => filling.id === id);
            if (!filling) return undefined;
            return { id, label: filling.label };
          })
      : [];

    const priceColumns = allUsedFillings
      .filter((row) => row !== undefined && row?.id !== undefined)
      //@ts-ignore
      .sort((a, b) => a?.label.localeCompare(b?.label))
      .map<Column<Variant>>((row) => ({
        id: `${row?.id}-${row?.label}`,
        flexGrow: 10,
        name: row?.label || "",
        cellRenderer: (variant) => renderPriceCell(variant, row!.id),
      }));

    const getHeightCellRenderer = (variant: Variant) => {
      const text = variant.secondaryHeight
        ? `${formatInteger(variant.height)} / ${formatInteger(
            variant.secondaryHeight
          )}`
        : formatInteger(variant.height);

      return (
        <Typography key={`${variant.id}-height-cell`} sx={ELLIPSIS_STYLES}>
          {text}
        </Typography>
      );
    };

    const getNumberCellRenderer = (variant: Variant, field: string) => {
      return (
        <Typography key={`${variant.id}-${field}-cell`} sx={ELLIPSIS_STYLES}>
          {
            // @ts-ignore
            formatInteger(variant[field])
          }
        </Typography>
      );
    };

    return [
      {
        id: "modulesCount",
        accessor: "modulesCount",
        flexGrow: 10,
        name: t("__moduly"),
      },
      {
        id: "length",
        cellRenderer: (variant) => getNumberCellRenderer(variant, "length"),
        flexGrow: 10,
        name: t("__dlugosc"),
      },
      {
        id: "widthInt",
        cellRenderer: (variant) => getNumberCellRenderer(variant, "widthInt"),
        flexGrow: 10,
        name: t("__szerokosc_wewnetrzna"),
      },
      {
        id: "widthExt",
        cellRenderer: (variant) => getNumberCellRenderer(variant, "widthExt"),
        flexGrow: 10,
        name: t("__szerokosc_zewnetrzna"),
      },
      {
        id: "height",
        cellRenderer: (variant) => getHeightCellRenderer(variant),
        flexGrow: 10,
        name: t("__wysokosc"),
      },
      ...priceColumns,
      {
        id: "actions",
        cellRenderer: renderActionsCell,
        name: "",
        justify: "end",
      },
    ];
  };

  const table = (
    <PaginationTable<Variant>
      withPagination={true}
      loading={loading}
      fetcherAction={getVariants}
      reducerName="variant"
      columns={getColumns()}
    />
  );

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("__dezaktywacja_variantu_pytanie")
            : t("__aktywacja_variantu_pytanie")}
        </Typography>
      </ConfirmationDialog>
      <Section
        title={t("__warianty_zadaszenia")}
        actions={<VariantFilter modelId={modelId} />}
      >
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(VariantsList);
