import { SxProps, Theme } from "@mui/material";
import { Role } from "../types/auth-types";
import { Roles } from "../constants/roles";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";

export const toRem = (px: number) => `${px / 16}rem`;

export const prepareSx = (sx?: SxProps<Theme>) =>
  Array.isArray(sx) ? sx : [sx];

export const getUnique = (v: number, i: number, a: number[]) =>
  a.indexOf(v) === i;

export const isAdminRole = (role: Role) => role.name === Roles.Admin;

export const isPartner = (role: Role) => role.name === Roles.Partner;

export const getInputAdornment = (label: string) => ({
  endAdornment: <InputAdornment position="start">{label}</InputAdornment>,
});
