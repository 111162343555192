import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { editBrake, getBrakes } from "../../../../redux/actions/brake-actions";
import MenuActions, {
  Action,
} from "../../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../../constants/navigation-constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Brake } from "../../../../types/brake-types";
import { Columns } from "../../../../components/pagination-table/table-types";
import PaginationTable from "../../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../../../components/confirmation-dialog/confirmation-dialog";
import { Typography } from "@mui/material";
import Section from "../../../../components/section/section";
import BrakeFilters from "./brake-filters/brake-filters";
import { DEFAULT_TABLE_WIDTH } from "../../../../theme/sizes";

const tableStyles = {
  minWidth: 500,
  maxWidth: DEFAULT_TABLE_WIDTH,
  margin: "0 auto",
};

interface BrakeListProps extends WithPageLoaderProps {}

const BrakeList = ({ finishLoading }: BrakeListProps) => {
  const brakeReducer = useSelector((state: Store) => state.brake);
  const { loading, size, sort, filters } = brakeReducer;
  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const isActive = filters.active;

  const init = async () => {
    await Promise.all([
      dispatch(
        getBrakes({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      await dispatch(editBrake({ id: pickedId, active: !isActive }));
      dispatch(
        getBrakes({ params: { size, page: 0, sort, filters }, reload: true })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const getBrakeName = () =>
    brakeReducer.content?.find((brake) => brake.id === pickedId)?.label;

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.BrakeEdit}/${id}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderActionsCell = (brake: Brake) => (
    <MenuActions
      key={`${brake.id}-actions-cell`}
      actions={actions}
      entityId={brake.id}
    />
  );

  const columns: Columns<Brake> = [
    { id: "label", accessor: "label", flexGrow: 10, name: t("nazwa") },
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const table = (
    <PaginationTable<Brake>
      withPagination={true}
      loading={loading}
      fetcherAction={getBrakes}
      reducerName="brake"
      columns={columns}
    />
  );

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("dezaktywacja_blokady_pytanie")
            : t("aktywacja_blokady_pytanie")}{" "}
          <strong>({getBrakeName()})</strong>
        </Typography>
      </ConfirmationDialog>
      <Section title={t("blokady")} actions={<BrakeFilters />} sx={tableStyles}>
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(BrakeList);
