import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  getModuleDependantPriceItems,
  getPriceItems,
} from "../../../redux/actions/price-actions";
import MenuActions, {
  Action,
} from "../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../constants/navigation-constants";
import {
  ModuleDependantPriceItem,
  PriceItem,
} from "../../../types/price-types";
import { Columns } from "../../../components/pagination-table/table-types";
import PaginationTable from "../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import Section from "../../../components/section/section";
import { Typography, useTheme } from "@mui/material";
import { getStyles } from "./price-list.styles";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { formatPrice } from "../../../utils/formatter-utils";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import GlobalPriceChangeDialog from "./global-price-change-dialog/global-price-change-dialog";
import { getAllCovers } from "../../../redux/actions/cover-actions";

interface PriceListProps extends WithPageLoaderProps {}

const PriceList = ({ finishLoading }: PriceListProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const { priceItems, moduleDependantPriceItems } = useSelector(
    (state: Store) => state.price
  );

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await Promise.all([
      dispatch(getPriceItems(true)),
      dispatch(getModuleDependantPriceItems(true)),
      dispatch(getAllCovers({})),
    ]);
    finishLoading();
  };

  const basicActions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.PriceItemEdit}/${id}`);
      },
    },
  ];

  const renderNameCell = (priceItem: PriceItem) => (
    <Typography key={`${priceItem.id}-name-cell`}>
      {t(priceItem.name)}
    </Typography>
  );

  const renderPriceCell = (priceItem: PriceItem) => (
    <Typography key={`${priceItem.id}-price-cell`}>
      {formatPrice(priceItem.price.value)} €
    </Typography>
  );

  const renderBasicActionsCell = (priceItem: PriceItem) => (
    <MenuActions
      key={`${priceItem.id}-actions-cell`}
      actions={basicActions}
      entityId={priceItem.id}
    />
  );

  const basicColumns: Columns<PriceItem> = [
    {
      id: "name",
      cellRenderer: renderNameCell,
      flexGrow: 10,
      name: t("nazwa"),
    },
    {
      id: "price",
      cellRenderer: renderPriceCell,
      flexGrow: 10,
      name: t("__cena"),
    },
    {
      id: "actions",
      cellRenderer: renderBasicActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const renderBrakePriceCell = (priceItem: ModuleDependantPriceItem) => (
    <Typography key={`${priceItem.id}-brake-price-cell`}>
      {formatPrice(priceItem.brakePrice.value)} €
    </Typography>
  );

  const renderMontagePriceCell = (priceItem: ModuleDependantPriceItem) => (
    <Typography key={`${priceItem.id}-montage-price-cell`}>
      {formatPrice(priceItem.montagePrice.value)} €
    </Typography>
  );

  const mdActions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.ModuleDependantPriceItemEdit}/${id}`);
      },
    },
  ];

  const renderMdActionsCell = (priceItem: ModuleDependantPriceItem) => (
    <MenuActions
      key={`${priceItem.id}-md-actions-cell`}
      actions={mdActions}
      entityId={priceItem.id}
    />
  );

  const moduleDependantColumns: Columns<ModuleDependantPriceItem> = [
    {
      id: "module-count",
      accessor: "moduleCount",
      flexGrow: 10,
      name: t("__liczba_modulow"),
    },
    {
      id: "brake-price",
      cellRenderer: renderBrakePriceCell,
      flexGrow: 10,
      name: t("__cena_blokady"),
    },
    {
      id: "montage-price",
      cellRenderer: renderMontagePriceCell,
      flexGrow: 10,
      name: t("__cena_montazu"),
    },
    {
      id: "actions",
      cellRenderer: renderMdActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const priceItemTable = (
    <PaginationTable<PriceItem>
      withPagination={false}
      columns={basicColumns}
      data={priceItems}
    />
  );

  const moduleDependantPriceItemTable = (
    <PaginationTable<ModuleDependantPriceItem>
      withPagination={false}
      columns={moduleDependantColumns}
      data={moduleDependantPriceItems}
    />
  );

  const onAddClick = () => navigate(Route.ModuleDependantPriceItemEdit);

  const priceItemTableActions = (
    <Button
      variant="contained"
      onClick={() => setOpen(true)}
      endIcon={<CurrencyExchangeIcon />}
    >
      {t("__globalna_zmiana")}
    </Button>
  );

  const moduleDependantPriceItemTableActions = (
    <Button
      variant="contained"
      onClick={onAddClick}
      endIcon={<AddCircleIcon />}
    >
      {t("dodaj")}
    </Button>
  );

  return (
    <>
      <Box sx={styles.container}>
        <Section
          title={t("__ceny_podstawowe")}
          sx={styles.basicTable}
          actions={priceItemTableActions}
        >
          {priceItemTable}
        </Section>
        <Section
          title={t("__ceny_moduly")}
          sx={styles.basicTable}
          actions={moduleDependantPriceItemTableActions}
        >
          {moduleDependantPriceItemTable}
        </Section>
      </Box>
      <GlobalPriceChangeDialog open={isOpen} onCancel={() => setOpen(false)} />
    </>
  );
};

export default withPageLoader(PriceList);
