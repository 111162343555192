import React from "react";
import { useSelector } from "react-redux";
import { Store } from "../../../redux/store";
import { Navigate, useLocation } from "react-router-dom";
import { Route } from "../../../constants/navigation-constants";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { user, loading } = useSelector((state: Store) => state.auth);
  const location = useLocation();

  if (!user) {
    const from = loading === "succeeded" ? Route.Layout : location;
    return <Navigate to={Route.Login} state={{ from }} />;
  }

  return children;
};

export default PrivateRoute;
