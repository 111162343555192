import { Theme } from "@mui/material";

export const getStyles = (theme: Theme, isMobile: boolean) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: isMobile ? "flex-start" : "center",
    width: "100%",
    height: "100vh",
    background: `linear-gradient(90deg, ${theme.palette.grey["300"]} 53%, ${theme.palette.grey["200"]} 47%)`,
    paddingTop: isMobile ? 4 : 0,
  },
  loader: {
    placeSelf: "center",
  },
});
