import { createAsyncThunk } from "@reduxjs/toolkit";
import { flatPageParams, preparePageForAction } from "../redux-utils";
import { PageParams, PageResponse } from "../../types/common-types";
import axios from "axios";
import { CreatedBy } from "../slices/user-offer-search-slice";

export const getUsersOfferSearch = createAsyncThunk(
  "user/getUsersOfferSearch",
  async ({ params }: PageParams) => {
    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<CreatedBy>>(
      "/user/offer-search",
      {
        params: flatParams,
      }
    );
    return preparePageForAction<CreatedBy>(data, params);
  }
);

export const fetchNextPageUsersOfferSearch = createAsyncThunk(
  "user/fetchNextPageUsersOfferSearch",
  async ({ params }: PageParams) => {
    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<CreatedBy>>(
      "/user/offer-search",
      {
        params: flatParams,
      }
    );
    return preparePageForAction<CreatedBy>(data, params);
  }
);
