import { Theme } from "@mui/material";

export const getStyles = (theme: Theme) => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  newChip: {
    cursor: "pointer",
    transition: "color 200ms, border-color 200ms",
    "&:hover": {
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
    },
  },
  updateChip: {
    cursor: "pointer",
    transition: "color 200ms, border-color 200ms",
    "&:hover": {
      color: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
  },
});
