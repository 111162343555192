import { Loading, Pageable, WithId } from "../../types/common-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNextPageUsersOfferSearch,
  getUsersOfferSearch,
} from "../actions/user-offer-search-actions";
import { updateStateWithPageable } from "../redux-utils";

export interface CreatedBy extends WithId {
  name: string;
}

export interface UserOfferSearchState extends Pageable<CreatedBy> {
  loading: Loading;
  users: CreatedBy[];
}

export const DEFAULT_USER_SEARCH_FILTERS = {
  name: "",
};

const initialState: UserOfferSearchState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: [],
  filters: DEFAULT_USER_SEARCH_FILTERS,
  users: [],
  size: 5,
};

const userOfferSearchSlice = createSlice({
  name: "userOfferSearchSlice",
  initialState,
  reducers: {
    reset(state) {
      state.users = initialState.users;
      state.loading = initialState.loading;
      state.sort = initialState.sort;
      state.page = initialState.page;
      state.size = initialState.size;
      state.content = initialState.content;
      state.filters = initialState.filters;
      state.totalPages = initialState.totalPages;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersOfferSearch.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUsersOfferSearch.fulfilled, (state, action) => {
      updateStateWithPageable(state, action.payload);
      if (action.payload.content) {
        state.users = action.payload.content;
      }
      state.loading = "succeeded";
    });
    builder.addCase(getUsersOfferSearch.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(fetchNextPageUsersOfferSearch.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(
      fetchNextPageUsersOfferSearch.fulfilled,
      (state, action) => {
        updateStateWithPageable(state, action.payload);
        if (action.payload.content) {
          state.users.push(...action.payload.content);
        }
        state.loading = "succeeded";
      }
    );
    builder.addCase(fetchNextPageUsersOfferSearch.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default userOfferSearchSlice.reducer;
export const { reset } = userOfferSearchSlice.actions;
