import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Filters,
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import {
  Color,
  ColorPatchRequest,
  ColorPostRequest,
} from "../../types/color-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { DEFAULT_COLOR_SORT } from "../slices/color-slice";

export const getColors = createAsyncThunk(
  "colors/getColors",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { color } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, color)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Color>>("/color", {
      params: flatParams,
    });

    return preparePageForAction<Color>(data, params);
  }
);

export const getAllColors = createAsyncThunk(
  "colors/getAllColors",
  async (config: { active?: boolean }) => {
    const filters: Filters = {};

    if (config.active) {
      filters.active = config.active;
    }

    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort: DEFAULT_COLOR_SORT,
      filters,
    };

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Color>>("/color", {
      params: flatParams,
    });
    return data.content;
  }
);

export const getColor = createAsyncThunk(
  "colors/getColor",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Color>>(`/color`, {
      params: { id },
    });
    return getFirstElementFromPage<Color>(data);
  }
);

export const addColor = createAsyncThunk(
  "colors/addColor",
  async (params: ColorPostRequest, thunkAPI) => {
    const { data } = await axios.post<Color>("/color", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editColor = createAsyncThunk(
  "colors/editColor",
  async (params: ColorPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Color>("/color", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
