import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { EnhancedStore, AnyAction } from "@reduxjs/toolkit";
import { setAlert } from "./redux/slices/alert-slice";
import qs from "qs";
import { setSessionTimer } from "./redux/slices/auth-slice";
import { Dispatch } from "react";
import i18next from "./i18n";
import { logout } from "./redux/actions/auth-actions";

type Error = AxiosError<{ code: string }>;

const EXCLUDED_URLS = ["authorizationFailure", "user/whoami"];

const showError = (
  dispatch: Dispatch<AnyAction>,
  message: string,
  isFatal?: boolean
) => {
  dispatch(setAlert({ message, variant: "error", isFatal }));
};

const shouldIgnoreError = (error: Error, status?: number) =>
  EXCLUDED_URLS.some((url) =>
    error.response?.request?.responseURL.includes(url)
  ) && status !== 500;

const handleError = (
  dispatch: Dispatch<any>,
  error: Error,
  status?: number
) => {
  switch (status) {
    case 0:
      showError(dispatch, "blad_uwierzytelniania");
      dispatch(logout());
      break;
    case 400:
      showError(dispatch, "niepoprawne_dane");
      break;
    case 401:
      showError(dispatch, "blad_uwierzytelniania");
      dispatch(logout());
      break;
    case 403:
      showError(dispatch, "niepoprawne_dane");
      break;
    case 404:
      showError(dispatch, "nieodnaleziono");
      break;
    case 500:
    default:
      showError(dispatch, error.response?.data?.code || "500", true);
      break;
  }
};

const refreshSession = (store: EnhancedStore<any, any, any>) => {
  const { dispatch } = store;
  if (store.getState().auth.user) {
    clearTimeout(store.getState().auth.sessionTimerId);
    const sessionTimerId = setTimeout(() => {
      dispatch(logout());
    }, Number(process.env.REACT_APP_SESSION_TIME_SEC) * 1000);
    dispatch(setSessionTimer({ sessionTimerId }));
  }
};

const interceptRequestFulfill = (
  request: AxiosRequestConfig,
  store: EnhancedStore<any, AnyAction, any>
) => {
  refreshSession(store);

  request.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  };
  request.headers = request.headers
    ? { ...request.headers, "Accept-Language": i18next.resolvedLanguage }
    : { "Accept-Language": i18next.resolvedLanguage };

  return Promise.resolve(request);
};

const interceptResponseReject = (
  error: Error,
  store: EnhancedStore<any, AnyAction, any>
) => {
  const { dispatch } = store;
  const status = error.response?.status;
  const ignore = shouldIgnoreError(error, status);

  if (!ignore) {
    handleError(dispatch, error, status);
  }

  return Promise.reject(error);
};

export const setupAxiosConfig = (store: EnhancedStore<any, AnyAction, any>) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_PREFIX;
  axios.interceptors.request.use(
    (request) => interceptRequestFulfill(request, store),
    (error) => error
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => interceptResponseReject(error, store)
  );
};
