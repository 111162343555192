import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { CURRENCIES } from "../../../../../../types/price-types";
import MenuItem from "@mui/material/MenuItem";
import NumericInput from "../../../../../../components/numeric-input/numeric-input";
import {
  calculateFinal,
  getCommonProps,
  getInputProps,
} from "../billing-info.utils";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "../billing-info.styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../../redux/store";
import useDisableAll from "../../../../../../hooks/useDisableAll";
import {
  BasicClientPrice,
  ClientPrice,
  DEFAULT_CLIENT_PRICE,
  setClientPrice,
} from "../../../../../../redux/slices/order-slice";
import useUpdateEffect from "../../../../../../hooks/useUpdateEffect";

const BasicClientPrices = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();

  const { response: calculation } = useSelector(
    (state: Store) => state.order.calculation
  );

  const { validation, currentOrder } = useSelector(
    (state: Store) => state.order
  );

  const { clientDelivery, clientPrice } = currentOrder;

  useUpdateEffect(() => {
    if (!calculation || !clientDelivery) {
      resetClientPrice();
    }
  }, [calculation, clientDelivery]);

  const resetClientPrice = () => {
    dispatch(setClientPrice(DEFAULT_CLIENT_PRICE));
  };

  const COMMON_PROPS = getCommonProps(
    !!calculation || !!currentOrder.payment,
    disableAll,
    clientDelivery
  );

  const handleCurrencyChange = (value: string) => {
    const price: ClientPrice = {
      ...clientPrice,
      currency: CURRENCIES.find((x) => x.code === value)!,
    };
    dispatch(setClientPrice(price));
  };

  const handleVatRateChange = (value: string) => {
    const vatRate = Number(value);
    const final = Number(clientPrice.final);
    const finalWithVat = final * (1 + vatRate / 100);
    const price: BasicClientPrice = {
      base: clientPrice.base,
      discount: clientPrice.discount,
      final: clientPrice.final,
      vatRate: value,
      finalWithVat: finalWithVat.toString(),
      currency: clientPrice.currency,
    };
    dispatch(setClientPrice(price));
  };

  const handleDiscountChange = (value: string) => {
    const base = Number(clientPrice.base);
    const discount = Number(value);
    const vatRate = Number(clientPrice.vatRate);
    const [final, finalWithVat] = calculateFinal(
      base,
      discount,
      vatRate,
      clientPrice.final
    );
    const price: BasicClientPrice = {
      base: clientPrice.base,
      discount: value,
      final,
      vatRate: clientPrice.vatRate,
      finalWithVat,
      currency: clientPrice.currency,
    };
    dispatch(setClientPrice(price));
  };

  const handleBaseChange = (value: string) => {
    const base = Number(value);
    const discount = Number(clientPrice.discount);
    const vatRate = Number(clientPrice.vatRate);
    const [final, finalWithVat] = calculateFinal(
      base,
      discount,
      vatRate,
      clientPrice.final
    );
    const price: BasicClientPrice = {
      base: value,
      discount: clientPrice.discount,
      final,
      vatRate: clientPrice.vatRate,
      finalWithVat,
      currency: clientPrice.currency,
    };
    dispatch(setClientPrice(price));
  };

  const handleFinalChange = (value: string) => {
    const discount = Number(clientPrice.discount);
    const final = Number(value);
    const base = final / (1 - discount / 100);
    const vatRate = Number(clientPrice.vatRate);
    const finalWithVat = final * (1 + vatRate / 100);
    const price: BasicClientPrice = {
      base: isNaN(base) ? clientPrice.base.toString() : base.toString(),
      discount: clientPrice.discount,
      final: value,
      vatRate: clientPrice.vatRate,
      finalWithVat: finalWithVat.toString(),
      currency: clientPrice.currency,
    };
    dispatch(setClientPrice(price));
  };

  const handleFinalWithVatChange = (value: string) => {
    const finalWithVat = Number(value);
    const vatRate = Number(clientPrice.vatRate);
    const final = finalWithVat / (1 + vatRate / 100);
    const discount = Number(clientPrice.discount);
    const base = final / (1 - discount / 100);

    const price: BasicClientPrice = {
      base: isNaN(base) ? clientPrice.base.toString() : base.toString(),
      discount: clientPrice.discount,
      final: isNaN(final) ? clientPrice.final.toString() : final.toString(),
      vatRate: vatRate.toString(),
      finalWithVat: value,
      currency: clientPrice.currency,
    };
    dispatch(setClientPrice(price));
  };

  return (
    <Box sx={styles.fields}>
      <Box sx={styles.field}>
        <FormControl fullWidth>
          <InputLabel>{t("__waluta_klienta")}</InputLabel>
          <Select
            {...COMMON_PROPS}
            value={clientPrice.currency.code}
            label={t("__waluta_klienta")}
            onChange={(e) => handleCurrencyChange(e.target.value)}
            size="small"
          >
            {CURRENCIES.sort((a, b) => t(a.code).localeCompare(t(b.code))).map(
              ({ code, symbol }) => (
                <MenuItem key={code} value={code}>
                  {`${t(code)} (${symbol})`}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__stawka_vat")}
          value={clientPrice.vatRate}
          onChange={(e) => handleVatRateChange(e.target.value)}
          InputProps={getInputProps("%")}
          required={clientDelivery}
          error={!!validation && !!validation["clientPrice.vatRate"]}
          helperText={!!validation && validation["clientPrice.vatRate"]}
        />
      </Box>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__rabat_dla_klienta")}
          value={clientPrice.discount}
          onChange={(e) => handleDiscountChange(e.target.value)}
          InputProps={getInputProps("%")}
          required={clientDelivery}
          error={!!validation && !!validation["clientPrice.discount"]}
          helperText={!!validation && validation["clientPrice.discount"]}
        />
      </Box>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__cena_dla_klienta_przed_rabatem")}
          value={clientPrice.base}
          onChange={(e) => handleBaseChange(e.target.value)}
          InputProps={getInputProps(clientPrice.currency.symbol)}
          required={clientDelivery}
          error={!!validation && !!validation["clientPrice.base"]}
          helperText={!!validation && validation["clientPrice.base"]}
          isMoney
        />
      </Box>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__cena_dla_klienta_po_rabacie")}
          value={clientPrice.final}
          onChange={(e) => handleFinalChange(e.target.value)}
          InputProps={getInputProps(clientPrice.currency.symbol)}
          required={clientDelivery}
          error={!!validation && !!validation["clientPrice.final"]}
          helperText={!!validation && validation["clientPrice.final"]}
          isMoney
        />
      </Box>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__cena_brutto")}
          value={clientPrice.finalWithVat}
          onChange={(e) => handleFinalWithVatChange(e.target.value)}
          InputProps={getInputProps(clientPrice.currency.symbol)}
          required={clientDelivery}
          error={!!validation && !!validation["clientPrice.finalWithVat"]}
          helperText={!!validation && validation["clientPrice.finalWithVat"]}
          isMoney
        />
      </Box>
    </Box>
  );
};

export default BasicClientPrices;
