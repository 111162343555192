import {
  Cover,
  CoverPatchRequest,
  CoverPostRequest,
} from "../../../types/cover-types";
import { FormValues } from "./cover-edit.types";
import {
  mapClapEnumsToClaps,
  mapDoorEnumsToDoors,
  mapFormValuesToComponentsAvailability,
} from "../../config-pages/category-page/category-edit/category-edit.utils";
import { TFunction } from "react-i18next";
import * as yup from "yup";
import { Language } from "../../../types/language-types";
import {
  TranslationPatchRequest,
  TranslationPostRequest,
} from "../../../types/translation-types";
import { File } from "../../../types/file-types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  return yup.object({
    polishName: yup.string().required(requiredFieldError),
    englishName: yup.string().required(requiredFieldError),
    germanName: yup.string().required(requiredFieldError),
    swedenName: yup.string().required(requiredFieldError),
    active: yup.boolean(),
    rank: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
    profileId: yup.string().required(requiredFieldError),
    categoryId: yup.string().required(requiredFieldError),
    railCount: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
    defaultPhoto: yup.string().required(requiredFieldError),
    defaultWallIncrease: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
  });
};

const formatPhoto = (photo: string): File | undefined => {
  if (!photo) {
    return { base64: null };
  }
  if (!photo.includes("base64")) {
    return undefined;
  }
  return { base64: photo };
};

export const mapFormValuesToCoverPostRequest = (
  values: FormValues
): CoverPostRequest => {
  const labelTranslations: TranslationPostRequest[] = [];

  labelTranslations.push({ langIso: "pl", value: values.polishName });
  labelTranslations.push({ langIso: "en", value: values.englishName });
  labelTranslations.push({ langIso: "de", value: values.germanName });
  labelTranslations.push({ langIso: "sv", value: values.swedenName });

  return {
    categoryId: Number(values.categoryId),
    componentsAvailability: mapFormValuesToComponentsAvailability(values),
    defaultPhoto: formatPhoto(values.defaultPhoto),
    doorPhoto: formatPhoto(values.doorPhoto),
    flapPhoto: formatPhoto(values.flapPhoto),
    secondaryDoorPhoto: formatPhoto(values.secondaryDoorPhoto),
    secondaryFlapPhoto: formatPhoto(values.secondaryFlapPhoto),
    labelTranslations,
    profileId: Number(values.profileId),
    railCount: Number(values.railCount),
    rank: Number(values.rank),
    defaultWallIncrease: Number(values.defaultWallIncrease),
  };
};

const findLabelId = (cover: Cover, lang: Language): number =>
  cover.labelTranslations.find((translation) => translation.langIso === lang)!
    .id;

export const mapFormValuesToCoverPatchRequest = (
  values: FormValues,
  cover: Cover
): CoverPatchRequest => {
  const labelTranslations: TranslationPatchRequest[] = [];

  labelTranslations.push(
    {
      id: findLabelId(cover, "pl"),
      langIso: "pl",
      value: values.polishName,
    },
    {
      id: findLabelId(cover, "en"),
      langIso: "en",
      value: values.englishName,
    },
    {
      id: findLabelId(cover, "de"),
      langIso: "de",
      value: values.germanName,
    },
    {
      id: findLabelId(cover, "sv"),
      langIso: "sv",
      value: values.swedenName,
    }
  );

  return {
    id: cover.id,
    active: values.active,
    categoryId: Number(values.categoryId),
    componentsAvailability: {
      id: cover.componentsAvailability.id,
      ...mapFormValuesToComponentsAvailability(values),
    },
    defaultPhoto: formatPhoto(values.defaultPhoto),
    doorPhoto: formatPhoto(values.doorPhoto),
    flapPhoto: formatPhoto(values.flapPhoto),
    secondaryDoorPhoto: formatPhoto(values.secondaryDoorPhoto),
    secondaryFlapPhoto: formatPhoto(values.secondaryFlapPhoto),
    labelTranslations,
    profileId: Number(values.profileId),
    railCount: Number(values.railCount),
    rank: Number(values.rank),
    defaultWallIncrease: Number(values.defaultWallIncrease),
  };
};

const findNameTranslation = (cover: Cover, lang: Language) =>
  cover.labelTranslations.find((translation) => translation.langIso === lang)!
    .value;

const mapCoverToFormValues = (cover: Cover): FormValues => ({
  active: cover.active,
  polishName: findNameTranslation(cover, "pl"),
  germanName: findNameTranslation(cover, "de"),
  englishName: findNameTranslation(cover, "en"),
  swedenName: findNameTranslation(cover, "sv"),
  rank: cover.rank.toString(),
  doors: mapDoorEnumsToDoors(cover.componentsAvailability.doors),
  claps: mapClapEnumsToClaps(cover.componentsAvailability.claps),
  fillings: cover.componentsAvailability.fillings,
  colors: cover.componentsAvailability.colors,
  brakes: cover.componentsAvailability.brakes,
  fillingsInherited: cover.componentsAvailability.fillingsInherited,
  doorsInherited: cover.componentsAvailability.doorsInherited,
  colorsInherited: cover.componentsAvailability.colorsInherited,
  brakesInherited: cover.componentsAvailability.brakesInherited,
  clapsInherited: cover.componentsAvailability.clapsInherited,
  categoryId: cover.categoryId.toString(),
  profileId: cover.profileId.toString(),
  railCount: cover.railCount.toString(),
  defaultPhoto: cover.defaultPhoto?.id?.toString() || "",
  doorPhoto: cover.doorPhoto?.id?.toString() || "",
  flapPhoto: cover.flapPhoto?.id?.toString() || "",
  secondaryDoorPhoto: cover.secondaryDoorPhoto?.id?.toString() || "",
  secondaryFlapPhoto: cover.secondaryFlapPhoto?.id?.toString() || "",
  defaultWallIncrease: cover.defaultWallIncrease.toString(),
});

const getDefaultFormValues = (): FormValues => ({
  active: true,
  englishName: "",
  germanName: "",
  polishName: "",
  swedenName: "",
  rank: "",
  brakes: [],
  colors: [],
  fillings: [],
  doors: [],
  claps: [],
  brakesInherited: true,
  colorsInherited: true,
  doorsInherited: true,
  fillingsInherited: true,
  clapsInherited: true,
  profileId: "",
  categoryId: "",
  railCount: "",
  flapPhoto: "",
  doorPhoto: "",
  secondaryFlapPhoto: "",
  secondaryDoorPhoto: "",
  defaultPhoto: "",
  defaultWallIncrease: "",
});

export const getInitFormValues = (cover: Cover | null): FormValues =>
  cover ? mapCoverToFormValues(cover) : getDefaultFormValues();
