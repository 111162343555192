import { Loading } from "../../types/common-types";
import { ModuleDependantPriceItem, PriceItem } from "../../types/price-types";
import { createSlice } from "@reduxjs/toolkit";
import {
  addModuleDependantPriceItem,
  editModuleDependantPriceItem,
  editPriceItem,
  getModuleDependantPriceItems,
  getPriceItems,
  updatePricesGlobally,
} from "../actions/price-actions";

export interface PriceState {
  loading: Loading;
  priceItems: PriceItem[] | null;
  moduleDependantPriceItems: ModuleDependantPriceItem[] | null;
}

const initialState: PriceState = {
  loading: "idle",
  priceItems: null,
  moduleDependantPriceItems: null,
};

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPriceItems.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPriceItems.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.priceItems = action.payload;
      }
      state.loading = "succeeded";
    });
    builder.addCase(getPriceItems.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editPriceItem.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editPriceItem.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editPriceItem.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getModuleDependantPriceItems.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getModuleDependantPriceItems.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.moduleDependantPriceItems = action.payload;
      }
      state.loading = "succeeded";
    });
    builder.addCase(getModuleDependantPriceItems.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addModuleDependantPriceItem.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addModuleDependantPriceItem.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addModuleDependantPriceItem.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editModuleDependantPriceItem.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editModuleDependantPriceItem.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editModuleDependantPriceItem.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(updatePricesGlobally.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updatePricesGlobally.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(updatePricesGlobally.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default priceSlice.reducer;
