import React from "react";
import Section from "../../../../../components/section/section";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useDisableAll from "../../../../../hooks/useDisableAll";
import { getStyles } from "./billing-info.styles";
import BucoversClientPrices from "./bucovers-client-prices/bucovers-client-prices";
import BasicClientPrices from "./basic-client-prices/basic-client-prices";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { setDetailedPrice } from "../../../../../redux/slices/order-slice";
import DetailedClientPrices from "./detailed-client-prices/detailed-client-prices";
import Divider from "@mui/material/Divider";

const BillingInfo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();

  const { isDetailedPrice } = useSelector(
    (state: Store) => state.order.currentOrder.clientPrice
  );

  const actions = (
    <FormControlLabel
      disabled={disableAll}
      control={
        <Checkbox
          checked={isDetailedPrice}
          onChange={(value) => dispatch(setDetailedPrice(value.target.checked))}
        />
      }
      label={t("__rozbicie_ceny")}
    />
  );

  return (
    <Section
      sx={styles.section}
      title={t("__szczegoly_platnosci")}
      actions={actions}
    >
      <BucoversClientPrices />
      <Divider sx={styles.divider} />
      {isDetailedPrice ? <DetailedClientPrices /> : <BasicClientPrices />}
    </Section>
  );
};

export default BillingInfo;
