import React, { useEffect } from "react";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../redux/store";
import {
  deactivatePosts,
  getPostsForPopup,
} from "../../redux/actions/post-actions";
import PostItem from "../../pages/post-pages/post-overview-page/post-overview-list/post-item/post-item";
import { Colors } from "../../theme/colors";

const getStyles = () => ({
  dialog: {
    "& .MuiDialogContent-root": {
      backgroundColor: Colors.Body,
    },
  },
});

const PostPopup = () => {
  const { postsForPopup } = useSelector((state: Store) => state.post);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const styles = getStyles();

  useEffect(() => {
    dispatch(getPostsForPopup());
  }, []);

  if (!postsForPopup) {
    return null;
  }

  const onClose = () => {
    const ids = postsForPopup.map((post) => post.id);
    dispatch(deactivatePosts(ids));
  };

  const renderContent = () =>
    postsForPopup.map((post) => <PostItem post={post} />);

  return (
    <ConfirmationDialog
      open={postsForPopup.length > 0}
      title={t("aktualnosci")}
      sx={styles.dialog}
      secondaryText={t("zamknij")}
      onSecondary={onClose}
    >
      {postsForPopup && renderContent()}
    </ConfirmationDialog>
  );
};

export default PostPopup;
