import { useSelector } from "react-redux";
import { Store } from "../redux/store";

const useRole = () => {
  const { user, roles } = useSelector((state: Store) => state.auth);

  const userRole = roles.find((role) => role.id === user?.roleId);

  if (!userRole) {
    throw new Error("Role is missing");
  }

  return userRole;
};

export default useRole;
