import React, { ReactNode } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type Color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";

interface ConfirmationDialogProps {
  open: boolean;
  onPrimary?: () => void;
  onSecondary?: () => void;
  primaryText?: string;
  secondaryText?: string;
  title?: string;
  children: ReactNode;
  loading?: boolean;
  sx?: SxProps<Theme>;
  renderTitle?: () => ReactNode;
  disablePrimary?: boolean;
  secondaryColor?: Color;
  onClose?: () => void;
}

const ConfirmationDialog = ({
  children,
  onPrimary,
  onSecondary,
  open,
  title,
  primaryText,
  secondaryText,
  loading,
  sx,
  renderTitle,
  disablePrimary,
  secondaryColor,
  onClose,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  const getTitle = () => {
    if (renderTitle) {
      return renderTitle();
    }

    if (title) {
      return t(title);
    }

    return "";
  };

  return (
    <Dialog open={open} disableEscapeKeyDown sx={sx} onClose={() => {}}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {getTitle()}
        {onClose && (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {onSecondary && secondaryText && (
          <LoadingButton
            variant="outlined"
            onClick={onSecondary}
            loading={loading}
            color={secondaryColor || "primary"}
          >
            {t(secondaryText)}
          </LoadingButton>
        )}
        {onPrimary && primaryText && (
          <LoadingButton
            variant="contained"
            onClick={onPrimary}
            loading={loading}
            disabled={disablePrimary}
          >
            {t(primaryText)}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
