import { createAsyncThunk } from "@reduxjs/toolkit";
import { PageParams, PageResponse } from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import {
  User,
  UserPatchRequest,
  UserPostRequest,
} from "../../types/user-types";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import { DEFAULT_USER_FILTERS } from "../slices/user-slice";

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { user } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, user)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const partnerFilter =
      flatParams.partnerId === DEFAULT_USER_FILTERS.partnerId
        ? undefined
        : flatParams.partnerId;

    const roleFilter =
      flatParams.roleId === DEFAULT_USER_FILTERS.roleId
        ? undefined
        : [flatParams.roleId];

    const adjustedParams = {
      ...flatParams,
      "partner.id": partnerFilter,
      roleId: roleFilter,
    };

    const { data } = await axios.get<PageResponse<User>>("/user", {
      params: adjustedParams,
    });
    return preparePageForAction<User>(data, params);
  }
);

export const getUser = createAsyncThunk("user/getUser", async (id: string) => {
  const { data } = await axios.get<PageResponse<User>>("/user", {
    params: { id },
  });
  return getFirstElementFromPage<User>(data);
});

export const addUser = createAsyncThunk(
  "user/addUser",
  async (params: UserPostRequest, thunkAPI) => {
    const { data } = await axios.post<User>("/user", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editUser = createAsyncThunk(
  "user/editUser",
  async (params: UserPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<User>("/user", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
