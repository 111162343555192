import { Theme } from "@mui/material";
import { toRem } from "../../../utils/common-utils";

export const getStyles = (theme: Theme, isTablet: boolean) => ({
  fields: {
    width: isTablet ? "90%" : "60%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(isTablet ? 1 : 3),
  },
  prompt: {
    fontSize: toRem(16),
    fontWeight: 300,
    color: theme.palette.grey["500"],
    marginBottom: isTablet ? 0.5 : 3,
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(2),
  },
  button: {
    width: 150,
    marginTop: isTablet ? 0.5 : 3,
  },
});
