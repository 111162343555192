import { DoorEnum } from "../constants/door-constants";
import { ClapEnum } from "../constants/claps-constants";
import { Client } from "../redux/slices/order-slice";
import { WithId } from "./common-types";
import { User } from "./user-types";
import { Filling } from "./filling-types";
import { Color } from "./color-types";
import { Brake } from "./brake-types";
import { Cover } from "./cover-types";
import { Address, Partner } from "./partner-types";
import { Category } from "./category-types";
import { Profile } from "./profile-types";
import { Variant } from "./variant-types";

export enum OrderStatus {
  OFFER = "OFFER",
  WAITING_FOR_ACCEPT = "WAITING_FOR_ACCEPT",
  ACCEPTED = "ACCEPTED",
  DELIVERED = "DELIVERED",
  CANCELED = "CANCELED",
  DELETED = "DELETED",
  EXPIRED = "EXPIRED",
}

export enum RailPosition {
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

export interface Payment {
  retailPrice: number;
  partnerPrice: number;
  clientPrice?: number;
  clientDiscount?: number;
  transportPrice: number;
  montagePrice: number;
  doorPrice: number;
  doorPriceDiscount: number;
  brakePrice: number;
  brakePriceDiscount: number;
  variantPrice: number;
  variantPriceDiscount: number;
  railExtensionPrice: number;
  railExtensionPriceDiscount: number;
  partnerDiscount: number;
  partnerDiscounted: number;
  clientCurrency: string;
  clientVat: number;
  boxMontagePrice: number;
  transportClientPrice?: number;
  transportClientPriceDiscount?: number;
  montageClientPrice?: number;
  montageClientPriceDiscount?: number;
  doorClientPrice?: number;
  doorClientPriceDiscount?: number;
  brakeClientPrice?: number;
  brakeClientPriceDiscount?: number;
  variantClientPrice?: number;
  variantClientPriceDiscount?: number;
  railExtensionClientPrice?: number;
  railExtensionClientPriceDiscount?: number;
  boxMontageClientPrice?: number;
  boxMontageClientPriceDiscount?: number;
}

export interface Order extends WithId {
  createdBy: User;
  createdDate: string;
  status: OrderStatus;
  modulesCount: number;
  widthExt: number;
  widthInt: number;
  length: number;
  height: number;
  secondaryHeight?: number;
  railLength: number;
  wallIncrease: number;
  doorType: DoorEnum[];
  clapType: ClapEnum[];
  filling: Filling;
  color: Color;
  brake: Brake;
  brakeKey: boolean;
  model: Cover;
  client?: Client;
  partner: Partner;
  transportAddress: Address;
  payment: Payment;
  withMontage: boolean;
  withTransport: boolean;
  term: string;
  distance?: number;
  floorBase: string;
  category: Category;
  profile: Profile;
  variant: Variant;
  railPosition: RailPosition;
  box: boolean;
}

export interface OrderPostRequest {
  variantId: number;
  status: OrderStatus;
  modulesCount: number;
  widthExt: number;
  widthInt: number;
  length: number;
  height: number;
  secondaryHeight?: number;
  railLength: number;
  wallIncrease: number;
  doorType: DoorEnum[];
  clapType: ClapEnum[];
  fillingId: number;
  colorId: number;
  brakeId: number;
  brakeKey: boolean;
  client?: Client;
  distance?: number;
  withMontage: boolean;
  withTransport: boolean;
  partnerId?: number;
  term?: string;
  price: number;
  clientPrice?: number;
  clientDiscount?: number;
  floorBase: string;
  railPosition?: RailPosition;
  box?: boolean;
  clientCurrency: string;
  clientVat: number;
  detailedPrices: boolean;
  transportClientPrice?: number;
  transportClientPriceDiscount?: number;
  montageClientPrice?: number;
  montageClientPriceDiscount?: number;
  doorClientPrice?: number;
  doorClientPriceDiscount?: number;
  brakeClientPrice?: number;
  brakeClientPriceDiscount?: number;
  variantClientPrice?: number;
  variantClientPriceDiscount?: number;
  railExtensionClientPrice?: number;
  railExtensionClientPriceDiscount?: number;
  boxMontageClientPrice?: number;
  boxMontageClientPriceDiscount?: number;
}

export type OrderPatchRequest = Partial<OrderPostRequest> & WithId;

export interface AvailablePrices {
  transportWithMontage: number;
  transport: number;
  door: number;
  montage: Record<string, number>;
  brake: Record<string, number>;
  variant: Record<string, number>;
  railExtension: number;
  boxMontage: number;
}
