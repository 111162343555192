import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { editUser, getUsers } from "../../../redux/actions/user-actions";
import MenuActions, {
  Action,
} from "../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../constants/navigation-constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { User } from "../../../types/user-types";
import { Columns } from "../../../components/pagination-table/table-types";
import PaginationTable from "../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../../components/confirmation-dialog/confirmation-dialog";
import Typography from "@mui/material/Typography";
import Section from "../../../components/section/section";
import UsersFilters from "./users-filters/users-filters";
import { ELLIPSIS_STYLES } from "../../../components/pagination-table/table-row/table-row";
import { getAllPartners } from "../../../redux/actions/partner-actions";

interface UsersListProps extends WithPageLoaderProps {}

const tableStyles = {
  minWidth: 900,
  maxWidth: 1150,
  margin: "0 auto",
};

const UsersList = ({ finishLoading }: UsersListProps) => {
  const roles = useSelector((state: Store) => state.auth.roles);
  const userReducer = useSelector((state: Store) => state.user);
  const { allPartners } = useSelector((state: Store) => state.partner);
  const { loading, size, sort, filters } = userReducer;

  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const isActive = filters.active;

  const init = async () => {
    await Promise.all([
      dispatch(getAllPartners()),
      dispatch(
        getUsers({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      await dispatch(editUser({ id: pickedId, active: !isActive }));
      dispatch(
        getUsers({ params: { size, page: 0, sort, filters }, reload: true })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const getUsername = () =>
    userReducer.content?.find((user) => user.id === pickedId)?.username;

  const getRoleName = (id: number) =>
    roles.find((role) => role.id === id)?.name || "brak";

  const getPartnerName = (id?: number) => {
    const partner = allPartners?.find((partner) => partner.id === id);
    return partner
      ? partner.active
        ? partner.name
        : `${partner.name} (${t("dezaktywowany")})`
      : "-";
  };

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.UsersEdit}/${id}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderActionsCell = (user: User) => (
    <MenuActions
      key={`${user.id}-actions-cell`}
      actions={actions}
      entityId={user.id}
    />
  );

  const renderRoleCell = (user: User) => (
    <Typography key={`${user.id}-role-cell`} sx={ELLIPSIS_STYLES}>
      {t(getRoleName(user.roleId))}
    </Typography>
  );

  const renderPartnerCell = (user: User) => (
    <Typography key={`${user.id}-partner-cell`} sx={ELLIPSIS_STYLES}>
      {getPartnerName(user.partnerId)}
    </Typography>
  );

  const columns: Columns<User> = [
    { id: "email", accessor: "username", name: t("mail"), flexGrow: 3 },
    {
      id: "partner",
      name: t("partner"),
      cellRenderer: renderPartnerCell,
      flexGrow: 3,
    },
    {
      id: "role",
      accessor: "roleId",
      name: t("rola"),
      flexGrow: 1,
      cellRenderer: renderRoleCell,
    },
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const table = (
    <PaginationTable<User>
      withPagination={true}
      loading={loading}
      fetcherAction={getUsers}
      reducerName="user"
      columns={columns}
    />
  );

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("dezaktywacja_uzytkwnika_pytanie")
            : t("aktywacja_uzytkownika_pytanie")}{" "}
          <strong>({getUsername()})</strong>
        </Typography>
      </ConfirmationDialog>
      <Section
        title={t("uzytkownicy")}
        actions={<UsersFilters />}
        sx={tableStyles}
      >
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(UsersList);
