import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { editColor, getColors } from "../../../../redux/actions/color-actions";
import MenuActions, {
  Action,
} from "../../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../../constants/navigation-constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Color } from "../../../../types/color-types";
import { Columns } from "../../../../components/pagination-table/table-types";
import PaginationTable from "../../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../../../components/confirmation-dialog/confirmation-dialog";
import { Typography } from "@mui/material";
import Section from "../../../../components/section/section";
import ColorFilter from "./color-filter/color-filter";
import { DEFAULT_TABLE_WIDTH } from "../../../../theme/sizes";

const tableStyles = {
  minWidth: 500,
  maxWidth: DEFAULT_TABLE_WIDTH,
  margin: "0 auto",
};

interface ColorListProps extends WithPageLoaderProps {}

const ColorList = ({ finishLoading }: ColorListProps) => {
  const reducer = useSelector((state: Store) => state.color);
  const { loading, size, sort, filters } = reducer;
  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const isActive = filters.active;

  const init = async () => {
    await Promise.all([
      dispatch(
        getColors({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      dispatch(editColor({ id: pickedId, active: !isActive }));
      await dispatch(
        getColors({ params: { size, page: 0, sort, filters }, reload: true })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const getColorName = () =>
    reducer.content?.find((color) => color.id === pickedId)?.label;

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.ColorEdit}/${id}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderActionsCell = (color: Color) => (
    <MenuActions
      key={`${color.id}-actions-cell`}
      actions={actions}
      entityId={color.id}
    />
  );

  const columns: Columns<Color> = [
    { id: "label", accessor: "label", flexGrow: 10, name: t("nazwa") },
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const table = (
    <PaginationTable<Color>
      withPagination={true}
      loading={loading}
      fetcherAction={getColors}
      reducerName="color"
      columns={columns}
    />
  );

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("__dezaktywacja_koloru_pytanie")
            : t("__aktywacja_koloru_pytanie")}{" "}
          <strong>({getColorName()})</strong>
        </Typography>
      </ConfirmationDialog>
      <Section title={t("kolory")} actions={<ColorFilter />} sx={tableStyles}>
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(ColorList);
