import { Cover } from "../../types/cover-types";
import { Category } from "../../types/category-types";
import { Variant } from "../../types/variant-types";
import { Color } from "../../types/color-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FillingWithPrice } from "../../pages/orders-page/order-edit/order-form/model-config/model-config.types";
import { Profile } from "../../types/profile-types";
import { Brake } from "../../types/brake-types";
import { Partner, Phone } from "../../types/partner-types";
import { DoorEnum } from "../../constants/door-constants";
import { ClapEnum } from "../../constants/claps-constants";
import { CalculationResponse } from "../../types/calculation-types";
import {
  addOrder,
  calculate,
  editOrder,
  getAvailablePrices,
  getOrderCounts,
  getOrders,
} from "../actions/order-actions";
import { Loading, Pageable } from "../../types/common-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import {
  AvailablePrices,
  Order,
  OrderStatus,
  Payment,
  RailPosition,
} from "../../types/order-types";
import { updateStateWithPageable } from "../redux-utils";
import { Currency, DEFAULT_CURRENCY } from "../../types/price-types";

export interface Validation {
  [key: string]: string;
}

export interface Address {
  street?: string;
  houseNumber?: string;
  flatNumber?: string;
  countryId: number;
  zipCode: string;
  city: string;
}

export interface Client {
  name: string;
  email?: string;
  phone: Partial<Phone>;
  address: Address;
}

export interface PriceWithDiscount {
  price: string;
  discount: string;
  final: string;
}

export interface DetailedClientPrice {
  transport: PriceWithDiscount;
  montage: PriceWithDiscount;
  door: PriceWithDiscount;
  brake: PriceWithDiscount;
  variant: PriceWithDiscount;
  railExtension: PriceWithDiscount;
  boxMontage: PriceWithDiscount;
}

export interface ClientPrice {
  base: string;
  discount: string;
  final: string;
  finalWithVat: string;
  currency: Currency;
  vatRate: string;
  isDetailedPrice: boolean;
  detailedClientPrice: DetailedClientPrice;
}

export type BasicClientPrice = Omit<
  ClientPrice,
  "isDetailedPrice" | "detailedClientPrice"
>;

const DEFAULT_ADDRESS: Address = {
  countryId: -1,
  city: "",
  zipCode: "",
  flatNumber: "",
  houseNumber: "",
  street: "",
};

const DEFAULT_PHONE: Phone = {
  number: "",
  prefix: "",
};

export const DEFAULT_CLIENT: Client = {
  address: DEFAULT_ADDRESS,
  email: "",
  name: "",
  phone: DEFAULT_PHONE,
};

export const DEFAULT_PRICE_WITH_DISCOUNT: PriceWithDiscount = {
  price: "0",
  discount: "0",
  final: "0",
};

export const DEFAULT_DETAILED_CLIENT_PRICE: DetailedClientPrice = {
  boxMontage: DEFAULT_PRICE_WITH_DISCOUNT,
  brake: DEFAULT_PRICE_WITH_DISCOUNT,
  door: DEFAULT_PRICE_WITH_DISCOUNT,
  montage: DEFAULT_PRICE_WITH_DISCOUNT,
  railExtension: DEFAULT_PRICE_WITH_DISCOUNT,
  transport: DEFAULT_PRICE_WITH_DISCOUNT,
  variant: DEFAULT_PRICE_WITH_DISCOUNT,
};

export const DEFAULT_CLIENT_PRICE: ClientPrice = {
  base: "",
  discount: "",
  final: "",
  finalWithVat: "",
  vatRate: "",
  currency: DEFAULT_CURRENCY,
  isDetailedPrice: false,
  detailedClientPrice: DEFAULT_DETAILED_CLIENT_PRICE,
};

export interface CurrentOrder {
  category: Category | null;
  profile: Profile | null;
  model: Cover | null;
  variant: Variant | null;
  filling: FillingWithPrice | null;
  color: Color | null;
  brake: Brake | null;
  railExtension: string;
  floorBase: string;
  wallIncrease: number | null;
  brakeKey: boolean;
  clientDelivery: boolean;
  client: Client;
  doorType: DoorEnum[];
  clapType: ClapEnum[];
  withMontage: boolean | null;
  withTransport: boolean | null;
  distance: string;
  partner: Partner | null;
  clientPrice: ClientPrice;
  id: number | null;
  status: OrderStatus;
  partnerPrice: number | null;
  term: Date | null;
  payment: Payment | null;
  railPosition: RailPosition | null;
  box: boolean;
}

export interface OrderCounts {
  [key: string]: number;
}

export interface OrderState extends Pageable<Order> {
  pageLoading: Loading;
  entityLoading: Loading;
  currentOrder: CurrentOrder;
  calculation: {
    loading: boolean;
    response: CalculationResponse | null;
  };
  orderCounts: OrderCounts | null;
  validation: Validation | null;
  calculateValidation: Validation;
  availablePrices: AvailablePrices | null;
  rollback: boolean;
}

export const DEFAULT_ORDER_FILTERS = {
  id: "",
  clientName: "",
  partnerId: null,
  status: [
    OrderStatus.OFFER,
    OrderStatus.WAITING_FOR_ACCEPT,
    OrderStatus.CANCELED,
    OrderStatus.DELIVERED,
    OrderStatus.ACCEPTED,
    OrderStatus.EXPIRED,
  ],
  createdBy: null,
};

export const DEFAULT_ORDER_SORT = ["createdDate,desc"];

export const DEFAULT_CURRENT_ORDER: CurrentOrder = {
  id: null,
  category: null,
  profile: null,
  model: null,
  variant: null,
  filling: null,
  color: null,
  brake: null,
  railExtension: "0",
  floorBase: "",
  wallIncrease: null,
  brakeKey: false,
  clientDelivery: true,
  client: DEFAULT_CLIENT,
  clapType: [],
  doorType: [],
  withMontage: null,
  withTransport: null,
  distance: "",
  partner: null,
  clientPrice: DEFAULT_CLIENT_PRICE,
  status: OrderStatus.OFFER,
  partnerPrice: null,
  term: null,
  payment: null,
  railPosition: null,
  box: false,
};

const initialState: OrderState = {
  ...DEFAULT_PAGEABLE,
  pageLoading: "idle",
  entityLoading: "idle",
  sort: DEFAULT_ORDER_SORT,
  filters: DEFAULT_ORDER_FILTERS,
  currentOrder: DEFAULT_CURRENT_ORDER,
  calculation: {
    loading: true,
    response: null,
  },
  orderCounts: null,
  validation: null,
  calculateValidation: {},
  availablePrices: null,
  rollback: false,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<Category | null>) {
      state.currentOrder.category = action.payload;
    },
    setProfile(state, action: PayloadAction<Profile | null>) {
      state.currentOrder.profile = action.payload;
    },
    setModel(state, action: PayloadAction<Cover | null>) {
      state.currentOrder.model = action.payload;
    },
    setVariant(state, action: PayloadAction<Variant | null>) {
      state.currentOrder.variant = action.payload;
    },
    setFilling(state, action: PayloadAction<FillingWithPrice | null>) {
      state.currentOrder.filling = action.payload;
    },
    setColor(state, action: PayloadAction<Color | null>) {
      state.currentOrder.color = action.payload;
    },
    setBrake(state, action: PayloadAction<Brake | null>) {
      state.currentOrder.brake = action.payload;
    },
    setRailExtension(state, action: PayloadAction<string>) {
      state.currentOrder.railExtension = action.payload;
    },
    setFloorBase(state, action: PayloadAction<string>) {
      state.currentOrder.floorBase = action.payload;
    },
    setWallIncrease(state, action: PayloadAction<number | null>) {
      state.currentOrder.wallIncrease = action.payload;
    },
    setBrakeKey(state, action: PayloadAction<boolean>) {
      state.currentOrder.brakeKey = action.payload;
    },
    setClientDelivery(state, action: PayloadAction<boolean>) {
      state.currentOrder.clientDelivery = action.payload;
    },
    setClient(state, action: PayloadAction<Client>) {
      state.currentOrder.client = action.payload;
    },
    setDoors(state, action: PayloadAction<DoorEnum[]>) {
      state.currentOrder.doorType = action.payload;
    },
    setClaps(state, action: PayloadAction<ClapEnum[]>) {
      state.currentOrder.clapType = action.payload;
    },
    setWithMontage(state, action: PayloadAction<boolean | null>) {
      state.currentOrder.withMontage = action.payload;
    },
    setWithTransport(state, action: PayloadAction<boolean | null>) {
      state.currentOrder.withTransport = action.payload;
    },
    setDistance(state, action: PayloadAction<string>) {
      state.currentOrder.distance = action.payload;
    },
    setPartner(state, action: PayloadAction<Partner | null>) {
      state.currentOrder.partner = action.payload;
    },
    resetCalculation(state) {
      state.calculation.loading = false;
      state.calculation.response = null;
    },
    setClientPrice(state, action: PayloadAction<BasicClientPrice>) {
      state.currentOrder.clientPrice.base = action.payload.base;
      state.currentOrder.clientPrice.currency = action.payload.currency;
      state.currentOrder.clientPrice.discount = action.payload.discount;
      state.currentOrder.clientPrice.vatRate = action.payload.vatRate;
      state.currentOrder.clientPrice.final = action.payload.final;
      state.currentOrder.clientPrice.finalWithVat = action.payload.finalWithVat;
    },
    setCurrentOrder(
      state,
      action: PayloadAction<{ currentOrder: CurrentOrder; rollback?: boolean }>
    ) {
      state.currentOrder = action.payload.currentOrder;
      state.rollback = !!action.payload.rollback;
    },
    setValidation(state, action: PayloadAction<Validation | null>) {
      state.validation = action.payload;
    },
    setCalculateValidation(state, action: PayloadAction<Validation>) {
      state.calculateValidation = action.payload;
    },
    setStatus(state, action: PayloadAction<OrderStatus>) {
      state.currentOrder.status = action.payload;
    },
    setTerm(state, action: PayloadAction<Date | null>) {
      state.currentOrder.term = action.payload;
    },
    setRollback(state, action: PayloadAction<boolean>) {
      state.rollback = action.payload;
    },
    setRailPosition(state, action: PayloadAction<RailPosition | null>) {
      state.currentOrder.railPosition = action.payload;
    },
    setBox(state, action: PayloadAction<boolean>) {
      state.currentOrder.box = action.payload;
    },
    setDetailedPrice(state, action: PayloadAction<boolean>) {
      state.currentOrder.clientPrice.base = DEFAULT_CLIENT_PRICE.base;
      state.currentOrder.clientPrice.vatRate = DEFAULT_CLIENT_PRICE.vatRate;
      state.currentOrder.clientPrice.finalWithVat =
        DEFAULT_CLIENT_PRICE.finalWithVat;
      state.currentOrder.clientPrice.final = DEFAULT_CLIENT_PRICE.final;
      state.currentOrder.clientPrice.discount = DEFAULT_CLIENT_PRICE.discount;
      state.currentOrder.clientPrice.currency = DEFAULT_CLIENT_PRICE.currency;
      state.currentOrder.clientPrice.detailedClientPrice.variant.price =
        DEFAULT_DETAILED_CLIENT_PRICE.variant.price;
      state.currentOrder.clientPrice.detailedClientPrice.variant.discount =
        DEFAULT_DETAILED_CLIENT_PRICE.variant.discount;
      state.currentOrder.clientPrice.detailedClientPrice.variant.final =
        DEFAULT_DETAILED_CLIENT_PRICE.variant.final;
      state.currentOrder.clientPrice.detailedClientPrice.railExtension.price =
        DEFAULT_DETAILED_CLIENT_PRICE.railExtension.price;
      state.currentOrder.clientPrice.detailedClientPrice.railExtension.discount =
        DEFAULT_DETAILED_CLIENT_PRICE.railExtension.discount;
      state.currentOrder.clientPrice.detailedClientPrice.railExtension.final =
        DEFAULT_DETAILED_CLIENT_PRICE.railExtension.final;
      state.currentOrder.clientPrice.detailedClientPrice.brake.price =
        DEFAULT_DETAILED_CLIENT_PRICE.brake.price;
      state.currentOrder.clientPrice.detailedClientPrice.brake.discount =
        DEFAULT_DETAILED_CLIENT_PRICE.brake.discount;
      state.currentOrder.clientPrice.detailedClientPrice.brake.final =
        DEFAULT_DETAILED_CLIENT_PRICE.brake.final;
      state.currentOrder.clientPrice.detailedClientPrice.door.price =
        DEFAULT_DETAILED_CLIENT_PRICE.door.price;
      state.currentOrder.clientPrice.detailedClientPrice.door.discount =
        DEFAULT_DETAILED_CLIENT_PRICE.door.discount;
      state.currentOrder.clientPrice.detailedClientPrice.door.final =
        DEFAULT_DETAILED_CLIENT_PRICE.door.final;
      state.currentOrder.clientPrice.detailedClientPrice.transport.price =
        DEFAULT_DETAILED_CLIENT_PRICE.transport.price;
      state.currentOrder.clientPrice.detailedClientPrice.transport.discount =
        DEFAULT_DETAILED_CLIENT_PRICE.transport.discount;
      state.currentOrder.clientPrice.detailedClientPrice.transport.final =
        DEFAULT_DETAILED_CLIENT_PRICE.transport.final;
      state.currentOrder.clientPrice.detailedClientPrice.montage.price =
        DEFAULT_DETAILED_CLIENT_PRICE.montage.price;
      state.currentOrder.clientPrice.detailedClientPrice.montage.discount =
        DEFAULT_DETAILED_CLIENT_PRICE.montage.discount;
      state.currentOrder.clientPrice.detailedClientPrice.montage.final =
        DEFAULT_DETAILED_CLIENT_PRICE.montage.final;
      state.currentOrder.clientPrice.detailedClientPrice.boxMontage.price =
        DEFAULT_DETAILED_CLIENT_PRICE.boxMontage.price;
      state.currentOrder.clientPrice.detailedClientPrice.boxMontage.discount =
        DEFAULT_DETAILED_CLIENT_PRICE.boxMontage.discount;
      state.currentOrder.clientPrice.detailedClientPrice.boxMontage.final =
        DEFAULT_DETAILED_CLIENT_PRICE.boxMontage.final;
      state.currentOrder.clientPrice.isDetailedPrice = action.payload;
    },
    setVariantPrice(state, action: PayloadAction<PriceWithDiscount>) {
      state.currentOrder.clientPrice.detailedClientPrice.variant.price =
        action.payload.price;
      state.currentOrder.clientPrice.detailedClientPrice.variant.discount =
        action.payload.discount;
      state.currentOrder.clientPrice.detailedClientPrice.variant.final =
        action.payload.final;
    },
    setRailExtensionPrice(state, action: PayloadAction<PriceWithDiscount>) {
      state.currentOrder.clientPrice.detailedClientPrice.railExtension.price =
        action.payload.price;
      state.currentOrder.clientPrice.detailedClientPrice.railExtension.discount =
        action.payload.discount;
      state.currentOrder.clientPrice.detailedClientPrice.railExtension.final =
        action.payload.final;
    },
    setDoorPrice(state, action: PayloadAction<PriceWithDiscount>) {
      state.currentOrder.clientPrice.detailedClientPrice.door.price =
        action.payload.price;
      state.currentOrder.clientPrice.detailedClientPrice.door.discount =
        action.payload.discount;
      state.currentOrder.clientPrice.detailedClientPrice.door.final =
        action.payload.final;
    },
    setBrakePrice(state, action: PayloadAction<PriceWithDiscount>) {
      state.currentOrder.clientPrice.detailedClientPrice.brake.price =
        action.payload.price;
      state.currentOrder.clientPrice.detailedClientPrice.brake.discount =
        action.payload.discount;
      state.currentOrder.clientPrice.detailedClientPrice.brake.final =
        action.payload.final;
    },
    setTransportPrice(state, action: PayloadAction<PriceWithDiscount>) {
      state.currentOrder.clientPrice.detailedClientPrice.transport.price =
        action.payload.price;
      state.currentOrder.clientPrice.detailedClientPrice.transport.discount =
        action.payload.discount;
      state.currentOrder.clientPrice.detailedClientPrice.transport.final =
        action.payload.final;
    },
    setMontagePrice(state, action: PayloadAction<PriceWithDiscount>) {
      state.currentOrder.clientPrice.detailedClientPrice.montage.price =
        action.payload.price;
      state.currentOrder.clientPrice.detailedClientPrice.montage.discount =
        action.payload.discount;
      state.currentOrder.clientPrice.detailedClientPrice.montage.final =
        action.payload.final;
    },
    setBoxMontagePrice(state, action: PayloadAction<PriceWithDiscount>) {
      state.currentOrder.clientPrice.detailedClientPrice.boxMontage.price =
        action.payload.price;
      state.currentOrder.clientPrice.detailedClientPrice.boxMontage.discount =
        action.payload.discount;
      state.currentOrder.clientPrice.detailedClientPrice.boxMontage.final =
        action.payload.final;
    },
    setFinalClientPrice(
      state,
      action: PayloadAction<{ final: string; finalWithVat: string }>
    ) {
      state.currentOrder.clientPrice.final = action.payload.final;
      state.currentOrder.clientPrice.finalWithVat = action.payload.finalWithVat;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(calculate.pending, (state) => {
      state.calculation.loading = true;
    });
    builder.addCase(calculate.fulfilled, (state, action) => {
      state.calculation.loading = false;
      state.calculation.response = action.payload;
    });
    builder.addCase(calculate.rejected, (state) => {
      state.calculation.loading = false;
      state.calculation.response = null;
    });
    builder.addCase(addOrder.pending, (state) => {
      state.entityLoading = "pending";
    });
    builder.addCase(addOrder.fulfilled, (state) => {
      state.entityLoading = "succeeded";
    });
    builder.addCase(addOrder.rejected, (state) => {
      state.entityLoading = "failed";
    });
    builder.addCase(editOrder.pending, (state) => {
      state.entityLoading = "pending";
    });
    builder.addCase(editOrder.fulfilled, (state) => {
      state.entityLoading = "succeeded";
    });
    builder.addCase(editOrder.rejected, (state) => {
      state.entityLoading = "failed";
    });
    builder.addCase(getOrders.pending, (state) => {
      state.pageLoading = "pending";
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.pageLoading = "succeeded";
    });
    builder.addCase(getOrders.rejected, (state) => {
      state.pageLoading = "failed";
    });
    builder.addCase(getOrderCounts.fulfilled, (state, action) => {
      const counts = action.payload;
      const total = Object.entries(counts).reduce((acc, [key, value]) => {
        if (key === OrderStatus.DELETED) {
          return acc;
        }
        return acc + value;
      }, 0);
      state.orderCounts = { ...counts, total };
    });
    builder.addCase(getAvailablePrices.pending, (state) => {
      state.availablePrices = null;
    });
    builder.addCase(getAvailablePrices.fulfilled, (state, action) => {
      state.availablePrices = action.payload;
    });
    builder.addCase(getAvailablePrices.rejected, (state) => {
      state.availablePrices = null;
    });
  },
});

export default orderSlice.reducer;
export const {
  setCategory,
  setProfile,
  setModel,
  setVariant,
  setFilling,
  setColor,
  setBrake,
  setRailExtension,
  setFloorBase,
  setWallIncrease,
  setBrakeKey,
  setClientDelivery,
  setClient,
  setClaps,
  setDoors,
  setWithMontage,
  setWithTransport,
  setDistance,
  setPartner,
  resetCalculation,
  setClientPrice,
  setCurrentOrder,
  setValidation,
  setCalculateValidation,
  setStatus,
  setTerm,
  setRollback,
  setRailPosition,
  setBox,
  setDetailedPrice,
  setVariantPrice,
  setRailExtensionPrice,
  setDoorPrice,
  setBrakePrice,
  setTransportPrice,
  setMontagePrice,
  setBoxMontagePrice,
  setFinalClientPrice,
} = orderSlice.actions;
