export const plCountriesTranslations = {
  af: "Afganistan",
  al: "Albania",
  dz: "Algieria",
  ad: "Andora",
  ao: "Angola",
  ag: "Antigua i Barbuda",
  sa: "Arabia Saudyjska",
  ar: "Argentyna",
  am: "Armenia",
  au: "Australia",
  at: "Austria",
  az: "Azerbejdżan",
  bs: "Bahamy",
  bh: "Bahrajn",
  bd: "Bangladesz",
  bb: "Barbados",
  be: "Belgia",
  bz: "Belize",
  bj: "Benin",
  bt: "Bhutan",
  by: "Białoruś",
  bo: "Boliwia",
  ba: "Bośnia i Hercegowina",
  bw: "Botswana",
  br: "Brazylia",
  bn: "Brunei",
  bg: "Bułgaria",
  bf: "Burkina Faso",
  bi: "Burundi",
  cl: "Chile",
  cn: "Chiny",
  hr: "Chorwacja",
  cy: "Cypr",
  td: "Czad",
  me: "Czarnogóra",
  cz: "Czechy",
  dk: "Dania",
  cd: "Demokratyczna Republika Konga",
  dm: "Dominika",
  do: "Dominikana",
  dj: "Dżibuti",
  eg: "Egipt",
  ec: "Ekwador",
  er: "Erytrea",
  ee: "Estonia",
  sz: "Eswatini",
  et: "Etiopia",
  fj: "Fidżi",
  ph: "Filipiny",
  fi: "Finlandia",
  fr: "Francja",
  ga: "Gabon",
  gm: "Gambia",
  gh: "Ghana",
  gr: "Grecja",
  gd: "Grenada",
  ge: "Gruzja",
  gy: "Gujana",
  gt: "Gwatemala",
  gw: "Gwinea Bissau",
  gq: "Gwinea Równikowa",
  gn: "Gwinea",
  ht: "Haiti",
  es: "Hiszpania",
  nl: "Holandia",
  hn: "Honduras",
  in: "Indie",
  id: "Indonezja",
  iq: "Irak",
  ir: "Iran",
  ie: "Irlandia",
  is: "Islandia",
  il: "Izrael",
  jm: "Jamajka",
  jp: "Japonia",
  ye: "Jemen",
  jo: "Jordania",
  kh: "Kambodża",
  cm: "Kamerun",
  ca: "Kanada",
  qa: "Katar",
  kz: "Kazachstan",
  ke: "Kenia",
  kg: "Kirgistan",
  ki: "Kiribati",
  co: "Kolumbia",
  km: "Komory",
  cg: "Kongo",
  kr: "Korea Południowa",
  kp: "Korea Północna",
  cr: "Kostaryka",
  cu: "Kuba",
  kw: "Kuwejt",
  la: "Laos",
  ls: "Lesotho",
  lb: "Liban",
  lr: "Liberia",
  ly: "Libia",
  li: "Liechtenstein",
  lt: "Litwa",
  lu: "Luksemburg",
  lv: "Łotwa",
  mk: "Macedonia Północna",
  mg: "Madagaskar",
  mw: "Malawi",
  mv: "Malediwy",
  my: "Malezja",
  ml: "Mali",
  mt: "Malta",
  ma: "Maroko",
  mr: "Mauretania",
  mu: "Mauritius",
  mx: "Meksyk",
  fm: "Mikronezja",
  mm: "Mjanma",
  md: "Mołdawia",
  mc: "Monako",
  mn: "Mongolia",
  mz: "Mozambik",
  na: "Namibia",
  nr: "Nauru",
  np: "Nepal",
  de: "Niemcy",
  ne: "Niger",
  ng: "Nigeria",
  ni: "Nikaragua",
  no: "Norwegia",
  nz: "Nowa Zelandia",
  om: "Oman",
  pk: "Pakistan",
  pw: "Palau",
  pa: "Panama",
  pg: "Papua-Nowa Gwinea",
  py: "Paragwaj",
  pe: "Peru",
  pl: "Polska",
  pt: "Portugalia",
  za: "Południowa Afryka",
  cf: "Republika Środkowoafrykańska",
  cv: "Republika Zielonego Przylądka",
  ru: "Rosja",
  ro: "Rumunia",
  rw: "Rwanda",
  kn: "Saint Kitts i Nevis",
  lc: "Saint Lucia",
  vc: "Saint Vincent i Grenadyny",
  sv: "Salwador",
  ws: "Samoa",
  sm: "San Marino",
  sn: "Senegal",
  rs: "Serbia",
  sc: "Seszele",
  sl: "Sierra Leone",
  sg: "Singapur",
  sk: "Słowacja",
  si: "Słowenia",
  so: "Somalia",
  lk: "Sri Lanka",
  us: "Stany Zjednoczone",
  sd: "Sudan",
  ss: "Sudan Południowy",
  sr: "Surinam",
  sy: "Syria",
  ch: "Szwajcaria",
  se: "Szwecja",
  tj: "Tadżykistan",
  th: "Tajlandia",
  tz: "Tanzania",
  tl: "Timor Wschodni",
  tg: "Togo",
  to: "Tonga",
  tt: "Trynidad i Tobago",
  tn: "Tunezja",
  tr: "Turcja",
  tm: "Turkmenistan",
  tv: "Tuvalu",
  ug: "Uganda",
  ua: "Ukraina",
  uy: "Urugwaj",
  uz: "Uzbekistan",
  vu: "Vanuatu",
  ve: "Wenezuela",
  hu: "Węgry",
  gb: "Wielka Brytania",
  vn: "Wietnam",
  it: "Włochy",
  ci: "Wybrzeże Kości Słoniowej",
  mh: "Wyspy Marshalla",
  sb: "Wyspy Salomona",
  st: "Wyspy Świętego Tomasza i Książęca",
  zm: "Zambia",
  zw: "Zimbabwe",
  ae: "Zjednoczone Emiraty Arabskie",
};
