import { Loading, Pageable } from "../../types/common-types";
import { Brake } from "../../types/brake-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { updateStateWithPageable } from "../redux-utils";
import {
  addBrake,
  editBrake,
  getAllBrakes,
  getBrakes,
} from "../actions/brake-actions";

export interface BrakeState extends Pageable<Brake> {
  loading: Loading;
  allBrakes: Brake[] | null;
}

export const DEFAULT_BRAKE_FILTERS = {
  active: true,
};

export const DEFAULT_BRAKE_SORT = ["label", "id"];

const initialState: BrakeState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_BRAKE_SORT,
  filters: DEFAULT_BRAKE_FILTERS,
  allBrakes: null,
};

const brakeSlice = createSlice({
  name: "brake",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBrakes.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getBrakes.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getBrakes.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addBrake.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addBrake.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addBrake.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editBrake.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editBrake.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editBrake.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getAllBrakes.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllBrakes.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allBrakes = action.payload;
    });
    builder.addCase(getAllBrakes.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default brakeSlice.reducer;
