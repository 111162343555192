import React from "react";
import "./i18n";
import Alert from "./components/alert/alert";
import Routing from "./components/routing/routing";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import enLocale from "date-fns/locale/en-GB";
import deLocale from "date-fns/locale/de";
import svLocale from "date-fns/locale/sv";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();

  const getLocale = () => {
    switch (i18n.resolvedLanguage) {
      case "pl":
        return plLocale;
      case "de":
        return deLocale;
      case "sv":
        return svLocale;
      case "en":
      default:
        return enLocale;
    }
  };

  return (
    <div>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getLocale()}
      >
        <Routing />
        <Alert />
      </LocalizationProvider>
    </div>
  );
}

export default App;
