import React from "react";
import Section from "../../../../../components/section/section";
import { useTranslation } from "react-i18next";
import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PhotoPreview from "../../../../../components/photo-preview/photo-preview";
import { useSelector } from "react-redux";
import { Store } from "../../../../../redux/store";
import useMediaQuery from "@mui/material/useMediaQuery";

const getStyles = (theme: Theme, isMobile: boolean) => ({
  container: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    gap: theme.spacing(2),
    width: "100%",
  },
  fields: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    flexDirection: "column",
    width: "100%",
  },
});

export const COMMON_PROPS = {
  required: true,
  disabled: true,
  fullWidth: true,
  size: "small" as const,
  variant: "outlined" as const,
};

const ModelSummary = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const styles = getStyles(theme, isMobile);

  const categories = useSelector(
    (state: Store) => state.category.allCategories
  );
  const profiles = useSelector((state: Store) => state.profile.allProfiles);
  const { model } = useSelector((state: Store) => state.order.currentOrder);

  const getCategoryNameById = (id: number) =>
    categories?.find((category) => category.id === id)?.label;

  const getProfileNameById = (id: number) =>
    profiles?.find((profile) => profile.id === id)?.label;

  if (!model) {
    return null;
  }

  return (
    <Section title={t("__wybrany_model")}>
      <Box sx={styles.container}>
        <PhotoPreview model={model} hideLabel={true} blockInteractions={true} />
        <Box sx={styles.fields}>
          <TextField {...COMMON_PROPS} label={t("nazwa")} value={model.label} />
          <TextField
            {...COMMON_PROPS}
            label={t("__kategoria")}
            value={getCategoryNameById(model.categoryId)}
          />
          <TextField
            {...COMMON_PROPS}
            label={t("__profil")}
            value={getProfileNameById(model.profileId)}
          />
          <TextField
            {...COMMON_PROPS}
            label={t("__liczba_szyn")}
            value={model.railCount}
          />
        </Box>
      </Box>
    </Section>
  );
};

export default ModelSummary;
