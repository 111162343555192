import { Theme } from "@mui/material";
import { Colors } from "../../theme/colors";

export const getStyles = (theme: Theme, error?: boolean) => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      position: "relative",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    input: {
      display: "none",
    },
    label: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      border: error ? `1px solid ${Colors.Red}` : "none",
    },
    overlayContainer: {
      height: "100%",
      position: "relative",
      "&:hover #preview": {
        transform: "scale(1.05)",
      },
      "&:hover #placeholderContent": {
        transform: "translate(-50%, -50%) scale(1.05)",
      },
    },
    overlay: {
      position: "absolute",
      inset: 0,
      backgroundColor: theme.palette.grey["700"],
      opacity: 0,
      transition: "opacity 300ms",
      "&:hover": {
        opacity: 0.2,
      },
    },
    preview: {
      transition: "transform 300ms",
    },
    placeholder: {
      position: "relative",
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.grey["500"],
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(3),
      padding: "30%",
      color: Colors.White,
      svg: {
        width: "3em",
        height: "3em",
      },
    },
    placeholderContent: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      transition: "transform 300ms",
    },
    placeholderText: {
      fontSize: 14,
      textAlign: "center",
    },
    text: {
      fontSize: 16,
      textAlign: "center",
    },
    remove: {
      display: "flex",
      position: "absolute",
      top: "2%",
      right: "2%",
      backgroundColor: theme.palette.grey["500"],
      padding: theme.spacing(0.5),
      borderRadius: "50%",
      color: Colors.White,
      cursor: "pointer",
      transition: "background-color 300ms",
      "&:hover": {
        backgroundColor: theme.palette.grey["600"],
      },
    },
    errorText: {
      fontSize: 12,
      color: Colors.Red,
    },
  };
};
