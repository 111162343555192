import { TFunction } from "react-i18next";
import * as yup from "yup";
import { getPasswordSchema } from "../../login-page/temp-password-form/temp-password-form.utils";
import { FormValues } from "./user-edit.types";
import {
  User,
  UserPatchRequest,
  UserPostRequest,
} from "../../../types/user-types";
import { Role } from "../../../types/auth-types";
import { Roles } from "../../../constants/roles";
import { Phone } from "../../../types/partner-types";

export const getValidationSchema = (
  t: TFunction<"translation">,
  isEdit: boolean,
  isPartner: boolean
) => {
  const requiredFieldError = t("wymagane_pole");

  const passwordSchema = isEdit
    ? getPasswordSchema(t)
    : getPasswordSchema(t).required(requiredFieldError);

  const partnerIdSchema = isPartner
    ? yup.string().required(requiredFieldError)
    : yup.string();

  return yup.object({
    firstname: yup.string().required(requiredFieldError),
    lastname: yup.string().required(requiredFieldError),
    username: yup
      .string()
      .email(t("niepoprawny_mail"))
      .required(requiredFieldError),
    roleId: yup
      .number()
      .min(0, requiredFieldError)
      .required(requiredFieldError)
      .typeError(requiredFieldError),
    password: passwordSchema,
    partnerId: partnerIdSchema,
    active: yup.boolean(),
    phonePrefix: yup.string().required(requiredFieldError),
    phoneNumber: yup.string().required(requiredFieldError),
  });
};

export const mapFormValuesToUserPostRequest = (
  values: FormValues
): UserPostRequest => {
  const phone: Phone = {
    number: values.phoneNumber,
    prefix: values.phonePrefix,
  };

  return {
    roleId: values.roleId,
    username: values.username,
    partnerId: values.partnerId === "" ? undefined : Number(values.partnerId),
    firstname: values.firstname,
    lastname: values.lastname,
    password: values.password,
    phone,
  };
};

export const mapFormValuesToUserPatchRequest = (
  values: FormValues,
  user: User
): UserPatchRequest => {
  const phone: Phone = {
    number: values.phoneNumber,
    prefix: values.phonePrefix,
  };

  return {
    id: user.id,
    firstname: values.firstname,
    lastname: values.lastname,
    password: values.password || undefined,
    active: values.active,
    phone,
  };
};

export const mapUserToFormValues = (user: User): FormValues => ({
  password: "",
  lastname: user.lastname,
  firstname: user.firstname,
  partnerId: user.partnerId?.toString() || "",
  username: user.username,
  roleId: user.roleId,
  active: user.active,
  phoneNumber: user.phone.number,
  phonePrefix: user.phone.prefix,
});

export const getDefaultFormValues = (): FormValues => ({
  active: true,
  roleId: -1,
  username: "",
  partnerId: "",
  firstname: "",
  lastname: "",
  password: "",
  phonePrefix: "",
  phoneNumber: "",
});

export const getInitFormValues = (user: User | null): FormValues =>
  user ? mapUserToFormValues(user) : getDefaultFormValues();

export const isPartnerRole = (roles: Role[], id?: number) =>
  roles.find((role) => role.id === id)?.name === Roles.Partner;
