import React, { ReactNode } from "react";
import { Column, TableRowProps } from "../table-types";
import Box from "@mui/material/Box";
import { Theme, useTheme } from "@mui/material";
import { Colors } from "../../../theme/colors";
import Typography from "@mui/material/Typography";
import { WithId } from "../../../types/common-types";

export const ELLIPSIS_STYLES = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const getCellStyles = (theme: Theme) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flex: "1 1 0",
  padding: theme.spacing(2),
  minWidth: 0,
});

const getStyles = (theme: Theme, isLastOne?: boolean) => ({
  rowWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(2),
    borderBottom: isLastOne ? "none" : `solid 1px ${Colors.DividerGrey}`,
  },
  cell: getCellStyles(theme),
  text: ELLIPSIS_STYLES,
});

const TableRow = <T extends WithId>(props: TableRowProps<T>) => {
  const { columns, row, isLastOne } = props;
  const theme = useTheme();
  const styles = getStyles(theme, isLastOne);

  const getDefaultCell = ({ accessor }: Column<T>) =>
    accessor ? (
      <Typography sx={styles.text}>
        {row[accessor] as unknown as ReactNode}
      </Typography>
    ) : null;

  return (
    <Box sx={styles.rowWrapper}>
      {columns.map((column) => {
        const cell = column.cellRenderer
          ? column.cellRenderer(row)
          : getDefaultCell(column);

        return (
          <Box
            key={`${row.id}-${column.id}`}
            sx={{
              ...styles.cell,
              flexGrow: column.flexGrow,
              justifyContent: column.justify,
            }}
          >
            {cell}
          </Box>
        );
      })}
    </Box>
  );
};

export default TableRow;
