import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Partner,
  PartnerPatchRequest,
  PartnerPostRequest,
} from "../../types/partner-types";
import axios from "axios";
import {
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import {
  DEFAULT_PARTNER_FILTERS,
  DEFAULT_PARTNER_SORT,
} from "../slices/partner-slice";

export const getPartners = createAsyncThunk(
  "partner/getPartners",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { partner } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, partner)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const countryFilter =
      flatParams.country === DEFAULT_PARTNER_FILTERS.country
        ? undefined
        : flatParams.country;

    const adjustedParams = {
      ...flatParams,
      "address.countryId": countryFilter,
    };

    const { data } = await axios.get<PageResponse<Partner>>("/partner", {
      params: adjustedParams,
    });

    return preparePageForAction<Partner>(data, params);
  }
);

export const getAllPartners = createAsyncThunk(
  "partner/getAllPartners",
  async () => {
    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort: DEFAULT_PARTNER_SORT,
      filters: {},
    };

    const { data } = await axios.get<PageResponse<Partner>>("/partner", {
      params,
    });
    return data.content;
  }
);

export const getPartner = createAsyncThunk(
  "partner/getPartner",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Partner>>(`/partner`, {
      params: { id },
    });
    return getFirstElementFromPage<Partner>(data);
  }
);

export const addPartner = createAsyncThunk(
  "partner/addPartner",
  async (params: PartnerPostRequest, thunkAPI) => {
    const { data } = await axios.post<Partner>("/partner", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editPartner = createAsyncThunk(
  "partner/editPartner",
  async (params: PartnerPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Partner>("/partner", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
