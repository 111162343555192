import { Variant } from "../../../../../types/variant-types";
import { TFunction } from "i18next";
import { Typography } from "@mui/material";
import React from "react";
import { Validation } from "../../../../../redux/slices/order-slice";
import TextField from "@mui/material/TextField";

export const PICK_UP = "__obior_osobisty";
export const WITH_TRANSPORT = "__transport_bucovers";
export const WITH_MONTAGE = "__montaz_transport_bucovers";

export const DELIVERY_OPTIONS = [PICK_UP, WITH_TRANSPORT, WITH_MONTAGE];

export const getAutocompleteCommonProps = (
  variant: Variant | null,
  t: TFunction,
  getLabel: (option: string) => string,
  disableAll: boolean
) => ({
  size: "small" as const,
  autoHighlight: true,
  fullWidth: true,
  noOptionsText: t("__brak_opcji"),
  disabled: !variant || disableAll,
  disableClearable: true,
  getOptionLabel: (option: string) => getLabel(option),
  isOptionEqualToValue: (option: string, value: string) => option === value,
  renderOption: (props: any, option: string) => (
    <li {...props} key={option}>
      <Typography>{getLabel(option)}</Typography>
    </li>
  ),
});

export const getRenderInput =
  (
    label: string,
    validation: Validation | null,
    fieldKey: string,
    required = true
  ) =>
  (params: any) =>
    (
      <TextField
        {...params}
        label={label}
        required={required}
        error={validation && Boolean(validation[fieldKey])}
        helperText={validation && validation[fieldKey]}
        inputProps={{
          ...params.inputProps,
          autoComplete: "off",
        }}
      />
    );

export const getValue = (
  withMontage: boolean | null,
  withTransport: boolean | null
) => {
  if (withMontage === null || withTransport === null) {
    return null;
  }

  if (!withTransport && !withMontage) {
    return PICK_UP;
  }

  if (withTransport && !withMontage) {
    return WITH_TRANSPORT;
  }

  if (withTransport && withMontage) {
    return WITH_MONTAGE;
  }

  return null;
};
