import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import ConfirmationDialog from "../../../../../components/confirmation-dialog/confirmation-dialog";

interface CancelOrderDialogProps {
  open: boolean;
  onCancelClick: () => void;
  onConfirmation: () => void;
  id?: number;
  loading?: boolean;
}

const CancelOrderDialog = ({
  onConfirmation,
  onCancelClick,
  open,
  id,
  loading = false,
}: CancelOrderDialogProps) => {
  const { t } = useTranslation();

  const content = (
    <Typography>
      {t("__anulowac_zamowienie")} <strong>{id}</strong>?
    </Typography>
  );

  return (
    <ConfirmationDialog
      open={open}
      title={t("potwierdz_operacje")}
      primaryText={t("powrot")}
      onPrimary={onCancelClick}
      secondaryText={t("__tak")}
      onSecondary={onConfirmation}
      secondaryColor="error"
      loading={loading}
    >
      <Typography>{content}</Typography>
    </ConfirmationDialog>
  );
};

export default CancelOrderDialog;
