import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Theme, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { LoadingButton } from "@mui/lab";
import { logout } from "../../../redux/actions/auth-actions";
import { Colors } from "../../../theme/colors";

const getStyles = (theme: Theme) => ({
  logoutButton: {
    minWidth: 0,
    padding: theme.spacing(1),
    borderRadius: "50%",
    backgroundColor: Colors.ButtonBackground,
    "&:hover": {
      backgroundColor: Colors.ButtonBachgroundHover,
    },
  },
});

const Logout = () => {
  const loading = useSelector((state: Store) => state.auth.loading);
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const styles = getStyles(theme);

  const onClick = () => {
    dispatch(logout());
  };

  return (
    <>
      <LoadingButton
        onClick={onClick}
        loading={loading === "pending"}
        sx={styles.logoutButton}
      >
        <LogoutIcon />
      </LoadingButton>
    </>
  );
};

export default Logout;
