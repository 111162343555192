import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material/Alert";

export interface AlertState {
  message?: string;
  variant?: AlertColor;
  isFatal: boolean;
}

const initialState: AlertState = {
  message: undefined,
  variant: undefined,
  isFatal: false,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert(
      state,
      action: PayloadAction<{
        message: string;
        variant?: AlertColor;
        isFatal?: boolean;
      }>
    ) {
      state.message = action.payload.message;
      state.variant = action.payload.variant || "success";
      state.isFatal = action.payload.isFatal || false;
    },
    clearAlert(state) {
      state.message = undefined;
      state.isFatal = false;
    },
  },
});

export default alertSlice.reducer;
export const { setAlert, clearAlert } = alertSlice.actions;
