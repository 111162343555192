import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import useFetchEntity, { FetchEntityArgs } from "../../../hooks/useFetchEntity";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getStyles } from "../../partners-page/partner-edit/partner-edit.styles";
import { PriceItem } from "../../../types/price-types";
import { Route } from "../../../constants/navigation-constants";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Section from "../../../components/section/section";
import TextField from "@mui/material/TextField";
import { FormValues } from "./price-item-edit.types";
import {
  getInitialFormValues,
  getValidationSchema,
  mapFormValuesToPriceItemPatchRequest,
} from "./price-item-edit.utils";
import {
  editPriceItem,
  getPriceItem,
} from "../../../redux/actions/price-actions";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../redux/slices/changes-slice";
import SectionTitle from "../../../components/section-title/section-title";
import { getInputAdornment } from "../../../utils/common-utils";
import NumericInput from "../../../components/numeric-input/numeric-input";

interface PriceItemEditProps extends WithPageLoaderProps {}

const PriceItemEdit = ({ finishLoading }: PriceItemEditProps) => {
  const { id } = useParams<{ id: string }>();
  const [edited, setEdited] = useState<PriceItem | null>(null);
  const { priceItems, loading } = useSelector((state: Store) => state.price);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<PriceItem> = {
      callback: setEdited,
      collection: priceItems,
      fallbackRoute: Route.Prices,
      fetcherAction: getPriceItem,
      id,
    };
    await Promise.all([fetchEntity<PriceItem>(fetchArgs)]);
    finishLoading();
  };

  const onSubmit = async (values: FormValues) => {
    if (!edited) {
      return;
    }
    const priceItem = mapFormValuesToPriceItemPatchRequest(values, edited);
    const { payload } = await dispatch(editPriceItem(priceItem));
    if (payload) {
      setEdited(payload as PriceItem);
    }
  };

  const formik = useFormik({
    initialValues: getInitialFormValues(edited),
    validationSchema: getValidationSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitialFormValues(edited);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [edited]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.Prices);

  const onRollbackClick = () => {
    formik.setValues(getInitialFormValues(edited));
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={t("__edycja_ceny")}
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(edited)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_podstawowe")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <TextField
              id="name"
              name="name"
              fullWidth
              disabled
              label={t("nazwa")}
              size="small"
              value={t(formik.values.name)}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="price"
              name="price"
              fullWidth
              label={t("__cena")}
              size="small"
              value={formik.values.price}
              onChange={formik.handleChange}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              InputProps={getInputAdornment("€")}
              isMoney
            />
          </Box>
        </Box>
      </Section>
    </form>
  );
};

export default withPageLoader(PriceItemEdit);
