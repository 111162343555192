import React from "react";
import ConfirmationDialog from "../../../../../components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Store } from "../../../../../redux/store";
import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Typography from "@mui/material/Typography";
import InfoIcon from "../../../../../components/info-icon/info-icon";
import { isAdminRole, toRem } from "../../../../../utils/common-utils";
import {
  addDays,
  endOfWeek,
  isSameDay,
  isWithinInterval,
  startOfWeek,
} from "date-fns";
import { styled } from "@mui/material/styles";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import useRole from "../../../../../hooks/useRole";

interface TermPickerProps {
  open: boolean;
  onClose: () => void;
  onOrder?: () => void;
  term: Date | null;
  onChange: (term: Date | null) => void;
}

const getStyles = (theme: Theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  titleText: {
    fontWeight: 500,
    fontSize: toRem(20),
  },
  tooltipText: {
    textAlign: "justify",
    width: 350,
    fontSize: 14,
  },
});

interface CustomPickerDayProps extends PickersDayProps<Date> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  width: "40px",
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderRadius: "0%",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderRadius: "0%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const TermPicker = ({
  open,
  onClose,
  onOrder,
  onChange,
  term,
}: TermPickerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const userRole = useRole();

  const { entityLoading } = useSelector((state: Store) => state.order);
  const isLoading = entityLoading === "pending";

  const renderTitle = () => {
    const tooltipContent = (
      <Typography sx={styles.tooltipText}>{t("__termin_tooltip")}</Typography>
    );
    return (
      <Box sx={styles.title}>
        <Typography sx={styles.titleText}>{t("__termin_dostawy")}</Typography>
        <InfoIcon content={tooltipContent} />
      </Box>
    );
  };

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    if (!date || !term) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(term, { weekStartsOn: 1 });
    const end = endOfWeek(term, { weekStartsOn: 1 });

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(start, date);
    const isLastDay = isSameDay(end, date);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const minDate = addDays(new Date(), 10);

  return (
    <ConfirmationDialog
      open={open}
      renderTitle={renderTitle}
      loading={isLoading}
      secondaryText={t("zamknij")}
      onSecondary={onClose}
      onPrimary={onOrder}
      primaryText={isAdminRole(userRole) ? t("zapisz") : t("__zamow")}
      disablePrimary={!term}
    >
      <Box sx={styles.content}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          label={t("__termin_dostawy")}
          value={term}
          onChange={onChange}
          minDate={minDate}
          showToolbar={false}
          renderInput={(params) => <TextField {...params} />}
          renderDay={renderWeekPickerDay}
          defaultCalendarMonth={minDate}
        />
      </Box>
    </ConfirmationDialog>
  );
};

export default TermPicker;
