import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Filters,
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import {
  Brake,
  BrakePatchRequest,
  BrakePostRequest,
} from "../../types/brake-types";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { DEFAULT_BRAKE_SORT } from "../slices/brake-slice";

export const getBrakes = createAsyncThunk(
  "brake/getBrakes",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { brake } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, brake)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Brake>>("/brake", {
      params: flatParams,
    });

    return preparePageForAction<Brake>(data, params);
  }
);

export const getAllBrakes = createAsyncThunk(
  "brakes/getAllBrakes",
  async (config: { active?: boolean }) => {
    const filters: Filters = {};

    if (config.active) {
      filters.active = config.active;
    }

    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort: DEFAULT_BRAKE_SORT,
      filters,
    };

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Brake>>("/brake", {
      params: flatParams,
    });
    return data.content;
  }
);

export const getBrake = createAsyncThunk(
  "brake/getBrake",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Brake>>("/brake", {
      params: { id },
    });
    return getFirstElementFromPage<Brake>(data);
  }
);

export const addBrake = createAsyncThunk(
  "brake/addBrake",
  async (params: BrakePostRequest, thunkAPI) => {
    const { data } = await axios.post<Brake>("/brake", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editBrake = createAsyncThunk(
  "brake/editBrake",
  async (params: BrakePatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Brake>("/brake", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
