export const getCommonProps = (
  clientDelivery: boolean,
  required: boolean,
  disableAll: boolean
) => ({
  fullWidth: true,
  size: "small" as const,
  variant: "outlined" as const,
  disabled: !clientDelivery || disableAll,
});
