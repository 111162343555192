import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import useFetchEntity, { FetchEntityArgs } from "../../../hooks/useFetchEntity";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getStyles } from "../../partners-page/partner-edit/partner-edit.styles";
import { Variant } from "../../../types/variant-types";
import { Route } from "../../../constants/navigation-constants";
import { getAllBrakes } from "../../../redux/actions/brake-actions";
import { getAllColors } from "../../../redux/actions/color-actions";
import { getAllFillings } from "../../../redux/actions/filling-actions";
import {
  addVariant,
  editVariant,
  getVariant,
} from "../../../redux/actions/variant-actions";
import { useFormik } from "formik";
import {
  getInitFormValues,
  getValidationSchema,
  mapFormValuesToVariantPatchRequest,
  mapFormValuesToVariantPostRequest,
} from "./variant-edit.utils";
import { Filling } from "../../../types/filling-types";
import Box from "@mui/material/Box";
import Section from "../../../components/section/section";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ComponentsAvailability from "../../../components/components-availability/components-availability";
import { getCover } from "../../../redux/actions/cover-actions";
import { Cover } from "../../../types/cover-types";
import { FormValues, FormValuesWithPrices } from "./varaint-edit.types";
import VariantPrices, {
  VariantPriceRow,
} from "./variant-prices/variant-prices";
import { getCategory } from "../../../redux/actions/category-actions";
import { Category } from "../../../types/category-types";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../redux/slices/changes-slice";
import SectionTitle from "../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../redux/redux-utils";
import { VariantPrice } from "../../../types/price-types";
import FormHelperText from "@mui/material/FormHelperText";
import { getModuleDependantPriceItems } from "../../../redux/actions/price-actions";
import equal from "fast-deep-equal";
import { getInputAdornment } from "../../../utils/common-utils";
import NumericInput from "../../../components/numeric-input/numeric-input";

interface VariantEditProps extends WithPageLoaderProps {}

const VariantEdit = ({ finishLoading }: VariantEditProps) => {
  const { id, modelId } = useParams<{ id?: string; modelId: string }>();
  const [edited, setEdited] = useState<Variant | null>(null);
  const [model, setModel] = useState<Cover | null | undefined>(null);
  const [category, setCategory] = useState<Category | null>(null);
  const { content: variants, loading } = useSelector(
    (state: Store) => state.variant
  );
  const allFillings = useSelector((state: Store) =>
    state.filling.allFillings?.filter((filling) => filling.active)
  );
  const { originalData } = useSelector((state: Store) => state.changes);
  const { moduleDependantPriceItems } = useSelector(
    (state: Store) => state.price
  );
  const [prices, setPrices] = useState<VariantPriceRow[]>([]);
  const [originalPrices, setOriginalPrices] = useState<VariantPrice[]>([]);
  const [originalPriceRows, setOriginalPriceRows] = useState<VariantPriceRow[]>(
    []
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<Variant> = {
      callback: () => {},
      collection: variants,
      fallbackRoute: `${Route.CoverEdit}/${modelId}`,
      fetcherAction: getVariant,
      id,
    };
    const responses = await Promise.all([
      fetchEntity<Variant>(fetchArgs),
      dispatch(getAllBrakes({})),
      dispatch(getAllColors({})),
      dispatch(getAllFillings({})),
      dispatch(getCover(modelId!)),
      dispatch(getModuleDependantPriceItems(true)),
    ]);

    const [fetchedVariant, , , fillingsPayload, coverPayload] = responses;

    fetchedVariant && setOriginalPrices(fetchedVariant.price);

    const fetchedFillings = fillingsPayload.payload
      ? (fillingsPayload.payload as Filling[])
      : allFillings;

    const cover = coverPayload.payload as Cover;
    setModel(cover);

    const categoryPayload = await dispatch(
      getCategory(cover?.categoryId?.toString())
    );

    const category = categoryPayload.payload as Category;
    setCategory(category);

    let inheritedFillings: Filling[];

    if (id && fetchedVariant && fetchedFillings) {
      if (fetchedVariant?.componentsAvailability.fillingsInherited) {
        if (cover?.componentsAvailability.fillingsInherited) {
          if (category?.componentsAvailability.fillingsInherited) {
            inheritedFillings = fetchedFillings || [];
          } else {
            inheritedFillings = category?.componentsAvailability.fillings || [];
          }
        } else {
          inheritedFillings = cover?.componentsAvailability.fillings || [];
        }
      } else {
        inheritedFillings =
          fetchedVariant?.componentsAvailability.fillings || [];
      }

      const priceRows = inheritedFillings
        .filter((filling) => filling.active)
        .map<VariantPriceRow>((filling) => {
          const existingPriceRow = fetchedVariant.price.find(
            (priceRow) => priceRow.fillingId === filling.id
          );

          return {
            active: existingPriceRow?.active || false,
            fillingId: filling.id,
            label: filling.label,
            error: false,
            helperText: t("niepoprawna_wartosc"),
            price: existingPriceRow?.price.toString() || "0",
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      setPrices(priceRows);
      setOriginalPriceRows(priceRows);
    } else {
      if (cover?.componentsAvailability.fillingsInherited) {
        if (category?.componentsAvailability.fillingsInherited) {
          inheritedFillings = fetchedFillings || [];
        } else {
          inheritedFillings = category?.componentsAvailability.fillings || [];
        }
      } else {
        inheritedFillings = cover?.componentsAvailability.fillings || [];
      }

      const priceRows = mapFillingsToPriceRows(inheritedFillings);
      setPrices(priceRows);
      setOriginalPriceRows(priceRows);
    }
    if (fetchedVariant) {
      setEdited(fetchedVariant);
    }
    finishLoading();
  };

  const onSubmit = async (values: FormValues) => {
    const valuesWithPrices: FormValuesWithPrices = { ...values, prices };
    const saveFn = edited ? submitEdit : submitAdd;
    const saved = await saveFn(valuesWithPrices);
    if (saved) {
      setEdited(saved);
    }
  };

  const submitAdd = async (values: FormValuesWithPrices) => {
    const variant = mapFormValuesToVariantPostRequest(values);
    const { payload } = await dispatch(addVariant(variant));
    return payload as Variant;
  };

  const submitEdit = async (values: FormValuesWithPrices) => {
    const variant = mapFormValuesToVariantPatchRequest(values, edited!);
    const originalEntity = mapFormValuesToVariantPatchRequest(
      originalData as FormValuesWithPrices,
      edited!
    );
    const adjustedEntity = {
      ...preparePatchEntity(variant, originalEntity),
      secondaryHeight: variant.secondaryHeight,
    };

    if (adjustedEntity.price) {
      const adjustedPrices: VariantPrice[] = [];
      adjustedEntity.price.forEach((patchPrice) => {
        const originalPrice = originalEntity.price?.find(
          (originalPrice) => originalPrice.fillingId === patchPrice.fillingId
        );
        if (originalPrice) {
          if (!equal(originalPrice, patchPrice)) {
            const adjustedPrice = {
              ...preparePatchEntity(patchPrice, originalPrice),
              fillingId: patchPrice.fillingId,
            };
            adjustedPrices.push(adjustedPrice);
          }
        } else {
          adjustedPrices.push(patchPrice);
        }
      });
      adjustedEntity.price =
        adjustedPrices.length > 0 ? adjustedPrices : undefined;
    }

    const { payload } = await dispatch(editVariant(adjustedEntity));
    return payload as Variant;
  };

  const formik = useFormik({
    initialValues: getInitFormValues(edited),
    validationSchema: getValidationSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(edited);
    edited && setOriginalPrices(edited.price);
    setOriginalPriceRows(prices);
    dispatch(setOriginalData({ originalData: { ...originalData, prices } }));

    return () => {
      dispatch(resetChanges());
    };
  }, [edited]);

  useEffect(() => {
    dispatch(applyChanges({ data: { ...formik.values, prices } }));
  }, [formik.values, prices]);

  const mapFillingsToPriceRows = (fillings: Filling[]): VariantPriceRow[] =>
    fillings
      .filter((filling) => filling.active)
      .map<VariantPriceRow>((filling) => {
        const commons = {
          fillingId: filling.id,
          helperText: t("niepoprawna_wartosc"),
          label: filling.label,
        };

        const originalPrice = originalPrices.find(
          (price) => price.fillingId === filling.id
        );

        if (originalPrice) {
          return {
            active: originalPrice.active,
            error: false,
            price: originalPrice.price.toString(),
            ...commons,
          };
        }

        return {
          active: false,
          error: false,
          price: "0",
          ...commons,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

  const onFillingsInheritedChanged = (value: boolean) => {
    let fillings: Filling[];
    if (value) {
      if (model?.componentsAvailability.fillingsInherited) {
        if (category?.componentsAvailability.fillingsInherited) {
          fillings = allFillings || [];
        } else {
          fillings = category?.componentsAvailability.fillings || [];
        }
      } else {
        fillings = model?.componentsAvailability.fillings || [];
      }
    } else {
      fillings = formik.values.fillings;
    }

    const priceRows = mapFillingsToPriceRows(fillings);
    setPrices(priceRows);
  };

  const onFillingsChanged = (fillings: Filling[]) => {
    const priceRows = fillings
      .map<VariantPriceRow>((filling) => {
        const commons = {
          fillingId: filling.id,
          helperText: t("niepoprawna_wartosc"),
          label: filling.label,
        };

        const existingRow = prices.find((row) => row.fillingId === filling.id);

        if (existingRow) {
          return {
            active: existingRow.active,
            error: existingRow.error,
            price: existingRow.price,
            ...commons,
          };
        }

        const originalPrice = originalPrices.find(
          (price) => price.fillingId === filling.id
        );

        if (originalPrice) {
          return {
            active: originalPrice.active,
            error: false,
            price: originalPrice.price.toString(),
            ...commons,
          };
        }

        return {
          active: false,
          error: false,
          price: "0",
          ...commons,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    setPrices(priceRows);
  };

  const onBackClick = () => navigate(`${Route.CoverEdit}/${modelId}`);

  useEffect(() => {
    if (model) {
      formik.setFieldValue("modelId", model.id.toString());
    }
  }, [model]);

  if (modelId === undefined || model === undefined) {
    navigate(Route.Covers);
  }

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(edited));
    setPrices(originalPriceRows);
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={edited ? t("__edycja_wariantu") : t("__nowy_wariant")}
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(edited)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_podstawowe")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <TextField
              id="__model"
              name="__model"
              fullWidth
              disabled
              label={t("__model")}
              size="small"
              value={model?.label || ""}
            />
          </Box>
          <Box sx={styles.field}>
            <FormControl fullWidth>
              <InputLabel>{t("status")}</InputLabel>
              <Select
                id="active"
                name="active"
                size="small"
                value={formik.values.active?.toString()}
                label={t("status")}
                disabled={!edited}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  formik.setFieldValue("active", value);
                }}
              >
                <MenuItem value="true">{t("aktywny")}</MenuItem>
                <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.field}>
            <FormControl
              fullWidth
              size="small"
              error={
                formik.touched.modulesCount &&
                Boolean(formik.errors.modulesCount)
              }
            >
              <InputLabel>{t("__liczba_modulow")}</InputLabel>
              <Select
                id="modulesCount"
                name="modulesCount"
                size="small"
                value={formik.values.modulesCount}
                label={t("__liczba_modulow")}
                onChange={formik.handleChange}
              >
                {moduleDependantPriceItems?.map((priceItem) => (
                  <MenuItem key={priceItem.id} value={priceItem.moduleCount}>
                    {priceItem.moduleCount}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.modulesCount && formik.errors.modulesCount}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="length"
              name="length"
              fullWidth
              label={t("__dlugosc")}
              size="small"
              value={formik.values.length}
              onChange={formik.handleChange}
              error={formik.touched.length && Boolean(formik.errors.length)}
              helperText={formik.touched.length && formik.errors.length}
              InputProps={getInputAdornment("cm")}
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="widthInt"
              name="widthInt"
              fullWidth
              label={t("__szerokosc_wewnetrzna")}
              size="small"
              value={formik.values.widthInt}
              onChange={formik.handleChange}
              error={formik.touched.widthInt && Boolean(formik.errors.widthInt)}
              helperText={formik.touched.widthInt && formik.errors.widthInt}
              InputProps={getInputAdornment("cm")}
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="widthExt"
              name="widthExt"
              fullWidth
              label={t("__szerokosc_zewnetrzna")}
              size="small"
              value={formik.values.widthExt}
              onChange={formik.handleChange}
              error={formik.touched.widthExt && Boolean(formik.errors.widthExt)}
              helperText={formik.touched.widthExt && formik.errors.widthExt}
              InputProps={getInputAdornment("cm")}
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="height"
              name="height"
              fullWidth
              label={t("__wysokosc")}
              size="small"
              value={formik.values.height}
              onChange={formik.handleChange}
              error={formik.touched.height && Boolean(formik.errors.height)}
              helperText={formik.touched.height && formik.errors.height}
              InputProps={getInputAdornment("cm")}
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="secondaryHeight"
              name="secondaryHeight"
              fullWidth
              label={t("__druga_wysokosc")}
              size="small"
              value={formik.values.secondaryHeight}
              onChange={formik.handleChange}
              error={
                formik.touched.secondaryHeight &&
                Boolean(formik.errors.secondaryHeight)
              }
              helperText={
                formik.touched.secondaryHeight && formik.errors.secondaryHeight
              }
              InputProps={getInputAdornment("cm")}
            />
          </Box>
          <Box sx={{ ...styles.field, display: "flex" }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="standardLength"
                  name="standardLength"
                  checked={formik.values.standardLength}
                  onChange={formik.handleChange}
                />
              }
              label={t("__standardowa_dlugosc")}
            />
          </Box>
        </Box>
      </Section>
      <ComponentsAvailability
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        fillingsInherited={formik.values.fillingsInherited}
        colorsInherited={formik.values.colorsInherited}
        brakesInherited={formik.values.brakesInherited}
        doorsInherited={formik.values.doorsInherited}
        clapsInherited={formik.values.clapsInherited}
        brakes={formik.values.brakes}
        colors={formik.values.colors}
        doors={formik.values.doors}
        claps={formik.values.claps}
        fillings={formik.values.fillings}
        infoText="__wszystkie_z_modelu"
        additionalFillingsInheritedHandler={onFillingsInheritedChanged}
        additionalFillingsHandler={onFillingsChanged}
      />
      <VariantPrices variantPrices={prices} setVariantPrices={setPrices} />
    </form>
  );
};

export default withPageLoader(VariantEdit);
