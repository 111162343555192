import { Order, Payment } from "../../../types/order-types";
import {
  ClientPrice,
  CurrentOrder,
  DEFAULT_CLIENT,
  DEFAULT_CLIENT_PRICE,
  DEFAULT_DETAILED_CLIENT_PRICE,
  PriceWithDiscount,
} from "../../../redux/slices/order-slice";
import { Variant } from "../../../types/variant-types";
import * as yup from "yup";
import { TFunction } from "react-i18next";
import { CURRENCIES, DEFAULT_CURRENCY } from "../../../types/price-types";

export const getClientPrice = (
  payment: Payment,
  isClient: boolean
): ClientPrice => {
  if (!isClient) {
    return DEFAULT_CLIENT_PRICE;
  }

  if (
    payment.clientPrice !== undefined &&
    payment.clientDiscount !== undefined
  ) {
    const clientDiscount = payment.clientDiscount;
    const price = payment.clientPrice;
    const base = clientDiscount === 0 ? price : price / (1 - clientDiscount);

    return {
      base: base.toString(),
      discount: (clientDiscount * 100).toString(),
      final: payment.clientPrice.toString(),
      vatRate: (payment.clientVat * 100).toString(),
      finalWithVat: (payment.clientPrice * (1 + payment.clientVat)).toString(),
      currency:
        CURRENCIES.find(
          (currency) => currency.code === payment.clientCurrency
        ) || DEFAULT_CURRENCY,
      isDetailedPrice: false,
      detailedClientPrice: DEFAULT_DETAILED_CLIENT_PRICE,
    };
  }

  let totalFinal = 0;

  const buildPriceWithDiscount = (
    clientPrice?: number,
    clientDiscount?: number
  ): PriceWithDiscount => {
    const price = clientPrice || 0;
    const discount = (clientDiscount || 0) * 100;
    const final = price * (1 - discount / 100);
    totalFinal += final;

    return {
      price: price.toString(),
      discount: discount.toString(),
      final: final.toString(),
    };
  };

  return {
    base: DEFAULT_CLIENT_PRICE.base,
    discount: DEFAULT_CLIENT_PRICE.discount,
    vatRate: (payment.clientVat * 100).toString(),
    currency:
      CURRENCIES.find((currency) => currency.code === payment.clientCurrency) ||
      DEFAULT_CURRENCY,
    isDetailedPrice: true,
    detailedClientPrice: {
      boxMontage: buildPriceWithDiscount(
        payment.boxMontageClientPrice,
        payment.boxMontageClientPriceDiscount
      ),
      transport: buildPriceWithDiscount(
        payment.transportClientPrice,
        payment.transportClientPriceDiscount
      ),
      variant: buildPriceWithDiscount(
        payment.variantClientPrice,
        payment.variantClientPriceDiscount
      ),
      railExtension: buildPriceWithDiscount(
        payment.railExtensionClientPrice,
        payment.railExtensionClientPriceDiscount
      ),
      montage: buildPriceWithDiscount(
        payment.montageClientPrice,
        payment.montageClientPriceDiscount
      ),
      brake: buildPriceWithDiscount(
        payment.brakeClientPrice,
        payment.brakeClientPriceDiscount
      ),
      door: buildPriceWithDiscount(
        payment.doorClientPrice,
        payment.doorClientPriceDiscount
      ),
    },
    final: totalFinal.toString(),
    finalWithVat: (totalFinal * (1 + payment.clientVat)).toString(),
  };
};

export const mapOrderToCurrentOrder = (order: Order): CurrentOrder => {
  const variant: Variant = {
    ...order.variant,
    length: order.length,
    widthInt: order.widthInt,
    widthExt: order.widthExt,
    modulesCount: order.modulesCount,
    height: order.height,
    modelId: order.model.id,
  };
  return {
    brake: order.brake,
    brakeKey: order.brakeKey,
    category: order.category,
    clapType: order.clapType,
    client: order.client || DEFAULT_CLIENT,
    clientDelivery: Boolean(order.client),
    clientPrice: getClientPrice(order.payment, !!order.client),
    color: order.color,
    distance: order.distance?.toString() || "0",
    doorType: order.doorType,
    filling: { ...order.filling, price: 0 },
    floorBase: order.floorBase,
    model: order.model,
    partner: order.partner,
    profile: order.profile,
    railExtension: order.railLength.toString(),
    variant: variant,
    wallIncrease: order.wallIncrease,
    withMontage: order.withMontage,
    withTransport: order.withTransport,
    id: order.id,
    status: order.status,
    partnerPrice: order.payment.partnerPrice,
    term: order.term ? new Date(order.term) : null,
    payment: order.payment,
    railPosition: order.railPosition,
    box: !order.box,
  };
};

function emptyStringToNull(value: any, originalValue: any) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
}

export const getCalculateSchema = (
  t: TFunction<"translation">,
  box: boolean
) => {
  const invalidValueError = t("niepoprawna_wartosc");
  const requiredFieldError = t("wymagane_pole");

  return yup.object({
    railExtension: yup
      .number()
      .integer(invalidValueError)
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .max(1000, invalidValueError)
      .min(box ? 220 : 0, invalidValueError),
    distance: yup
      .number()
      .integer(invalidValueError)
      .transform(emptyStringToNull)
      .nullable()
      .typeError(invalidValueError),
  });
};

export const getOrderSchema = (
  t: TFunction<"translation">,
  clientRequired: boolean,
  distanceRequired: boolean,
  termRequired: boolean,
  railPositionRequired: boolean,
  box: boolean,
  isDetailedPrice: boolean
) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  const clientSchema = yup.object({
    name: yup.string().required(requiredFieldError),
    address: yup.object({
      countryId: yup
        .number()
        .required(requiredFieldError)
        .min(0, requiredFieldError),
      zipCode: yup.string().required(requiredFieldError),
      city: yup.string().required(requiredFieldError),
    }),
  });

  const distanceSchema = yup
    .number()
    .integer(invalidValueError)
    .transform(emptyStringToNull)
    .nullable()
    .typeError(invalidValueError);

  const basicPriceSchema = yup.object({
    base: yup.number().min(0, invalidValueError).typeError(requiredFieldError),
    discount: yup
      .number()
      .typeError(requiredFieldError)
      .min(0, invalidValueError)
      .max(100, invalidValueError),
    final: yup.number().min(0, invalidValueError).typeError(requiredFieldError),
    vatRate: yup
      .number()
      .typeError(requiredFieldError)
      .min(0, invalidValueError)
      .max(100, invalidValueError),
    finalWithVat: yup
      .number()
      .min(0, invalidValueError)
      .typeError(requiredFieldError),
  });

  const priceWithDiscountSchema = yup.object({
    price: yup.number().min(0, invalidValueError).typeError(requiredFieldError),
    discount: yup
      .number()
      .min(0, invalidValueError)
      .typeError(requiredFieldError),
    final: yup.number().min(0, invalidValueError).typeError(requiredFieldError),
  });

  const detailedPriceSchema = yup.object({
    vatRate: yup
      .number()
      .typeError(requiredFieldError)
      .min(0, invalidValueError)
      .max(100, invalidValueError),
    detailedClientPrice: yup.object({
      variant: priceWithDiscountSchema,
      transport: priceWithDiscountSchema,
      montage: priceWithDiscountSchema,
      door: priceWithDiscountSchema,
      brake: priceWithDiscountSchema,
      railExtension: priceWithDiscountSchema,
      boxMontage: priceWithDiscountSchema,
    }),
  });

  const termSchema = yup
    .date()
    .required(requiredFieldError)
    .typeError(requiredFieldError);

  const railPositionSchema = yup
    .string()
    .required(requiredFieldError)
    .typeError(requiredFieldError);

  return yup.object({
    ...{
      variant: yup.object().typeError(requiredFieldError),
      filling: yup.object().typeError(requiredFieldError),
      color: yup.object().typeError(requiredFieldError),
      brake: yup.object().typeError(requiredFieldError),
      wallIncrease: yup.number().typeError(requiredFieldError),
      floorBase: yup.string().required(requiredFieldError),
      railExtension: yup
        .number()
        .integer(invalidValueError)
        .typeError(invalidValueError)
        .required(requiredFieldError)
        .max(1000, invalidValueError)
        .min(box ? 220 : 0, invalidValueError),
      withMontage: yup.boolean().typeError(requiredFieldError),
      withTransport: yup.boolean().typeError(requiredFieldError),
      box: yup.boolean().typeError(requiredFieldError),
    },
    ...(clientRequired ? { client: clientSchema } : {}),
    ...(distanceRequired ? { distance: distanceSchema } : {}),
    ...(clientRequired
      ? {
          clientPrice: isDetailedPrice ? detailedPriceSchema : basicPriceSchema,
        }
      : {}),
    ...(termRequired ? { term: termSchema } : {}),
    ...(railPositionRequired ? { railPosition: railPositionSchema } : {}),
  });
};
