import { Theme } from "@mui/material";

export const getStyles = (theme: Theme, isMobile: boolean) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  field: {
    flex: "1 1 0",
    minWidth: 250,
  },
  fields: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  dialog: {
    "& .MuiDialog-paper": {
      maxWidth: isMobile ? 600 : 1000,
    },
  },
});
