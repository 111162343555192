import InputAdornment from "@mui/material/InputAdornment";
import React from "react";

export const getCommonProps = (
  prices: boolean,
  disableAll: boolean,
  clientDelivery: boolean,
  required = true
) => ({
  fullWidth: true,
  size: "small" as const,
  variant: "outlined" as const,
  disabled: !prices || !clientDelivery || disableAll,
  required,
});

export const formatValue = (value?: number) => {
  return value === undefined ? "" : value.toString();
};

export const getInputProps = (sign: string) => ({
  endAdornment: <InputAdornment position="start">{sign}</InputAdornment>,
});

export const formatDiscount = (value?: number) => {
  if (value === undefined) {
    return "";
  }
  const percentage = value * 100;
  return `${percentage.toString()}`;
};

export const calculateFinal = (
  base: number,
  discount: number,
  vatRate: number,
  defaultFinal: string
) => {
  const final = base * (1 - discount / 100);
  const adjustedFinal = isNaN(final) ? Number(defaultFinal) : final;
  const finalWithVat = adjustedFinal * (1 + vatRate / 100);
  return [adjustedFinal.toString(), finalWithVat.toString()];
};
