import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import {
  getNotifications,
  getUnseenIds,
  markNotificationAsClicked,
  markNotificationsAsSeen,
} from "../../../../redux/actions/notification-actions";
import { Menu, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { getStyles } from "./notifications-styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../utils/formatter-utils";
import { Colors } from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";
import { Route } from "../../../../constants/navigation-constants";
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<number>(0);

  const notificationReducer = useSelector((state: Store) => state.notification);
  const { size, sort, filters, notifications, unseenIds, page, totalPages } =
    notificationReducer;
  pageRef.current = page;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (scrollableRef.current) {
        scrollableRef.current.addEventListener("scroll", onScroll);
      }
    }, 300);

    if (open && unseenIds.length > 0) {
      dispatch(markNotificationsAsSeen());
    }

    return () => {
      if (scrollableRef.current) {
        scrollableRef.current.removeEventListener("scroll", onScroll);
      }
    };
  }, [open]);

  const init = async () => {
    dispatch(getNotifications({ params: { size, sort, filters, page } }));
    dispatch(getUnseenIds());
  };

  const onScroll = () => {
    if (scrollableRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = scrollableRef.current;
      if (scrollHeight === scrollTop + clientHeight) {
        const pageToFetch =
          pageRef.current + 1 > totalPages ? totalPages : pageRef.current + 1;

        if (pageToFetch === pageRef.current) return;

        dispatch(
          getNotifications({
            params: {
              size,
              sort,
              filters,
              page: pageToFetch,
            },
          })
        );
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnNotificationClick = async (id: number, orderId: number) => {
    const url = `${Route.OrderEdit}/${orderId}`;
    if (url === location.pathname) {
      await dispatch(markNotificationAsClicked(id));
      navigate(url);
      navigate(0);
    } else {
      dispatch(markNotificationAsClicked(id));
      navigate(url);
    }
  };

  const renderNoNotificationsMessage = () => (
    <Box sx={styles.noNotifications}>{t("__brak_powiadomien")}</Box>
  );

  const renderNotifications = () =>
    notifications.map((notification) => (
      <MenuItem
        key={notification.id}
        sx={styles.menuItem}
        onClick={() =>
          handleOnNotificationClick(notification.id, notification.offerId)
        }
      >
        <Box sx={styles.item}>
          <Box sx={styles.texts}>
            <Typography sx={styles.text}>
              {t("__zmiana_statusu")} <strong>{notification.offerId}</strong>{" "}
              {t("__z")} <strong>{t(notification.fromStatus)}</strong>{" "}
              {t("__na")} <strong>{t(notification.toStatus)}</strong>
            </Typography>
            <Typography
              sx={{
                ...styles.date,
                color: notification.clicked
                  ? theme.palette.grey["500"]
                  : theme.palette.primary.main,
              }}
            >
              {formatDate(notification.createdDate)}
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.indicator,
              backgroundColor: notification.clicked
                ? Colors.Transparent
                : theme.palette.primary.main,
            }}
          />
        </Box>
      </MenuItem>
    ));

  return (
    <Box>
      <IconButton color="primary" onClick={handleClick}>
        <Badge sx={styles.badge} badgeContent={unseenIds.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        PaperProps={{ elevation: 0, sx: styles.menu }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        disableScrollLock
      >
        <Box sx={styles.wrapper} ref={scrollableRef}>
          {notifications.length > 0
            ? renderNotifications()
            : renderNoNotificationsMessage()}
        </Box>
      </Menu>
    </Box>
  );
};

export default Notifications;
