import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  editOrder,
  getOrderCounts,
  getOrders,
} from "../../../redux/actions/order-actions";
import { Box, useTheme } from "@mui/material";
import Section from "../../../components/section/section";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PaginationTable from "../../../components/pagination-table/pagination-table";
import { Order, OrderStatus } from "../../../types/order-types";
import { Columns } from "../../../components/pagination-table/table-types";
import MenuActions, {
  Action,
} from "../../../components/menu-actions/menu-actions";
import BallotIcon from "@mui/icons-material/Ballot";
import { Route } from "../../../constants/navigation-constants";
import OrderFilters from "./order-filters/order-filters";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { PageChanges } from "../../../types/common-types";
import { applyChangesToPage } from "../../../redux/redux-utils";
import {
  DEFAULT_CURRENT_ORDER,
  DEFAULT_ORDER_FILTERS,
  setCurrentOrder,
} from "../../../redux/slices/order-slice";
import {
  renderCityCell,
  renderClientCell,
  renderCreatedByCell,
  renderDateCell,
  renderIdCell,
  renderModelCell,
  renderPartnerCell,
  renderPriceCell,
  renderStatusCell,
} from "./order-list.utils";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TermPicker from "../order-edit/order-form/term-picker/term-picker";
import { format } from "date-fns";
import useRole from "../../../hooks/useRole";
import { isAdminRole } from "../../../utils/common-utils";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOrderDialog from "../order-edit/order-form/delete-order-dialog/delete-order-dialog";
import CancelOrderDialog from "../order-edit/order-form/cancel-order-dialog/cancel-order-dialog";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { getAllPartners } from "../../../redux/actions/partner-actions";
import DownloadIcon from "@mui/icons-material/Download";
import { getCountries } from "../../../redux/actions/country-actions";
import ExportDialog from "../../../components/export-dialog/export-dialog";
import TechnicalProjection from "../order-edit/order-form/technical-projection/technical-projection";
import { mapOrderToCurrentOrder } from "../order-edit/order-edit.utils";
import { getStyles } from "./order-list-styles";
import Chip from "@mui/material/Chip";
import {
  resetAreChangedOrders,
  resetAreNewOrders,
} from "../../../redux/slices/notification-slice";

interface OrdersListProps extends WithPageLoaderProps {}

const tableStyles = {
  minWidth: 950,
};

export const TOTAL = "total";

const OrdersList = ({ finishLoading }: OrdersListProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);
  const theme = useTheme();
  const styles = getStyles(theme);

  const reducer = useSelector((state: Store) => state.order);
  const { pageLoading, orderCounts, filters, entityLoading, content } = reducer;
  const { countries } = useSelector((state: Store) => state.country);
  const { areNewOrders, areChangedOrders } = useSelector(
    (state: Store) => state.notification
  );

  const [tab, setTab] = useState(TOTAL);
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [term, setTerm] = useState<Date | null>(null);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const [isExportModalOpen, setExportModalOpen] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (entityLoading === "succeeded") {
      fetchPage({ page: 0 });
      dispatch(
        getOrderCounts({ ...filters, status: DEFAULT_ORDER_FILTERS.status })
      );
    }
  }, [entityLoading]);

  useUpdateEffect(() => {
    handleTabChange();
  }, [tab]);

  const fetchPage = (changes?: PageChanges) => {
    const params = applyChangesToPage(reducer, changes);
    return dispatch(getOrders({ params, reload: true }));
  };

  const init = async () => {
    await Promise.all([
      fetchPage({
        page: 0,
        filters: { ...filters, status: DEFAULT_ORDER_FILTERS.status },
      }),
      dispatch(
        getOrderCounts({ ...filters, status: DEFAULT_ORDER_FILTERS.status })
      ),
      dispatch(getAllPartners()),
      dispatch(getCountries()),
    ]);
    finishLoading();
  };

  const handleTabChange = () => {
    if (tab === TOTAL) {
      fetchPage({
        page: 0,
        filters: { ...filters, status: DEFAULT_ORDER_FILTERS.status },
      });
      return;
    }
    fetchPage({
      page: 0,
      filters: { ...filters, status: tab },
    });

    if (areChangedOrders) {
      dispatch(
        getOrderCounts({ ...filters, status: DEFAULT_ORDER_FILTERS.status })
      );
    }

    if (tab === TOTAL || tab === OrderStatus.OFFER) {
      dispatch(resetAreNewOrders());
    }

    dispatch(resetAreChangedOrders());
  };

  const getTabLabel = (status: string, name?: string) => {
    return `${t(name || status)} (${orderCounts ? orderCounts[status] : ""})`;
  };

  const getClient = () => {
    return content?.find((x) => x.id === pickedId)?.client;
  };

  const getOrder = () => {
    return content?.find((x) => x.id === pickedId) || null;
  };

  const getClientCountryName = (order: Order | null) => {
    if (order === null) {
      return "";
    }
    return t(
      countries
        ?.find((c) => c.id === order.client?.address.countryId)
        ?.iso.toLowerCase() || ""
    );
  };

  const getPartnerCountryName = (order: Order | null) => {
    if (order === null) {
      return "";
    }
    return t(
      countries
        ?.find((c) => c.id === order.partner.address.countryId)
        ?.iso.toLowerCase() || ""
    );
  };

  const getActions = (order: Order): Action[] => [
    {
      id: "edit",
      icon: <BallotIcon color="primary" />,
      text: t("__szczegoly"),
      onClick: (id) => {
        navigate(`${Route.OrderEdit}/${id}`);
      },
    },
    ...(order.status === OrderStatus.OFFER
      ? [
          {
            id: "order",
            icon: <ShoppingCartIcon color="success" />,
            text: t("__zamow"),
            onClick: (id: number | undefined) => {
              handleOpenTermModal(id);
            },
          },
          {
            id: "delete",
            icon: <DeleteIcon color="error" />,
            text: t("__usun"),
            onClick: (id: number | undefined) => {
              handleOpenDeleteModal(id);
            },
          },
        ]
      : []),
    {
      id: "export",
      icon: <DownloadIcon color="primary" />,
      text: t("__pobierz"),
      onClick: (id) => {
        setPickedId(id);
        setExportModalOpen(true);
        const currentOrder = mapOrderToCurrentOrder(order);
        dispatch(setCurrentOrder({ currentOrder }));
      },
    },
    ...(order.status === OrderStatus.WAITING_FOR_ACCEPT
      ? [
          {
            id: "cancel",
            icon: <EventBusyIcon color="error" />,
            text: t("anuluj"),
            onClick: (id: number | undefined) => {
              handleOpenCancelModal(id);
            },
          },
        ]
      : []),
  ];

  const renderActionsCell = (order: Order) => (
    <MenuActions
      key={`${order.id}-actions-cell`}
      actions={getActions(order)}
      entityId={order.id}
    />
  );

  const columns: Columns<Order> = [
    {
      id: "offer-id",
      name: t("__numer_oferty"),
      cellRenderer: renderIdCell,
      flexGrow: 3,
    },
    ...(isAdminRole(userRole)
      ? [
          {
            id: "partner",
            name: t("partner"),
            cellRenderer: renderPartnerCell,
            flexGrow: 8,
          },
        ]
      : []),
    {
      id: "model",
      name: t("__model"),
      cellRenderer: renderModelCell,
      flexGrow: 8,
    },
    {
      id: "created-date",
      cellRenderer: renderDateCell,
      name: t("__data_utworzenia"),
      flexGrow: 4,
    },
    {
      id: "price",
      cellRenderer: renderPriceCell,
      name: t("__cena"),
      flexGrow: 4,
    },
    {
      id: "client",
      name: t("__klient"),
      cellRenderer: renderClientCell,
      flexGrow: 8,
    },
    {
      id: "city",
      name: t("miasto"),
      cellRenderer: renderCityCell,
      flexGrow: 8,
    },
    {
      id: "created-by",
      name: t("__dodane_przez"),
      cellRenderer: renderCreatedByCell,
      flexGrow: 8,
    },
    ...(tab === TOTAL
      ? [
          {
            id: "status",
            name: t("status"),
            cellRenderer: (order: Order) =>
              renderStatusCell(order.id, order.status, t),
            flexGrow: 8,
          },
        ]
      : []),
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const handleOpenTermModal = (id?: number) => {
    setOrderModalOpen(true);
    setPickedId(id);
  };

  const handleCloseTermModal = () => {
    setOrderModalOpen(false);
    setPickedId(undefined);
    setTerm(null);
  };

  const handleOpenDeleteModal = (id?: number) => {
    setDeleteModalOpen(true);
    setPickedId(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setPickedId(undefined);
  };

  const handleOpenCancelModal = (id?: number) => {
    setCancelModalOpen(true);
    setPickedId(id);
  };

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
    setPickedId(undefined);
  };

  const handleOrderClick = () => {
    if (pickedId !== undefined) {
      const adjustedTerm = term ? format(term, "yyyy-MM-dd") : undefined;
      dispatch(
        editOrder({
          id: pickedId,
          status: OrderStatus.WAITING_FOR_ACCEPT,
          term: adjustedTerm,
          client: getClient(),
        })
      );
      setPickedId(undefined);
      setOrderModalOpen(false);
      setTerm(null);
    }
  };

  const handleDeleteClick = () => {
    if (pickedId !== undefined) {
      dispatch(
        editOrder({
          id: pickedId,
          status: OrderStatus.DELETED,
          client: getClient(),
        })
      );
      setPickedId(undefined);
      setDeleteModalOpen(false);
    }
  };

  const handleCancelClick = () => {
    if (pickedId !== undefined) {
      dispatch(
        editOrder({
          id: pickedId,
          status: OrderStatus.CANCELED,
          client: getClient(),
        })
      );
      setPickedId(undefined);
      setCancelModalOpen(false);
    }
  };

  const table = (
    <PaginationTable<Order>
      withPagination={true}
      loading={pageLoading}
      //@ts-ignore
      fetcherAction={getOrders}
      reducerName="order"
      columns={columns}
    />
  );

  const closeExportModal = () => {
    setExportModalOpen(false);
    setPickedId(undefined);
    dispatch(
      setCurrentOrder({
        currentOrder: {
          ...DEFAULT_CURRENT_ORDER,
          partner: isAdmin ? null : reducer.currentOrder.partner,
        },
      })
    );
  };

  const onNewOrderChipClick = () => {
    fetchPage({
      page: 0,
      filters: { ...DEFAULT_ORDER_FILTERS, status: OrderStatus.OFFER },
    });
    dispatch(getOrderCounts(DEFAULT_ORDER_FILTERS));
    setTab(OrderStatus.OFFER);
    dispatch(resetAreNewOrders());
  };

  const onUpdateChipClick = () => {
    fetchPage({});
    dispatch(
      getOrderCounts({ ...filters, status: DEFAULT_ORDER_FILTERS.status })
    );
    dispatch(resetAreChangedOrders());
  };

  const renderSectionTitle = () => (
    <Box sx={styles.titleWrapper}>
      <span>{t("zamowienia")}</span>
      {areNewOrders && (
        <Chip
          sx={styles.newChip}
          label={t("__nowa_oferta")}
          color="success"
          variant="outlined"
          onClick={onNewOrderChipClick}
        />
      )}
      {areChangedOrders && (
        <Chip
          sx={styles.updateChip}
          label={t("__zmiany")}
          color="warning"
          variant="outlined"
          onClick={onUpdateChipClick}
        />
      )}
    </Box>
  );

  return (
    <Box>
      <TermPicker
        open={isOrderModalOpen}
        onClose={handleCloseTermModal}
        onOrder={handleOrderClick}
        term={term}
        onChange={setTerm}
      />
      <DeleteOrderDialog
        open={isDeleteModalOpen}
        onCancelClick={handleCloseDeleteModal}
        deleteCallback={handleDeleteClick}
        id={pickedId}
      />
      <CancelOrderDialog
        open={isCancelModalOpen}
        onCancelClick={handleCloseCancelModal}
        onConfirmation={handleCancelClick}
        id={pickedId}
      />
      <ExportDialog
        open={isExportModalOpen}
        clientCountryName={getClientCountryName(getOrder())}
        partnerCountryName={getPartnerCountryName(getOrder())}
        order={getOrder()}
        onFinish={closeExportModal}
        onClose={closeExportModal}
      />
      <Section
        title={renderSectionTitle()}
        actions={<OrderFilters />}
        sx={tableStyles}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: (theme) => theme.spacing(3),
          }}
        >
          <Tabs
            value={tab}
            onChange={(e, value) => setTab(value)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label={getTabLabel(TOTAL, "__wszystkie")} value={TOTAL} />
            <Tab
              label={getTabLabel(OrderStatus.OFFER, "__oferty")}
              value={OrderStatus.OFFER}
            />
            <Tab
              label={getTabLabel(OrderStatus.WAITING_FOR_ACCEPT)}
              value={OrderStatus.WAITING_FOR_ACCEPT}
            />
            <Tab
              label={getTabLabel(OrderStatus.ACCEPTED)}
              value={OrderStatus.ACCEPTED}
            />
            <Tab
              label={getTabLabel(OrderStatus.DELIVERED)}
              value={OrderStatus.DELIVERED}
            />
            <Tab
              label={getTabLabel(OrderStatus.CANCELED)}
              value={OrderStatus.CANCELED}
            />
            <Tab
              label={getTabLabel(OrderStatus.EXPIRED)}
              value={OrderStatus.EXPIRED}
            />
          </Tabs>
        </Box>
        {table}
      </Section>
      {pickedId !== undefined && (
        <Box
          sx={{
            opacity: 0,
            minWidth: tableStyles.minWidth,
          }}
        >
          <TechnicalProjection isPageLoading={false} />
        </Box>
      )}
    </Box>
  );
};

export default withPageLoader(OrdersList);
