import { Route } from "../../constants/navigation-constants";
import { MenuItemMetaData } from "./components/menu-item/menu-item.types";

export const drawerWidth = 240;

export const ADMIN_MENU_ITEMS: MenuItemMetaData[] = [
  { text: "zamowienia", icon: "shopping_cart", route: Route.Orders },
  {
    text: "konfiguracja",
    icon: "settings",
    route: Route.Config,
    nestedItems: [
      {
        text: "kolory",
        icon: "color_lens",
        route: Route.Colors,
      },
      {
        text: "blokady",
        icon: "lock",
        route: Route.Brakes,
      },
      {
        text: "wypelnienia",
        icon: "format_color_fill",
        route: Route.Fillings,
      },
      {
        text: "kategorie",
        icon: "category",
        route: Route.Categories,
      },
      {
        text: "profile",
        icon: "sort",
        route: Route.Profiles,
      },
    ],
  },
  { text: "zadaszenia", icon: "view_list", route: Route.Covers },
  { text: "aktualnosci", icon: "article", route: Route.PostManagement },
  { text: "partnerzy", icon: "business_center", route: Route.Partners },
  { text: "cennik", icon: "monetization_on", route: Route.Prices },
  { text: "uzytkownicy", icon: "people", route: Route.Users },
];

export const PARTNER_MENU_ITEMS: MenuItemMetaData[] = [
  { text: "zamowienia", icon: "shopping_cart", route: Route.Orders },
  { text: "aktualnosci", icon: "article", route: Route.PostOverview },
];
