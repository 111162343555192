import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import polandFlag from "../../../assets/images/png/poland_flag.png";
import germanyFlag from "../../../assets/images/png/germany_flag.png";
import englishFlag from "../../../assets/images/png/english_flag.png";
import swedenFlag from "../../../assets/images/png/sweden_flag.png";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import UnsavedChangesModal from "../../unsaved-changes-modal/unsaved-changes-modal";

interface Language {
  code: string;
  text: string;
  img: string;
}

const LANGUAGES: Language[] = [
  {
    code: "pl",
    text: "polski",
    img: polandFlag,
  },
  {
    code: "en",
    text: "English",
    img: englishFlag,
  },
  {
    code: "de",
    text: "Deutsch",
    img: germanyFlag,
  },
  {
    code: "sv",
    text: "svenska",
    img: swedenFlag,
  },
];

const getStyles = (theme: Theme) => ({
  image: {
    boxShadow: theme.shadows[2],
    width: 30,
  },
});

const getFlag = (code: string) => {
  const currentLanguage =
    LANGUAGES.find((lang) => lang.code === code) || LANGUAGES[0];
  return currentLanguage.img;
};

const LanguageSelect = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  const [pickedLang, setPickedLang] = useState<string | null>(null);
  const theme = useTheme();
  const styles = getStyles(theme);
  const { i18n } = useTranslation();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const currentFlag = getFlag(i18n.resolvedLanguage);

  const changeLanguageCallback = () => {
    window.location.reload();
  };

  const changeLanguage = () => {
    if (pickedLang) {
      i18n.changeLanguage(pickedLang, changeLanguageCallback);
    }
    setPickedLang(null);
    handleClose();
  };

  const onCancelClick = () => setOpen(false);

  const pickLang = (code: string) => {
    setOpen(true);
    setPickedLang(code);
  };

  return (
    <div>
      <UnsavedChangesModal
        open={isOpen}
        onCancelClick={onCancelClick}
        leaveCallback={changeLanguage}
      />
      <IconButton onClick={onClick}>
        <img src={currentFlag} alt="logo" style={styles.image} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock
      >
        <List>
          {LANGUAGES.map((lang) => (
            <ListItem
              key={lang.code}
              disablePadding
              onClick={() => pickLang(lang.code)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <img src={lang.img} alt="logo" style={styles.image} />
                </ListItemIcon>
                <ListItemText primary={lang.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
