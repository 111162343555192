import { Dispatch, SetStateAction, useEffect, useState } from "react";

const DEFAULT_DEBOUNCE_TIMEOUT = 500;

const useDebouncedState = <T>(
  initValue: T,
  delay?: number
): [T, T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(initValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initValue);

  const adjustedDelay = delay || DEFAULT_DEBOUNCE_TIMEOUT;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, adjustedDelay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, adjustedDelay]);

  return [value, debouncedValue, setValue];
};

export default useDebouncedState;
