import {
  FILLING_WIDTH,
  HEIGHT_PADDING,
  WHEELS_DIFF,
} from "./technical-projection.constants";
import { MetaData } from "./technical-projection.types";
import { Variant } from "../../../../../types/variant-types";
import { RailPosition } from "../../../../../types/order-types";

export const getRailAndWheelsWidth = (
  variant: Variant,
  railExtension: number,
  railCount: number
) => {
  let railWidth = 0;
  let wheelsWidth = 0;
  if (railCount === 1) {
    railWidth = (variant.widthExt - variant.widthInt + WHEELS_DIFF) / 2;
    wheelsWidth = railWidth - WHEELS_DIFF;
  } else if (railCount === 2) {
    railWidth = (variant.widthExt - variant.widthInt) / railCount;
  }

  return { railWidth, wheelsWidth };
};

export const prepareMetaData = (
  variant: Variant,
  railExtension: number,
  railCount: number,
  railPosition: RailPosition | null
): MetaData => {
  const totalLength = variant.length + railExtension;
  const { wheelsWidth, railWidth } = getRailAndWheelsWidth(
    variant,
    railExtension,
    railCount
  );

  const singleRailWidth = Math.round(railWidth / (variant.modulesCount + 1));
  const moduleLength = Math.round(
    (FILLING_WIDTH * variant.modulesCount + variant.length - FILLING_WIDTH) /
      variant.modulesCount
  );
  const totalHeight = variant.widthExt + HEIGHT_PADDING;

  return {
    modulesCount: variant.modulesCount,
    widthExt: variant.widthExt,
    widthInt: variant.widthInt,
    length: variant.length,
    railWidth,
    singleRailWidth,
    totalLength,
    moduleLength,
    railExtension,
    totalHeight,
    railPosition,
    wheelsWidth,
  };
};
