import React from "react";
import {
  Navigate,
  Route as RouterRoute,
  Routes as RouterRoutes,
} from "react-router-dom";
import { Route } from "../../constants/navigation-constants";
import PrivateRoute from "./private-route/private-route";
import MiniDrawer from "../navigation/drawer";
import PostOverviewList from "../../pages/post-pages/post-overview-page/post-overview-list/post-overview-list";
import PartnersList from "../../pages/partners-page/partners-list/partners-list";
import LoginPage from "../../pages/login-page/login-page";
import ProtectedRoute from "./protected-route/protected-route";
import { Roles } from "../../constants/roles";
import PartnerEdit from "../../pages/partners-page/partner-edit/partner-edit";
import UserEdit from "../../pages/users-page/user-edit/user-edit";
import UsersList from "../../pages/users-page/users-list/users-list";
import PostManagementList from "../../pages/post-pages/post-management-page/post-management-list/post-management-list";
import PostEdit from "../../pages/post-pages/post-management-page/post-edit/post-edit";
import ColorList from "../../pages/config-pages/color-page/color-list/color-list";
import BrakeList from "../../pages/config-pages/brake-page/brake-list/brake-list";
import FillingList from "../../pages/config-pages/filling-page/filling-list/filling-list";
import CategoryList from "../../pages/config-pages/category-page/category-list/category-list";
import ProfileList from "../../pages/config-pages/profile-page/profile-list/profile-list";
import BrakeEdit from "../../pages/config-pages/brake-page/brake-edit/brake-edit";
import ColorEdit from "../../pages/config-pages/color-page/color-edit/color-edit";
import FillingEdit from "../../pages/config-pages/filling-page/filling-edit/filling-edit";
import ProfileEdit from "../../pages/config-pages/profile-page/profile-edit/profile-edit";
import CategoryEdit from "../../pages/config-pages/category-page/category-edit/category-edit";
import PriceList from "../../pages/prices-page/price-list/price-list";
import PriceItemEdit from "../../pages/prices-page/price-item-edit/price-item-edit";
import ModuleDependantPriceItemEdit from "../../pages/prices-page/module-dependant-price-item-edit/md-price-item-edit";
import CoversList from "../../pages/covers-page/covers-list/covers-list";
import CoverEdit from "../../pages/covers-page/cover-edit/cover-edit";
import VariantEdit from "../../pages/covers-page/variant-edit/variant-edit";
import OrdersList from "../../pages/orders-page/orders-list/orders-list";
import OrderEdit from "../../pages/orders-page/order-edit/order-edit";

const getPrivateRoute = (element: JSX.Element, roles: Roles[]) => (
  <ProtectedRoute roles={roles}>{element}</ProtectedRoute>
);

const Routing = () => {
  return (
    <RouterRoutes>
      <RouterRoute
        path={Route.Layout}
        element={
          <PrivateRoute>
            <MiniDrawer />
          </PrivateRoute>
        }
      >
        <RouterRoute path="" element={<Navigate to={Route.Orders} />} />
        <RouterRoute
          path={Route.Colors}
          element={getPrivateRoute(<ColorList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.ColorEdit}
          element={getPrivateRoute(<ColorEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.ColorEdit}/:id`}
          element={getPrivateRoute(<ColorEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.Brakes}
          element={getPrivateRoute(<BrakeList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.BrakeEdit}
          element={getPrivateRoute(<BrakeEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.BrakeEdit}/:id`}
          element={getPrivateRoute(<BrakeEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.Fillings}
          element={getPrivateRoute(<FillingList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.FillingEdit}
          element={getPrivateRoute(<FillingEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.FillingEdit}/:id`}
          element={getPrivateRoute(<FillingEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.Categories}
          element={getPrivateRoute(<CategoryList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.CategoryEdit}
          element={getPrivateRoute(<CategoryEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.CategoryEdit}/:id`}
          element={getPrivateRoute(<CategoryEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.Profiles}
          element={getPrivateRoute(<ProfileList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.ProfileEdit}
          element={getPrivateRoute(<ProfileEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.ProfileEdit}/:id`}
          element={getPrivateRoute(<ProfileEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.Covers}
          element={getPrivateRoute(<CoversList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.CoverEdit}
          element={getPrivateRoute(<CoverEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.CoverEdit}/:id`}
          element={getPrivateRoute(<CoverEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.VariantEdit}/:modelId`}
          element={getPrivateRoute(<VariantEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.VariantEdit}/:modelId/:id`}
          element={getPrivateRoute(<VariantEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.PostManagement}
          element={getPrivateRoute(<PostManagementList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.PostEdit}
          element={getPrivateRoute(<PostEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.PostEdit}/:postId`}
          element={getPrivateRoute(<PostEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.PostOverview}
          element={getPrivateRoute(<PostOverviewList />, [Roles.Partner])}
        />
        <RouterRoute
          path={Route.Orders}
          element={getPrivateRoute(<OrdersList />, [
            Roles.Admin,
            Roles.Partner,
          ])}
        />
        <RouterRoute
          path={Route.OrderEdit}
          element={getPrivateRoute(<OrderEdit />, [Roles.Admin, Roles.Partner])}
        />
        <RouterRoute
          path={`${Route.OrderEdit}/:id`}
          element={getPrivateRoute(<OrderEdit />, [Roles.Admin, Roles.Partner])}
        />
        <RouterRoute
          path={Route.Partners}
          element={getPrivateRoute(<PartnersList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.PartnerEdit}
          element={getPrivateRoute(<PartnerEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.PartnerEdit}/:partnerId`}
          element={getPrivateRoute(<PartnerEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.Prices}
          element={getPrivateRoute(<PriceList />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.PriceItemEdit}/:id`}
          element={getPrivateRoute(<PriceItemEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.ModuleDependantPriceItemEdit}
          element={getPrivateRoute(<ModuleDependantPriceItemEdit />, [
            Roles.Admin,
          ])}
        />
        <RouterRoute
          path={`${Route.ModuleDependantPriceItemEdit}/:id`}
          element={getPrivateRoute(<ModuleDependantPriceItemEdit />, [
            Roles.Admin,
          ])}
        />
        <RouterRoute
          path={Route.Users}
          element={getPrivateRoute(<UsersList />, [Roles.Admin])}
        />
        <RouterRoute
          path={Route.UsersEdit}
          element={getPrivateRoute(<UserEdit />, [Roles.Admin])}
        />
        <RouterRoute
          path={`${Route.UsersEdit}/:userId`}
          element={getPrivateRoute(<UserEdit />, [Roles.Admin])}
        />
      </RouterRoute>
      <RouterRoute path={Route.Login} element={<LoginPage />} />
      <RouterRoute path="*" element={<Navigate to={Route.Layout} />} />
    </RouterRoutes>
  );
};

export default Routing;
