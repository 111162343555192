import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../types/user-types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, useTheme } from "@mui/material";
import { getStyles } from "../../partners-page/partner-edit/partner-edit.styles";
import { getAllPartners } from "../../../redux/actions/partner-actions";
import { Route } from "../../../constants/navigation-constants";
import {
  addUser,
  editUser,
  getUser,
} from "../../../redux/actions/user-actions";
import { FormValues } from "./user-edit.types";
import {
  getInitFormValues,
  getValidationSchema,
  isPartnerRole,
  mapFormValuesToUserPatchRequest,
  mapFormValuesToUserPostRequest,
} from "./user-edit.utils";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Section from "../../../components/section/section";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import useFetchEntity, { FetchEntityArgs } from "../../../hooks/useFetchEntity";
import Autocomplete from "@mui/material/Autocomplete";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../redux/slices/changes-slice";
import SectionTitle from "../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../redux/redux-utils";

interface UserEditProps extends WithPageLoaderProps {}

const UserEdit = ({ finishLoading }: UserEditProps) => {
  const { content: users, loading } = useSelector((state: Store) => state.user);
  const { roles } = useSelector((state: Store) => state.auth);
  const { allPartners } = useSelector((state: Store) => state.partner);
  const { originalData } = useSelector((state: Store) => state.changes);
  const { userId } = useParams<{ userId?: string }>();
  const [editedUser, setEditedUser] = useState<User | null>(null);
  const [isPartnerPicked, setPartnerPicked] = useState(
    isPartnerRole(roles, editedUser?.roleId)
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<User> = {
      fetcherAction: getUser,
      fallbackRoute: Route.Users,
      collection: users,
      callback: setEditedUser,
      id: userId,
    };

    await Promise.all([
      dispatch(getAllPartners()),
      fetchEntity<User>(fetchArgs),
    ]);

    finishLoading();
  };

  useEffect(() => {
    if (editedUser) {
      setPartnerPicked(isPartnerRole(roles, editedUser.roleId));
    }
  }, [editedUser]);

  const onSubmit = async (values: FormValues) => {
    const saveFn = editedUser ? submitEditUser : submitAddUser;
    const savedUser = await saveFn(values);
    if (savedUser) {
      setEditedUser(savedUser);
    }
  };

  const submitAddUser = async (values: FormValues) => {
    const user = mapFormValuesToUserPostRequest(values);
    const { payload } = await dispatch(addUser(user));
    return payload as User;
  };

  const submitEditUser = async (values: FormValues) => {
    const userPatchRequest = mapFormValuesToUserPatchRequest(
      values,
      editedUser!
    );
    const originalEntity = mapFormValuesToUserPatchRequest(
      originalData as FormValues,
      editedUser!
    );
    const adjustedEntity = preparePatchEntity(userPatchRequest, originalEntity);
    const { payload } = await dispatch(editUser(adjustedEntity));
    return payload as User;
  };

  const validationSchema = getValidationSchema(
    t,
    Boolean(editedUser),
    isPartnerPicked
  );

  const formik = useFormik({
    initialValues: getInitFormValues(editedUser),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(editedUser);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [editedUser]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.Users);

  const getPartnerById = (id: string) =>
    allPartners?.find((partner) => partner.id.toString() === id) || null;

  const adjustIdValue = (id: number) => (id === -1 ? "" : id);

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(editedUser));
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={editedUser ? t("edycja_usera") : t("nowy_user")}
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(editedUser)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_usera")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <TextField
              id="firstname"
              name="firstname"
              fullWidth
              label={t("imie")}
              size="small"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              error={
                formik.touched.firstname && Boolean(formik.errors.firstname)
              }
              helperText={formik.touched.firstname && formik.errors.firstname}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="lastname"
              name="lastname"
              fullWidth
              label={t("nazwisko")}
              size="small"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="phonePrefix"
              name="phonePrefix"
              fullWidth
              label={t("kierunkowy")}
              type="tel"
              size="small"
              value={formik.values.phonePrefix}
              onChange={formik.handleChange}
              error={
                formik.touched.phonePrefix && Boolean(formik.errors.phonePrefix)
              }
              helperText={
                formik.touched.phonePrefix && formik.errors.phonePrefix
              }
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              fullWidth
              label={t("numer_telefonu")}
              size="small"
              type="tel"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="username"
              name="username"
              fullWidth
              label={t("mail")}
              size="small"
              disabled={Boolean(editedUser)}
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="password"
              name="password"
              fullWidth
              label={t("haslo")}
              size="small"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Box>
          <Box sx={styles.field}>
            <FormControl
              fullWidth
              size="small"
              error={formik.touched.roleId && Boolean(formik.errors.roleId)}
            >
              <InputLabel>{t("rola")}</InputLabel>
              <Select
                id="roleId"
                name="roleId"
                size="small"
                value={adjustIdValue(formik.values.roleId)}
                disabled={Boolean(editedUser)}
                label={t("rola")}
                onChange={(event) => {
                  const { value } = event.target;
                  formik.setFieldValue("roleId", value);
                  formik.setFieldTouched("roleId", false, false);
                  formik.setFieldValue("partnerId", "");
                  formik.setFieldTouched("partnerId", false, false);
                  // @ts-ignore
                  setPartnerPicked(isPartnerRole(roles, event.target.value));
                }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {t(role.name)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.touched.roleId && formik.errors.roleId}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box sx={styles.field}>
            <Autocomplete
              id="partnerId"
              size="small"
              autoHighlight
              fullWidth
              disabled={!isPartnerPicked || Boolean(editedUser)}
              noOptionsText={t("__brak_opcji")}
              options={allPartners?.filter((partner) => partner.active) || []}
              value={getPartnerById(formik.values.partnerId)}
              onChange={(event, value) =>
                formik.setFieldValue("partnerId", value?.id.toString() || "")
              }
              getOptionLabel={(partner) =>
                partner.active
                  ? partner.name
                  : `${partner.name} (${t("dezaktywowany")})`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <Typography>{option.name}</Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("partner")}
                  error={
                    formik.touched.partnerId && Boolean(formik.errors.partnerId)
                  }
                  helperText={
                    formik.touched.partnerId && formik.errors.partnerId
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Box>
          <Box sx={styles.field}>
            <FormControl fullWidth size="small">
              <InputLabel>{t("status")}</InputLabel>
              <Select
                id="active"
                name="active"
                size="small"
                value={formik.values.active?.toString()}
                label={t("status")}
                disabled={!editedUser}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  formik.setFieldValue("active", value);
                }}
              >
                <MenuItem value="true">{t("aktywny")}</MenuItem>
                <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.field} />
        </Box>
      </Section>
    </form>
  );
};

export default withPageLoader(UserEdit);
