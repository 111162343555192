import React, { useEffect, useState } from "react";
import Section from "../../../../../components/section/section";
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "./model-config.styles";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { Color } from "../../../../../types/color-types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import { FillingWithPrice } from "./model-config.types";
import {
  getAutocompleteCommonProps,
  getCommonTextInputProps,
  getRenderInput,
  prepareEntities,
} from "./model-config.utils";
import {
  setBrake,
  setBrakeKey,
  setColor,
  setFilling,
  setFloorBase,
  setRailExtension,
  setRailPosition,
  setWallIncrease,
} from "../../../../../redux/slices/order-slice";
import TextField from "@mui/material/TextField";
import { Brake } from "../../../../../types/brake-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "../../../../../components/info-icon/info-icon";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import useDisableAll from "../../../../../hooks/useDisableAll";
import { formatPrice } from "../../../../../utils/formatter-utils";
import { getInputAdornment } from "../../../../../utils/common-utils";
import NumericInput from "../../../../../components/numeric-input/numeric-input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { RailPosition } from "../../../../../types/order-types";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import wallIncreaseImg from "../../../../../assets/images/png/wall-increase.png";

const ModelConfig = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();

  const { allColors } = useSelector((state: Store) => state.color);
  const { allFillings } = useSelector((state: Store) => state.filling);
  const { allBrakes } = useSelector((state: Store) => state.brake);
  const { moduleDependantPriceItems } = useSelector(
    (state: Store) => state.price
  );
  const {
    validation,
    currentOrder,
    calculateValidation,
    availablePrices,
    rollback,
  } = useSelector((state: Store) => state.order);
  const {
    category,
    profile,
    model,
    variant,
    filling,
    color,
    brake,
    railExtension,
    floorBase,
    wallIncrease,
    brakeKey,
    clapType,
    railPosition,
  } = currentOrder;

  const [fillings, setFillings] = useState<FillingWithPrice[]>([]);
  const [colors, setColors] = useState<Color[]>([]);
  const [brakes, setBrakes] = useState<Brake[]>([]);
  const [wallIncreases, setWallIncreases] = useState<number[]>([]);

  useUpdateEffect(() => {
    dispatch(setFilling(null));
    dispatch(setColor(null));
    dispatch(setBrake(null));
    dispatch(setRailExtension("0"));
    dispatch(setFloorBase(""));
    dispatch(setWallIncrease(null));
    dispatch(setBrakeKey(false));
  }, [model?.id]);

  useUpdateEffect(() => {
    if (!rollback) {
      dispatch(setFilling(null));
      dispatch(setColor(null));
      dispatch(setBrake(null));
      dispatch(setBrakeKey(false));
    }
  }, [variant?.id]);

  useEffect(() => {
    prepareData();
    prepareWallIncreases();
  }, [variant?.id]);

  const prepareData = () => {
    if (category && model && variant) {
      setColors(
        prepareEntities(
          category,
          model,
          variant,
          "colorsInherited",
          "colors",
          allColors
        )
      );

      const preparedFillings = prepareEntities(
        category,
        model,
        variant,
        "fillingsInherited",
        "fillings",
        allFillings
      );

      setBrakes(
        prepareEntities(
          category,
          model,
          variant,
          "brakesInherited",
          "brakes",
          allBrakes
        )
      );

      const fillingsWithPrices: FillingWithPrice[] = [];
      preparedFillings.forEach((preparedFilling) => {
        const price = variant.price.find(
          (price) => price.fillingId === preparedFilling.id
        );
        if (price?.active) {
          fillingsWithPrices.push({ ...preparedFilling, price: price.price });
        }
      });
      setFillings(fillingsWithPrices);
    }
  };

  const prepareWallIncreases = () => {
    if (model) {
      const offset = model.defaultWallIncrease;
      const length = 15 - offset + 1;
      setWallIncreases(Array.from({ length }, (_, i) => i + offset));
    }
  };

  const AUTOCOMPLETE_COMMON_PROPS = getAutocompleteCommonProps(
    variant,
    t,
    disableAll
  );

  const TEXT_FIELD_COMMON_PROPS = getCommonTextInputProps(disableAll, variant);

  const getFillingOptionLabel = (filling: FillingWithPrice) => {
    const price = availablePrices?.variant[filling.id] || filling.price || 0;
    return `${filling.label} (${formatPrice(price)} €)`;
  };

  const getWallIncreaseOptionLabel = (option: number) =>
    model?.defaultWallIncrease === option
      ? `${option} (${t("__standardowe")})`
      : option.toString();

  const getBrakeKeyLabel = () => {
    if (variant && moduleDependantPriceItems) {
      const price =
        availablePrices?.brake[variant.modulesCount] ||
        moduleDependantPriceItems.find(
          (x) => x.moduleCount === variant.modulesCount
        )?.brakePrice.value ||
        0;

      return `${t("__kluczyk_blokady")} (${formatPrice(price)} €)`;
    }

    return t("__kluczyk_blokady");
  };

  const getRailExtensionLabel = () => {
    if (model && variant) {
      const singlePriceForMeter =
        availablePrices?.railExtension ||
        profile?.railExtensionPrice.value ||
        0;

      const finalPrice =
        singlePriceForMeter * model.railCount * variant.modulesCount;
      return `${t("__przedluzenie_szyn")} (${formatPrice(finalPrice)} € / m)`;
    }

    return t("__przedluzenie_szyn");
  };

  const infoIconContent = (
    <Box sx={styles.infoIcon}>
      <img src={wallIncreaseImg} />
      <Typography>{t("__podwyzszenie_scian_tooltip")}</Typography>
    </Box>
  );

  const railExtensionInfoIconContent = (
    <Box sx={styles.infoIcon}>
      <Typography>{t("__przedluzenie_tooltip")}</Typography>
    </Box>
  );

  return (
    <Section title={t("__konfiguracja_modelu")}>
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <Autocomplete
            {...AUTOCOMPLETE_COMMON_PROPS}
            options={fillings}
            value={filling}
            onChange={(event, value) => dispatch(setFilling(value))}
            renderInput={getRenderInput(
              t("__wypelnienie"),
              validation,
              "filling"
            )}
            getOptionLabel={(option) => getFillingOptionLabel(option)}
            renderOption={(props: any, option) => (
              <li {...props} key={option.id}>
                <Typography>{getFillingOptionLabel(option)}</Typography>
              </li>
            )}
          />
        </Box>
        <Box sx={styles.field}>
          <Autocomplete
            {...AUTOCOMPLETE_COMMON_PROPS}
            options={colors}
            value={color}
            onChange={(event, value) => dispatch(setColor(value))}
            renderInput={getRenderInput(t("__kolor"), validation, "color")}
          />
        </Box>
        <Box sx={styles.field}>
          <Autocomplete
            {...AUTOCOMPLETE_COMMON_PROPS}
            options={brakes}
            value={brake}
            onChange={(event, value) => dispatch(setBrake(value))}
            renderInput={getRenderInput(t("__blokada"), validation, "brake")}
          />
        </Box>
        <Box sx={styles.fieldWithInfoIcon}>
          <NumericInput
            {...TEXT_FIELD_COMMON_PROPS}
            label={getRailExtensionLabel()}
            value={railExtension}
            onChange={(e) => dispatch(setRailExtension(e.target.value))}
            required
            error={
              (Boolean(validation) && Boolean(validation?.railExtension)) ||
              Boolean(calculateValidation.railExtension)
            }
            helperText={
              (Boolean(validation) && validation?.railExtension) ||
              calculateValidation.railExtension
            }
            InputProps={getInputAdornment("cm")}
          />
          {category?.box && (
            <InfoIcon
              content={railExtensionInfoIconContent}
              buttonStyles={styles.infoIconButton}
            />
          )}
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...TEXT_FIELD_COMMON_PROPS}
            label={t("__rodzaj_podloza")}
            value={floorBase}
            onChange={(e) => dispatch(setFloorBase(e.target.value))}
            error={Boolean(validation) && Boolean(validation?.floorBase)}
            helperText={Boolean(validation) && validation?.floorBase}
            InputProps={{}}
          />
        </Box>
        <Box sx={styles.fieldWithInfoIcon}>
          <Autocomplete
            {...AUTOCOMPLETE_COMMON_PROPS}
            options={wallIncreases}
            value={wallIncrease}
            disabled={!variant || disableAll || clapType.length > 0}
            onChange={(event, value) => dispatch(setWallIncrease(value))}
            renderInput={getRenderInput(
              t("__podwyzszenie_scian"),
              validation,
              "wallIncrease",
              true,
              "cm"
            )}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => getWallIncreaseOptionLabel(option)}
            renderOption={(props: any, option) => (
              <li {...props} key={option}>
                <Typography>{getWallIncreaseOptionLabel(option)}</Typography>
              </li>
            )}
          />
          <InfoIcon
            content={infoIconContent}
            buttonStyles={styles.infoIconButton}
          />
        </Box>
        {model?.railCount === 1 && (
          <Box sx={styles.field}>
            <FormControl size="small" fullWidth required>
              <InputLabel>{t("__pozycja_szyny")}</InputLabel>
              <Select
                fullWidth
                value={railPosition || ""}
                disabled={!variant || disableAll}
                label={t("__pozycja_szyny")}
                onChange={(e) =>
                  dispatch(setRailPosition(e.target.value as RailPosition))
                }
                size="small"
              >
                <MenuItem value={RailPosition.RIGHT}>
                  {t(RailPosition.RIGHT)}
                </MenuItem>
                <MenuItem value={RailPosition.LEFT}>
                  {t(RailPosition.LEFT)}
                </MenuItem>
              </Select>
              <FormHelperText
                error={Boolean(validation) && Boolean(validation?.railPosition)}
              >
                {Boolean(validation) && validation?.railPosition}
              </FormHelperText>
            </FormControl>
          </Box>
        )}
        <Box sx={{ ...styles.field, justifyContent: "flex-start" }}>
          <FormControlLabel
            disabled={!variant || disableAll}
            control={
              <Checkbox
                checked={brakeKey}
                onChange={(value) =>
                  dispatch(setBrakeKey(value.target.checked))
                }
              />
            }
            label={getBrakeKeyLabel()}
          />
        </Box>
        <Box sx={styles.field} />
      </Box>
    </Section>
  );
};

export default ModelConfig;
