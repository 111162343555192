import { configureStore, combineReducers } from "@reduxjs/toolkit";

import alertReducer, { AlertState } from "./slices/alert-slice";
import authReducer, { AuthState } from "./slices/auth-slice";
import partnerReducer, { PartnerState } from "./slices/partner-slice";
import countryReducer, { CountryState } from "./slices/country-slice";
import userReducer, { UserState } from "./slices/user-slice";
import postReducer, { PostState } from "./slices/post-slice";
import brakeReducer, { BrakeState } from "./slices/brake-slice";
import fillingReducer, { FillingState } from "./slices/filling-slice";
import colorReducer, { ColorState } from "./slices/color-slice";
import profileReducer, { ProfileState } from "./slices/profile-slice";
import categoryReducer, { CategoryState } from "./slices/category-slice";
import priceReducer, { PriceState } from "./slices/price-slice";
import coverReducer, { CoverState } from "./slices/cover-slice";
import variantReducer, { VariantState } from "./slices/variant-slice";
import changesReducer, { ChangesState } from "./slices/changes-slice";
import orderReducer, { OrderState } from "./slices/order-slice";
import uiReducer, { UiState } from "./slices/ui-slice";
import notificationReducer, {
  NotificationState,
} from "./slices/notification-slice";
import userOfferSearch, {
  UserOfferSearchState,
} from "./slices/user-offer-search-slice";

export interface Store {
  alert: AlertState;
  auth: AuthState;
  brake: BrakeState;
  category: CategoryState;
  changes: ChangesState;
  color: ColorState;
  country: CountryState;
  cover: CoverState;
  filling: FillingState;
  notification: NotificationState;
  order: OrderState;
  partner: PartnerState;
  post: PostState;
  price: PriceState;
  profile: ProfileState;
  ui: UiState;
  user: UserState;
  userOfferSearch: UserOfferSearchState;
  variant: VariantState;
}

const combinedReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  brake: brakeReducer,
  category: categoryReducer,
  changes: changesReducer,
  color: colorReducer,
  country: countryReducer,
  cover: coverReducer,
  filling: fillingReducer,
  notification: notificationReducer,
  order: orderReducer,
  partner: partnerReducer,
  post: postReducer,
  price: priceReducer,
  profile: profileReducer,
  ui: uiReducer,
  user: userReducer,
  userOfferSearch: userOfferSearch,
  variant: variantReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout/fulfilled") {
    clearTimeout(state.auth.sessionTimerId);
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;

export default store;
