import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "@mui/material/Icon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { MenuItemMetaData, MenuItemProps } from "./menu-item.types";
import { getStyles, ListItem } from "./menu-items.styles";
import { useTranslation } from "react-i18next";
import UnsavedChangesModal from "../../../unsaved-changes-modal/unsaved-changes-modal";
import { Route } from "../../../../constants/navigation-constants";

const MenuItem = ({ icon, open, text, route, nestedItems }: MenuItemProps) => {
  const [nestedOpen, setNestedOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [pickedRoute, setPickedRoute] = useState<Route | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      nestedItems?.some((nestedItem) => pathname.includes(nestedItem.route))
    ) {
      setNestedOpen(true);
    }
  }, []);

  const handleCollapseClick = () => {
    setNestedOpen((prev) => !prev);
  };

  const isActive = pathname.includes(route);

  const isNested = nestedItems && nestedItems.length > 0;

  const renderNestedItem = (nestedItem: MenuItemMetaData) => {
    const isNestedActive = pathname.includes(nestedItem.route);

    return (
      <ListItem
        key={nestedItem.text}
        disablePadding
        onClick={() => onItemClick(nestedItem.route)}
        isActive={isNestedActive}
      >
        <ListItemButton sx={styles.listItemButton(open, true)}>
          <ListItemIcon sx={styles.listItemIcon(open, isNestedActive)}>
            <Icon fontSize={open ? "medium" : "small"}>{nestedItem.icon}</Icon>
          </ListItemIcon>
          <ListItemText
            primary={t(nestedItem.text)}
            sx={styles.listItemText(open, isNestedActive)}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const onCancelClick = () => setOpen(false);

  const onLeaveClick = () => {
    if (pickedRoute) {
      navigate(pickedRoute);
    }
    setPickedRoute(null);
    setOpen(false);
  };

  const onItemClick = (route: Route) => {
    setPickedRoute(route);
    setOpen(true);
  };

  return (
    <>
      <UnsavedChangesModal
        open={isOpen}
        onCancelClick={onCancelClick}
        leaveCallback={onLeaveClick}
      />
      <ListItem key={text} disablePadding isActive={isActive}>
        <ListItemButton
          sx={styles.listItemButton(open)}
          onClick={() =>
            isNested ? handleCollapseClick() : onItemClick(route)
          }
        >
          <ListItemIcon sx={styles.listItemIcon(open, isActive)}>
            <Icon>{icon}</Icon>
          </ListItemIcon>
          <ListItemText
            primary={t(text)}
            sx={styles.listItemText(open, isActive)}
          />
          {isNested && open ? (
            nestedOpen ? (
              <ExpandLess sx={styles.collapseIcon} />
            ) : (
              <ExpandMore sx={styles.collapseIcon} />
            )
          ) : null}
        </ListItemButton>
        {isNested && (
          <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {nestedItems.map(renderNestedItem)}
            </List>
          </Collapse>
        )}
      </ListItem>
    </>
  );
};

export default MenuItem;
