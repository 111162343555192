export const deCountriesTranslations = {
  af: "Afghanistan",
  eg: "Ägypten",
  al: "Albanien",
  dz: "Algerien",
  ad: "Andorra",
  ao: "Angola",
  ag: "Antigua und Barbuda",
  gq: "Äquatorialguinea",
  ar: "Argentinien",
  am: "Armenien",
  az: "Aserbaidschan",
  et: "Äthiopien",
  au: "Australien",
  bs: "Bahamas",
  bh: "Bahrain",
  bd: "Bangladesch",
  bb: "Barbados",
  by: "Belarus",
  be: "Belgien",
  bz: "Belize",
  bj: "Benin",
  bt: "Bhutan",
  bo: "Bolivien",
  ba: "Bosnien und Herzegowina",
  bw: "Botswana",
  br: "Brasilien",
  bn: "Brunei",
  bg: "Bulgarien",
  bf: "Burkina Faso",
  bi: "Burundi",
  cl: "Chile",
  cn: "Volksrepublik China",
  cr: "Costa Rica",
  dk: "Dänemark",
  de: "Deutschland",
  dm: "Dominica",
  do: "Dominikanische Republik",
  dj: "Dschibuti",
  ec: "Ecuador",
  ci: "Elfenbeinküste",
  sv: "El Salvador",
  er: "Eritrea",
  ee: "Estland",
  sz: "Eswatini",
  fj: "Fidschi",
  fi: "Finnland",
  fr: "Frankreich",
  ga: "Gabun",
  gm: "Gambia",
  ge: "Georgien",
  gh: "Ghana",
  gd: "Grenada",
  gr: "Griechenland",
  gt: "Guatemala",
  gn: "Guinea",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  ht: "Haiti",
  hn: "Honduras",
  in: "Indien",
  id: "Indonesien",
  iq: "Irak",
  ir: "Iran",
  ie: "Irland",
  is: "Island",
  il: "Israel",
  it: "Italien",
  jm: "Jamaika",
  jp: "Japan",
  ye: "Jemen",
  jo: "Jordanien",
  kh: "Kambodscha",
  cm: "Kamerun",
  ca: "Kanada",
  cv: "Kap Verde",
  kz: "Kasachstan",
  qa: "Katar",
  ke: "Kenia",
  kg: "Kirgisistan",
  ki: "Kiribati",
  co: "Kolumbien",
  km: "Komoren",
  cd: "Kongo, Demokratische Republik",
  cg: "Kongo, Republik",
  kp: "Korea, Nord",
  kr: "Korea, Süd",
  hr: "Kroatien",
  cu: "Kuba",
  kw: "Kuwait",
  la: "Laos",
  ls: "Lesotho",
  lv: "Lettland",
  lb: "Libanon",
  lr: "Liberia",
  ly: "Libyen",
  li: "Liechtenstein",
  lt: "Litauen",
  lu: "Luxemburg",
  mg: "Madagaskar",
  mw: "Malawi",
  my: "Malaysia",
  mv: "Malediven",
  ml: "Mali",
  mt: "Malta",
  ma: "Marokko",
  mh: "Marshallinseln",
  mr: "Mauretanien",
  mu: "Mauritius",
  mx: "Mexiko",
  fm: "Mikronesien",
  md: "Moldau",
  mc: "Monaco",
  mn: "Mongolei",
  me: "Montenegro",
  mz: "Mosambik",
  mm: "Myanmar",
  na: "Namibia",
  nr: "Nauru",
  np: "Nepal",
  nz: "Neuseeland",
  ni: "Nicaragua",
  nl: "Niederlande",
  ne: "Niger",
  ng: "Nigeria",
  mk: "Nordmazedonien",
  no: "Norwegen",
  om: "Oman",
  at: "Österreich",
  tl: "Osttimor",
  pk: "Pakistan",
  pw: "Palau",
  pa: "Panama",
  pg: "Papua-Neuguinea",
  py: "Paraguay",
  pe: "Peru",
  ph: "Philippinen",
  pl: "Polen",
  pt: "Portugal",
  rw: "Ruanda",
  ro: "Rumänien",
  ru: "Russland",
  sb: "Salomonen",
  zm: "Sambia",
  ws: "Samoa",
  sm: "San Marino",
  st: "São Tomé und Príncipe",
  sa: "Saudi-Arabien",
  se: "Schweden",
  ch: "Schweiz",
  sn: "Senegal",
  rs: "Serbien",
  sc: "Seychellen",
  sl: "Sierra Leone",
  zw: "Simbabwe",
  sg: "Singapur",
  sk: "Slowakei",
  si: "Slowenien",
  so: "Somalia",
  es: "Spanien",
  lk: "Sri Lanka",
  kn: "St. Kitts und Nevis",
  lc: "St. Lucia",
  vc: "St. Vincent und die Grenadinen",
  za: "Südafrika",
  sd: "Sudan",
  ss: "Südsudan",
  sr: "Suriname",
  sy: "Syrien",
  tj: "Tadschikistan",
  tz: "Tansania",
  th: "Thailand",
  tg: "Togo",
  to: "Tonga",
  tt: "Trinidad und Tobago",
  td: "Tschad",
  cz: "Tschechien",
  tn: "Tunesien",
  tr: "Türkei",
  tm: "Turkmenistan",
  tv: "Tuvalu",
  ug: "Uganda",
  ua: "Ukraine",
  hu: "Ungarn",
  uy: "Uruguay",
  uz: "Usbekistan",
  vu: "Vanuatu",
  ve: "Venezuela",
  ae: "Vereinigte Arabische Emirate",
  us: "Vereinigte Staaten",
  gb: "Vereinigtes Königreich",
  vn: "Vietnam",
  cf: "Zentralafrikanische Republik",
  cy: "Zypern",
};
