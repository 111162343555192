import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GlobalPriceUpdateRequest,
  ModuleDependantPriceItem,
  ModuleDependantPriceItemGetResponse,
  ModuleDependantPriceItemPatchRequest,
  ModuleDependantPriceItemPostRequest,
  PriceItem,
  PriceItemPatchRequest,
} from "../../types/price-types";
import { Store } from "../store";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";

export const getPriceItems = createAsyncThunk(
  "price/getPriceItems",
  async (reload: boolean, thunkAPI) => {
    const { price } = thunkAPI.getState() as Store;

    if (!reload && price.priceItems) {
      return null;
    }

    const { data } = await axios.get<PriceItem[]>("/pricelist");
    return data;
  }
);

export const getPriceItem = createAsyncThunk(
  "price/getPriceItem",
  async (id: string) => {
    const { data } = await axios.get<PriceItem>(`/pricelist/${id}`);
    return data;
  }
);

export const editPriceItem = createAsyncThunk(
  "price/editPriceItem",
  async (params: PriceItemPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<PriceItem>("/pricelist", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const getModuleDependantPriceItems = createAsyncThunk(
  "price/getModuleDependantPriceItems",
  async (reload: boolean, thunkAPI) => {
    const { price } = thunkAPI.getState() as Store;

    if (!reload && price.moduleDependantPriceItems) {
      return null;
    }

    const { data } = await axios.get<ModuleDependantPriceItemGetResponse[]>(
      "/pricelist/module-dependant"
    );

    return data.map((entity) => ({ ...entity, id: entity.moduleCount }));
  }
);

export const getModuleDependantPriceItem = createAsyncThunk(
  "price/getModuleDependantPriceItem",
  async (id: string) => {
    const { data } = await axios.get<ModuleDependantPriceItem>(
      `/pricelist/module-dependant/${id}`
    );
    return data;
  }
);

export const addModuleDependantPriceItem = createAsyncThunk(
  "price/addModuleDependantPriceItem",
  async (params: ModuleDependantPriceItemPostRequest, thunkAPI) => {
    const { data } = await axios.post<ModuleDependantPriceItem>(
      "/pricelist/module-dependant",
      params
    );
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editModuleDependantPriceItem = createAsyncThunk(
  "price/editModuleDependantPriceItem",
  async (params: ModuleDependantPriceItemPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<ModuleDependantPriceItem>(
      "/pricelist/module-dependant",
      params
    );
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const updatePricesGlobally = createAsyncThunk(
  "price/updatePricesGlobally",
  async (params: GlobalPriceUpdateRequest, thunkAPI) => {
    await axios.patch("/variant/price", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
  }
);
