import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/store";
import MuiAlert from "@mui/material/Alert";
import { clearAlert } from "../../redux/slices/alert-slice";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";

const Alert = () => {
  const { message, variant, isFatal } = useSelector(
    (state: Store) => state.alert
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(clearAlert());
  };

  const renderSmallAlert = (message: string) => (
    <Snackbar
      open
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MuiAlert
        onClose={handleClose}
        severity={variant || "success"}
        sx={{ width: "100%" }}
      >
        {t(message)}
      </MuiAlert>
    </Snackbar>
  );

  const renderBigAlert = (message: string) => (
    <ConfirmationDialog
      open={true}
      title="wystapil_nieoczekiwany_blad"
      secondaryText="zamknij"
      onSecondary={handleClose}
    >
      <Typography gutterBottom>{t("nieoczekiwany_blad_tresc")}</Typography>
      <Typography>
        {t("identyfikator_bledu")}: <strong>{message}</strong>
      </Typography>
    </ConfirmationDialog>
  );

  const renderAlert = (message: string) =>
    isFatal ? renderBigAlert(message) : renderSmallAlert(message);

  return message ? renderAlert(message) : null;
};

export default Alert;
