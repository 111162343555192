import { Loading, Pageable } from "../../types/common-types";
import { Filling } from "../../types/filling-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { updateStateWithPageable } from "../redux-utils";
import {
  addFilling,
  editFilling,
  getAllFillings,
  getFillings,
} from "../actions/filling-actions";

export interface FillingState extends Pageable<Filling> {
  loading: Loading;
  allFillings: Filling[] | null;
}

export const DEFAULT_FILLING_FILTERS = {
  active: true,
};

export const DEFAULT_FILLING_SORT = ["label", "id"];

const initialState: FillingState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_FILLING_SORT,
  filters: DEFAULT_FILLING_FILTERS,
  allFillings: null,
};

const fillingSlice = createSlice({
  name: "filling",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFillings.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getFillings.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getFillings.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addFilling.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(addFilling.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addFilling.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editFilling.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(editFilling.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editFilling.rejected, (state) => {
      state.loading = "failed";
    });

    builder.addCase(getAllFillings.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllFillings.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allFillings = action.payload;
    });
    builder.addCase(getAllFillings.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default fillingSlice.reducer;
