import { FormValues } from "./post-edit.types";
import {
  PatchPostRequest,
  Post,
  PostPostRequest,
} from "../../../../types/post-types";
import {
  TranslationPatchRequest,
  TranslationPostRequest,
} from "../../../../types/translation-types";
import { Language } from "../../../../types/language-types";
import { TFunction } from "react-i18next";
import * as yup from "yup";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");

  return yup.object({
    polishTitle: yup.string().required(requiredFieldError),
    englishTitle: yup.string().required(requiredFieldError),
    germanTitle: yup.string().required(requiredFieldError),
    swedenTitle: yup.string().required(requiredFieldError),
    polishText: yup.string().required(requiredFieldError),
    englishText: yup.string().required(requiredFieldError),
    germanText: yup.string().required(requiredFieldError),
    swedenText: yup.string().required(requiredFieldError),
    active: yup.boolean(),
    createdDate: yup.string(),
  });
};

export const mapFormValuesToPostPostRequest = (
  values: FormValues
): PostPostRequest => {
  const titleTranslations: TranslationPostRequest[] = [];
  const labelTranslations: TranslationPostRequest[] = [];

  titleTranslations.push({ langIso: "pl", value: values.polishTitle });
  titleTranslations.push({ langIso: "en", value: values.englishTitle });
  titleTranslations.push({ langIso: "de", value: values.germanTitle });
  titleTranslations.push({ langIso: "sv", value: values.swedenTitle });

  labelTranslations.push({ langIso: "pl", value: values.polishText });
  labelTranslations.push({ langIso: "en", value: values.englishText });
  labelTranslations.push({ langIso: "de", value: values.germanText });
  labelTranslations.push({ langIso: "sv", value: values.swedenText });

  return { titleTranslations, labelTranslations };
};

export const mapFormValuesToPatchPostRequest = (
  values: FormValues,
  post: Post
): PatchPostRequest => {
  const titleTranslations: TranslationPatchRequest[] = [];
  const labelTranslations: TranslationPatchRequest[] = [];

  const findTitleId = (lang: Language) =>
    findTranslationId(post, "title", lang);
  const findTextId = (lang: Language) => findTranslationId(post, "text", lang);

  titleTranslations.push({
    id: findTitleId("pl"),
    langIso: "pl",
    value: values.polishTitle,
  });
  titleTranslations.push({
    id: findTitleId("en"),
    langIso: "en",
    value: values.englishTitle,
  });
  titleTranslations.push({
    id: findTitleId("de"),
    langIso: "de",
    value: values.germanTitle,
  });
  titleTranslations.push({
    id: findTitleId("sv"),
    langIso: "sv",
    value: values.swedenTitle,
  });

  labelTranslations.push({
    id: findTextId("pl"),
    langIso: "pl",
    value: values.polishText,
  });
  labelTranslations.push({
    id: findTextId("en"),
    langIso: "en",
    value: values.englishText,
  });
  labelTranslations.push({
    id: findTextId("de"),
    langIso: "de",
    value: values.germanText,
  });
  labelTranslations.push({
    id: findTextId("sv"),
    langIso: "sv",
    value: values.swedenText,
  });

  return {
    id: post.id,
    titleTranslations,
    labelTranslations,
    active: values.active,
  };
};

const findTranslationId = (
  post: Post,
  type: "title" | "text",
  lang: Language
) => {
  const collection =
    type === "title" ? post.titleTranslations : post.labelTranslations;
  return collection.find((translation) => translation.langIso === lang)!.id;
};

const findTranslationTitle = (post: Post, lang: Language) =>
  post.titleTranslations.find((translation) => translation.langIso === lang)!
    .value;

const findTranslationText = (post: Post, lang: Language) =>
  post.labelTranslations.find((translation) => translation.langIso === lang)!
    .value;

export const mapPostToFormValues = (post: Post): FormValues => ({
  active: post.active,
  createdDate: post.createdDate,
  englishText: findTranslationText(post, "en"),
  englishTitle: findTranslationTitle(post, "en"),
  germanText: findTranslationText(post, "de"),
  germanTitle: findTranslationTitle(post, "de"),
  polishText: findTranslationText(post, "pl"),
  polishTitle: findTranslationTitle(post, "pl"),
  swedenText: findTranslationText(post, "sv"),
  swedenTitle: findTranslationTitle(post, "sv"),
});

const getDefaultFormValues = (): FormValues => ({
  active: true,
  createdDate: "",
  englishText: "",
  englishTitle: "",
  germanText: "",
  germanTitle: "",
  polishText: "",
  polishTitle: "",
  swedenText: "",
  swedenTitle: "",
});

export const getInitFormValues = (post: Post | null): FormValues =>
  post ? mapPostToFormValues(post) : getDefaultFormValues();
