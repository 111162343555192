import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import ModelPicker from "./model-picker/model-picker";
import { getAllCategories } from "../../../redux/actions/category-actions";
import { getAllProfiles } from "../../../redux/actions/profile-actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { getAllCovers } from "../../../redux/actions/cover-actions";
import OrderForm from "./order-form/order-form";
import { getAllColors } from "../../../redux/actions/color-actions";
import { getAllFillings } from "../../../redux/actions/filling-actions";
import {
  CurrentOrder,
  resetCalculation,
  setCalculateValidation,
  setCategory,
  setCurrentOrder,
  setModel,
  setPartner,
  setProfile,
  setRollback,
  setValidation,
  Validation,
} from "../../../redux/slices/order-slice";
import { getAllBrakes } from "../../../redux/actions/brake-actions";
import {
  getModuleDependantPriceItems,
  getPriceItems,
} from "../../../redux/actions/price-actions";
import { getCountries } from "../../../redux/actions/country-actions";
import { getPartner } from "../../../redux/actions/partner-actions";
import { CalculationRequest } from "../../../types/calculation-types";
import {
  addOrder,
  calculate,
  editOrder,
  getAvailablePrices,
  getOrder,
} from "../../../redux/actions/order-actions";
import { useNavigate, useParams } from "react-router-dom";
import {
  Order,
  OrderPatchRequest,
  OrderStatus,
} from "../../../types/order-types";
import useFetchEntity, { FetchEntityArgs } from "../../../hooks/useFetchEntity";
import { Route } from "../../../constants/navigation-constants";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../redux/slices/changes-slice";
import {
  createOrderPatchRequest,
  createOrderPostRequest,
} from "./order-form/order-form.utils";
import { preparePatchEntity } from "../../../redux/redux-utils";
import {
  getCalculateSchema,
  getOrderSchema,
  mapOrderToCurrentOrder,
} from "./order-edit.utils";
import { useTranslation } from "react-i18next";
import useRole from "../../../hooks/useRole";
import { isAdminRole } from "../../../utils/common-utils";
import ConfirmationDialog from "../../../components/confirmation-dialog/confirmation-dialog";
import Typography from "@mui/material/Typography";

interface OrderEditProps extends WithPageLoaderProps {}

export const getStyles = () => ({
  container: {
    minWidth: 800,
  },
});

const OrderEdit = ({ finishLoading, startLoading }: OrderEditProps) => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const { fetchEntity } = useFetchEntity();
  const styles = getStyles();
  const { t } = useTranslation();
  const timeoutId = useRef<number | undefined>(undefined);
  const navigate = useNavigate();
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);

  const { currentOrder, calculation, validation } = useSelector(
    (state: Store) => state.order
  );
  const {
    model,
    category,
    variant,
    filling,
    railExtension,
    doorType,
    clapType,
    withTransport,
    withMontage,
    brakeKey,
    distance,
    clientDelivery,
    box,
  } = currentOrder;
  const { content: orders } = useSelector((state: Store) => state.order);
  const partnerId = useSelector((state: Store) => state.auth.user?.partnerId);
  const { originalData, areUnsavedChanges } = useSelector(
    (state: Store) => state.changes
  );

  const [order, setOrder] = useState<Order | null>(null);
  const [isMissingDataModalOpen, setMissingDataModalOpen] = useState(false);

  useEffect(() => {
    init();
    return () => {
      dispatch(resetChanges());
      startLoading();
      reset();
    };
  }, [id]);

  useEffect(() => {
    dispatch(applyChanges({ data: currentOrder }));
    dispatch(setRollback(false));
    try {
      if (validation !== null && currentOrder.model !== null) {
        getOrderSchema(
          t,
          clientDelivery,
          !!withTransport,
          currentOrder.status !== OrderStatus.OFFER && isAdmin,
          model?.railCount === 1,
          !!category?.box,
          currentOrder.clientPrice.isDetailedPrice
        ).validateSync(currentOrder, {
          abortEarly: false,
        });
        dispatch(setValidation({}));
      }
    } catch (e: any) {
      updateOrderValidation(e);
    }
  }, [currentOrder]);

  useEffect(() => {
    calculateIfNeeded();
  }, [
    model,
    variant,
    filling,
    railExtension,
    doorType,
    clapType,
    withMontage,
    withTransport,
    brakeKey,
    distance,
    areUnsavedChanges,
    box,
    category,
  ]);

  const updateOrderValidation = (e: any) => {
    dispatch(setValidation(preprocessValidationErrors(e)));
  };

  const updateCalculateValidation = (e: any) => {
    dispatch(setCalculateValidation(preprocessValidationErrors(e)));
  };

  const preprocessValidationErrors = (e: any) => {
    return e.inner.reduce(
      (acc: Validation, curr: { path: string; message: string }) => ({
        ...acc,
        [curr.path]: curr.message,
      }),
      {}
    );
  };

  const applyFetchedOrder = (order: Order) => {
    if (order) {
      setOrder(order);
      const currentOrder = mapOrderToCurrentOrder(order);
      dispatch(setCurrentOrder({ currentOrder }));
      dispatch(setOriginalData({ originalData: currentOrder }));
      dispatch(
        getAvailablePrices({
          offerId: order.id,
          variantId: order.variant.id,
        })
      );
    }
  };

  const init = async () => {
    const fetchArgs: FetchEntityArgs<Order> = {
      callback: () => {},
      collection: orders,
      fallbackRoute: Route.Orders,
      fetcherAction: getOrder,
      id,
    };

    const promises: Promise<any>[] = [
      dispatch(getCountries()),
      dispatch(getAllCategories({ active: true })),
      dispatch(getAllProfiles({ active: true })),
      dispatch(getAllCovers({ active: true })),
      dispatch(getAllColors({ active: true })),
      dispatch(getAllBrakes({ active: true })),
      dispatch(getModuleDependantPriceItems(true)),
      dispatch(getCountries()),
      dispatch(getPriceItems(true)),
      dispatch(getAllFillings({ active: true })),
      fetchEntity<Order>(fetchArgs),
    ];

    if (partnerId !== undefined) {
      promises.push(dispatch(getPartner(partnerId.toString())));
    }

    const responses = await Promise.all(promises);

    if (partnerId !== undefined) {
      const partner = responses.pop().payload;
      dispatch(setPartner(partner));
    }

    const order = responses.pop();
    applyFetchedOrder(order);

    finishLoading();
  };

  const handleAddOrder = async (
    status: OrderStatus,
    adjustedTerm: Date | undefined
  ) => {
    const orderPostRequest = createOrderPostRequest(
      currentOrder,
      status,
      calculation?.response || undefined,
      adjustedTerm
    );
    const { payload } = await dispatch(addOrder(orderPostRequest));
    return payload as Order;
  };

  const handleEditOrder = async (
    status: OrderStatus,
    adjustedTerm: Date | undefined
  ) => {
    const orderPatchRequest = createOrderPatchRequest(
      currentOrder,
      status,
      calculation?.response?.partnerPrice || undefined,
      adjustedTerm
    );
    const originalCurrentOrder = originalData as CurrentOrder;
    const originalEntity = createOrderPatchRequest(
      originalCurrentOrder,
      originalCurrentOrder.status,
      originalCurrentOrder.partnerPrice || 0,
      originalCurrentOrder.term || undefined
    );
    const adjustedEntity = preparePatchEntity(
      orderPatchRequest,
      originalEntity
    );
    const allDetailedPrices = orderPatchRequest.detailedPrices
      ? {
          transportClientPrice: orderPatchRequest.transportClientPrice,
          transportClientPriceDiscount:
            orderPatchRequest.transportClientPriceDiscount,
          montageClientPrice: orderPatchRequest.montageClientPrice,
          montageClientPriceDiscount:
            orderPatchRequest.montageClientPriceDiscount,
          doorClientPrice: orderPatchRequest.doorClientPrice,
          doorClientPriceDiscount: orderPatchRequest.doorClientPriceDiscount,
          brakeClientPrice: orderPatchRequest.brakeClientPrice,
          brakeClientPriceDiscount: orderPatchRequest.brakeClientPriceDiscount,
          variantClientPrice: orderPatchRequest.variantClientPrice,
          variantClientPriceDiscount:
            orderPatchRequest.variantClientPriceDiscount,
          railExtensionClientPrice: orderPatchRequest.railExtensionClientPrice,
          railExtensionClientPriceDiscount:
            orderPatchRequest.railExtensionClientPriceDiscount,
          boxMontageClientPrice: orderPatchRequest.boxMontageClientPrice,
          boxMontageClientPriceDiscount:
            orderPatchRequest.boxMontageClientPriceDiscount,
        }
      : {};
    const entityWithClient: OrderPatchRequest = {
      ...adjustedEntity,
      client: orderPatchRequest.client,
      price: orderPatchRequest.price,
      detailedPrices: orderPatchRequest.detailedPrices,
      ...allDetailedPrices,
    };
    const { payload } = await dispatch(editOrder(entityWithClient));
    return payload as Order;
  };

  const adjustTerm = (termToPost?: Date | null) => {
    if (termToPost) {
      return termToPost;
    }

    if (currentOrder.term) {
      return currentOrder.term;
    }

    return undefined;
  };

  const submitOrder = async ({
    status,
    termToPost,
    callback,
  }: {
    status?: OrderStatus;
    termToPost?: Date | null;
    callback?: () => void;
  }) => {
    try {
      const adjustedStatus = status || currentOrder.status;
      getOrderSchema(
        t,
        clientDelivery,
        !!withTransport,
        adjustedStatus !== OrderStatus.OFFER && isAdmin,
        model?.railCount === 1,
        !!category?.box,
        currentOrder.clientPrice.isDetailedPrice
      ).validateSync(currentOrder, {
        abortEarly: false,
      });
      dispatch(setValidation(null));
      const adjustedTerm = adjustTerm(termToPost);
      const savedOrder =
        currentOrder.id === null
          ? await handleAddOrder(adjustedStatus, adjustedTerm)
          : await handleEditOrder(adjustedStatus, adjustedTerm);
      const updatedCurrentOrder = mapOrderToCurrentOrder(savedOrder);
      setOrder(savedOrder);
      dispatch(setCurrentOrder({ currentOrder: updatedCurrentOrder }));
      dispatch(setOriginalData({ originalData: updatedCurrentOrder }));
      callback?.();
    } catch (e: any) {
      setMissingDataModalOpen(true);
      updateOrderValidation(e);
    }
  };

  const calculateIfNeeded = () => {
    if (model && variant && filling && category) {
      if (areUnsavedChanges) {
        try {
          getCalculateSchema(t, !!category?.box).validateSync(currentOrder, {
            abortEarly: false,
          });
          dispatch(setCalculateValidation({}));

          const calculationRequest: CalculationRequest = {
            offerId: currentOrder.id === null ? undefined : currentOrder.id,
            brakeKey,
            clapType,
            distance: distance === "" ? undefined : Number(distance),
            doorType,
            fillingId: filling.id,
            railLength: railExtension === "" ? 0 : Number(railExtension),
            variantId: variant.id,
            withMontage: Boolean(withMontage),
            partnerId: currentOrder.partner?.id,
            box: currentOrder.category?.box ? !currentOrder.box : undefined,
          };

          if (timeoutId) {
            clearTimeout(timeoutId.current);
          }
          // @ts-ignore
          timeoutId.current = setTimeout(() => {
            dispatch(calculate(calculationRequest));
          }, 300);
        } catch (e) {
          updateCalculateValidation(e);
        }
      }
    } else {
      dispatch(resetCalculation());
    }
  };

  const reset = () => {
    dispatch(setCategory(null));
    dispatch(setProfile(null));
    dispatch(setModel(null));
  };

  const onBackClick = () => {
    if (currentOrder.id === null) {
      reset();
    } else {
      navigate(Route.Orders);
    }
  };

  return (
    <Box sx={styles.container}>
      <ConfirmationDialog
        open={isMissingDataModalOpen}
        primaryText={t("zamknij")}
        onPrimary={() => setMissingDataModalOpen(false)}
        title={t("__brak_wymaganych_danych")}
      >
        <Typography>{t("__uzupelnij_brakujace_dane")}</Typography>
      </ConfirmationDialog>
      {model && category ? (
        <OrderForm
          onBackClick={onBackClick}
          submitOrder={submitOrder}
          order={order}
        />
      ) : (
        <ModelPicker />
      )}
    </Box>
  );
};

export default withPageLoader(OrderEdit);
