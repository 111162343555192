import React, { ReactNode } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { Theme, useTheme, SxProps, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { prepareSx, toRem } from "../../utils/common-utils";

interface SectionProps {
  title: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  sx?: SxProps<Theme>;
  id?: string;
}

const getStyles = (theme: Theme, actions: boolean) => ({
  container: {
    paddingBottom: theme.spacing(2),
    "& .MuiCardContent-root": {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
    flexWrap: "wrap",
  },
  title: {
    fontSize: toRem(22),
    fontWeight: 500,
  },
  divider: {
    marginTop: theme.spacing(actions ? 2 : 1),
  },
  sectionContent: {
    padding: theme.spacing(0, 1.5),
  },
});

const Section = ({ actions, children, title, sx, id }: SectionProps) => {
  const theme = useTheme();
  const styles = getStyles(theme, !!actions);

  return (
    <Card
      sx={[styles.container, ...prepareSx(sx)]}
      className="section-container"
      id={id}
    >
      <CardContent>
        <Box>
          <Box sx={styles.header}>
            <Typography sx={styles.title} className="section-title">
              {title}
            </Typography>
            {actions}
          </Box>
          {children && <Divider sx={styles.divider} />}
        </Box>
        {children && <Box sx={styles.sectionContent}>{children}</Box>}
      </CardContent>
    </Card>
  );
};

export default Section;
