import React, { useState } from "react";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { generatePdf } from "../../services/pdf-generator/pdf-generator";
import { Order } from "../../types/order-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isAdminRole } from "../../utils/common-utils";
import useRole from "../../hooks/useRole";

interface ExportDialogProps {
  open: boolean;
  clientCountryName: string;
  partnerCountryName: string;
  order: Order | null;
  onFinish: () => void;
  onClose: () => void;
}

const getStyles = (isMobile: boolean) => {
  if (!isMobile) return {};

  return {
    dialog: {
      "& .MuiDialog-container": {
        alignItems: "flex-start",
        marginTop: "40%",
      },
    },
  };
};

const ExportDialog = ({
  open,
  clientCountryName,
  partnerCountryName,
  order,
  onFinish,
  onClose,
}: ExportDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(isMobile);
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);

  const [isLoading, setLoading] = useState(false);

  const gen = (clientExport: boolean, bucoversClientExport: boolean) => {
    if (order) {
      setLoading(true);
      setTimeout(() => {
        generatePdf(
          order,
          theme,
          t,
          clientCountryName,
          partnerCountryName,
          clientExport,
          bucoversClientExport
        );
        onFinish();
        setLoading(false);
      }, 300);
    }
  };

  const onSecondary = () => {
    if (isAdmin) {
      gen(false, true);
    } else {
      gen(true, false);
    }
  };

  const onPrimary = () => {
    gen(false, false);
  };

  return (
    <ConfirmationDialog
      open={open}
      title={t("__rodzaj_eksportu")}
      loading={isLoading}
      secondaryText={t(
        isAdmin ? "__eksport_dla_klienta_bucovers" : "__eksport_dla_klienta"
      )}
      onSecondary={onSecondary}
      onPrimary={onPrimary}
      primaryText={t("__eksport_bucovers")}
      onClose={onClose}
      sx={styles.dialog}
    >
      <Typography>
        {t("__eksport_bucovers_opis")}{" "}
        {t(
          isAdmin
            ? "__eksport_dla_klienta_bucovers_opis"
            : "__eksport_dla_klienta_opis"
        )}
      </Typography>
    </ConfirmationDialog>
  );
};

export default ExportDialog;
