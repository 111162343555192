import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { deMainTranslations } from "./translations/de/de-main-translations";
import { enMainTranslations } from "./translations/en/en-main-translations";
import { plMainTranslations } from "./translations/pl/pl-main-translations";
import { svMainTranslations } from "./translations/sv/sv-main-translations";
import { plCountriesTranslations } from "./translations/pl/pl-countries-translations";
import { deCountriesTranslations } from "./translations/de/de-countries-translations";
import { enCountriesTranslations } from "./translations/en/en-countries-translations";
import { svCountriesTranslations } from "./translations/sv/sv-countries-translations";

const resources = {
  de: {
    translation: { ...deMainTranslations, ...deCountriesTranslations },
  },
  en: {
    translation: { ...enMainTranslations, ...enCountriesTranslations },
  },
  pl: {
    translation: { ...plMainTranslations, ...plCountriesTranslations },
  },
  sv: {
    translation: { ...svMainTranslations, ...svCountriesTranslations },
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "pl",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
