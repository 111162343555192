import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  Filters,
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import {
  Cover,
  CoverDTO,
  CoverPatchRequest,
  CoverPostRequest,
  mapCoverDtoToCover,
  mapCoverPatchRequestToCoverPatchDTO,
  mapCoverPostRequestToCoverPostDTO,
} from "../../types/cover-types";
import { Store } from "../store";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import {
  DEFAULT_COVER_FILTERS,
  DEFAULT_COVER_SORT,
} from "../slices/cover-slice";
import { DEFAULT_PAGEABLE } from "../../constants/common";

const preprocessContent = (data: PageResponse<CoverDTO>) =>
  data.content
    ? data.content.map((coverDto) => mapCoverDtoToCover(coverDto))
    : data.content;

export const getCovers = createAsyncThunk(
  "covers/getCovers",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { cover } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, cover)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const categoryFilter =
      flatParams.categoryId === DEFAULT_COVER_FILTERS.categoryId
        ? undefined
        : flatParams.categoryId;

    const adjustedParams = {
      ...flatParams,
      categoryId: categoryFilter,
    };

    const { data } = await axios.get<PageResponse<CoverDTO>>("/model", {
      params: adjustedParams,
    });

    const preprocessedData = { ...data, content: preprocessContent(data) };

    return preparePageForAction<Cover>(preprocessedData, params);
  }
);

export const getAllCovers = createAsyncThunk(
  "cover/getAllCovers",
  async (config: { active?: boolean }) => {
    const filters: Filters = {};

    if (config.active) {
      filters.active = config.active;
    }

    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort: DEFAULT_COVER_SORT,
      filters,
    };

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<CoverDTO>>("/model", {
      params: flatParams,
    });

    return preprocessContent(data);
  }
);

export const getCover = createAsyncThunk(
  "cover/getCover",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<CoverDTO>>("/model", {
      params: { id },
    });
    const preprocessedData = { ...data, content: preprocessContent(data) };
    return getFirstElementFromPage<Cover>(preprocessedData);
  }
);

export const addCover = createAsyncThunk(
  "cover/addCover",
  async (params: CoverPostRequest, thunkAPI) => {
    const payload = mapCoverPostRequestToCoverPostDTO(params);
    const { data } = await axios.post<Cover>("/model", payload);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return mapCoverDtoToCover(data);
  }
);

export const editCover = createAsyncThunk(
  "cover/editCover",
  async (params: CoverPatchRequest, thunkAPI) => {
    const payload = mapCoverPatchRequestToCoverPatchDTO(params);
    const { data } = await axios.patch<Cover>("/model", payload);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return mapCoverDtoToCover(data);
  }
);
