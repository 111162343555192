import * as yup from "yup";
import { FormValues } from "./partner-edit.types";
import {
  Address,
  Partner,
  PartnerPostRequest,
  Phone,
} from "../../../types/partner-types";
import { TFunction } from "react-i18next";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  return yup.object({
    name: yup.string().required(requiredFieldError),
    email: yup
      .string()
      .email(t("niepoprawny_mail"))
      .required(requiredFieldError),
    phonePrefix: yup.string().required(requiredFieldError),
    phoneNumber: yup.string().required(requiredFieldError),
    street: yup.string().required(requiredFieldError),
    houseNumber: yup.string().required(requiredFieldError),
    flatNumber: yup.string(),
    zipCode: yup.string().required(requiredFieldError),
    city: yup.string().required(requiredFieldError),
    discount: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError)
      .max(100, invalidValueError),
    countryId: yup
      .number()
      .typeError(requiredFieldError)
      .required(requiredFieldError),
    freeExtensions: yup.boolean(),
    active: yup.boolean(),
  });
};

export const mapFormValuesToPartnerPostRequest = (
  values: FormValues
): PartnerPostRequest => {
  const phone: Phone = {
    number: values.phoneNumber,
    prefix: values.phonePrefix,
  };

  const address: Address = {
    city: values.city,
    countryId: values.countryId,
    flatNumber: values.flatNumber,
    houseNumber: values.houseNumber,
    street: values.street,
    zipCode: values.zipCode,
  };

  return {
    address: address,
    discount: Number(values.discount) / 100,
    email: values.email,
    freeRailExtension: values.freeExtensions,
    name: values.name,
    phone,
    active: values.active,
  };
};

export const mapPartnerToFormValues = (partner: Partner): FormValues => {
  const { address, phone } = partner;

  return {
    name: partner.name,
    email: partner.email,
    phonePrefix: phone.prefix,
    phoneNumber: phone.number,
    street: address.street,
    houseNumber: address.houseNumber,
    flatNumber: address.flatNumber || "",
    zipCode: address.zipCode,
    city: address.city,
    discount: (partner.discount * 100).toString(),
    countryId: address.countryId,
    freeExtensions: partner.freeRailExtension,
    active: partner.active,
  };
};

export const getDefaultFormValues = (): FormValues => ({
  name: "",
  email: "",
  phonePrefix: "",
  phoneNumber: "",
  street: "",
  houseNumber: "",
  flatNumber: "",
  zipCode: "",
  city: "",
  discount: "",
  // @ts-ignore
  countryId: null,
  freeExtensions: false,
  active: true,
});

export const getInitFormValues = (partner: Partner | null): FormValues =>
  partner ? mapPartnerToFormValues(partner) : getDefaultFormValues();
