import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageChanges } from "../../../../types/common-types";
import { applyChangesToPage } from "../../../../redux/redux-utils";
import { getCovers } from "../../../../redux/actions/cover-actions";
import { DEFAULT_COVER_FILTERS } from "../../../../redux/slices/cover-slice";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Route } from "../../../../constants/navigation-constants";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Filters from "../../../../components/filters/filters";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Category } from "../../../../types/category-types";

const CoverFilter = () => {
  const reducer = useSelector((state: Store) => state.cover);
  const { allCategories } = useSelector((state: Store) => state.category);
  const [active, setActive] = useState(reducer.filters.active);
  const [categoryId, setCategoryId] = useState(reducer.filters.categoryId);
  const [label, setLabel] = useState(reducer.filters.label);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchPage = (changes?: PageChanges) => {
    const params = applyChangesToPage(reducer, changes);
    dispatch(getCovers({ params }));
  };

  const onSearch = () => {
    const updatedFilters = { active, label, categoryId };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onReset = () => {
    setActive(DEFAULT_COVER_FILTERS.active);
    setLabel(DEFAULT_COVER_FILTERS.label);
    setCategoryId(DEFAULT_COVER_FILTERS.categoryId);

    const updatedFilters = {
      active: DEFAULT_COVER_FILTERS.active,
      label: DEFAULT_COVER_FILTERS.label,
      categoryId: DEFAULT_COVER_FILTERS.categoryId,
    };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onActiveChange = (event: SelectChangeEvent) => {
    const value = event.target.value === "true";
    setActive(value);
  };

  const onNameChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setLabel(e.target.value);

  const onAddClick = () => navigate(Route.CoverEdit);

  const getCategoryById = () =>
    allCategories?.find((category) => category.id === categoryId) || null;

  const filters = (
    <>
      <FormControl sx={{ width: 170 }}>
        <InputLabel>{t("status")}</InputLabel>
        <Select
          value={active?.toString()}
          label={t("status")}
          onChange={onActiveChange}
          size="small"
        >
          <MenuItem value="true">{t("aktywny")}</MenuItem>
          <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label={t("nazwa")}
        size="small"
        variant="outlined"
        value={label}
        onChange={onNameChange}
      />
      <Autocomplete
        sx={{ width: 250 }}
        size="small"
        autoHighlight
        fullWidth
        noOptionsText={t("__brak_opcji")}
        options={allCategories || []}
        value={getCategoryById()}
        onChange={(event: any, newValue: Category | null) => {
          setCategoryId(newValue?.id || null);
        }}
        getOptionLabel={(category) =>
          category.active
            ? category.label
            : `${category.label} (${t("dezaktywowany")})`
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Typography>
              {option.active
                ? option.label
                : `${option.label} (${t("dezaktywowany")})`}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("__kategoria")}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        )}
      />
    </>
  );

  return (
    <Filters
      filters={filters}
      onReset={onReset}
      onSearch={onSearch}
      onAddClick={onAddClick}
    />
  );
};

export default CoverFilter;
