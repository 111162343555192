import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import { TFunction, withTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
  t: TFunction;
};

type State = {
  hasError: boolean;
};

const getStyles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 3,
    marginTop: "20%",
  },
  icon: {
    height: 50,
    width: 50,
  },
});

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const styles = getStyles();

    if (this.state.hasError) {
      return (
        <Box sx={styles.container}>
          <ErrorIcon sx={styles.icon} />
          <Typography variant="h5">{this.props.t("ups")}</Typography>
          <Typography>{this.props.t("ups_tresc")}</Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
