import { useSelector } from "react-redux";
import { Store } from "../redux/store";
import useRole from "./useRole";
import { OrderStatus } from "../types/order-types";
import { isPartner } from "../utils/common-utils";

const useDisableAll = () => {
  const userRole = useRole();
  const status = useSelector((state: Store) => state.order.currentOrder.status);

  return (
    status === OrderStatus.EXPIRED ||
    (status !== OrderStatus.OFFER && isPartner(userRole))
  );
};

export default useDisableAll;
