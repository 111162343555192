import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Filters,
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import {
  Profile,
  ProfilePatchRequest,
  ProfilePostRequest,
} from "../../types/profile-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { DEFAULT_PROFILE_SORT } from "../slices/profile-slice";

export const getProfiles = createAsyncThunk(
  "profiles/getProfiles",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { profile } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, profile)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Profile>>("/model-profile", {
      params: flatParams,
    });

    return preparePageForAction<Profile>(data, params);
  }
);

export const getAllProfiles = createAsyncThunk(
  "profiles/getAllProfiles",
  async (config: { active?: boolean }) => {
    const filters: Filters = {};

    if (config.active) {
      filters.active = config.active;
    }

    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort: DEFAULT_PROFILE_SORT,
      filters,
    };

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Profile>>("/model-profile", {
      params: flatParams,
    });
    return data.content;
  }
);

export const getProfile = createAsyncThunk(
  "profiles/getProfile",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Profile>>(`/model-profile`, {
      params: { id },
    });
    return getFirstElementFromPage<Profile>(data);
  }
);

export const addProfile = createAsyncThunk(
  "profiles/addProfile",
  async (params: ProfilePostRequest, thunkAPI) => {
    const { data } = await axios.post<Profile>("/model-profile", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editProfile = createAsyncThunk(
  "profiles/editProfile",
  async (params: ProfilePatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Profile>("/model-profile", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
