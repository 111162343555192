import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import {
  fetchNextPageUsersOfferSearch,
  getUsersOfferSearch,
} from "../../../../../redux/actions/user-offer-search-actions";
import {
  DEFAULT_USER_SEARCH_FILTERS,
  reset,
} from "../../../../../redux/slices/user-offer-search-slice";
import { CreatedBy } from "../../../../../redux/slices/user-offer-search-slice";
import useDebouncedState from "../../../../../hooks/useDebouncedState";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";

interface OrderUsersSearchProps {
  pickedUser: CreatedBy | null;
  setUser: (user: CreatedBy | null) => void;
}

const OrderUsersSearch = ({ pickedUser, setUser }: OrderUsersSearchProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { users, filters, size, page, sort, totalPages } = useSelector(
    (state: Store) => state.userOfferSearch
  );
  const [value, debouncedValue, setValue] = useDebouncedState("");

  console.log(debouncedValue);

  useEffect(() => {
    defaultFetch();

    return () => {
      dispatch(reset());
    };
  }, []);

  useUpdateEffect(() => {
    fetchWithName(0);
  }, [debouncedValue]);

  const defaultFetch = () => {
    dispatch(
      getUsersOfferSearch({
        params: {
          filters: { ...filters, name: DEFAULT_USER_SEARCH_FILTERS.name },
          size,
          sort,
          page: 0,
        },
      })
    );
  };

  const fetchWithName = (pageNumber: number) => {
    const updatedFilters = { ...filters, name: debouncedValue };
    const action =
      pageNumber === 0 ? getUsersOfferSearch : fetchNextPageUsersOfferSearch;
    dispatch(
      action({
        params: {
          filters: updatedFilters,
          size,
          sort,
          page: pageNumber,
        },
      })
    );
  };

  const fetchNextPageIfNeeded = () => {
    if (shouldFetchNextPage()) {
      fetchWithName(page + 1);
    }
  };

  const shouldFetchNextPage = () => {
    return page < totalPages;
  };

  const formatUser = (user: CreatedBy) => user.name;

  const onScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight ===
      listboxNode.scrollHeight
    ) {
      fetchNextPageIfNeeded();
    }
  };

  const onClose = () => {
    defaultFetch();
    if (pickedUser === null) {
      setValue("");
    }
  };

  return (
    <Autocomplete
      sx={{ width: 250 }}
      size="small"
      autoHighlight
      fullWidth
      noOptionsText={t("__brak_opcji")}
      options={users}
      onClose={onClose}
      value={pickedUser}
      ListboxProps={{
        onScroll,
        style: { maxHeight: 150 },
        role: "list-box",
      }}
      onChange={(event: any, newValue: CreatedBy | null) => {
        setUser(newValue);
        dispatch(
          getUsersOfferSearch({
            params: {
              filters: { ...filters, name: DEFAULT_USER_SEARCH_FILTERS.name },
              size,
              sort,
              page: 0,
            },
          })
        );
      }}
      getOptionLabel={(user) => formatUser(user)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Typography>{formatUser(option)}</Typography>
        </li>
      )}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("__dodane_przez")}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      )}
    />
  );
};

export default OrderUsersSearch;

//
