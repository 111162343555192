import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageChanges } from "../../../../types/common-types";
import { applyChangesToPage } from "../../../../redux/redux-utils";
import { getUsers } from "../../../../redux/actions/user-actions";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Route } from "../../../../constants/navigation-constants";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Filters from "../../../../components/filters/filters";
import { DEFAULT_USER_FILTERS } from "../../../../redux/slices/user-slice";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { Partner } from "../../../../types/partner-types";

const UsersFilters = () => {
  const userReducer = useSelector((state: Store) => state.user);
  const { allPartners } = useSelector((state: Store) => state.partner);
  const { roles } = useSelector((state: Store) => state.auth);

  const [active, setActive] = useState(userReducer.filters.active);
  const [partnerId, setPartnerId] = useState(userReducer.filters.partnerId);
  const [roleId, setRoleId] = useState(userReducer.filters.roleId);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchPage = (changes?: PageChanges) => {
    const params = applyChangesToPage(userReducer, changes);
    dispatch(getUsers({ params }));
  };

  const onSearch = () => {
    const updatedFilters = {
      active,
      partnerId,
      roleId,
    };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onReset = () => {
    setActive(DEFAULT_USER_FILTERS.active);
    setPartnerId(DEFAULT_USER_FILTERS.partnerId);
    setRoleId(DEFAULT_USER_FILTERS.roleId);

    const updatedFilters = {
      active: DEFAULT_USER_FILTERS.active,
      partnerId: DEFAULT_USER_FILTERS.partnerId,
      roleId: DEFAULT_USER_FILTERS.roleId,
    };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onActiveChange = (event: SelectChangeEvent) => {
    const value = event.target.value === "true";
    setActive(value);
  };

  const onRoleChange = (event: SelectChangeEvent) => {
    setRoleId(event.target.value);
  };

  const onAddClick = () => navigate(Route.UsersEdit);

  const getPartnerById = () =>
    allPartners?.find((partner) => partner.id === partnerId) || null;

  const filters = (
    <>
      <FormControl sx={{ width: 170 }} size="small">
        <InputLabel>{t("status")}</InputLabel>
        <Select
          value={active?.toString()}
          label={t("status")}
          onChange={onActiveChange}
          size="small"
        >
          <MenuItem value="true">{t("aktywny")}</MenuItem>
          <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
        </Select>
      </FormControl>
      <Autocomplete
        sx={{ width: 250 }}
        size="small"
        autoHighlight
        fullWidth
        noOptionsText={t("__brak_opcji")}
        options={allPartners || []}
        value={getPartnerById()}
        onChange={(event: any, newValue: Partner | null) => {
          setPartnerId(newValue?.id || null);
        }}
        getOptionLabel={(partner) =>
          partner.active
            ? partner.name
            : `${partner.name} (${t("dezaktywowany")})`
        }
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Typography>
              {option.active
                ? option.name
                : `${option.name} (${t("dezaktywowany")})`}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("partner")}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        )}
      />
      <FormControl sx={{ width: 150 }} size="small">
        <InputLabel>{t("rola")}</InputLabel>
        <Select
          size="small"
          value={roleId?.toString() || DEFAULT_USER_FILTERS.roleId}
          label={t("rola")}
          onChange={onRoleChange}
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {t(role.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  return (
    <Filters
      filters={filters}
      onReset={onReset}
      onSearch={onSearch}
      onAddClick={onAddClick}
    />
  );
};

export default UsersFilters;
