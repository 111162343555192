import React from "react";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../../../../components/confirmation-dialog/confirmation-dialog";
import { Typography } from "@mui/material";

interface DeleteOrderDialogProps {
  open: boolean;
  onCancelClick: () => void;
  deleteCallback: () => void;
  id?: number;
  loading?: boolean;
}

const DeleteOrderDialog = ({
  deleteCallback,
  onCancelClick,
  open,
  id,
  loading = false,
}: DeleteOrderDialogProps) => {
  const { t } = useTranslation();

  const content = (
    <Typography>
      {t("__usunac_oferte")} <strong>{id}</strong>?
    </Typography>
  );

  return (
    <ConfirmationDialog
      open={open}
      title={t("potwierdz_operacje")}
      primaryText={t("anuluj")}
      onPrimary={onCancelClick}
      secondaryText={t("__usun")}
      onSecondary={deleteCallback}
      secondaryColor="error"
      loading={loading}
    >
      <Typography>{content}</Typography>
    </ConfirmationDialog>
  );
};

export default DeleteOrderDialog;
