import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  editProfile,
  getProfiles,
} from "../../../../redux/actions/profile-actions";
import MenuActions, {
  Action,
} from "../../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../../constants/navigation-constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Profile } from "../../../../types/profile-types";
import { Columns } from "../../../../components/pagination-table/table-types";
import PaginationTable from "../../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../../../components/confirmation-dialog/confirmation-dialog";
import { Typography } from "@mui/material";
import Section from "../../../../components/section/section";
import ProfileFilter from "./profile-filter/profile-filter";
import { formatPrice } from "../../../../utils/formatter-utils";
import { DEFAULT_TABLE_WIDTH } from "../../../../theme/sizes";

const tableStyles = {
  minWidth: 600,
  maxWidth: DEFAULT_TABLE_WIDTH,
  margin: "0 auto",
};

interface ProfileListProps extends WithPageLoaderProps {}

const ProfileList = ({ finishLoading }: ProfileListProps) => {
  const reducer = useSelector((state: Store) => state.profile);
  const { loading, size, sort, filters } = reducer;
  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const isActive = filters.active;

  const init = async () => {
    await Promise.all([
      dispatch(
        getProfiles({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      await dispatch(editProfile({ id: pickedId, active: !isActive }));
      dispatch(
        getProfiles({ params: { size, page: 0, sort, filters }, reload: true })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const getProfileName = () =>
    reducer.content?.find((profile) => profile.id === pickedId)?.label;

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.ProfileEdit}/${id}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderPriceCell = (profile: Profile) => (
    <Typography key={`${profile.id}-price-cell`}>
      {formatPrice(profile.railExtensionPrice.value)} €
    </Typography>
  );

  const renderActionsCell = (profile: Profile) => (
    <MenuActions
      key={`${profile.id}-actions-cell`}
      actions={actions}
      entityId={profile.id}
    />
  );

  const columns: Columns<Profile> = [
    { id: "label", accessor: "label", flexGrow: 10, name: t("nazwa") },
    {
      id: "price",
      cellRenderer: renderPriceCell,
      flexGrow: 10,
      name: t("__cena__przedluzenia_w_profilu"),
    },
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const table = (
    <PaginationTable<Profile>
      withPagination={true}
      loading={loading}
      fetcherAction={getProfiles}
      reducerName="profile"
      columns={columns}
    />
  );

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("__dezaktywacja_profilu_pytanie")
            : t("__aktywacja_profilu_pytanie")}{" "}
          <strong>({getProfileName()})</strong>
        </Typography>
      </ConfirmationDialog>
      <Section
        title={t("profile")}
        actions={<ProfileFilter />}
        sx={tableStyles}
      >
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(ProfileList);
