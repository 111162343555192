import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "../billing-info.styles";
import NumericInput from "../../../../../../components/numeric-input/numeric-input";
import {
  calculateFinal,
  getCommonProps,
  getInputProps,
} from "../billing-info.utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../../redux/store";
import useDisableAll from "../../../../../../hooks/useDisableAll";
import { useTranslation } from "react-i18next";
import {
  PriceWithDiscount,
  setVariantPrice,
  setRailExtensionPrice,
  setDoorPrice,
  setBrakePrice,
  setTransportPrice,
  setMontagePrice,
  setBoxMontagePrice,
  ClientPrice,
  setClientPrice,
  BasicClientPrice,
  setFinalClientPrice,
} from "../../../../../../redux/slices/order-slice";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { CURRENCIES } from "../../../../../../types/price-types";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

type FieldName =
  | "variant"
  | "railExtension"
  | "door"
  | "brake"
  | "transport"
  | "montage"
  | "boxMontage";
type WithFieldName = { fieldName: FieldName };
type Change = PriceWithDiscount & WithFieldName;

const DetailedClientPrices = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isFull = useMediaQuery("(max-width: 1440px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();

  const { response: calculation } = useSelector(
    (state: Store) => state.order.calculation
  );

  const { currentOrder, validation } = useSelector(
    (state: Store) => state.order
  );

  const { clientDelivery, clientPrice } = currentOrder;
  const { detailedClientPrice } = clientPrice;
  const {
    variant,
    transport,
    montage,
    door,
    brake,
    boxMontage,
    railExtension,
  } = detailedClientPrice;

  const COMMON_PROPS = getCommonProps(
    !!calculation || !!currentOrder.payment,
    disableAll,
    clientDelivery
  );

  useEffect(() => {
    const final =
      Number(variant.final) +
      Number(transport.final) +
      Number(montage.final) +
      Number(door.final) +
      Number(brake.final) +
      Number(boxMontage.final) +
      Number(railExtension.final);
    const finalWithVat = final * (1 + Number(clientPrice.vatRate) / 100);
    dispatch(
      setFinalClientPrice({
        final: final.toString(),
        finalWithVat: finalWithVat.toString(),
      })
    );
  }, [detailedClientPrice]);

  const handleChange = ({ fieldName, price, discount, final }: Change) => {
    const priceNum = Number(price);
    const discountNum = Number(discount);
    const [calculatedFinal] = calculateFinal(priceNum, discountNum, 0, final);
    const changeToDispatch: Change = {
      price,
      discount,
      final: calculatedFinal,
      fieldName,
    };
    dispatchChange(changeToDispatch);
  };

  const handleFinalChange = ({ fieldName, price, discount, final }: Change) => {
    const discountNum = Number(discount);
    const finalNum = Number(final);
    const priceNum = finalNum / (1 - discountNum / 100);
    const changeToDispatch: Change = {
      price: isNaN(priceNum) ? price : priceNum.toString(),
      discount,
      final,
      fieldName,
    };
    dispatchChange(changeToDispatch);
  };

  const dispatchChange = (change: Change) => {
    switch (change.fieldName) {
      case "variant":
        dispatch(setVariantPrice(change));
        break;
      case "railExtension":
        dispatch(setRailExtensionPrice(change));
        break;
      case "door":
        dispatch(setDoorPrice(change));
        break;
      case "brake":
        dispatch(setBrakePrice(change));
        break;
      case "transport":
        dispatch(setTransportPrice(change));
        break;
      case "montage":
        dispatch(setMontagePrice(change));
        break;
      case "boxMontage":
        dispatch(setBoxMontagePrice(change));
        break;
    }
  };

  const handleCurrencyChange = (value: string) => {
    const price: ClientPrice = {
      ...clientPrice,
      currency: CURRENCIES.find((x) => x.code === value)!,
    };
    dispatch(setClientPrice(price));
  };

  const handleVatRateChange = (value: string) => {
    const vatRate = Number(value);
    const final = Number(clientPrice.final);
    const finalWithVat = final * (1 + vatRate / 100);
    const price: BasicClientPrice = {
      base: clientPrice.base,
      discount: clientPrice.discount,
      final: clientPrice.final,
      vatRate: value,
      finalWithVat: finalWithVat.toString(),
      currency: clientPrice.currency,
    };
    dispatch(setClientPrice(price));
  };

  return (
    <>
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <FormControl fullWidth>
            <InputLabel>{t("__waluta_klienta")}</InputLabel>
            <Select
              {...COMMON_PROPS}
              value={clientPrice.currency.code}
              label={t("__waluta_klienta")}
              onChange={(e) => handleCurrencyChange(e.target.value)}
              size="small"
            >
              {CURRENCIES.sort((a, b) =>
                t(a.code).localeCompare(t(b.code))
              ).map(({ code, symbol }) => (
                <MenuItem key={code} value={code}>
                  {`${t(code)} (${symbol})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__stawka_vat")}
            value={clientPrice.vatRate}
            onChange={(e) => handleVatRateChange(e.target.value)}
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={!!validation && !!validation["clientPrice.vatRate"]}
            helperText={!!validation && validation["clientPrice.vatRate"]}
          />
        </Box>
        {!isFull && <Box sx={styles.field} />}
      </Box>
      <Divider sx={styles.divider} />
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_wariantu_dla_klienta_przed_rabatem")}
            value={variant.price}
            onChange={(e) =>
              handleChange({
                fieldName: "variant",
                price: e.target.value,
                discount: variant.discount,
                final: variant.final,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.variant.price"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.variant.price"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__rabat_na_wariant_dla_klienta")}
            value={variant.discount}
            onChange={(e) =>
              handleChange({
                fieldName: "variant",
                price: variant.price,
                discount: e.target.value,
                final: variant.final,
              })
            }
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.variant.discount"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.variant.discount"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_wariantu_dla_klienta_po_rabacie")}
            value={variant.final}
            onChange={(e) =>
              handleFinalChange({
                fieldName: "variant",
                price: variant.price,
                discount: variant.discount,
                final: e.target.value,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.variant.final"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.variant.final"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_przedluzenia_szyn_dla_klienta_przed_rabatem")}
            value={railExtension.price}
            onChange={(e) =>
              handleChange({
                fieldName: "railExtension",
                price: e.target.value,
                discount: railExtension.discount,
                final: railExtension.final,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation[
                "clientPrice.detailedClientPrice.railExtension.price"
              ]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.railExtension.price"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__rabat_na_przedluzenie_szyn_dla_klienta")}
            value={railExtension.discount}
            onChange={(e) =>
              handleChange({
                fieldName: "railExtension",
                price: railExtension.price,
                discount: e.target.value,
                final: railExtension.final,
              })
            }
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={
              !!validation &&
              !!validation[
                "clientPrice.detailedClientPrice.railExtension.discount"
              ]
            }
            helperText={
              !!validation &&
              validation[
                "clientPrice.detailedClientPrice.railExtension.discount"
              ]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_przedluzenia_szyn_dla_klienta_po_rabacie")}
            value={railExtension.final}
            onChange={(e) =>
              handleFinalChange({
                fieldName: "railExtension",
                price: railExtension.price,
                discount: railExtension.discount,
                final: e.target.value,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation[
                "clientPrice.detailedClientPrice.railExtension.final"
              ]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.railExtension.final"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_kluczyka_blokady_dla_klienta_przed_rabatem")}
            value={brake.price}
            onChange={(e) =>
              handleChange({
                fieldName: "brake",
                price: e.target.value,
                discount: brake.discount,
                final: brake.final,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.brake.price"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.brake.price"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__rabat_na_kluczyk_blokady_dla_klienta")}
            value={brake.discount}
            onChange={(e) =>
              handleChange({
                fieldName: "brake",
                price: brake.price,
                discount: e.target.value,
                final: brake.final,
              })
            }
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.brake.discount"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.brake.discount"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_kluczyka_blokady_dla_klienta_po_rabacie")}
            value={brake.final}
            onChange={(e) =>
              handleFinalChange({
                fieldName: "brake",
                price: brake.price,
                discount: brake.discount,
                final: e.target.value,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.brake.final"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.brake.final"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_drzwi_dla_klienta_przed_rabatem")}
            value={door.price}
            onChange={(e) =>
              handleChange({
                fieldName: "door",
                price: e.target.value,
                discount: door.discount,
                final: door.final,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.door.price"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.door.price"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__rabat_na_drzwi_dla_klienta")}
            value={door.discount}
            onChange={(e) =>
              handleChange({
                fieldName: "door",
                price: door.price,
                discount: e.target.value,
                final: door.final,
              })
            }
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.door.discount"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.door.discount"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_drzwi_dla_klienta_po_rabacie")}
            value={door.final}
            onChange={(e) =>
              handleFinalChange({
                fieldName: "door",
                price: door.price,
                discount: door.discount,
                final: e.target.value,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.door.final"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.door.final"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_transportu_dla_klienta_przed_rabatem")}
            value={transport.price}
            onChange={(e) =>
              handleChange({
                fieldName: "transport",
                price: e.target.value,
                discount: transport.discount,
                final: transport.final,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.transport.price"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.transport.price"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__rabat_na_transport_dla_klienta")}
            value={transport.discount}
            onChange={(e) =>
              handleChange({
                fieldName: "transport",
                price: transport.price,
                discount: e.target.value,
                final: transport.final,
              })
            }
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.transport.discount"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.transport.discount"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_transportu_dla_klienta_po_rabacie")}
            value={transport.final}
            onChange={(e) =>
              handleFinalChange({
                fieldName: "transport",
                price: transport.price,
                discount: transport.discount,
                final: e.target.value,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.transport.final"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.transport.final"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_montazu_dla_klienta_przed_rabatem")}
            value={montage.price}
            onChange={(e) =>
              handleChange({
                fieldName: "montage",
                price: e.target.value,
                discount: montage.discount,
                final: montage.final,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.montage.price"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.montage.price"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__rabat_na_montaz_dla_klienta")}
            value={montage.discount}
            onChange={(e) =>
              handleChange({
                fieldName: "montage",
                price: montage.price,
                discount: e.target.value,
                final: montage.final,
              })
            }
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.montage.discount"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.montage.discount"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_montazu_dla_klienta_po_rabacie")}
            value={montage.final}
            onChange={(e) =>
              handleFinalChange({
                fieldName: "montage",
                price: montage.price,
                discount: montage.discount,
                final: e.target.value,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.montage.final"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.montage.final"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_zlozenia_zadaszenia_dla_klienta_przed_rabatem")}
            value={boxMontage.price}
            onChange={(e) =>
              handleChange({
                fieldName: "boxMontage",
                price: e.target.value,
                discount: boxMontage.discount,
                final: boxMontage.final,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.boxMontage.price"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.boxMontage.price"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__rabat_na_zlozenie_zadaszenia_dla_klienta")}
            value={boxMontage.discount}
            onChange={(e) =>
              handleChange({
                fieldName: "boxMontage",
                price: boxMontage.price,
                discount: e.target.value,
                final: boxMontage.final,
              })
            }
            InputProps={getInputProps("%")}
            required={clientDelivery}
            error={
              !!validation &&
              !!validation[
                "clientPrice.detailedClientPrice.boxMontage.discount"
              ]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.boxMontage.discount"]
            }
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_zlozenia_zadaszenia_dla_klienta_po_rabacie")}
            value={boxMontage.final}
            onChange={(e) =>
              handleFinalChange({
                fieldName: "boxMontage",
                price: boxMontage.price,
                discount: boxMontage.discount,
                final: e.target.value,
              })
            }
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={clientDelivery}
            isMoney
            error={
              !!validation &&
              !!validation["clientPrice.detailedClientPrice.boxMontage.final"]
            }
            helperText={
              !!validation &&
              validation["clientPrice.detailedClientPrice.boxMontage.final"]
            }
          />
        </Box>
        {isFull && <Box sx={styles.field} />}
      </Box>
      <Divider sx={styles.divider} />
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_dla_klienta")}
            value={clientPrice.final}
            InputProps={getInputProps(clientPrice.currency.symbol)}
            isMoney
            disabled
            required={false}
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__cena_brutto")}
            value={clientPrice.finalWithVat}
            InputProps={getInputProps(clientPrice.currency.symbol)}
            required={false}
            isMoney
            disabled
          />
        </Box>
        <Box sx={styles.field} />
      </Box>
    </>
  );
};

export default DetailedClientPrices;
