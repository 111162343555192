import React, { useEffect, useState } from "react";
import Render from "./render";
import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import Section from "../section/section";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const getStyles = (theme: Theme, isMobile: boolean) => ({
  container: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  },
});

interface RendersProps {
  defaultPhoto: string;
  doorPhoto: string;
  flapPhoto: string;
  secondaryDoorPhoto: string;
  secondaryFlapPhoto: string;
  onChangeDefaultPhoto: (base64: string) => void;
  onChangeFlapPhoto: (base64: string) => void;
  onChangeDoorPhoto: (base64: string) => void;
  onChangeSecondaryFlapPhoto: (base64: string) => void;
  onChangeSecondaryDoorPhoto: (base64: string) => void;
  error?: boolean;
  helperText?: string | false;
}

const Renders = ({
  defaultPhoto,
  doorPhoto,
  flapPhoto,
  secondaryFlapPhoto,
  secondaryDoorPhoto,
  onChangeDefaultPhoto,
  onChangeDoorPhoto,
  onChangeFlapPhoto,
  onChangeSecondaryDoorPhoto,
  onChangeSecondaryFlapPhoto,
  error,
  helperText,
}: RendersProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 1200px)");
  const styles = getStyles(theme, isMobile);
  const { t } = useTranslation();

  const [areAdditionalPhotos, setAdditionalPhotos] = useState(false);

  useEffect(() => {
    if (Boolean(secondaryFlapPhoto) || Boolean(secondaryDoorPhoto)) {
      setAdditionalPhotos(true);
    }
  }, [secondaryFlapPhoto, secondaryDoorPhoto]);

  const handleAdditionalPhotosChange = (value: boolean) => {
    onChangeSecondaryFlapPhoto("");
    onChangeSecondaryDoorPhoto("");
    setAdditionalPhotos(value);
  };

  const actions = (
    <FormControlLabel
      control={
        <Checkbox
          checked={areAdditionalPhotos}
          onChange={(value) =>
            handleAdditionalPhotosChange(value.target.checked)
          }
        />
      }
      label={t("__dodatkowe_zdjecia")}
    />
  );

  return (
    <Section title={t("__rendery_zadaszenia")} actions={actions}>
      <Box sx={styles.container}>
        <Render
          id="default-image"
          text={t("__domyslny")}
          value={defaultPhoto}
          onChange={onChangeDefaultPhoto}
          error={error}
          helperText={helperText}
        />
        <Render
          id="doors-image"
          text={t("__z_drzwiami")}
          value={doorPhoto}
          onChange={onChangeDoorPhoto}
        />
        <Render
          id="flap-image"
          text={t("__z_klapa")}
          value={flapPhoto}
          onChange={onChangeFlapPhoto}
        />
        {areAdditionalPhotos && (
          <>
            <Render
              id="secondary-doors-image"
              text={t("__dodatkowe_z_drzwiami")}
              value={secondaryDoorPhoto}
              onChange={onChangeSecondaryDoorPhoto}
            />
            <Render
              id="secondary-flap-image"
              text={t("__dodatkowe_z_klapa")}
              value={secondaryFlapPhoto}
              onChange={onChangeSecondaryFlapPhoto}
            />
          </>
        )}
      </Box>
    </Section>
  );
};

export default Renders;
