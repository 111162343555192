import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Section from "../section/section";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../redux/store";
import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import UnsavedChangesModal from "../unsaved-changes-modal/unsaved-changes-modal";
import RefreshIcon from "@mui/icons-material/Refresh";
import useMediaQuery from "@mui/material/useMediaQuery";
import { APP_BAR_CONTAINER } from "../navigation/drawer";
import { setPinned } from "../../redux/slices/ui-slice";

interface SectionTitleProps {
  title: ReactNode;
  isLoading: boolean;
  onBackClick: () => void;
  isEditing: boolean;
  additionalActions?: ReactNode;
  onSubmit?: () => void;
  onRollback: () => void;
  hideApiButtons?: boolean;
}

const getStyles = (
  theme: Theme,
  isSmall: boolean,
  additionalActions?: boolean
) => ({
  section: {
    "& .MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(0),
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    flexWrap: additionalActions ? "wrap" : "none",
  },
});

const SectionTitle = ({
  title,
  isLoading,
  onBackClick,
  isEditing,
  additionalActions,
  onSubmit,
  onRollback,
  hideApiButtons,
}: SectionTitleProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery("(max-width: 1260px)");
  const styles = getStyles(theme, isSmall, Boolean(additionalActions));
  const dispatch = useDispatch<AppDispatch>();

  const areUnsavedChanges = useSelector(
    (state: Store) => state.changes.areUnsavedChanges
  );
  const { isPinned } = useSelector((state: Store) => state.ui);

  const [isUnsavedChangesModalOpen, setUnsavedChangesModalOpen] =
    useState(false);
  const [isRollbackChangesModalOpen, setRollbackChangesModalOpen] =
    useState(false);

  useEffect(() => {
    return () => {
      dispatch(setPinned(false));
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener("scroll", onscroll);

    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("scroll", onscroll);
    };
  }, [isPinned, isSmall]);

  const onscroll = () => {
    if (!isSmall && window.scrollY > 150 && !isPinned) {
      dispatch(setPinned(true));
    }
    if (window.scrollY < 150 && isPinned) {
      dispatch(setPinned(false));
    }
  };

  const titleNode = areUnsavedChanges ? (
    <Box sx={styles.wrapper}>
      <span>{title}</span>
      <Chip
        label={t("__niezapisane_zmiany")}
        color="warning"
        variant="outlined"
      />
    </Box>
  ) : (
    title
  );

  const actions = (
    <Box sx={styles.actions}>
      <LoadingButton
        variant="outlined"
        onClick={() => setUnsavedChangesModalOpen(true)}
        loading={isLoading}
        loadingPosition="start"
        startIcon={<ArrowBackIcon />}
      >
        {t("powrot")}
      </LoadingButton>
      {!hideApiButtons && (
        <>
          {isEditing && (
            <LoadingButton
              variant="outlined"
              onClick={() => setRollbackChangesModalOpen(true)}
              loading={isLoading}
              loadingPosition="end"
              disabled={isEditing && !areUnsavedChanges}
              endIcon={<RefreshIcon />}
            >
              {t("__porzuc_zmiany")}
            </LoadingButton>
          )}
          <LoadingButton
            variant="contained"
            type={onSubmit ? "button" : "submit"}
            loading={isLoading}
            loadingPosition="end"
            disabled={isEditing && !areUnsavedChanges}
            endIcon={<SaveIcon />}
            {...(onSubmit
              ? {
                  onClick: () => {
                    onSubmit();
                  },
                }
              : {})}
          >
            {t("zapisz")}
          </LoadingButton>
          {additionalActions}
        </>
      )}
    </Box>
  );

  const onCancelDiscardClick = () => setUnsavedChangesModalOpen(false);

  const onCancelRollbackClick = () => setRollbackChangesModalOpen(false);

  const handleDiscardClick = () => {
    setUnsavedChangesModalOpen(false);
    onBackClick();
  };

  const handleRollbackClick = () => {
    setRollbackChangesModalOpen(false);
    onRollback();
  };

  const appBarLocation = document.getElementById(APP_BAR_CONTAINER);

  const content = (
    <>
      <UnsavedChangesModal
        open={isUnsavedChangesModalOpen}
        onCancelClick={onCancelDiscardClick}
        leaveCallback={handleDiscardClick}
      />
      <UnsavedChangesModal
        open={isRollbackChangesModalOpen}
        onCancelClick={onCancelRollbackClick}
        leaveCallback={handleRollbackClick}
      />
      <Section title={titleNode} actions={actions} sx={styles.section} />
    </>
  );

  return isPinned && appBarLocation
    ? createPortal(content, appBarLocation)
    : content;
};

export default SectionTitle;
