import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import useFetchEntity, {
  FetchEntityArgs,
} from "../../../../hooks/useFetchEntity";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getStyles } from "../../../partners-page/partner-edit/partner-edit.styles";
import { Category } from "../../../../types/category-types";
import { Route } from "../../../../constants/navigation-constants";
import {
  addCategory,
  editCategory,
  getCategory,
} from "../../../../redux/actions/category-actions";
import { FormValues } from "./category-edit.types";
import {
  getInitFormValues,
  getValidationSchema,
  mapFormValuesToCategoryPatchRequest,
  mapFormValuesToCategoryPostRequest,
} from "./category-edit.utils";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Section from "../../../../components/section/section";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { getAllBrakes } from "../../../../redux/actions/brake-actions";
import { getAllColors } from "../../../../redux/actions/color-actions";
import { getAllFillings } from "../../../../redux/actions/filling-actions";
import ComponentsAvailability from "../../../../components/components-availability/components-availability";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../../redux/slices/changes-slice";
import SectionTitle from "../../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../../redux/redux-utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface CategoryEditProps extends WithPageLoaderProps {}

const CategoryEdit = ({ finishLoading }: CategoryEditProps) => {
  const { id } = useParams<{ id?: string }>();
  const [edited, setEdited] = useState<Category | null>(null);
  const { content: categories, loading } = useSelector(
    (state: Store) => state.category
  );
  const { originalData } = useSelector((state: Store) => state.changes);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<Category> = {
      callback: setEdited,
      collection: categories,
      fallbackRoute: Route.Categories,
      fetcherAction: getCategory,
      id,
    };
    await Promise.all([
      fetchEntity<Category>(fetchArgs),
      dispatch(getAllBrakes({})),
      dispatch(getAllColors({})),
      dispatch(getAllFillings({})),
    ]);
    finishLoading();
  };

  const onSubmit = async (values: FormValues) => {
    const saveFn = edited ? submitEditCategory : submitAddCategory;
    const savedCategory = await saveFn(values);
    if (savedCategory) {
      setEdited(savedCategory);
    }
  };

  const submitAddCategory = async (values: FormValues) => {
    const category = mapFormValuesToCategoryPostRequest(values);
    const { payload } = await dispatch(addCategory(category));
    return payload as Category;
  };

  const submitEditCategory = async (values: FormValues) => {
    const category = mapFormValuesToCategoryPatchRequest(values, edited!);
    const originalEntity = mapFormValuesToCategoryPatchRequest(
      originalData as FormValues,
      edited!
    );
    const adjustedEntity = preparePatchEntity(category, originalEntity);
    const { payload } = await dispatch(editCategory(adjustedEntity));
    return payload as Category;
  };

  const formik = useFormik({
    initialValues: getInitFormValues(edited),
    validationSchema: getValidationSchema(t),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(edited);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [edited]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.Categories);

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(edited));
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={edited ? t("__edycja_kategorii") : t("__nowa_kategoria")}
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(edited)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_podstawowe")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <TextField
              id="polishName"
              name="polishName"
              fullWidth
              label={t("polska_nazwa")}
              size="small"
              value={formik.values.polishName}
              onChange={formik.handleChange}
              error={
                formik.touched.polishName && Boolean(formik.errors.polishName)
              }
              helperText={formik.touched.polishName && formik.errors.polishName}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="englishName"
              name="englishName"
              fullWidth
              label={t("angielska_nazwa")}
              size="small"
              value={formik.values.englishName}
              onChange={formik.handleChange}
              error={
                formik.touched.englishName && Boolean(formik.errors.englishName)
              }
              helperText={
                formik.touched.englishName && formik.errors.englishName
              }
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="germanName"
              name="germanName"
              fullWidth
              label={t("niemiecka_nazwa")}
              size="small"
              value={formik.values.germanName}
              onChange={formik.handleChange}
              error={
                formik.touched.germanName && Boolean(formik.errors.germanName)
              }
              helperText={formik.touched.germanName && formik.errors.germanName}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="swedenName"
              name="swedenName"
              fullWidth
              label={t("szwedzka_nazwa")}
              size="small"
              value={formik.values.swedenName}
              onChange={formik.handleChange}
              error={
                formik.touched.swedenName && Boolean(formik.errors.swedenName)
              }
              helperText={formik.touched.swedenName && formik.errors.swedenName}
            />
          </Box>
          <Box sx={styles.field}>
            <FormControl fullWidth>
              <InputLabel>{t("status")}</InputLabel>
              <Select
                id="active"
                name="active"
                size="small"
                value={formik.values.active?.toString()}
                label={t("status")}
                disabled={!edited}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  formik.setFieldValue("active", value);
                }}
              >
                <MenuItem value="true">{t("aktywny")}</MenuItem>
                <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="rank"
              name="rank"
              fullWidth
              label={t("__pozycja_na_liscie")}
              size="small"
              value={formik.values.rank}
              onChange={formik.handleChange}
              error={formik.touched.rank && Boolean(formik.errors.rank)}
              helperText={formik.touched.rank && formik.errors.rank}
            />
          </Box>
          <Box sx={styles.field}>
            <FormControlLabel
              control={
                <Checkbox
                  id="box"
                  name="box"
                  checked={formik.values.box}
                  onChange={formik.handleChange}
                  disabled={!!edited}
                />
              }
              label={t("__box")}
            />
          </Box>
          <Box sx={styles.field} />
        </Box>
      </Section>
      <ComponentsAvailability
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        fillingsInherited={formik.values.fillingsInherited}
        colorsInherited={formik.values.colorsInherited}
        brakesInherited={formik.values.brakesInherited}
        doorsInherited={formik.values.doorsInherited}
        clapsInherited={formik.values.clapsInherited}
        brakes={formik.values.brakes}
        colors={formik.values.colors}
        doors={formik.values.doors}
        claps={formik.values.claps}
        fillings={formik.values.fillings}
        infoText="__wszystkie_istniejace"
      />
    </form>
  );
};

export default withPageLoader(CategoryEdit);
