import { Theme } from "@mui/material";

export const getStyles = (theme: Theme, isMobile: boolean) => ({
  container: {
    display: "flex",
    flexDirection: "column" as const,
    gap: theme.spacing(2),
    // maxWidth: 700,
    margin: "0 auto",
  },
  fields: {
    display: "flex",
    justifyContent: "space-between",
    justifyItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  field: {
    flex: "1 1 0",
    minWidth: isMobile ? 200 : 250,
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },
});
