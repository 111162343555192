import React, { useEffect, useRef, useState } from "react";
import Section from "../../../../../components/section/section";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Store } from "../../../../../redux/store";
import { Variant } from "../../../../../types/variant-types";
import { prepareMetaData } from "./technical-projection.utils";
import {
  MetaData,
  TechnicalProjectionProps,
} from "./technical-projection.types";
import { getStyles } from "./technical-projection.styles";
import Box from "@mui/material/Box";
import { CANVAS_ID, WIDTH_PADDING } from "./technical-projection.constants";
import {
  drawBottomRailTechnicalProjection,
  drawStandardTechnicalProjection,
  drawTopRailTechnicalProjection,
} from "../../../../../services/technical-projection-drawer/technical-projection-drawer";
import { Cover } from "../../../../../types/cover-types";
import { RailPosition } from "../../../../../types/order-types";
import Typography from "@mui/material/Typography";

const TechnicalProjection = ({ isPageLoading }: TechnicalProjectionProps) => {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const styles = getStyles();

  const { variant, railExtension, doorType, railPosition, model } = useSelector(
    (state: Store) => state.order.currentOrder
  );

  const [resizeFactor, setResizeFactor] = useState(1);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  useEffect(() => {
    if (
      containerRef.current &&
      canvasRef.current &&
      variant &&
      model &&
      !isPageLoading
    ) {
      if (model.railCount === 1 && !railPosition) {
        return;
      }

      const ctx = canvasRef.current.getContext("2d");

      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        const metaData = prepareMetaData(
          variant,
          railExtension === "" ? 0 : Number(railExtension),
          model.railCount,
          railPosition
        );

        let rf = 1;
        const containerWidth = containerRef.current.offsetWidth;
        if (metaData.totalLength + WIDTH_PADDING > containerWidth) {
          rf = containerWidth / (metaData.totalLength + WIDTH_PADDING);
        }
        setResizeFactor(rf);

        setCanvasWidth(metaData.totalLength + WIDTH_PADDING);
        setCanvasHeight(metaData.totalHeight);
        const fontSize = Math.round(16 / rf);
        ctx.lineWidth = Math.round(1 / rf);
        ctx.font = `${fontSize}px roboto`;
        ctx.textAlign = "center";
        //@ts-ignore
        ctx.arrowSize = Math.round(10 / rf);
        //@ts-ignore
        ctx.innerArrowOffset = Math.round(20);
        //@ts-ignore
        ctx.textOffset = Math.round(10);
        drawTechnicalProjection(ctx, model, variant, metaData, railPosition);
      }
    }
  }, [
    variant?.id,
    railExtension,
    doorType,
    canvasHeight,
    canvasWidth,
    isPageLoading,
    railPosition,
    model,
  ]);

  const drawTechnicalProjection = (
    ctx: CanvasRenderingContext2D,
    model: Cover,
    variant: Variant,
    metaData: MetaData,
    railPosition: RailPosition | null
  ) => {
    if (model.railCount === 2) {
      drawStandardTechnicalProjection(ctx, variant, metaData, theme, doorType);
    }
    if (model.railCount === 1) {
      if (railPosition === RailPosition.LEFT) {
        drawTopRailTechnicalProjection(ctx, variant, metaData, theme, doorType);
      }
      if (railPosition === RailPosition.RIGHT) {
        drawBottomRailTechnicalProjection(
          ctx,
          variant,
          metaData,
          theme,
          doorType
        );
      }
    }
  };

  const renderProjection = () => {
    return (
      <Box
        ref={containerRef}
        sx={styles.container}
        height={canvasHeight * resizeFactor}
      >
        <canvas
          id={CANVAS_ID}
          ref={canvasRef}
          width={canvasWidth}
          height={canvasHeight}
        />
      </Box>
    );
  };

  const renderErrorMessage = () => {
    return <Typography>{t("__rzut_blad")}</Typography>;
  };

  return (
    <Section title={t("__rzut_zadaszenia")}>
      {Number(railExtension) > 1000 ? renderErrorMessage() : renderProjection()}
    </Section>
  );
};

export default TechnicalProjection;
