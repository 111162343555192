import {
  Brake,
  BrakePatchRequest,
  BrakePostRequest,
} from "../../../../types/brake-types";
import { FormValues } from "./brake-edit.types";
import { Language } from "../../../../types/language-types";
import { TFunction } from "react-i18next";
import * as yup from "yup";
import {
  TranslationPatchRequest,
  TranslationPostRequest,
} from "../../../../types/translation-types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");

  return yup.object({
    polishName: yup.string().required(requiredFieldError),
    englishName: yup.string().required(requiredFieldError),
    germanName: yup.string().required(requiredFieldError),
    swedenName: yup.string().required(requiredFieldError),
    active: yup.boolean(),
  });
};

export const mapFormValuesToBrakePostRequest = (
  values: FormValues
): BrakePostRequest => {
  const labelTranslations: TranslationPostRequest[] = [];

  labelTranslations.push({ langIso: "pl", value: values.polishName });
  labelTranslations.push({ langIso: "en", value: values.englishName });
  labelTranslations.push({ langIso: "de", value: values.germanName });
  labelTranslations.push({ langIso: "sv", value: values.swedenName });

  return { labelTranslations };
};

const findLabelId = (brake: Brake, lang: Language): number =>
  brake.labelTranslations.find((translation) => translation.langIso === lang)!
    .id;

export const mapFormValuesToBrakePatchRequest = (
  values: FormValues,
  brake: Brake
): BrakePatchRequest => {
  const labelTranslations: TranslationPatchRequest[] = [];

  labelTranslations.push(
    {
      id: findLabelId(brake, "pl"),
      langIso: "pl",
      value: values.polishName,
    },
    {
      id: findLabelId(brake, "en"),
      langIso: "en",
      value: values.englishName,
    },
    {
      id: findLabelId(brake, "de"),
      langIso: "de",
      value: values.germanName,
    },
    {
      id: findLabelId(brake, "sv"),
      langIso: "sv",
      value: values.swedenName,
    }
  );

  return {
    id: brake.id,
    active: values.active,
    labelTranslations,
  };
};

const findNameTranslation = (brake: Brake, lang: Language) =>
  brake.labelTranslations.find((translation) => translation.langIso === lang)!
    .value;

const mapBrakeToFormValues = (brake: Brake): FormValues => ({
  active: brake.active,
  polishName: findNameTranslation(brake, "pl"),
  germanName: findNameTranslation(brake, "de"),
  englishName: findNameTranslation(brake, "en"),
  swedenName: findNameTranslation(brake, "sv"),
});

const getDefaultFormValues = (): FormValues => ({
  active: true,
  englishName: "",
  germanName: "",
  polishName: "",
  swedenName: "",
});

export const getInitFormValues = (brake: Brake | null): FormValues =>
  brake ? mapBrakeToFormValues(brake) : getDefaultFormValues();
