import React, { ChangeEvent, useEffect, useState } from "react";
import ConfirmationDialog from "../../../../components/confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import Box from "@mui/material/Box";
import NumericInput from "../../../../components/numeric-input/numeric-input";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import {
  Direction,
  GlobalPriceChangeDialogProps,
} from "./global-price-change-dialog.types";
import { getStyles } from "./global-price-change-dialog.styles";
import { GlobalPriceUpdateRequest } from "../../../../types/price-types";
import { updatePricesGlobally } from "../../../../redux/actions/price-actions";

const GlobalPriceChangeDialog = ({
  open,
  onCancel,
}: GlobalPriceChangeDialogProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(theme, isMobile);

  const { allCovers } = useSelector((state: Store) => state.cover);
  const { loading } = useSelector((state: Store) => state.price);

  const [changeValue, setChangeValue] = useState("0");
  const [direction, setDirection] = useState<Direction>("increment");
  const [checkedModels, setCheckedModels] = useState<number[]>([]);

  useEffect(() => {
    if (allCovers) {
      setCheckedModels(allCovers.map((model) => model.id));
    }
  }, [allCovers]);

  const handleChangeDirection = (event: ChangeEvent<HTMLInputElement>) => {
    setDirection((event.target as HTMLInputElement).value as Direction);
  };

  const handleSelectAllClick = () => {
    if (checkedModels.length === 0 && allCovers) {
      setCheckedModels(allCovers.map((model) => model.id));
    } else {
      setCheckedModels([]);
    }
  };

  const handleOnModelClick = (id: number) => {
    if (checkedModels.includes(id)) {
      setCheckedModels((prev) => prev.filter((x) => x !== id));
    } else {
      setCheckedModels((prev) => [...prev, id]);
    }
  };

  const handleUpdateClick = async () => {
    const multiplier = direction === "increment" ? 1 : -1;
    const payload: GlobalPriceUpdateRequest = {
      modelIds: checkedModels,
      priceMultiplier: (Number(changeValue) / 100) * multiplier,
    };
    await dispatch(updatePricesGlobally(payload));
    onCancel();
  };

  return (
    <ConfirmationDialog
      open={open}
      title={t("__globalna_zmiana")}
      primaryText={t("__zmien")}
      onPrimary={handleUpdateClick}
      secondaryText={t("anuluj")}
      onSecondary={onCancel}
      loading={loading === "pending"}
      disablePrimary={Number(changeValue) <= 0}
      sx={styles.dialog}
    >
      <Box sx={styles.container}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <NumericInput
              size="small"
              variant="outlined"
              label={t("__procentowa_zmiana")}
              value={changeValue}
              onChange={(e) => setChangeValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              fullWidth
              required
            />
          </Box>
          <Box sx={styles.field}>
            <FormControl size="small">
              <RadioGroup
                value={direction}
                onChange={handleChangeDirection}
                row
              >
                <FormControlLabel
                  value="increment"
                  control={<Radio size="small" />}
                  label={t("__powieksz")}
                />
                <FormControlLabel
                  value="decrement"
                  control={<Radio size="small" />}
                  label={t("__zmniejsz")}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Divider />
        {allCovers && (
          <Box sx={styles.fields}>
            <Box sx={styles.field}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allCovers.length === checkedModels.length}
                    indeterminate={
                      checkedModels.length > 0 &&
                      allCovers.length > checkedModels.length
                    }
                    onChange={handleSelectAllClick}
                  />
                }
                label={t("__zaznacz_wszystkie")}
              />
            </Box>
            {allCovers.map((model) => (
              <Box sx={styles.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedModels.includes(model.id)}
                      onChange={() => {
                        handleOnModelClick(model.id);
                      }}
                    />
                  }
                  label={model.label}
                />
              </Box>
            ))}
            {(allCovers.length || 0 % 2 === 0) && <Box sx={styles.field} />}
          </Box>
        )}
      </Box>
    </ConfirmationDialog>
  );
};

export default GlobalPriceChangeDialog;
