import React from "react";
import Section from "../section/section";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { Brake } from "../../types/brake-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Color } from "../../types/color-types";
import { Filling } from "../../types/filling-types";
import { Door, DOORS } from "../../constants/door-constants";
import { Clap, CLAPS } from "../../constants/claps-constants";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getStyles } from "../../pages/partners-page/partner-edit/partner-edit.styles";
import { useSelector } from "react-redux";
import { Store } from "../../redux/store";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ComponentsAvailabilityProps {
  handleChange: any;
  setFieldValue: any;
  fillingsInherited: boolean;
  colorsInherited: boolean;
  brakesInherited: boolean;
  doorsInherited: boolean;
  clapsInherited: boolean;
  brakes: Brake[];
  colors: Color[];
  doors: Door[];
  claps: Clap[];
  fillings: Filling[];
  infoText: string;
  additionalFillingsInheritedHandler?: (value: boolean) => void;
  additionalFillingsHandler?: (fillings: Filling[]) => void;
}

const ComponentsAvailability = ({
  brakes,
  brakesInherited,
  claps,
  clapsInherited,
  colors,
  colorsInherited,
  doors,
  doorsInherited,
  fillings,
  fillingsInherited,
  handleChange,
  setFieldValue,
  infoText,
  additionalFillingsInheritedHandler,
  additionalFillingsHandler,
}: ComponentsAvailabilityProps) => {
  const allBrakes = useSelector((state: Store) =>
    state.brake.allBrakes?.filter((brake) => brake.active)
  );
  const allColors = useSelector((state: Store) =>
    state.color.allColors?.filter((color) => color.active)
  );
  const allFillings = useSelector((state: Store) =>
    state.filling.allFillings?.filter((filling) => filling.active)
  );
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);

  const getOptionLabel = <T extends { active: boolean; label: string }>(
    option: T
  ) =>
    option.active ? option.label : `${option.label} (${t("dezaktywowany")})`;

  return (
    <>
      <Section title={t("__dostepne_blokady")}>
        <Box sx={styles.fields}>
          <FormControlLabel
            control={
              <Checkbox
                id="brakesInherited"
                name="brakesInherited"
                checked={brakesInherited}
                onChange={handleChange}
              />
            }
            label={t(infoText)}
          />
          <Autocomplete<Brake, true>
            id="brakes"
            multiple
            fullWidth
            autoHighlight
            size="small"
            disabled={brakesInherited}
            options={allBrakes || []}
            value={brakesInherited || !brakes ? [] : brakes}
            onChange={(event, newValue) => {
              setFieldValue("brakes", newValue);
            }}
            disableCloseOnSelect
            noOptionsText={t("__brak_opcji")}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={Boolean(brakes.find((x) => x.id === option.id))}
                />
                <Typography>{getOptionLabel(option)}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("blokady")}
                placeholder={t("__wybierz_dostepne_blokady")}
                fullWidth
                size="small"
              />
            )}
          />
        </Box>
      </Section>
      <Section title={t("__dostepne_kolory")}>
        <Box sx={styles.fields}>
          <FormControlLabel
            control={
              <Checkbox
                id="colorsInherited"
                name="colorsInherited"
                checked={colorsInherited}
                onChange={handleChange}
              />
            }
            label={t(infoText)}
          />
          <Autocomplete<Color, true>
            id="colors"
            multiple
            fullWidth
            autoHighlight
            size="small"
            disabled={colorsInherited}
            options={allColors || []}
            value={colorsInherited || !colors ? [] : colors}
            onChange={(event, newValue) => {
              setFieldValue("colors", newValue);
            }}
            disableCloseOnSelect
            noOptionsText={t("__brak_opcji")}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={Boolean(colors.find((x) => x.id === option.id))}
                />
                <Typography>{getOptionLabel(option)}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("kolory")}
                placeholder={t("__wybierz_dostepne_kolory")}
                fullWidth
                size="small"
              />
            )}
          />
        </Box>
      </Section>
      <Section title={t("__dostepne_wypelnienia")}>
        <Box sx={styles.fields}>
          <FormControlLabel
            control={
              <Checkbox
                id="fillingsInherited"
                name="fillingsInherited"
                checked={fillingsInherited}
                onChange={(e) => {
                  setFieldValue("fillingsInherited", e.target.checked);
                  additionalFillingsInheritedHandler?.(e.target.checked);
                }}
              />
            }
            label={t(infoText)}
          />
          <Autocomplete<Filling, true>
            id="fillings"
            multiple
            fullWidth
            autoHighlight
            size="small"
            disabled={fillingsInherited}
            options={allFillings || []}
            value={fillingsInherited || !fillings ? [] : fillings}
            onChange={(event, newValue) => {
              setFieldValue("fillings", newValue);
              additionalFillingsHandler?.(newValue);
            }}
            disableCloseOnSelect
            noOptionsText={t("__brak_opcji")}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={Boolean(fillings.find((x) => x.id === option.id))}
                />
                <Typography>{getOptionLabel(option)}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("wypelnienia")}
                placeholder={t("__wybierz_dostepne_wypelnienia")}
                fullWidth
                size="small"
              />
            )}
          />
        </Box>
      </Section>
      <Section title={t("__dostepne_drzwi")}>
        <Box sx={styles.fields}>
          <FormControlLabel
            control={
              <Checkbox
                id="doorsInherited"
                name="doorsInherited"
                checked={doorsInherited}
                onChange={handleChange}
              />
            }
            label={t(infoText)}
          />
          <Autocomplete<Door, true>
            id="doors"
            multiple
            fullWidth
            autoHighlight
            size="small"
            disabled={doorsInherited}
            options={DOORS}
            value={doorsInherited || !doors ? [] : doors}
            onChange={(event, newValue) => {
              setFieldValue("doors", newValue);
            }}
            disableCloseOnSelect
            noOptionsText={t("__brak_opcji")}
            getOptionLabel={(option) => t(option.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={Boolean(doors.find((x) => x.id === option.id))}
                />
                <Typography>{t(option.id)}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("__drzwi")}
                placeholder={t("__wybierz_dostepne_drzwi")}
                fullWidth
                size="small"
              />
            )}
          />
        </Box>
      </Section>
      <Section title={t("__dostepne_klapy")}>
        <Box sx={styles.fields}>
          <FormControlLabel
            control={
              <Checkbox
                id="clapsInherited"
                name="clapsInherited"
                checked={clapsInherited}
                onChange={handleChange}
              />
            }
            label={t(infoText)}
          />
          <Autocomplete<Clap, true>
            id="claps"
            multiple
            fullWidth
            autoHighlight
            size="small"
            disabled={clapsInherited}
            options={CLAPS}
            value={clapsInherited || !claps ? [] : claps}
            onChange={(event, newValue) => {
              setFieldValue("claps", newValue);
            }}
            disableCloseOnSelect
            noOptionsText={t("__brak_opcji")}
            getOptionLabel={(option) => t(option.id)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={Boolean(claps.find((x) => x.id === option.id))}
                />
                <Typography>{t(option.id)}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("__klapy")}
                placeholder={t("__wybierz_dostepne_klapy")}
                fullWidth
                size="small"
              />
            )}
          />
        </Box>
      </Section>
    </>
  );
};

export default ComponentsAvailability;
