import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getStyles } from "./login-form.styles";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFormik } from "formik";
import { getValidationSchema } from "../login-page.utils";
import { LoginFormProps } from "./login-form.types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Store } from "../../../redux/store";

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const { loading } = useSelector((state: Store) => state.auth);
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const styles = getStyles(theme, isTablet);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: getValidationSchema(t),
    onSubmit,
  });

  return (
    <>
      <Typography sx={styles.prompt}>{t("login_zacheta")}</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={styles.fields}>
          <TextField
            variant="outlined"
            id="email"
            name="email"
            label={t("mail")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            id="password"
            name="password"
            label={t("haslo")}
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <LoadingButton
            sx={styles.button}
            endIcon={<ArrowForwardIcon />}
            loading={loading === "pending"}
            loadingPosition="end"
            variant="contained"
            type="submit"
          >
            {t("login")}
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default LoginForm;
