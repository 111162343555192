import React from "react";
import Box from "@mui/material/Box";
import NumericInput from "../../../../../../components/numeric-input/numeric-input";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "../billing-info.styles";
import {
  formatDiscount,
  formatValue,
  getCommonProps,
  getInputProps,
} from "../billing-info.utils";
import { useSelector } from "react-redux";
import { Store } from "../../../../../../redux/store";
import useDisableAll from "../../../../../../hooks/useDisableAll";
import { useTranslation } from "react-i18next";

const BucoversClientPrices = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isFull = useMediaQuery("(max-width: 1440px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();
  const { t } = useTranslation();

  const { response: calculation } = useSelector(
    (state: Store) => state.order.calculation
  );
  const { currentOrder } = useSelector((state: Store) => state.order);
  const { clientDelivery, partner } = currentOrder;

  const COMMON_PROPS = getCommonProps(
    !!calculation || !!currentOrder.payment,
    disableAll,
    clientDelivery
  );

  return (
    <Box sx={styles.fields}>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__cena_przed_rabatem")}
          value={formatValue(
            calculation
              ? calculation.retailPrice
              : currentOrder.payment?.retailPrice
          )}
          InputProps={{ ...getInputProps("€"), readOnly: true }}
          disabled
          isMoney
        />
      </Box>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__przyznany_rabat")}
          value={formatDiscount(
            currentOrder.payment?.partnerDiscount ?? partner?.discount
          )}
          InputProps={{ ...getInputProps("%"), readOnly: true }}
          disabled
        />
      </Box>
      <Box sx={styles.field}>
        <NumericInput
          {...COMMON_PROPS}
          label={t("__cena_po_rabacie")}
          value={formatValue(
            calculation
              ? calculation.partnerPrice
              : currentOrder.payment?.partnerPrice
          )}
          InputProps={{ ...getInputProps("€"), readOnly: true }}
          disabled
          isMoney
        />
      </Box>
      {isFull && <Box sx={styles.field} />}
    </Box>
  );
};

export default BucoversClientPrices;
