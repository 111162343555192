import { Loading, Pageable } from "../../types/common-types";
import { Category } from "../../types/category-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { updateStateWithPageable } from "../redux-utils";
import {
  addCategory,
  editCategory,
  getAllCategories,
  getCategories,
} from "../actions/category-actions";

export interface CategoryState extends Pageable<Category> {
  loading: Loading;
  allCategories: Category[] | null;
}

export const DEFAULT_CATEGORY_FILTERS = {
  active: true,
};

export const DEFAULT_CATEGORY_SORT = ["rank", "label", "id"];

const initialState: CategoryState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_CATEGORY_SORT,
  filters: DEFAULT_CATEGORY_FILTERS,
  allCategories: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addCategory.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addCategory.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addCategory.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editCategory.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editCategory.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editCategory.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getAllCategories.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allCategories = action.payload;
    });
    builder.addCase(getAllCategories.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default categorySlice.reducer;
