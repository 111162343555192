import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  whoAmI,
  login,
  logout,
  getRoles,
  changePassword,
  acceptPolicy,
} from "../actions/auth-actions";
import { Role, Auth } from "../../types/auth-types";
import { Loading } from "../../types/common-types";

export interface AuthState {
  user?: Auth;
  loading: Loading;
  didAutoLogin: boolean;
  roles: Role[];
  sessionTimerId: NodeJS.Timeout | null;
}

const initialState: AuthState = {
  loading: "idle",
  user: undefined,
  didAutoLogin: false,
  roles: [],
  sessionTimerId: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSessionTimer(
      state,
      action: PayloadAction<{ sessionTimerId: NodeJS.Timeout }>
    ) {
      state.sessionTimerId = action.payload.sessionTimerId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(whoAmI.fulfilled, (state, action) => {
      state.user = action.payload;
      state.didAutoLogin = true;
      state.loading = "succeeded";
    });
    builder.addCase(whoAmI.rejected, (state) => {
      state.didAutoLogin = true;
      state.loading = "failed";
    });
    builder.addCase(login.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(logout.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
    builder.addCase(changePassword.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = "succeeded";
      state.user!.tempPassword = false;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(acceptPolicy.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(acceptPolicy.fulfilled, (state) => {
      state.loading = "succeeded";
      state.user!.termsAgreement = true;
    });
    builder.addCase(acceptPolicy.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default authSlice.reducer;
export const { setSessionTimer } = authSlice.actions;
