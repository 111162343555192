import { Theme } from "@mui/material";
import { DEFAULT_TABLE_WIDTH } from "../../../theme/sizes";

export const getStyles = (theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    maxWidth: DEFAULT_TABLE_WIDTH,
    margin: "0 auto",
  },
  basicTable: {
    minWidth: 500,
  },
});
