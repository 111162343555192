import React from "react";
import { Box, Theme, useTheme } from "@mui/material";
import { Colors } from "../../theme/colors";
import logo from "../../assets/images/png/logo_black.png";
import { keyframes } from "@emotion/react";
import { SxProps } from "@mui/system";
import { prepareSx } from "../../utils/common-utils";

interface LoaderProps {
  sx?: SxProps<Theme>;
  size?: string;
}

const DEFAULT_PARAMS: LoaderProps = {
  size: "150px",
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const getStyles = (theme: Theme, params: LoaderProps) =>
  ({
    container: {
      position: "relative",
      display: "grid",
      placeItems: "center",
      width: params.size,
      height: params.size,
      borderRadius: "50%",
      backgroundColor: Colors.White,
      boxShadow: theme.shadows[2],
      "&::after": {
        content: '""',
        position: "absolute",
        width: params.size,
        height: params.size,
        borderRadius: "50%",
        border: `2px solid ${Colors.White}`,
        borderTop: `2px solid ${Colors.Black}`,
        backgroundColor: Colors.Transparent,
        animation: `${spin} 1s linear infinite`,
      },
    },
  } as const);

const Loader = ({ sx, ...params }: LoaderProps) => {
  const theme = useTheme();
  const combinedParams = { ...DEFAULT_PARAMS, ...params };
  const styles = getStyles(theme, combinedParams);

  return (
    <Box sx={[styles.container, ...prepareSx(sx)]}>
      <img src={logo} width="75%" alt="logo" />
    </Box>
  );
};

export default Loader;
