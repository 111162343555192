import React, { MouseEvent, ReactNode, useRef } from "react";
import Box from "@mui/material/Box";
import { Theme, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ScrollableContainerProps {
  children: ReactNode;
}

interface ScrollPosition {
  top: number;
  left: number;
  x: number;
  y: number;
}

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
  top: 0,
  left: 0,
  x: 0,
  y: 0,
};

export const getStyles = (theme: Theme, isMobile: boolean) => ({
  models: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    gap: theme.spacing(2),
    overflow: "auto",
    padding: theme.spacing(2),
  },
});

const ScrollableContainer = ({ children }: ScrollableContainerProps) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef<ScrollPosition>(DEFAULT_SCROLL_POSITION);
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 650px)");
  const styles = getStyles(theme, isMobile);

  const mouseMoveHandler = (e: MouseEvent) => {
    if (scrollableRef.current) {
      const dx = e.clientX - scrollPosition.current.x;
      const dy = e.clientY - scrollPosition.current.y;
      scrollableRef.current.scrollTop = scrollPosition.current.top - dy;
      scrollableRef.current.scrollLeft = scrollPosition.current.left - dx;
      scrollableRef.current.style.pointerEvents = "none";
    }
  };

  const mouseUpHandler = () => {
    if (scrollableRef.current) {
      // @ts-ignore
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      scrollableRef.current.style.removeProperty("user-select");
      scrollableRef.current.style.pointerEvents = "auto";
    }
  };

  const mouseDownHandler = (e: MouseEvent) => {
    if (scrollableRef.current) {
      scrollPosition.current = {
        left: scrollableRef.current.scrollLeft,
        top: scrollableRef.current.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };
      // @ts-ignore
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      scrollableRef.current.style.userSelect = "none";
    }
  };

  return (
    <Box ref={scrollableRef} onMouseDown={mouseDownHandler} sx={styles.models}>
      {children}
    </Box>
  );
};

export default ScrollableContainer;
