import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getStyles } from "./render.styles";
import { useTranslation } from "react-i18next";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import DeleteIcon from "@mui/icons-material/Delete";

interface RenderProps {
  id: string;
  text: string;
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  helperText?: string | false;
}

const Render = ({
  id,
  text,
  error,
  helperText,
  onChange,
  value,
}: RenderProps) => {
  const theme = useTheme();
  const styles = getStyles(theme, error);
  const { t } = useTranslation();

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploaded = event.target.files?.item(0);
    if (uploaded) {
      const reader = new FileReader();
      event.target.value = "";
      reader.onload = () => {
        // @ts-ignore
        onChange(reader.result);
      };
      reader.readAsDataURL(uploaded);
    }
  };

  const isBase64 = value.includes("base64");

  const handleRemove = () => onChange("");

  return (
    <Box sx={styles.container}>
      <input
        id={id}
        type="file"
        name="file"
        onChange={onInputChange}
        accept="image/*"
        style={styles.input}
      />
      <label htmlFor={id} style={styles.label}>
        <Card sx={styles.card} elevation={3}>
          <Box sx={styles.overlayContainer}>
            {value ? (
              <CardMedia
                id="preview"
                component="img"
                sx={styles.preview}
                image={
                  isBase64
                    ? value
                    : `${process.env.REACT_APP_API_PREFIX}/storage/photo/${value}`
                }
              />
            ) : (
              <CardMedia
                id="placeholder"
                component="div"
                sx={styles.placeholder}
              >
                <Box id="placeholderContent" sx={styles.placeholderContent}>
                  <PhotoSizeSelectActualIcon />
                  <Typography sx={styles.placeholderText}>
                    {t("__kliknij_zdjecie")}
                  </Typography>
                </Box>
              </CardMedia>
            )}
            <Box sx={styles.overlay} />
          </Box>
          <CardContent>
            <Typography sx={styles.text}>{text}</Typography>
          </CardContent>
        </Card>
      </label>
      {value && (
        <Box sx={styles.remove} onClick={handleRemove}>
          <DeleteIcon />
        </Box>
      )}
      <Typography sx={styles.errorText}>{helperText}</Typography>
    </Box>
  );
};

export default Render;
