import React from "react";
import Divider from "@mui/material/Divider";
import { Theme, useTheme } from "@mui/material";

const getStyles = (theme: Theme, height?: number) => ({
  divider: {
    height: theme.spacing(height ?? 6),
    color: theme.palette.grey["500"],
  },
});

interface ToolbarDividerProps {
  height?: number;
}

const HorizontalDivider = ({ height }: ToolbarDividerProps) => {
  const theme = useTheme();
  const styles = getStyles(theme, height);

  return <Divider sx={styles.divider} orientation="vertical" />;
};

export default HorizontalDivider;
