import { createAsyncThunk } from "@reduxjs/toolkit";
import { flatPageParams, preparePageForAction } from "../redux-utils";
import { PageParams, PageResponse } from "../../types/common-types";
import axios from "axios";
import { Notification } from "../../types/notification-types";
import { Store } from "../store";

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async ({ params }: PageParams) => {
    const flatParams = flatPageParams(params);
    const { data } = await axios.get<PageResponse<Notification>>(
      "/notification",
      {
        params: flatParams,
      }
    );
    return preparePageForAction<Notification>(data, params);
  }
);

export const getUnseenIds = createAsyncThunk(
  "notification/getUnseenIds",
  async () => {
    const { data } = await axios.get<number[]>("/notification/unseen");
    return data;
  }
);

export const markNotificationsAsSeen = createAsyncThunk(
  "notification/markNotificationsAsSeen",
  async (params, thunkAPI) => {
    const { notification } = thunkAPI.getState() as Store;
    const payload = notification.unseenIds.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: { viewed: true },
      }),
      {}
    );
    await axios.patch("/notification", payload);
  }
);

export const markNotificationAsClicked = createAsyncThunk(
  "notification/markNotificationAsClicked",
  async (id: number) => {
    const { data } = await axios.patch<Notification[]>("/notification", {
      [id]: { clicked: true },
    });
    return data;
  }
);
