import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import useFetchEntity, {
  FetchEntityArgs,
} from "../../../../hooks/useFetchEntity";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getStyles } from "../../../partners-page/partner-edit/partner-edit.styles";
import { Profile } from "../../../../types/profile-types";
import { Route } from "../../../../constants/navigation-constants";
import {
  addProfile,
  editProfile,
  getProfile,
} from "../../../../redux/actions/profile-actions";
import { FormValues } from "./profile-edit.types";
import {
  getInitFormValues,
  getValidationSchema,
  mapFormValuesToProfilePatchRequest,
  mapFormValuesToProfilePostRequest,
} from "./profile-edit.utils";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Section from "../../../../components/section/section";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../../redux/slices/changes-slice";
import SectionTitle from "../../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../../redux/redux-utils";
import { getInputAdornment } from "../../../../utils/common-utils";
import NumericInput from "../../../../components/numeric-input/numeric-input";

interface ProfileEditProps extends WithPageLoaderProps {}

const ProfileEdit = ({ finishLoading }: ProfileEditProps) => {
  const { id } = useParams<{ id?: string }>();
  const [edited, setEdited] = useState<Profile | null>(null);
  const { content: profiles, loading } = useSelector(
    (state: Store) => state.profile
  );
  const { originalData } = useSelector((state: Store) => state.changes);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<Profile> = {
      callback: setEdited,
      collection: profiles,
      fallbackRoute: Route.Profiles,
      fetcherAction: getProfile,
      id,
    };
    await Promise.all([fetchEntity<Profile>(fetchArgs)]);
    finishLoading();
  };

  const onSubmit = async (values: FormValues) => {
    const saveFn = edited ? submitEditProfile : submitAddProfile;
    const savedProfile = await saveFn(values);
    if (savedProfile) {
      setEdited(savedProfile);
    }
  };

  const submitAddProfile = async (values: FormValues) => {
    const profile = mapFormValuesToProfilePostRequest(values);
    const { payload } = await dispatch(addProfile(profile));
    return payload as Profile;
  };

  const submitEditProfile = async (values: FormValues) => {
    const profile = mapFormValuesToProfilePatchRequest(values, edited!);
    const originalEntity = mapFormValuesToProfilePatchRequest(
      originalData as FormValues,
      edited!
    );
    const adjustedEntity = preparePatchEntity(profile, originalEntity);
    const { payload } = await dispatch(editProfile(adjustedEntity));
    return payload as Profile;
  };

  const formik = useFormik({
    initialValues: getInitFormValues(edited),
    validationSchema: getValidationSchema(t),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(edited);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [edited]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.Profiles);

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(edited));
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={edited ? t("__edycja_profilu") : t("__nowy_profil")}
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(edited)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_podstawowe")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <TextField
              id="name"
              name="name"
              fullWidth
              label={t("nazwa")}
              size="small"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              id="price"
              name="price"
              fullWidth
              label={t("__cena__przedluzenia_w_profilu")}
              size="small"
              variant="outlined"
              value={formik.values.price}
              onChange={formik.handleChange}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              InputProps={getInputAdornment("€")}
              isMoney
            />
          </Box>
          <Box sx={styles.field}>
            <FormControl fullWidth>
              <InputLabel>{t("status")}</InputLabel>
              <Select
                id="active"
                name="active"
                size="small"
                value={formik.values.active?.toString()}
                label={t("status")}
                disabled={!edited}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  formik.setFieldValue("active", value);
                }}
              >
                <MenuItem value="true">{t("aktywny")}</MenuItem>
                <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.field} />
        </Box>
      </Section>
    </form>
  );
};

export default withPageLoader(ProfileEdit);
