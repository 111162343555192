import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Country } from "../../types/country-types";
import { Store } from "../store";

export const getCountries = createAsyncThunk(
  "country/getCountries",
  async (_, thunkAPI) => {
    const { country } = thunkAPI.getState() as Store;

    if (country.countries !== null) {
      return null;
    }

    const { data } = await axios.get<Country[]>("/country");
    return data;
  }
);
