import React, { ChangeEvent, useState } from "react";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Pageable, PageChanges, WithId } from "../../types/common-types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../redux/store";
import useDebouncedState from "../../hooks/useDebouncedState";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { applyChangesToPage } from "../../redux/redux-utils";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { getStyles } from "./pagination-table.styles";
import { PaginationTableProps } from "./table-types";
import TableRow from "./table-row/table-row";
import TableHeader from "./table-row/table-header";
import Loader from "../loader/loader";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";

const PaginationTable = <T extends WithId>({
  loading,
  renderRow,
  reducerName,
  fetcherAction,
  columns,
  withPagination,
  data,
  paginationBackground,
}: PaginationTableProps<T>) => {
  const reducer: Pageable<T> = useSelector(
    // @ts-ignore
    (state: Store) => state[reducerName]
  );
  const { size, content: paginationData, totalPages, page } = reducer || {};

  const content = withPagination ? paginationData : data;

  const [localSize, setLocalSize] = useState(size);
  const [localPage, debouncedLocalPage, setLocalPage] =
    useDebouncedState<number>(page, 250);

  const isLoading = loading === "pending";
  const isInitFinished = content !== null;

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(
    theme,
    isLoading,
    withPagination,
    paginationBackground
  );

  const fetchPage = (changes?: PageChanges) => {
    const params = applyChangesToPage(reducer, changes);
    fetcherAction && dispatch(fetcherAction({ params }));
  };

  useUpdateEffect(() => {
    fetchPage({ page: debouncedLocalPage });
  }, [debouncedLocalPage]);

  useUpdateEffect(() => {
    fetchPage({ page: 0, size: localSize });
  }, [localSize]);

  useUpdateEffect(() => {
    setLocalPage(page);
  }, [page]);

  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    setLocalPage(page - 1);
  };

  const handleSizeChange = (event: SelectChangeEvent) => {
    const newSize = Number(event.target.value);
    setLocalSize(newSize);
  };

  const getRows = () => {
    if (renderRow) {
      return content!.map(renderRow);
    }

    if (columns) {
      return content!.map((row, index) => (
        <TableRow<T>
          key={row.id}
          columns={columns}
          row={row}
          isLastOne={index === content!.length - 1}
        />
      ));
    }
  };

  const renderRows = () => (
    <>
      {columns && <TableHeader columns={columns} />}
      {getRows()}
    </>
  );

  const noDataMessage = (
    <Box sx={styles.noDataMessageWrapper}>
      <Typography sx={styles.noDataMessage}>{t("empty_table")}</Typography>
    </Box>
  );

  const getTableContent = () =>
    content!.length > 0 ? renderRows() : noDataMessage;

  const loader = isLoading ? (
    <Box sx={styles.loader}>
      <Loader />
    </Box>
  ) : null;

  const PaginationElement = paginationBackground ? Card : Box;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>
        {isInitFinished && getTableContent()}
        {loader}
      </Box>
      {withPagination && (
        // @ts-ignore
        <PaginationElement sx={styles.bottom}>
          <FormControl sx={{ width: 120 }}>
            <InputLabel>{t("liczba_wierszy")}</InputLabel>
            <Select
              value={localSize.toString()}
              label={t("liczba_wierszy")}
              onChange={handleSizeChange}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <Pagination
            count={totalPages}
            page={localPage + 1}
            onChange={handlePageChange}
            color="primary"
          />
        </PaginationElement>
      )}
    </Box>
  );
};

export default PaginationTable;
