import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { setupAxiosConfig } from "./axios-config";
import ErrorBoundary from "./components/error-boundary/error-boundary";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";

setupAxiosConfig(store);

const rootEl = document.getElementById("root") as HTMLElement;

rootEl.setAttribute("version", process.env.REACT_APP_VERSION || "");

const root = ReactDOM.createRoot(rootEl);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);
