import React from "react";
import ConfirmationDialog from "../confirmation-dialog/confirmation-dialog";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Store } from "../../redux/store";

interface UnsavedChangesModalProps {
  open: boolean;
  onCancelClick: () => void;
  leaveCallback: () => void;
}

const UnsavedChangesModal = ({
  open,
  onCancelClick,
  leaveCallback,
}: UnsavedChangesModalProps) => {
  const areUnsavedChanges = useSelector(
    (state: Store) => state.changes.areUnsavedChanges
  );
  const { t } = useTranslation();

  if (open && !areUnsavedChanges) {
    leaveCallback();
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      title={t("potwierdz_operacje")}
      primaryText={t("anuluj")}
      onPrimary={onCancelClick}
      secondaryText={t("__tak")}
      onSecondary={leaveCallback}
    >
      <Typography>{t("__porzucone_zmiany_pytanie")}</Typography>
    </ConfirmationDialog>
  );
};

export default UnsavedChangesModal;
