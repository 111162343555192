import { Pageable } from "../../types/common-types";
import { Notification, Event } from "../../types/notification-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getNotifications,
  getUnseenIds,
  markNotificationAsClicked,
  markNotificationsAsSeen,
} from "../actions/notification-actions";
import { updateStateWithPageable } from "../redux-utils";

export interface NotificationState extends Pageable<Notification> {
  notifications: Notification[];
  unseenIds: number[];
  areNewOrders: boolean;
  areChangedOrders: boolean;
}

export const DEFAULT_NOTIFICATION_FILTERS = {};

export const DEFAULT_NOTIFICATION_SORT = ["createdDate,desc"];

const initialState: NotificationState = {
  ...DEFAULT_PAGEABLE,
  sort: DEFAULT_NOTIFICATION_SORT,
  filters: DEFAULT_NOTIFICATION_FILTERS,
  size: 5,
  notifications: [],
  unseenIds: [],
  areNewOrders: false,
  areChangedOrders: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotificationFromWebsocket(state, action: PayloadAction<Notification>) {
      state.notifications.unshift(action.payload);
      state.unseenIds.push(action.payload.id);
    },
    handleEvent(state, action: PayloadAction<Event>) {
      if (action.payload.name === "OFFER_CREATION") {
        state.areNewOrders = true;
      }
      if (action.payload.name === "OFFER_UPDATE") {
        state.areChangedOrders = true;
      }
    },
    resetEvents(state) {
      state.areNewOrders = false;
      state.areChangedOrders = false;
    },
    resetAreNewOrders(state) {
      state.areNewOrders = false;
    },
    resetAreChangedOrders(state) {
      state.areChangedOrders = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      updateStateWithPageable(state, action.payload);
      if (action.payload.content) {
        state.notifications.push(...action.payload.content);
      }
    });
    builder.addCase(getUnseenIds.fulfilled, (state, action) => {
      state.unseenIds = action.payload;
    });
    builder.addCase(markNotificationsAsSeen.fulfilled, (state) => {
      state.unseenIds = [];
    });
    builder.addCase(markNotificationAsClicked.fulfilled, (state, action) => {
      const index = state.notifications.findIndex(
        (x) => x.id === action.payload[0].id
      );
      if (state.notifications && index !== undefined && index > -1) {
        state.notifications[index].clicked = true;
      }
    });
  },
});

export default notificationSlice.reducer;
export const {
  addNotificationFromWebsocket,
  handleEvent,
  resetEvents,
  resetAreChangedOrders,
  resetAreNewOrders,
} = notificationSlice.actions;
