import React, { ReactNode } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Typography } from "@mui/material";

export interface Action {
  id: string;
  icon: ReactNode;
  text: string;
  onClick: (id?: number) => void;
}

interface MenuActionsProps {
  actions: Action[];
  entityId?: number;
}

const MenuActions = ({ actions, entityId }: MenuActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (callback: (entityId?: number) => void) => {
    handleClose();
    callback(entityId);
  };

  return (
    <Box>
      <IconButton color="primary" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disableScrollLock
      >
        {actions.map((action) => (
          <MenuItem key={action.id} onClick={() => onClick(action.onClick)}>
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText>
              <Typography>{action.text}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default MenuActions;
