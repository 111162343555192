import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Cover } from "../../../types/cover-types";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography, useTheme } from "@mui/material";
import { Route } from "../../../constants/navigation-constants";
import Box from "@mui/material/Box";
import Section from "../../../components/section/section";
import ComponentsAvailability from "../../../components/components-availability/components-availability";
import useFetchEntity, { FetchEntityArgs } from "../../../hooks/useFetchEntity";
import { getAllBrakes } from "../../../redux/actions/brake-actions";
import { getAllColors } from "../../../redux/actions/color-actions";
import { getAllFillings } from "../../../redux/actions/filling-actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useFormik } from "formik";
import {
  getInitFormValues,
  getValidationSchema,
  mapFormValuesToCoverPatchRequest,
  mapFormValuesToCoverPostRequest,
} from "./cover-edit.utils";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { getAllCategories } from "../../../redux/actions/category-actions";
import { getAllProfiles } from "../../../redux/actions/profile-actions";
import Autocomplete from "@mui/material/Autocomplete";
import { FormValues } from "./cover-edit.types";
import {
  addCover,
  editCover,
  getCover,
} from "../../../redux/actions/cover-actions";
import Renders from "../../../components/renders/renders";
import { getVariants } from "../../../redux/actions/variant-actions";
import VariantsList from "../variants-list/variants-list";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../redux/slices/changes-slice";
import SectionTitle from "../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../redux/redux-utils";
import { getInputAdornment } from "../../../utils/common-utils";
import NumericInput from "../../../components/numeric-input/numeric-input";
import { getStyles } from "./cover-edit.styles";
import FormHelperText from "@mui/material/FormHelperText";

interface CoverEditProps extends WithPageLoaderProps {}

const CoverEdit = ({ finishLoading }: CoverEditProps) => {
  const { id } = useParams<{ id?: string }>();
  const [edited, setEdited] = useState<Cover | null>(null);
  const { content: covers, loading } = useSelector(
    (state: Store) => state.cover
  );
  const { size, sort, filters } = useSelector((state: Store) => state.variant);
  const { allCategories } = useSelector((state: Store) => state.category);
  const { allProfiles } = useSelector((state: Store) => state.profile);
  const { originalData } = useSelector((state: Store) => state.changes);
  const [didFetchVariants, setDidFetchVariants] = useState(false);
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    fetchVariantsForCover();
  }, [edited]);

  const fetchVariantsForCover = async () => {
    if (edited && !didFetchVariants) {
      const adjustedFilters = { ...filters, modelId: edited.id };
      await dispatch(
        getVariants({
          params: { size, page: 0, sort, filters: adjustedFilters },
          reload: true,
        })
      );
      setDidFetchVariants(true);
      finishLoading();
    }
  };

  const init = async () => {
    const fetchArgs: FetchEntityArgs<Cover> = {
      callback: setEdited,
      collection: covers,
      fallbackRoute: Route.Covers,
      fetcherAction: getCover,
      id,
    };

    const promises = [
      fetchEntity<Cover>(fetchArgs),
      dispatch(getAllBrakes({})),
      dispatch(getAllColors({})),
      dispatch(getAllFillings({})),
      dispatch(getAllCategories({})),
      dispatch(getAllProfiles({})),
    ];

    await Promise.all(promises);

    if (!edited) {
      finishLoading();
    }
  };

  const onSubmit = async (values: FormValues) => {
    const saveFn = edited ? submitEdit : submitAdd;
    const saved = await saveFn(values);
    if (saved) {
      setEdited(saved);
    }
  };

  const submitAdd = async (values: FormValues) => {
    const cover = mapFormValuesToCoverPostRequest(values);
    const { payload } = await dispatch(addCover(cover));
    return payload as Cover;
  };

  const submitEdit = async (values: FormValues) => {
    const cover = mapFormValuesToCoverPatchRequest(values, edited!);
    const originalEntity = mapFormValuesToCoverPatchRequest(
      originalData as FormValues,
      edited!
    );
    const adjustedEntity = preparePatchEntity(cover, originalEntity);
    const { payload } = await dispatch(editCover(adjustedEntity));
    return payload as Cover;
  };

  const formik = useFormik({
    initialValues: getInitFormValues(edited),
    validationSchema: getValidationSchema(t),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(edited);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [edited]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.Covers);

  const getCategoryById = (id: string) =>
    allCategories?.find((category) => category.id.toString() === id) || null;

  const getProfileById = (id: string) =>
    allProfiles?.find((profile) => profile.id.toString() === id) || null;

  const wrapperStyles = {
    ".section-container": {
      minWidth: 800,
    },
  };

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(edited));
  };

  return (
    <Box sx={wrapperStyles}>
      <form style={styles.container}>
        <SectionTitle
          title={edited ? t("__edycja_zadaszenia") : t("__nowe_zadaszenie")}
          onBackClick={onBackClick}
          isLoading={isLoading}
          isEditing={Boolean(edited)}
          onRollback={onRollbackClick}
          onSubmit={formik.handleSubmit}
        />
        <Section title={t("dane_podstawowe")}>
          <Box sx={styles.fields}>
            <Box sx={styles.field}>
              <TextField
                id="polishName"
                name="polishName"
                fullWidth
                label={t("polska_nazwa")}
                size="small"
                value={formik.values.polishName}
                onChange={formik.handleChange}
                error={
                  formik.touched.polishName && Boolean(formik.errors.polishName)
                }
                helperText={
                  formik.touched.polishName && formik.errors.polishName
                }
              />
            </Box>
            <Box sx={styles.field}>
              <TextField
                id="englishName"
                name="englishName"
                fullWidth
                label={t("angielska_nazwa")}
                size="small"
                value={formik.values.englishName}
                onChange={formik.handleChange}
                error={
                  formik.touched.englishName &&
                  Boolean(formik.errors.englishName)
                }
                helperText={
                  formik.touched.englishName && formik.errors.englishName
                }
              />
            </Box>
            <Box sx={styles.field}>
              <TextField
                id="germanName"
                name="germanName"
                fullWidth
                label={t("niemiecka_nazwa")}
                size="small"
                value={formik.values.germanName}
                onChange={formik.handleChange}
                error={
                  formik.touched.germanName && Boolean(formik.errors.germanName)
                }
                helperText={
                  formik.touched.germanName && formik.errors.germanName
                }
              />
            </Box>
            <Box sx={styles.field}>
              <TextField
                id="swedenName"
                name="swedenName"
                fullWidth
                label={t("szwedzka_nazwa")}
                size="small"
                value={formik.values.swedenName}
                onChange={formik.handleChange}
                error={
                  formik.touched.swedenName && Boolean(formik.errors.swedenName)
                }
                helperText={
                  formik.touched.swedenName && formik.errors.swedenName
                }
              />
            </Box>
            <Box sx={styles.field}>
              <FormControl fullWidth>
                <InputLabel>{t("status")}</InputLabel>
                <Select
                  id="active"
                  name="active"
                  size="small"
                  value={formik.values.active?.toString()}
                  label={t("status")}
                  disabled={!edited}
                  onChange={(event) => {
                    const value = event.target.value === "true";
                    formik.setFieldValue("active", value);
                  }}
                >
                  <MenuItem value="true">{t("aktywny")}</MenuItem>
                  <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={styles.field}>
              <Autocomplete
                id="category"
                size="small"
                autoHighlight
                fullWidth
                noOptionsText={t("__brak_opcji")}
                options={
                  allCategories?.filter((category) => category.active) || []
                }
                value={getCategoryById(formik.values.categoryId)}
                onChange={(event, value) =>
                  formik.setFieldValue("categoryId", value?.id.toString() || "")
                }
                getOptionLabel={(category) =>
                  category.active
                    ? category.label
                    : `${category.label} (${t("dezaktywowany")})`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    <Typography>{option.label}</Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("__kategoria")}
                    error={
                      formik.touched.categoryId &&
                      Boolean(formik.errors.categoryId)
                    }
                    helperText={
                      formik.touched.categoryId && formik.errors.categoryId
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={styles.field}>
              <Autocomplete
                id="profile"
                size="small"
                autoHighlight
                fullWidth
                noOptionsText={t("__brak_opcji")}
                options={allProfiles?.filter((profile) => profile.active) || []}
                value={getProfileById(formik.values.profileId)}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "profileId",
                    newValue?.id.toString() || ""
                  );
                }}
                getOptionLabel={(profile) =>
                  profile.active
                    ? profile.label
                    : `${profile.label} (${t("dezaktywowany")})`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    <Typography>{option.label}</Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("__profil")}
                    error={
                      formik.touched.profileId &&
                      Boolean(formik.errors.profileId)
                    }
                    helperText={
                      formik.touched.profileId && formik.errors.profileId
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={styles.field}>
              <NumericInput
                variant="outlined"
                id="rank"
                name="rank"
                fullWidth
                label={t("__pozycja_na_liscie")}
                size="small"
                value={formik.values.rank}
                onChange={formik.handleChange}
                error={formik.touched.rank && Boolean(formik.errors.rank)}
                helperText={formik.touched.rank && formik.errors.rank}
              />
            </Box>
            <Box sx={styles.field}>
              <FormControl
                fullWidth
                size="small"
                error={
                  formik.touched.railCount && Boolean(formik.errors.railCount)
                }
              >
                <InputLabel>{t("__liczba_szyn")}</InputLabel>
                <Select
                  id="railCount"
                  name="railCount"
                  size="small"
                  value={formik.values.railCount}
                  label={t("__liczba_szyn")}
                  onChange={formik.handleChange}
                  disabled={!!edited}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.railCount && formik.errors.railCount}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box sx={styles.field}>
              <NumericInput
                variant="outlined"
                id="defaultWallIncrease"
                name="defaultWallIncrease"
                fullWidth
                label={t("__domyslne_podwyzszenie_scian")}
                size="small"
                value={formik.values.defaultWallIncrease}
                onChange={formik.handleChange}
                error={
                  formik.touched.defaultWallIncrease &&
                  Boolean(formik.errors.defaultWallIncrease)
                }
                helperText={
                  formik.touched.defaultWallIncrease &&
                  formik.errors.defaultWallIncrease
                }
                InputProps={getInputAdornment("cm")}
              />
            </Box>
          </Box>
        </Section>
        {edited && <VariantsList modelId={edited.id} />}
        <Renders
          defaultPhoto={formik.values.defaultPhoto}
          doorPhoto={formik.values.doorPhoto}
          flapPhoto={formik.values.flapPhoto}
          secondaryDoorPhoto={formik.values.secondaryDoorPhoto}
          secondaryFlapPhoto={formik.values.secondaryFlapPhoto}
          onChangeDefaultPhoto={(value: string) =>
            formik.setFieldValue("defaultPhoto", value)
          }
          onChangeDoorPhoto={(value: string) =>
            formik.setFieldValue("doorPhoto", value)
          }
          onChangeFlapPhoto={(value: string) =>
            formik.setFieldValue("flapPhoto", value)
          }
          onChangeSecondaryDoorPhoto={(value: string) =>
            formik.setFieldValue("secondaryDoorPhoto", value)
          }
          onChangeSecondaryFlapPhoto={(value: string) =>
            formik.setFieldValue("secondaryFlapPhoto", value)
          }
          error={
            formik.touched.defaultPhoto && Boolean(formik.errors.defaultPhoto)
          }
          helperText={formik.touched.defaultPhoto && formik.errors.defaultPhoto}
        />
        <ComponentsAvailability
          handleChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
          fillingsInherited={formik.values.fillingsInherited}
          colorsInherited={formik.values.colorsInherited}
          brakesInherited={formik.values.brakesInherited}
          doorsInherited={formik.values.doorsInherited}
          clapsInherited={formik.values.clapsInherited}
          brakes={formik.values.brakes}
          colors={formik.values.colors}
          doors={formik.values.doors}
          claps={formik.values.claps}
          fillings={formik.values.fillings}
          infoText="__wszystkie_z_katerogii"
        />
      </form>
    </Box>
  );
};

export default withPageLoader(CoverEdit);
