import React, { useEffect } from "react";
import Section from "../../../../../components/section/section";
import { Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "./admin-section.styles";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Partner } from "../../../../../types/partner-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { getAllPartners } from "../../../../../redux/actions/partner-actions";
import { OrderStatus } from "../../../../../types/order-types";
import { setPartner, setStatus } from "../../../../../redux/slices/order-slice";

const AdminSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 650px)");
  const styles = getStyles(theme, isMobile);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { allPartners } = useSelector((state: Store) => state.partner);
  const { partner, status, id } = useSelector(
    (state: Store) => state.order.currentOrder
  );

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (!partner) {
      dispatch(getAllPartners());
    }
  };

  return (
    <Section title={t("dane_podstawowe")}>
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <Autocomplete
            size="small"
            autoHighlight
            fullWidth
            noOptionsText={t("__brak_opcji")}
            disabled={Boolean(id)}
            options={allPartners || []}
            value={partner}
            onChange={(event: any, newValue: Partner | null) => {
              dispatch(setPartner(newValue));
            }}
            getOptionLabel={(partner) =>
              partner.active
                ? partner.name
                : `${partner.name} (${t("dezaktywowany")})`
            }
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Typography>
                  {option.active
                    ? option.name
                    : `${option.name} (${t("dezaktywowany")})`}
                </Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("partner")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                }}
              />
            )}
          />
        </Box>
        <Box sx={styles.field}>
          <FormControl fullWidth>
            <InputLabel>{t("status")}</InputLabel>
            <Select
              fullWidth
              value={status}
              label={t("status")}
              disabled={status === OrderStatus.EXPIRED}
              onChange={(e) =>
                dispatch(setStatus(e.target.value as OrderStatus))
              }
              size="small"
            >
              {Object.keys(OrderStatus).map((status) => (
                <MenuItem
                  key={status}
                  value={status}
                  disabled={status === OrderStatus.EXPIRED}
                >
                  {t(status)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Section>
  );
};

export default AdminSection;
