import { Theme } from "@mui/material";

export const getStyles = (theme: Theme, isMobile: boolean) => ({
  container: {
    display: "flex",
    flexDirection: "column" as const,
    gap: theme.spacing(2),
  },
  fields: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  field: {
    flex: "1 1 0",
    minWidth: isMobile ? 200 : 300,
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  infoIcon: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    img: {
      width: 350,
    },
    "& p": { textAlign: "justify", width: 350, fontSize: 14 },
  },
  fieldWithInfoIcon: {
    flex: "1 1 0",
    minWidth: isMobile ? 200 : 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  infoIconButton: {
    transform: "translateY(30%)",
  },
});
