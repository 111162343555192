import { TFunction } from "react-i18next";
import * as yup from "yup";
import {
  TranslationPatchRequest,
  TranslationPostRequest,
} from "../../../../types/translation-types";
import {
  Color,
  ColorPatchRequest,
  ColorPostRequest,
} from "../../../../types/color-types";
import { Language } from "../../../../types/language-types";
import { FormValues } from "./color-edit.types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");

  return yup.object({
    polishName: yup.string().required(requiredFieldError),
    englishName: yup.string().required(requiredFieldError),
    germanName: yup.string().required(requiredFieldError),
    swedenName: yup.string().required(requiredFieldError),
    active: yup.boolean(),
  });
};

export const mapFormValuesToColorPostRequest = (
  values: FormValues
): ColorPostRequest => {
  const labelTranslations: TranslationPostRequest[] = [];

  labelTranslations.push({ langIso: "pl", value: values.polishName });
  labelTranslations.push({ langIso: "en", value: values.englishName });
  labelTranslations.push({ langIso: "de", value: values.germanName });
  labelTranslations.push({ langIso: "sv", value: values.swedenName });

  return { labelTranslations };
};

const findLabelId = (color: Color, lang: Language): number =>
  color.labelTranslations.find((translation) => translation.langIso === lang)!
    .id;

export const mapFormValuesToColorPatchRequest = (
  values: FormValues,
  color: Color
): ColorPatchRequest => {
  const labelTranslations: TranslationPatchRequest[] = [];

  labelTranslations.push(
    {
      id: findLabelId(color, "pl"),
      langIso: "pl",
      value: values.polishName,
    },
    {
      id: findLabelId(color, "en"),
      langIso: "en",
      value: values.englishName,
    },
    {
      id: findLabelId(color, "de"),
      langIso: "de",
      value: values.germanName,
    },
    {
      id: findLabelId(color, "sv"),
      langIso: "sv",
      value: values.swedenName,
    }
  );

  return {
    id: color.id,
    active: values.active,
    labelTranslations,
  };
};

const findNameTranslation = (color: Color, lang: Language) =>
  color.labelTranslations.find((translation) => translation.langIso === lang)!
    .value;

const mapColorToFormValues = (color: Color): FormValues => ({
  active: color.active,
  polishName: findNameTranslation(color, "pl"),
  germanName: findNameTranslation(color, "de"),
  englishName: findNameTranslation(color, "en"),
  swedenName: findNameTranslation(color, "sv"),
});

const getDefaultFormValues = (): FormValues => ({
  active: true,
  englishName: "",
  germanName: "",
  polishName: "",
  swedenName: "",
});

export const getInitFormValues = (color: Color | null): FormValues =>
  color ? mapColorToFormValues(color) : getDefaultFormValues();
