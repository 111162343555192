import { DependencyList, EffectCallback, useEffect, useRef } from "react";

const useUpdateEffect = (callback: EffectCallback, deps: DependencyList) => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    callback();
  }, deps);
};

export default useUpdateEffect;
