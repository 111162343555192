import {
  Pageable,
  PageChanges,
  PageRequest,
  PageResponse,
} from "../types/common-types";
import { Draft } from "immer";
import deepEqual from "deep-equal";
import equal from "fast-deep-equal";

export const updateStateWithPageable = <T>(
  state: Draft<Pageable<T>>,
  page: Pageable<T>
) => {
  // @ts-ignore
  state.content = page.content;
  state.page = page.page;
  state.totalPages = page.totalPages;
  // noinspection JSConstantReassignment
  state.size = page.size;
  state.sort = page.sort;
  state.filters = page.filters;
};

export const preparePageForAction = <T>(
  response: PageResponse<T>,
  params: PageRequest
) => {
  const page: Pageable<T> = {
    content: response.content,
    page: params.page,
    size: params.size,
    totalPages: response.totalPages,
    sort: params.sort,
    filters: params.filters,
  };

  return page;
};

export const isPageUpToDate = (params: PageRequest, state: Pageable<any>) =>
  state.page === params.page &&
  deepEqual(params.filters, state.filters) &&
  params.size === state.size &&
  state.content !== null;

export const flatPageParams = (params: PageRequest): Record<string, any> => {
  const { filters, ...otherParams } = params;
  return { ...otherParams, ...filters };
};

export const applyChangesToPage = (
  original: PageRequest,
  changes?: PageChanges
): PageRequest => ({
  size: changes?.size ?? original.size,
  page: changes?.page ?? original.page,
  filters: changes?.filters || original.filters,
  sort: changes?.sort || original.sort,
});

export const getFirstElementFromPage = <T>(data: PageResponse<T>) =>
  data.content ? data.content[0] : null;

export const preparePatchEntity = <T>(patchEntity: T, originalEntity: T): T => {
  // @ts-ignore
  const adjustedEntity: T = { id: patchEntity?.id };
  Object.entries(patchEntity).forEach(([key, value]) => {
    // @ts-ignore
    if (!equal(originalEntity[key], value)) {
      // @ts-ignore
      adjustedEntity[key] = value;
    }
  });

  return adjustedEntity;
};
