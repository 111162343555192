import React, { ComponentType, useState } from "react";
import Box from "@mui/material/Box";
import Loader from "../components/loader/loader";
import { Colors } from "../theme/colors";

export interface WithPageLoaderProps {
  startLoading: () => void;
  finishLoading: () => void;
  isPageLoading: boolean;
}

const getStyles = () => ({
  container: {
    display: "flex",
    justifyContent: "center",
    placeItems: "center",
    height: "calc(100vh - 250px)",
    position: "relative",
  },
  content: {
    display: "none",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    placeItems: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.Body,
    zIndex: 1,
  },
});

export function withPageLoader<T extends WithPageLoaderProps>(
  WrappedComponent: ComponentType<T>
) {
  return (props: Omit<T, keyof WithPageLoaderProps>) => {
    const [isPageLoading, setPageLoading] = useState(true);
    const styles = getStyles();

    const finishLoading = () => setPageLoading(false);

    const startLoading = () => setPageLoading(true);

    return (
      <Box sx={isPageLoading ? styles.container : null}>
        <Box sx={isPageLoading ? styles.content : null}>
          <WrappedComponent
            {...(props as T)}
            finishLoading={finishLoading}
            startLoading={startLoading}
            isPageLoading={isPageLoading}
          />
        </Box>
        {isPageLoading ? (
          <Box sx={styles.loader}>
            <Loader />
          </Box>
        ) : null}
      </Box>
    );
  };
}
export default withPageLoader;
