import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Filters,
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import {
  Category,
  CategoryPatchRequest,
  CategoryPostRequest,
} from "../../types/category-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { DEFAULT_CATEGORY_SORT } from "../slices/category-slice";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { category } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, category)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Category>>(
      "/model-category",
      {
        params: flatParams,
      }
    );

    return preparePageForAction<Category>(data, params);
  }
);

export const getAllCategories = createAsyncThunk(
  "categories/getAllCategories",
  async (config: { active?: boolean }) => {
    const filters: Filters = {};

    if (config.active) {
      filters.active = config.active;
    }

    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort: DEFAULT_CATEGORY_SORT,
      filters,
    };

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Category>>(
      "/model-category",
      {
        params: flatParams,
      }
    );
    return data.content;
  }
);

export const getCategory = createAsyncThunk(
  "categories/getCategory",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Category>>(
      "/model-category",
      {
        params: { id },
      }
    );
    return getFirstElementFromPage<Category>(data);
  }
);

export const addCategory = createAsyncThunk(
  "categories/addCategory",
  async (params: CategoryPostRequest, thunkAPI) => {
    const { data } = await axios.post<Category>("/model-category", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editCategory = createAsyncThunk(
  "categories/editCategory",
  async (params: CategoryPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Category>("/model-category", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
