import { createTheme } from "@mui/material";
import { Colors } from "./colors";

export const theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& > div.Mui-disabled": {
            backgroundColor: Colors.DisabledBackgroundGrey,
          },
          "& input.Mui-disabled": {
            color: Colors.DisabledTextGrey,
            "-webkit-text-fill-color": "unset",
          },
          "& > label.Mui-disabled": {
            color: Colors.DisabledTextGrey,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.ButtonBackground,
          "&:hover": {
            backgroundColor: Colors.ButtonBachgroundHover,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.ButtonBackground,
          "&:hover": {
            backgroundColor: Colors.ButtonBachgroundHover,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          "& .MuiButtonBase-root": {
            backgroundColor: Colors.Transparent,
            "&:hover": {
              backgroundColor: Colors.Transparent,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
});
