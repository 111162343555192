import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TableHeaderProps } from "../table-types";
import { Theme, useTheme } from "@mui/material";
import { ELLIPSIS_STYLES, getCellStyles } from "./table-row";
import { toRem } from "../../../utils/common-utils";
import { WithId } from "../../../types/common-types";

const getStyles = (theme: Theme) => ({
  rowWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(2),
  },
  cell: {
    ...getCellStyles(theme),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    "& .MuiTypography-root": {
      fontSize: toRem(13),
    },
    "& .header": {
      fontSize: toRem(16),
    },
  },
  text: {
    ...ELLIPSIS_STYLES,
    color: theme.palette.grey["500"],
  },
});

const TableHeader = <T extends WithId>({ columns }: TableHeaderProps<T>) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Box sx={styles.rowWrapper}>
      {columns.map((column) => {
        return (
          <Box
            key={column.name}
            sx={{ ...styles.cell, flexGrow: column.flexGrow }}
          >
            <Typography sx={styles.text} className="header">
              {column.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default TableHeader;
