import { styled, Theme } from "@mui/material/styles";
import MuiListItem from "@mui/material/ListItem";
import { Colors } from "../../../../theme/colors";

export const ListItem = styled(MuiListItem)<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    display: "block",
    ...(isActive && {
      borderRight: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
    }),
  })
);

export const getStyles = (theme: Theme) => ({
  listItemButton: (open: boolean, isNested?: boolean) => ({
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    pl: open && isNested ? 4 : 2,
  }),
  listItemIcon: (open: boolean, isActive: boolean) => ({
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
    color: isActive ? Colors.White : theme.palette.grey["400"],
  }),
  listItemText: (open: boolean, isActive: boolean) => ({
    opacity: open ? 1 : 0,
    color: isActive ? Colors.White : theme.palette.grey["400"],
  }),
  collapseIcon: {
    color: theme.palette.grey["400"],
  },
});
