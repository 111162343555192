import { Order, OrderStatus } from "../../../types/order-types";
import { Typography } from "@mui/material";
import { ELLIPSIS_STYLES } from "../../../components/pagination-table/table-row/table-row";
import Chip from "@mui/material/Chip";
import React from "react";
import { TFunction } from "i18next";
import { formatInteger, formatPrice } from "../../../utils/formatter-utils";

export const renderClientCell = (order: Order) => (
  <Typography key={`${order.id}-client-cell`} sx={ELLIPSIS_STYLES}>
    {order.client?.name || "-"}
  </Typography>
);

export const renderStatusCell = (
  id: number,
  status: OrderStatus,
  t: TFunction
) => {
  let color: "primary" | "success" | "error" = "primary";

  switch (status) {
    case OrderStatus.ACCEPTED:
    case OrderStatus.DELIVERED:
      color = "success";
      break;
    case OrderStatus.CANCELED:
    case OrderStatus.DELETED:
    case OrderStatus.EXPIRED:
      color = "error";
      break;
  }

  return (
    <Chip
      key={`${id}-status-cell`}
      // @ts-ignore
      label={t(status)}
      color={color}
      variant="outlined"
    />
  );
};

export const renderCreatedByCell = (order: Order) => (
  <Typography key={`${order.id}-created-by-cell`} sx={ELLIPSIS_STYLES}>
    {`${order.createdBy.firstname} ${order.createdBy.lastname}` || "-"}
  </Typography>
);

export const renderCityCell = (order: Order) => (
  <Typography key={`${order.id}-city-cell`} sx={ELLIPSIS_STYLES}>
    {order.client?.address.city || "-"}
  </Typography>
);

export const renderModelCell = (order: Order) => (
  <Typography key={`${order.id}-model-cell`} sx={ELLIPSIS_STYLES}>
    {order.model.label}
  </Typography>
);

export const renderDateCell = (order: Order) => (
  <Typography key={`${order.id}-date-cell`} sx={ELLIPSIS_STYLES}>
    {new Date(order.createdDate).toLocaleDateString()}
  </Typography>
);

export const renderPriceCell = (order: Order) => (
  <Typography key={`${order.id}-price-cell`} sx={ELLIPSIS_STYLES}>
    {`${formatPrice(order.payment.partnerPrice)} €`}
  </Typography>
);

export const renderIdCell = (order: Order) => (
  <Typography key={`${order.id}-id-cell`} sx={ELLIPSIS_STYLES}>
    {formatInteger(order.id)}
  </Typography>
);

export const renderPartnerCell = (order: Order) => (
  <Typography key={`${order.id}-partner-cell`} sx={ELLIPSIS_STYLES}>
    {order.partner.name}
  </Typography>
);
