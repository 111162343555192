import { Loading, Pageable } from "../../types/common-types";
import { Cover } from "../../types/cover-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { updateStateWithPageable } from "../redux-utils";
import {
  addCover,
  editCover,
  getAllCovers,
  getCovers,
} from "../actions/cover-actions";

export interface CoverState extends Pageable<Cover> {
  loading: Loading;
  allCovers: Cover[] | null;
}

export const DEFAULT_COVER_FILTERS = {
  active: true,
  label: "",
  categoryId: null,
};

export const DEFAULT_COVER_SORT = [
  "category.rank",
  "model.rank",
  "label",
  "id",
];

const initialState: CoverState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_COVER_SORT,
  filters: DEFAULT_COVER_FILTERS,
  allCovers: null,
};

const coverSlice = createSlice({
  name: "cover",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCovers.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getCovers.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getCovers.rejected, (state) => {
      state.loading = "failed";
    });

    builder.addCase(addCover.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addCover.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addCover.rejected, (state) => {
      state.loading = "failed";
    });

    builder.addCase(editCover.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editCover.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editCover.rejected, (state) => {
      state.loading = "failed";
    });

    builder.addCase(getAllCovers.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllCovers.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allCovers = action.payload;
    });
    builder.addCase(getAllCovers.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default coverSlice.reducer;
