import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CalculationRequest,
  CalculationResponse,
} from "../../types/calculation-types";
import axios from "axios";
import {
  AvailablePrices,
  Order,
  OrderPatchRequest,
  OrderPostRequest,
} from "../../types/order-types";
import { Filters, PageParams, PageResponse } from "../../types/common-types";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import { DEFAULT_ORDER_FILTERS, OrderCounts } from "../slices/order-slice";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import { Store } from "../store";
import { mapCoverDtoToCover } from "../../types/cover-types";

const preprocessContent = (data: PageResponse<Order>) =>
  data.content
    ? data.content.map((order) => ({
        ...order,
        model: mapCoverDtoToCover(order.model),
      }))
    : data.content;

export const calculate = createAsyncThunk(
  "order/calculate",
  async (params: CalculationRequest) => {
    const { data } = await axios.get<CalculationResponse>(
      "/offer/calculation",
      { params }
    );
    return data;
  }
);

export const addOrder = createAsyncThunk(
  "order/createOrder",
  async (params: OrderPostRequest, thunkAPI) => {
    const { data } = await axios.post<Order>("/offer", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return { ...data, model: mapCoverDtoToCover(data.model) };
  }
);

export const editOrder = createAsyncThunk(
  "order/editOrder",
  async (params: OrderPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Order>("/offer", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return { ...data, model: mapCoverDtoToCover(data.model) };
  }
);

export const getOrders = createAsyncThunk(
  "order/getOrders",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { order } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, order)) {
      return null;
    }

    const flatParams = flatPageParams(params);
    const idFilter =
      flatParams.id === DEFAULT_ORDER_FILTERS.id ? undefined : flatParams.id;
    const partnerFilter =
      flatParams.partnerId === DEFAULT_ORDER_FILTERS.partnerId
        ? undefined
        : flatParams.partnerId;
    const createdBy =
      flatParams.createdBy === DEFAULT_ORDER_FILTERS.createdBy
        ? undefined
        : flatParams.createdBy;
    const clientNameFilter =
      flatParams.clientName === DEFAULT_ORDER_FILTERS.clientName
        ? undefined
        : flatParams.clientName;
    const adjustedFilters = {
      ...flatParams,
      id: idFilter,
      partnerId: partnerFilter,
      createdById: createdBy,
      "client.name": clientNameFilter,
    };
    const { data } = await axios.get<PageResponse<Order>>("/offer", {
      params: adjustedFilters,
    });
    const preprocessedData = { ...data, content: preprocessContent(data) };
    return preparePageForAction<Order>(preprocessedData, params);
  }
);

export const getOrder = createAsyncThunk(
  "order/getOrder",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Order>>("/offer", {
      params: { id },
    });
    const preprocessedData = { ...data, content: preprocessContent(data) };
    return getFirstElementFromPage<Order>(preprocessedData);
  }
);

export const getOrderCounts = createAsyncThunk(
  "order/getOrderCounts",
  async (filters: Filters) => {
    const idFilter =
      filters.id === DEFAULT_ORDER_FILTERS.id ? undefined : filters.id;
    const partnerFilter =
      filters.partnerId === DEFAULT_ORDER_FILTERS.partnerId
        ? undefined
        : filters.partnerId;
    const clientNameFilter =
      filters.clientName === DEFAULT_ORDER_FILTERS.clientName
        ? undefined
        : filters.clientName;
    const adjustedFilters = {
      ...filters,
      id: idFilter,
      partnerId: partnerFilter,
      "client.name": clientNameFilter,
    };

    const { data } = await axios.get<OrderCounts>("/offer/counts", {
      params: adjustedFilters,
    });
    return data;
  }
);

export const getAvailablePrices = createAsyncThunk(
  "order/getAvailablePrices",
  async (params: { offerId: number; variantId: number }) => {
    const { data } = await axios.get<AvailablePrices>(
      "/offer/available-prices",
      {
        params,
      }
    );
    return data;
  }
);
