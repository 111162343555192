import React, { useState } from "react";
import Filters from "../../../../components/filters/filters";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { Route } from "../../../../constants/navigation-constants";
import { PageChanges } from "../../../../types/common-types";
import { applyChangesToPage } from "../../../../redux/redux-utils";
import {
  getOrderCounts,
  getOrders,
} from "../../../../redux/actions/order-actions";
import { DEFAULT_ORDER_FILTERS } from "../../../../redux/slices/order-slice";
import Autocomplete from "@mui/material/Autocomplete";
import { Partner } from "../../../../types/partner-types";
import { Typography } from "@mui/material";
import useRole from "../../../../hooks/useRole";
import { isAdminRole } from "../../../../utils/common-utils";
import OrderUsersSearch from "./order-users-search/order-users-search";
import { CreatedBy } from "../../../../redux/slices/user-offer-search-slice";

const OrderFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);

  const reducer = useSelector((state: Store) => state.order);
  const { allPartners } = useSelector((state: Store) => state.partner);

  const [id, setId] = useState(reducer.filters.id);
  const [partnerId, setPartnerId] = useState(reducer.filters.partnerId);
  const [clientName, setClientName] = useState(reducer.filters.clientName);
  const [createdBy, setCreatedBy] = useState<CreatedBy | null>(null);

  const fetchPage = (changes?: PageChanges) => {
    const params = applyChangesToPage(reducer, changes);
    dispatch(getOrders({ params }));
  };

  const onSearch = () => {
    const updatedFilters = {
      id,
      partnerId,
      clientName,
      status: reducer.filters.status,
      createdBy: createdBy?.id || null,
    };
    dispatch(
      getOrderCounts({
        ...updatedFilters,
        status: DEFAULT_ORDER_FILTERS.status,
      })
    );
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onReset = () => {
    setId(DEFAULT_ORDER_FILTERS.id);
    setPartnerId(DEFAULT_ORDER_FILTERS.partnerId);
    setClientName(DEFAULT_ORDER_FILTERS.clientName);
    setCreatedBy(DEFAULT_ORDER_FILTERS.createdBy);
    dispatch(getOrderCounts(DEFAULT_ORDER_FILTERS));
    fetchPage({
      page: 0,
      filters: { ...DEFAULT_ORDER_FILTERS, status: reducer.filters.status },
    });
  };

  const onAddClick = () => navigate(Route.OrderEdit);

  const getPartnerById = () =>
    allPartners?.find((partner) => partner.id === partnerId) || null;

  const filters = (
    <>
      <TextField
        label={t("__numer_oferty")}
        size="small"
        variant="outlined"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      {isAdmin && (
        <Autocomplete
          sx={{ width: 250 }}
          size="small"
          autoHighlight
          fullWidth
          noOptionsText={t("__brak_opcji")}
          options={allPartners || []}
          value={getPartnerById()}
          onChange={(event: any, newValue: Partner | null) => {
            setPartnerId(newValue?.id || null);
          }}
          getOptionLabel={(partner) =>
            partner.active
              ? partner.name
              : `${partner.name} (${t("dezaktywowany")})`
          }
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <Typography>
                {option.active
                  ? option.name
                  : `${option.name} (${t("dezaktywowany")})`}
              </Typography>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("partner")}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
              }}
            />
          )}
        />
      )}
      {!isAdmin && (
        <OrderUsersSearch
          pickedUser={createdBy as CreatedBy | null}
          setUser={setCreatedBy}
        />
      )}
      <TextField
        label={t("__klient")}
        size="small"
        variant="outlined"
        value={clientName}
        onChange={(e) => setClientName(e.target.value)}
      />
    </>
  );

  return (
    <Filters
      filters={filters}
      onReset={onReset}
      onSearch={onSearch}
      onAddClick={onAddClick}
    />
  );
};

export default OrderFilters;
