import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useNavigate, useParams } from "react-router-dom";
import { Post } from "../../../../types/post-types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import useFetchEntity, {
  FetchEntityArgs,
} from "../../../../hooks/useFetchEntity";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getStyles } from "../../../partners-page/partner-edit/partner-edit.styles";
import { Route } from "../../../../constants/navigation-constants";
import {
  addPost,
  editPost,
  getPost,
} from "../../../../redux/actions/post-actions";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Section from "../../../../components/section/section";
import { FormValues } from "./post-edit.types";
import {
  getInitFormValues,
  getValidationSchema,
  mapFormValuesToPatchPostRequest,
  mapFormValuesToPostPostRequest,
} from "./post-edit.utils";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { formatDate } from "../../../../utils/formatter-utils";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../../redux/slices/changes-slice";
import SectionTitle from "../../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../../redux/redux-utils";

interface PostEditProps extends WithPageLoaderProps {}

const PostEdit = ({ finishLoading }: PostEditProps) => {
  const { postId } = useParams<{ postId?: string }>();
  const [editedPost, setEditedPost] = useState<Post | null>(null);
  const { content: posts, loading } = useSelector((state: Store) => state.post);
  const { originalData } = useSelector((state: Store) => state.changes);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<Post> = {
      callback: setEditedPost,
      collection: posts,
      fallbackRoute: Route.PostManagement,
      fetcherAction: getPost,
      id: postId,
    };

    await Promise.all([fetchEntity<Post>(fetchArgs)]);

    finishLoading();
  };

  const onSubmit = async (values: FormValues) => {
    const saveFn = editedPost ? submitEditPost : submitAddPost;
    const savedPost = await saveFn(values);
    if (savedPost) {
      setEditedPost(savedPost);
    }
  };

  const submitAddPost = async (values: FormValues) => {
    const post = mapFormValuesToPostPostRequest(values);
    const { payload } = await dispatch(addPost(post));
    return payload as Post;
  };

  const submitEditPost = async (values: FormValues) => {
    const post = mapFormValuesToPatchPostRequest(values, editedPost!);
    const originalEntity = mapFormValuesToPatchPostRequest(
      originalData as FormValues,
      editedPost!
    );
    const adjustedEntity = preparePatchEntity(post, originalEntity);
    const { payload } = await dispatch(editPost(adjustedEntity));
    return payload as Post;
  };

  const formik = useFormik({
    initialValues: getInitFormValues(editedPost),
    validationSchema: getValidationSchema(t),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(editedPost);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [editedPost]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.PostManagement);

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(editedPost));
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={editedPost ? t("edytcja_posta") : t("nowy_post")}
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(editedPost)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_podstawowe")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <FormControl fullWidth>
              <InputLabel>{t("status")}</InputLabel>
              <Select
                id="active"
                name="active"
                size="small"
                value={formik.values.active?.toString()}
                label={t("status")}
                disabled={!editedPost}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  formik.setFieldValue("active", value);
                }}
              >
                <MenuItem value="true">{t("aktywny")}</MenuItem>
                <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="createdDate"
              name="createdDate"
              fullWidth
              label={t("data_dodania")}
              size="small"
              disabled
              value={
                formik.values.createdDate
                  ? formatDate(formik.values.createdDate)
                  : formik.values.createdDate
              }
              onChange={formik.handleChange}
              error={
                formik.touched.createdDate && Boolean(formik.errors.createdDate)
              }
              helperText={
                formik.touched.createdDate && formik.errors.createdDate
              }
            />
          </Box>
        </Box>
      </Section>
      <Section title={t("tytul")}>
        <Box sx={styles.fields}>
          <TextField
            id="polishTitle"
            name="polishTitle"
            fullWidth
            label={t("polski")}
            size="small"
            value={formik.values.polishTitle}
            onChange={formik.handleChange}
            error={
              formik.touched.polishTitle && Boolean(formik.errors.polishTitle)
            }
            helperText={formik.touched.polishTitle && formik.errors.polishTitle}
          />
          <TextField
            id="englishTitle"
            name="englishTitle"
            fullWidth
            label={t("angielski")}
            size="small"
            value={formik.values.englishTitle}
            onChange={formik.handleChange}
            error={
              formik.touched.englishTitle && Boolean(formik.errors.englishTitle)
            }
            helperText={
              formik.touched.englishTitle && formik.errors.englishTitle
            }
          />
          <TextField
            id="germanTitle"
            name="germanTitle"
            fullWidth
            label={t("niemiecki")}
            size="small"
            value={formik.values.germanTitle}
            onChange={formik.handleChange}
            error={
              formik.touched.germanTitle && Boolean(formik.errors.germanTitle)
            }
            helperText={formik.touched.germanTitle && formik.errors.germanTitle}
          />
          <TextField
            id="swedenTitle"
            name="swedenTitle"
            fullWidth
            label={t("szwedzki")}
            size="small"
            value={formik.values.swedenTitle}
            onChange={formik.handleChange}
            error={
              formik.touched.swedenTitle && Boolean(formik.errors.swedenTitle)
            }
            helperText={formik.touched.swedenTitle && formik.errors.swedenTitle}
          />
        </Box>
      </Section>
      <Section title={t("tresc")}>
        <Box sx={styles.fields}>
          <TextField
            id="polishText"
            name="polishText"
            fullWidth
            label={t("polski")}
            size="small"
            value={formik.values.polishText}
            onChange={formik.handleChange}
            multiline
            minRows={4}
            error={
              formik.touched.polishText && Boolean(formik.errors.polishText)
            }
            helperText={formik.touched.polishText && formik.errors.polishText}
          />
          <TextField
            id="englishText"
            name="englishText"
            fullWidth
            label={t("angielski")}
            size="small"
            value={formik.values.englishText}
            multiline
            minRows={4}
            onChange={formik.handleChange}
            error={
              formik.touched.englishText && Boolean(formik.errors.englishText)
            }
            helperText={formik.touched.englishText && formik.errors.englishText}
          />
          <TextField
            id="germanText"
            name="germanText"
            fullWidth
            label={t("niemiecki")}
            size="small"
            multiline
            minRows={4}
            value={formik.values.germanText}
            onChange={formik.handleChange}
            error={
              formik.touched.germanText && Boolean(formik.errors.germanText)
            }
            helperText={formik.touched.germanText && formik.errors.germanText}
          />
          <TextField
            id="swedenText"
            name="swedenText"
            fullWidth
            label={t("szwedzki")}
            size="small"
            value={formik.values.swedenText}
            onChange={formik.handleChange}
            multiline
            minRows={4}
            error={
              formik.touched.swedenText && Boolean(formik.errors.swedenText)
            }
            helperText={formik.touched.swedenText && formik.errors.swedenText}
          />
        </Box>
      </Section>
    </form>
  );
};

export default withPageLoader(PostEdit);
