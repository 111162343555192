import { Theme } from "@mui/material";

export const getStyles = (theme: Theme, isMobile: boolean) => ({
  fields: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  field: {
    flex: "1 1 0",
    minWidth: isMobile ? 200 : 350,
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
});
