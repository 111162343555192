import { Role } from "../../types/auth-types";
import { Roles } from "../../constants/roles";
import { ADMIN_MENU_ITEMS, PARTNER_MENU_ITEMS } from "./drawer.constants";

export const getMenuItems = (userRole: Role) => {
  if (userRole.name === Roles.Admin) {
    return ADMIN_MENU_ITEMS;
  }

  if (userRole.name === Roles.Partner) {
    return PARTNER_MENU_ITEMS;
  }

  return [];
};
