import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Section from "../../../components/section/section";
import TextField from "@mui/material/TextField";
import CountrySelect from "../../../components/country-select/country-select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import {
  addPartner,
  editPartner,
  getPartner,
} from "../../../redux/actions/partner-actions";
import {
  getInitFormValues,
  getValidationSchema,
  mapFormValuesToPartnerPostRequest,
} from "./partner-edit.utils";
import { FormValues } from "./partner-edit.types";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { getCountries } from "../../../redux/actions/country-actions";
import {
  Partner,
  PartnerPatchRequest,
  PartnerPostRequest,
} from "../../../types/partner-types";
import { Route } from "../../../constants/navigation-constants";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { getStyles } from "./partner-edit.styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import useFetchEntity, { FetchEntityArgs } from "../../../hooks/useFetchEntity";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../redux/slices/changes-slice";
import SectionTitle from "../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../redux/redux-utils";
import { getInputAdornment } from "../../../utils/common-utils";
import NumericInput from "../../../components/numeric-input/numeric-input";

interface PartnersEditProps extends WithPageLoaderProps {}

const PartnerEdit = ({ finishLoading }: PartnersEditProps) => {
  const { partnerId } = useParams<{ partnerId?: string }>();
  const [editedPartner, setEditedPartner] = useState<Partner | null>(null);
  const { content: partners, loading } = useSelector(
    (state: Store) => state.partner
  );
  const { originalData } = useSelector((state: Store) => state.changes);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const theme = useTheme();
  const styles = getStyles(theme, isMobile);

  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<Partner> = {
      callback: setEditedPartner,
      collection: partners,
      fallbackRoute: Route.Partners,
      fetcherAction: getPartner,
      id: partnerId,
    };

    await Promise.all([
      dispatch(getCountries()),
      fetchEntity<Partner>(fetchArgs),
    ]);

    finishLoading();
  };

  const onSubmit = async (values: FormValues) => {
    const partner = mapFormValuesToPartnerPostRequest(values);
    const saveFn = editedPartner ? submitEditPartner : submitAddPartner;
    const savedPartner = await saveFn(partner);
    if (savedPartner) {
      setEditedPartner(savedPartner);
    }
  };

  const submitAddPartner = async (partner: PartnerPostRequest) => {
    const { payload } = await dispatch(addPartner(partner));
    return payload as Partner;
  };

  const submitEditPartner = async (partner: PartnerPostRequest) => {
    const partnerPatchRequest: PartnerPatchRequest = {
      ...partner,
      id: editedPartner!.id,
    };

    const originalEntity = {
      ...mapFormValuesToPartnerPostRequest(originalData as FormValues),
      id: editedPartner!.id,
    };

    const adjustedEntity = preparePatchEntity(
      partnerPatchRequest,
      originalEntity
    );

    const { payload } = await dispatch(editPartner(adjustedEntity));
    return payload as Partner;
  };

  const formik = useFormik({
    initialValues: getInitFormValues(editedPartner),
    validationSchema: getValidationSchema(t),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(editedPartner);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [editedPartner]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.Partners);

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(editedPartner));
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={editedPartner ? t("edycja_partnera") : t("nowy_partner")}
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(editedPartner)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_partnera")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <TextField
              id="name"
              name="name"
              fullWidth
              label={t("nazwa")}
              size="small"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="email"
              name="email"
              fullWidth
              label={t("mail")}
              size="small"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="phonePrefix"
              name="phonePrefix"
              fullWidth
              label={t("kierunkowy")}
              type="tel"
              size="small"
              value={formik.values.phonePrefix}
              onChange={formik.handleChange}
              error={
                formik.touched.phonePrefix && Boolean(formik.errors.phonePrefix)
              }
              helperText={
                formik.touched.phonePrefix && formik.errors.phonePrefix
              }
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              fullWidth
              label={t("numer_telefonu")}
              size="small"
              type="tel"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="discount"
              fullWidth
              name="discount"
              size="small"
              label={t("znizka")}
              value={formik.values.discount}
              onChange={formik.handleChange}
              error={formik.touched.discount && Boolean(formik.errors.discount)}
              helperText={formik.touched.discount && formik.errors.discount}
              InputProps={getInputAdornment("%")}
            />
          </Box>
          <Box sx={styles.field}>
            <FormControl fullWidth>
              <InputLabel>{t("status")}</InputLabel>
              <Select
                id="active"
                name="active"
                size="small"
                value={formik.values.active?.toString()}
                label={t("status")}
                disabled={!editedPartner}
                onChange={(event) => {
                  const value = event.target.value === "true";
                  formik.setFieldValue("active", value);
                }}
              >
                <MenuItem value="true">{t("aktywny")}</MenuItem>
                <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.field}>
            <FormControlLabel
              control={
                <Checkbox
                  id="freeExtensions"
                  name="freeExtensions"
                  checked={formik.values.freeExtensions}
                  onChange={formik.handleChange}
                />
              }
              label={t("darmowe_przedluzenie_szyn")}
            />
          </Box>
        </Box>
      </Section>
      <Section title={t("dane_adresowe")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <TextField
              id="street"
              name="street"
              label={t("ulica")}
              fullWidth
              size="small"
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="houseNumber"
              name="houseNumber"
              label={t("numer_domu")}
              fullWidth
              size="small"
              value={formik.values.houseNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.houseNumber && Boolean(formik.errors.houseNumber)
              }
              helperText={
                formik.touched.houseNumber && formik.errors.houseNumber
              }
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="flatNumber"
              fullWidth
              name="flatNumber"
              label={t("numer_lokalu")}
              size="small"
              value={formik.values.flatNumber}
              onChange={formik.handleChange}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="zipCode"
              name="zipCode"
              size="small"
              label={t("kod_pocztowy")}
              fullWidth
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
            />
          </Box>
          <Box sx={styles.field}>
            <TextField
              id="city"
              name="city"
              size="small"
              fullWidth
              label={t("miasto")}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Box>
          <Box sx={styles.field}>
            <CountrySelect
              fullWidth
              countryId={formik.values.countryId}
              onChange={(value) => {
                formik.setFieldValue("countryId", value);
              }}
              error={
                formik.touched.countryId && Boolean(formik.errors.countryId)
              }
              helperText={formik.touched.countryId && formik.errors.countryId}
            />
          </Box>
        </Box>
      </Section>
    </form>
  );
};

export default withPageLoader(PartnerEdit);
