import { TFunction } from "react-i18next";
import * as yup from "yup";
import {
  TranslationPatchRequest,
  TranslationPostRequest,
} from "../../../../types/translation-types";
import {
  Category,
  CategoryPatchRequest,
  CategoryPostRequest,
} from "../../../../types/category-types";
import { Language } from "../../../../types/language-types";
import { FormValues, FormWithAvailabilities } from "./category-edit.types";
import { WithId } from "../../../../types/common-types";
import { Door, DoorEnum } from "../../../../constants/door-constants";
import { Clap, ClapEnum } from "../../../../constants/claps-constants";
import { ComponentsAvailabilityPostRequest } from "../../../../types/components-availability.types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  return yup.object({
    polishName: yup.string().required(requiredFieldError),
    englishName: yup.string().required(requiredFieldError),
    germanName: yup.string().required(requiredFieldError),
    swedenName: yup.string().required(requiredFieldError),
    active: yup.boolean(),
    box: yup.boolean().required(requiredFieldError),
    rank: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
  });
};

const mapEntitiesIdsToIds = (entities: WithId[]): WithId[] =>
  entities.map((withId) => ({ id: withId.id }));

export const mapFormValuesToComponentsAvailability = (
  values: FormWithAvailabilities
): ComponentsAvailabilityPostRequest => ({
  brakes: values.brakesInherited ? [] : mapEntitiesIdsToIds(values.brakes),
  brakesInherited: values.brakesInherited,
  colors: values.colorsInherited ? [] : mapEntitiesIdsToIds(values.colors),
  colorsInherited: values.colorsInherited,
  doors: values.doorsInherited ? [] : values.doors.map((door) => door.id),
  doorsInherited: values.doorsInherited,
  fillings: values.fillingsInherited
    ? []
    : mapEntitiesIdsToIds(values.fillings),
  fillingsInherited: values.fillingsInherited,
  claps: values.clapsInherited ? [] : values.claps.map((clap) => clap.id),
  clapsInherited: values.clapsInherited,
});

export const mapFormValuesToCategoryPostRequest = (
  values: FormValues
): CategoryPostRequest => {
  const labelTranslations: TranslationPostRequest[] = [];

  labelTranslations.push({ langIso: "pl", value: values.polishName });
  labelTranslations.push({ langIso: "en", value: values.englishName });
  labelTranslations.push({ langIso: "de", value: values.germanName });
  labelTranslations.push({ langIso: "sv", value: values.swedenName });

  return {
    labelTranslations,
    componentsAvailability: mapFormValuesToComponentsAvailability(values),
    rank: Number(values.rank),
    box: values.box,
  };
};

const findLabelId = (category: Category, lang: Language): number =>
  category.labelTranslations.find(
    (translation) => translation.langIso === lang
  )!.id;

export const mapFormValuesToCategoryPatchRequest = (
  values: FormValues,
  category: Category
): CategoryPatchRequest => {
  const labelTranslations: TranslationPatchRequest[] = [];

  labelTranslations.push(
    {
      id: findLabelId(category, "pl"),
      langIso: "pl",
      value: values.polishName,
    },
    {
      id: findLabelId(category, "en"),
      langIso: "en",
      value: values.englishName,
    },
    {
      id: findLabelId(category, "de"),
      langIso: "de",
      value: values.germanName,
    },
    {
      id: findLabelId(category, "sv"),
      langIso: "sv",
      value: values.swedenName,
    }
  );

  return {
    id: category.id,
    active: values.active,
    labelTranslations,
    componentsAvailability: {
      id: category.componentsAvailability.id,
      ...mapFormValuesToComponentsAvailability(values),
    },
    rank: Number(values.rank),
    box: values.box,
  };
};

const findNameTranslation = (category: Category, lang: Language) =>
  category.labelTranslations.find(
    (translation) => translation.langIso === lang
  )!.value;

export const mapDoorEnumsToDoors = (doorEnums: DoorEnum[]): Door[] =>
  doorEnums.map((doorEnum) => ({ id: doorEnum }));

export const mapClapEnumsToClaps = (clapEnums: ClapEnum[]): Clap[] =>
  clapEnums.map((clapEnum) => ({ id: clapEnum }));

const mapCategoryToFormValues = (category: Category): FormValues => ({
  active: category.active,
  polishName: findNameTranslation(category, "pl"),
  germanName: findNameTranslation(category, "de"),
  englishName: findNameTranslation(category, "en"),
  swedenName: findNameTranslation(category, "sv"),
  doors: mapDoorEnumsToDoors(category.componentsAvailability.doors),
  claps: mapClapEnumsToClaps(category.componentsAvailability.claps),
  fillings: category.componentsAvailability.fillings,
  colors: category.componentsAvailability.colors,
  brakes: category.componentsAvailability.brakes,
  fillingsInherited: category.componentsAvailability.fillingsInherited,
  doorsInherited: category.componentsAvailability.doorsInherited,
  colorsInherited: category.componentsAvailability.colorsInherited,
  brakesInherited: category.componentsAvailability.brakesInherited,
  clapsInherited: category.componentsAvailability.clapsInherited,
  rank: category.rank.toString(),
  box: category.box,
});

const getDefaultFormValues = (): FormValues => ({
  active: true,
  englishName: "",
  germanName: "",
  polishName: "",
  swedenName: "",
  brakes: [],
  colors: [],
  fillings: [],
  doors: [],
  claps: [],
  brakesInherited: true,
  colorsInherited: true,
  doorsInherited: true,
  fillingsInherited: true,
  clapsInherited: true,
  rank: "",
  box: false,
});

export const getInitFormValues = (category: Category | null): FormValues =>
  category ? mapCategoryToFormValues(category) : getDefaultFormValues();
