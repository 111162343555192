import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuActions, {
  Action,
} from "../../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../../constants/navigation-constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Columns } from "../../../../components/pagination-table/table-types";
import PaginationTable from "../../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../../../components/confirmation-dialog/confirmation-dialog";
import { Typography } from "@mui/material";
import Section from "../../../../components/section/section";
import {
  editFilling,
  getFillings,
} from "../../../../redux/actions/filling-actions";
import { Filling } from "../../../../types/filling-types";
import FillingFilter from "./filling-filter/filling-filter";

const tableStyles = {
  minWidth: 500,
  maxWidth: 900,
  margin: "0 auto",
};

interface FillingListProps extends WithPageLoaderProps {}

const FillingList = ({ finishLoading }: FillingListProps) => {
  const reducer = useSelector((state: Store) => state.filling);
  const { loading, size, sort, filters } = reducer;
  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const isActive = filters.active;

  const init = async () => {
    await Promise.all([
      dispatch(
        getFillings({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      await dispatch(editFilling({ id: pickedId, active: !isActive }));
      dispatch(
        getFillings({ params: { size, page: 0, sort, filters }, reload: true })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const getFillingName = () =>
    reducer.content?.find((filling) => filling.id === pickedId)?.label;

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.FillingEdit}/${id}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderActionsCell = (filling: Filling) => (
    <MenuActions
      key={`${filling.id}-actions-cell`}
      actions={actions}
      entityId={filling.id}
    />
  );

  const columns: Columns<Filling> = [
    { id: "label", accessor: "label", flexGrow: 10, name: t("nazwa") },
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const table = (
    <PaginationTable<Filling>
      withPagination={true}
      loading={loading}
      fetcherAction={getFillings}
      reducerName="filling"
      columns={columns}
    />
  );

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("__dezaktywacja_wypelnienia_pytanie")
            : t("__aktywacja_wypelnienia_pytanie")}{" "}
          <strong>({getFillingName()})</strong>
        </Typography>
      </ConfirmationDialog>
      <Section
        title={t("wypelnienia")}
        actions={<FillingFilter />}
        sx={tableStyles}
      >
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(FillingList);
