import React, { Fragment } from "react";
import Section from "../../../../components/section/section";
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "../../../partners-page/partner-edit/partner-edit.styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { VariantPrice } from "../../../../types/price-types";
import Divider from "@mui/material/Divider";
import { getInputAdornment } from "../../../../utils/common-utils";
import NumericInput from "../../../../components/numeric-input/numeric-input";

export type VariantPriceRow = Omit<VariantPrice, "price"> & {
  label: string;
  error?: boolean;
  helperText?: string | false;
  price: string;
};

interface VariantPricesProps {
  variantPrices: VariantPriceRow[];
  setVariantPrices: (prices: VariantPriceRow[]) => void;
}

function isNumeric(str: any) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

const VariantPrices = ({
  variantPrices,
  setVariantPrices,
}: VariantPricesProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);

  const updateRow = (updatedRow: VariantPriceRow, index: number) => {
    const updatedRows = [...variantPrices];
    updatedRows[index] = updatedRow;
    setVariantPrices(updatedRows);
  };

  const handlePriceChange = (id: number, price: string) => {
    const index = variantPrices.findIndex((row) => row.fillingId === id);
    if (index >= 0) {
      const updatedRow = {
        ...variantPrices[index],
        price,
        error: !isNumeric(price),
      };
      updateRow(updatedRow, index);
    }
  };

  const handleStatusChange = (id: number, status: boolean) => {
    const index = variantPrices.findIndex((row) => row.fillingId === id);
    if (index >= 0) {
      const updatedRow = {
        ...variantPrices[index],
        active: status,
      };
      updateRow(updatedRow, index);
    }
  };

  const renderPriceRow = (row: VariantPriceRow, index: number) => (
    <Fragment key={row.fillingId}>
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <TextField
            fullWidth
            label={t("__wypelnienie")}
            size="small"
            value={row.label}
            disabled
          />
        </Box>
        <Box sx={styles.field}>
          <FormControl fullWidth>
            <InputLabel>{t("status")}</InputLabel>
            <Select
              id="active"
              name="active"
              size="small"
              value={row.active.toString()}
              label={t("status")}
              onChange={(event) => {
                const value = event.target.value === "true";
                handleStatusChange(row.fillingId, value);
              }}
            >
              <MenuItem value="true">{t("__aktywna")}</MenuItem>
              <MenuItem value="false">{t("__dezaktywowana")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            id="price"
            variant="outlined"
            name="price"
            fullWidth
            label={t("__cena")}
            size="small"
            value={row.price}
            onChange={(e) => handlePriceChange(row.fillingId, e.target.value)}
            error={row.error}
            helperText={row.error && row.helperText}
            InputProps={getInputAdornment("€")}
            isMoney
          />
        </Box>
        <Box sx={styles.field} />
      </Box>
      {index !== variantPrices.length - 1 && <Divider />}
    </Fragment>
  );

  return (
    <Section title={t("__ceny_wariantu")}>
      {variantPrices.length > 0 ? (
        <Box sx={styles.container}>{variantPrices.map(renderPriceRow)}</Box>
      ) : (
        <Typography>{t("__brak_dostepnych_wypelnien")}</Typography>
      )}
    </Section>
  );
};

export default VariantPrices;
