import { Category } from "../../../../../types/category-types";
import { Cover } from "../../../../../types/cover-types";
import { Variant } from "../../../../../types/variant-types";
import {
  InheritedCollection,
  InheritedField,
  InheritedType,
} from "./model-config.types";
import { TFunction } from "i18next";
import { WithId } from "../../../../../types/common-types";
import { Typography } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import { Validation } from "../../../../../redux/slices/order-slice";
import InputAdornment from "@mui/material/InputAdornment";

export const prepareEntities = (
  category: Category,
  model: Cover,
  variant: Variant,
  inheritedField: InheritedField,
  inheritedCollection: InheritedCollection,
  allCollection: InheritedType[] | null
) => {
  let preparedEntities: InheritedType[];

  if (variant.componentsAvailability[inheritedField]) {
    if (model.componentsAvailability[inheritedField]) {
      if (category.componentsAvailability[inheritedField]) {
        preparedEntities = allCollection ? [...allCollection] : [];
      } else {
        preparedEntities = category.componentsAvailability[
          inheritedCollection
        ].filter((entity) => entity.active);
      }
    } else {
      preparedEntities = model.componentsAvailability[
        inheritedCollection
      ].filter((entity) => entity.active);
    }
  } else {
    preparedEntities = variant.componentsAvailability[
      inheritedCollection
    ].filter((entity) => entity.active);
  }
  return preparedEntities;
};

export const getAutocompleteCommonProps = (
  variant: Variant | null,
  t: TFunction,
  disableAll: boolean
) => ({
  size: "small" as const,
  autoHighlight: true,
  fullWidth: true,
  noOptionsText: t("__brak_opcji"),
  disabled: !variant || disableAll,
  disableClearable: true,
  getOptionLabel: <T extends { label: string }>(option: T) => option.label,
  isOptionEqualToValue: <T extends WithId>(option: T, value: T) =>
    option.id === value.id,
  renderOption: <T extends { id: number; label: string }>(
    props: any,
    option: T
  ) => (
    <li {...props} key={option.id}>
      <Typography>{option.label}</Typography>
    </li>
  ),
});

export const getCommonTextInputProps = (
  disableAll: boolean,
  variant: Variant | null
) => ({
  required: true,
  fullWidth: true,
  size: "small" as const,
  variant: "outlined" as const,
  disabled: !variant || disableAll,
});

export const getRenderInput =
  (
    label: string,
    validation: Validation | null,
    fieldKey: string,
    required = true,
    adornment?: string
  ) =>
  (params: any) =>
    (
      <TextField
        {...params}
        label={label}
        required={required}
        error={validation && Boolean(validation[fieldKey])}
        helperText={validation && validation[fieldKey]}
        inputProps={{
          ...params.inputProps,
          autoComplete: "off",
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              <InputAdornment
                position="start"
                sx={{ marginRight: (theme) => theme.spacing(4) }}
              >
                {adornment}
              </InputAdornment>
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
