import { Country } from "../../types/country-types";
import { Loading } from "../../types/common-types";
import { createSlice } from "@reduxjs/toolkit";
import { getCountries } from "../actions/country-actions";

export interface CountryState {
  countries: Country[] | null;
  loading: Loading;
}

const initialState: CountryState = {
  countries: null,
  loading: "idle",
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.countries = action.payload;
      }
      state.loading = "succeeded";
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default countrySlice.reducer;
