import { Typography } from "@mui/material";
import React from "react";
import { Validation } from "../../../../../redux/slices/order-slice";
import InputAdornment from "@mui/material/InputAdornment";
import { formatInteger } from "../../../../../utils/formatter-utils";
import NumericInput from "../../../../../components/numeric-input/numeric-input";

export const AUTOCOMPLETE_COMMON_PROPS = {
  size: "small" as const,
  autoHighlight: true,
  required: true,
  fullWidth: true,
  disableClearable: true,
  getOptionLabel: (option: number) => option.toString(),
  renderOption: (props: any, option: number) => (
    <li {...props} key={option}>
      <Typography>{formatInteger(option)}</Typography>
    </li>
  ),
};

export const getRenderInput =
  (
    label: string,
    validation: Validation | null,
    value: number | null,
    required = true,
    adornment?: string
  ) =>
  (params: any) =>
    (
      <NumericInput
        {...params}
        label={label}
        required={required}
        error={validation && value === null && Boolean(validation.variant)}
        helperText={validation && value === null && validation.variant}
        inputProps={{
          ...params.inputProps,
          autoComplete: "off",
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              <InputAdornment
                position="start"
                sx={{ marginRight: (theme) => theme.spacing(4) }}
              >
                {adornment}
              </InputAdornment>
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
