import { TFunction } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "./profile-edit.types";
import {
  Profile,
  ProfilePatchRequest,
  ProfilePostRequest,
} from "../../../../types/profile-types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  return yup.object({
    name: yup.string().required(requiredFieldError),
    active: yup.boolean(),
    price: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
  });
};

export const mapFormValuesToProfilePostRequest = (
  values: FormValues
): ProfilePostRequest => ({
  label: values.name,
  railExtensionPrice: { value: Number(values.price) },
});

export const mapFormValuesToProfilePatchRequest = (
  values: FormValues,
  profile: Profile
): ProfilePatchRequest => ({
  id: profile.id,
  active: values.active,
  label: values.name,
  railExtensionPrice: { value: Number(values.price) },
});

const mapProfileToFormValues = (profile: Profile): FormValues => ({
  active: profile.active,
  name: profile.label,
  price: profile.railExtensionPrice.value.toString(),
});

const getDefaultFormValues = (): FormValues => ({
  active: true,
  name: "",
  price: "",
});

export const getInitFormValues = (profile: Profile | null): FormValues =>
  profile ? mapProfileToFormValues(profile) : getDefaultFormValues();
