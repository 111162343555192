import { Loading, Pageable } from "../../types/common-types";
import { Variant } from "../../types/variant-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addVariant,
  editVariant,
  getAllVariants,
  getVariants,
} from "../actions/variant-actions";
import { updateStateWithPageable } from "../redux-utils";

export interface VariantState extends Pageable<Variant> {
  loading: Loading;
  allVariants: Variant[] | null;
}

export const DEFAULT_VARIANT_FILTERS = {
  active: true,
};

export const DEFAULT_VARIANT_SORT = [
  "modulesCount",
  "length",
  "widthInt",
  "widthExt",
  "height",
];

const initialState: VariantState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_VARIANT_SORT,
  filters: DEFAULT_VARIANT_FILTERS,
  allVariants: null,
};

const variantSlice = createSlice({
  name: "variant",
  initialState,
  reducers: {
    setAllVariants(state, action: PayloadAction<Variant[] | null>) {
      state.allVariants = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVariants.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getVariants.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getVariants.rejected, (state) => {
      state.loading = "failed";
    });

    builder.addCase(addVariant.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addVariant.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addVariant.rejected, (state) => {
      state.loading = "failed";
    });

    builder.addCase(editVariant.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editVariant.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editVariant.rejected, (state) => {
      state.loading = "failed";
    });

    builder.addCase(getAllVariants.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllVariants.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allVariants = action.payload;
    });
    builder.addCase(getAllVariants.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default variantSlice.reducer;
export const { setAllVariants } = variantSlice.actions;
