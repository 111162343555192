import React from "react";
import {InputAttributes, NumericFormat} from "react-number-format";
import TextField, {OutlinedTextFieldProps} from "@mui/material/TextField";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<typeof NumericFormat<InputAttributes>,
    CustomProps>(function NumberFormatCustom(props, ref) {
    const {onChange, ...other} = props;


    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={({value}) => onChange({
                target: {
                    name: props.name,
                    value,
                },
            })}
            thousandSeparator=" "
            decimalSeparator=","
            valueIsNumericString={true}
            allowLeadingZeros={false}
            allowNegative={false}
        />
    );
});

const MoneyFormatCustom = React.forwardRef<typeof NumericFormat<InputAttributes>,
    CustomProps>(function NumberFormatCustom(props, ref) {
    const {onChange, ...other} = props;


    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={({value}) => onChange({
                target: {
                    name: props.name,
                    value,
                },
            })}
            thousandSeparator=" "
            decimalSeparator=","
            valueIsNumericString={true}
            allowLeadingZeros={false}
            allowNegative={false}
            fixedDecimalScale
            decimalScale={2}
        />
    );
});

type NumericInput = { isMoney?: boolean } & OutlinedTextFieldProps

const NumericInput = ({isMoney, ...props}: NumericInput) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: isMoney ? MoneyFormatCustom as any : NumberFormatCustom as any,
                ...props.InputProps,
            }}
        />
    );
}

export default NumericInput;