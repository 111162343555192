import { Variant } from "../../types/variant-types";
import { MetaData } from "../../pages/orders-page/order-edit/order-form/technical-projection/technical-projection.types";
import { Theme } from "@mui/material";
import {
  drawDoorWithArrow,
  drawLengths,
  drawModules,
  drawModulesLengths,
  drawRails,
  drawRailWidths,
  drawWidths,
} from "./standard-technical-projection-drawer";
import {
  drawDoorWithArrow as drawDoorWithArrowTopRail,
  drawLengths as drawLengthsTopRail,
  drawModules as drawModulesTopRail,
  drawModulesLengths as drawModulesLengthsTopRail,
  drawRails as drawRailsTopRail,
  drawRailWidths as drawRailWidthsTopRail,
  drawWidths as drawWidthsTopRail,
  drawWheels as drawWheelsTopRail,
} from "./top-rail-technical-projection-drawer";
import {
  drawDoorWithArrow as drawDoorWithArrowBottomRail,
  drawLengths as drawLengthsBottomRail,
  drawModules as drawModulesBottomRail,
  drawModulesLengths as drawModulesLengthsBottomRail,
  drawRails as drawRailsBottomRail,
  drawRailWidths as drawRailWidthsBottomRail,
  drawWidths as drawWidthsBottomRail,
  drawWheels as drawWheelsBottomRail,
} from "./bottom-rail-technical-projection-drawer";
import { DoorEnum } from "../../constants/door-constants";

export const drawStandardTechnicalProjection = (
  ctx: CanvasRenderingContext2D,
  variant: Variant,
  metaData: MetaData,
  theme: Theme,
  doorType: DoorEnum[]
) => {
  drawRails(ctx, metaData, theme);
  drawModules(ctx, metaData);
  drawDoorWithArrow(ctx, metaData, doorType);
  drawLengths(ctx, metaData, theme);
  drawModulesLengths(ctx, metaData, theme);
  drawWidths(ctx, metaData, theme);
  drawRailWidths(ctx, metaData, theme);
};

export const drawTopRailTechnicalProjection = (
  ctx: CanvasRenderingContext2D,
  variant: Variant,
  metaData: MetaData,
  theme: Theme,
  doorType: DoorEnum[]
) => {
  drawModulesLengthsTopRail(ctx, metaData, theme);
  drawRailsTopRail(ctx, metaData, theme);
  drawDoorWithArrowTopRail(ctx, metaData, doorType);
  drawLengthsTopRail(ctx, metaData, theme);
  drawWidthsTopRail(ctx, metaData, theme);
  drawRailWidthsTopRail(ctx, metaData, theme);
  drawWheelsTopRail(ctx, metaData);
  drawModulesTopRail(ctx, metaData);
};

export const drawBottomRailTechnicalProjection = (
  ctx: CanvasRenderingContext2D,
  variant: Variant,
  metaData: MetaData,
  theme: Theme,
  doorType: DoorEnum[]
) => {
  drawRailsBottomRail(ctx, metaData, theme);
  drawDoorWithArrowBottomRail(ctx, metaData, doorType);
  drawLengthsBottomRail(ctx, metaData, theme);
  drawModulesLengthsBottomRail(ctx, metaData, theme);
  drawWidthsBottomRail(ctx, metaData, theme);
  drawRailWidthsBottomRail(ctx, metaData, theme);
  drawModulesBottomRail(ctx, metaData);
  drawWheelsBottomRail(ctx, metaData);
};
