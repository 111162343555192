import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { WithId } from "../types/common-types";

export interface FetchEntityArgs<T extends WithId> {
  fallbackRoute: string;
  callback: (entity: T) => void;
  fetcherAction: (id: string) => AsyncThunkAction<T | null, string, {}>;
  collection: T[] | null;
  id?: string;
}

const useFetchEntity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const fetchEntity = async <T extends WithId>(args: FetchEntityArgs<T>) => {
    const { id, fallbackRoute, callback, fetcherAction, collection } = args;

    if (id !== undefined) {
      const entityFromStore = collection?.find(
        (entity) => entity.id.toString() === id
      );
      if (!entityFromStore) {
        const { payload } = await dispatch(fetcherAction(id));
        if (payload) {
          callback(payload as T);
          return payload as T;
        } else {
          navigate(fallbackRoute);
        }
      } else {
        callback(entityFromStore);
        return entityFromStore;
      }
    }
  };

  return { fetchEntity };
};

export default useFetchEntity;
