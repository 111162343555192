import { Loading, Pageable } from "../../types/common-types";
import { Color } from "../../types/color-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { updateStateWithPageable } from "../redux-utils";
import {
  addColor,
  editColor,
  getAllColors,
  getColors,
} from "../actions/color-actions";

export interface ColorState extends Pageable<Color> {
  loading: Loading;
  allColors: Color[] | null;
}

export const DEFAULT_COLOR_FILTERS = {
  active: true,
};

export const DEFAULT_COLOR_SORT = ["label", "id"];

const initialState: ColorState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_COLOR_SORT,
  filters: DEFAULT_COLOR_FILTERS,
  allColors: null,
};

const colorSlice = createSlice({
  name: "color",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getColors.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getColors.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getColors.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addColor.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addColor.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addColor.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editColor.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editColor.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editColor.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getAllColors.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllColors.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allColors = action.payload;
    });
    builder.addCase(getAllColors.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default colorSlice.reducer;
