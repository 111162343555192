import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import equal from "fast-deep-equal";

export interface ChangesState {
  areUnsavedChanges: boolean;
  originalData: object | null;
}

const initialState: ChangesState = {
  areUnsavedChanges: false,
  originalData: null,
};

const changesSlice = createSlice({
  name: "changes",
  initialState,
  reducers: {
    setOriginalData(state, action: PayloadAction<{ originalData: object }>) {
      state.originalData = action.payload.originalData;
      state.areUnsavedChanges = false;
    },
    applyChanges(
      state,
      action: PayloadAction<{
        data: object;
      }>
    ) {
      if (state.originalData === null) return;
      Object.keys(action.payload.data).forEach((key) => {
        // @ts-ignore
        if (!equal(state.originalData[key], action.payload.data[key])) {
          console.log("============");
          console.log(key);
          console.log(
            "state.originalData[key]",
            // @ts-ignore
            current(state.originalData)[key]
          );
          // @ts-ignore
          console.log("action.payload.data[key]", action.payload.data[key]);
        }
      });
      state.areUnsavedChanges = !equal(state.originalData, action.payload.data);
    },
    resetChanges(state) {
      state.areUnsavedChanges = false;
      state.originalData = null;
    },
  },
});

export default changesSlice.reducer;
export const { applyChanges, setOriginalData, resetChanges } =
  changesSlice.actions;
