import { Theme } from "@mui/material";
import { Colors } from "../../theme/colors";
import { toRem } from "../../utils/common-utils";

export const getStyles = (
  theme: Theme,
  isLoading: boolean,
  withPagination: boolean,
  paginationBackground?: boolean
) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    "& .MuiTypography-root": {
      fontSize: "0.875rem",
    },
  },
  content: {
    position: isLoading ? "relative" : "static",
    minHeight: withPagination ? 200 : "auto",
  },
  loader: {
    position: "absolute",
    inset: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: paginationBackground
      ? Colors.Transparent
      : Colors.LoaderBackground,
  },
  noDataMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(10),
    color: theme.palette.grey["600"],
  },
  noDataMessageWrapper: {
    padding: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: toRem(14),
    },
  },
  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    width: "fit-content",
    alignSelf: "end",
  },
});
