export enum Route {
  Layout = "/",
  Config = "/config",
  Covers = "/covers",
  CoverEdit = "/covers/edit",
  VariantEdit = "/covers/edit/variants/edit",
  PostManagement = "/post_management",
  PostEdit = "/post_management/edit",
  PostOverview = "/post_overview",
  Orders = "/orders",
  OrderEdit = "/orders/edit",
  Partners = "/partners",
  PartnerEdit = "/partners/edit",
  Prices = "/prices",
  PriceItemEdit = "/prices/edit",
  ModuleDependantPriceItemEdit = "/prices/edit/md",
  Users = "/users",
  UsersEdit = "/users/edit",
  Login = "/login",
  Colors = "/colors",
  ColorEdit = "/colors/edit",
  Brakes = "/brakes",
  BrakeEdit = "/brakes/edit",
  Fillings = "/fillings",
  FillingEdit = "/fillings/edit",
  Categories = "/categories",
  CategoryEdit = "/categories/edit",
  Profiles = "/profiles",
  ProfileEdit = "/profiles/edit",
}
