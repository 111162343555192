import { useEffect, useRef, useState } from "react";
import SockJS from "sockjs-client";
import { CompatClient, Stomp } from "@stomp/stompjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../redux/store";
import {
  addNotificationFromWebsocket,
  handleEvent,
  resetEvents,
} from "../redux/slices/notification-slice";
import useRole from "./useRole";
import { isAdminRole } from "../utils/common-utils";
import { useLocation } from "react-router-dom";
import { Route } from "../constants/navigation-constants";
import { Event } from "../types/notification-types";
import useUpdateEffect from "./useUpdateEffect";
import { Order } from "../types/order-types";

const useWebSocket = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);
  const location = useLocation();
  const eventSubRef = useRef<string>("");
  const contentRef = useRef<Order[] | null>(null);

  const { content } = useSelector((state: Store) => state.order);
  contentRef.current = content;

  const [globalStomp, setGlobalStomp] = useState<CompatClient | null>();

  useEffect(() => {
    const stomp = initializeConnection();
    setGlobalStomp(stomp);

    return () => {
      stomp.disconnect();
    };
  }, []);

  useUpdateEffect(() => {
    subscribeEvents();
  }, [location.pathname, globalStomp]);

  const subscribeEvents = () => {
    if (globalStomp && globalStomp.connected) {
      if (location.pathname === Route.Orders) {
        const { id } = globalStomp.subscribe(
          "/user/queue/event",
          (response) => {
            handleEventMessage(response.body);
          }
        );
        eventSubRef.current = id;
      } else {
        globalStomp.unsubscribe(eventSubRef.current);
        dispatch(resetEvents());
      }
    }
  };

  const handleEventMessage = (message: string) => {
    const event: Event = JSON.parse(message);
    // if (
    //   event.name === "OFFER_UPDATE" &&
    //   !contentRef.current?.find((x) => x.id === event.targetId)
    // ) {
    //   return;
    // }
    dispatch(handleEvent(event));
  };

  const getSocketUrl = () => {
    const base =
      process.env.REACT_APP_WEBSOCKET_URL ||
      `${window.location.protocol}//${window.location.hostname}`;
    const prefix = process.env.REACT_APP_API_PREFIX
      ? `${process.env.REACT_APP_API_PREFIX}/`
      : "";
    return `${base}${prefix}/websocket/register`;
  };

  const initializeConnection = () => {
    const socket = new SockJS(getSocketUrl());
    const stomp = Stomp.over(socket);

    stomp.connect({}, () => {
      if (!isAdmin) {
        stomp.subscribe("/user/queue/notification", (response) => {
          dispatch(addNotificationFromWebsocket(JSON.parse(response.body)));
        });
      }
      const { id } = stomp.subscribe("/user/queue/event", (response) => {
        handleEventMessage(response.body);
      });
      eventSubRef.current = id;
    });

    return stomp;
  };
};

export default useWebSocket;
