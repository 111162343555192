import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Role, Auth } from "../../types/auth-types";

interface LoginParams {
  email: string;
  password: string;
}

interface ChangePasswordParams {
  password: string;
}

export const getRoles = createAsyncThunk("auth/getRoles", async () => {
  const { data } = await axios.get<Role[]>("/role");
  return data;
});

export const whoAmI = createAsyncThunk(
  "auth/autoLogin",
  async (_params, thunkApi) => {
    const { data: user } = await axios.get<Auth>("/user/whoami");
    await thunkApi.dispatch(getRoles());
    return user;
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (params: LoginParams, thunkApi) => {
    const bodyFormData = new FormData();
    bodyFormData.append("username", params.email);
    bodyFormData.append("password", params.password);

    await axios({
      method: "POST",
      url: "login",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    thunkApi.dispatch(whoAmI());
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (params: ChangePasswordParams) => {
    await axios.put("/user/change-password", {
      ...params,
    });
  }
);

export const acceptPolicy = createAsyncThunk("auth/acceptPolicy", async () => {
  await axios.post("/user/agree-terms");
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await axios.post("/logout");
});
