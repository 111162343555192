import React, { useState } from "react";
import Section from "../../../../../components/section/section";
import { useTranslation } from "react-i18next";
import { Box, Typography, useTheme } from "@mui/material";
import { getStyles } from "./transport-info.styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import {
  setDistance,
  setTerm,
  setWithMontage,
  setWithTransport,
  setBox,
} from "../../../../../redux/slices/order-slice";
import TextField from "@mui/material/TextField";
import {
  DELIVERY_OPTIONS,
  getAutocompleteCommonProps,
  getRenderInput,
  getValue,
  PICK_UP,
  WITH_MONTAGE,
  WITH_TRANSPORT,
} from "./transport-info.utils";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import useDisableAll from "../../../../../hooks/useDisableAll";
import { formatPrice } from "../../../../../utils/formatter-utils";
import {
  getInputAdornment,
  isAdminRole,
} from "../../../../../utils/common-utils";
import NumericInput from "../../../../../components/numeric-input/numeric-input";
import TermPicker from "../term-picker/term-picker";
import useRole from "../../../../../hooks/useRole";
import { OrderStatus } from "../../../../../types/order-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "../../../../../components/info-icon/info-icon";

const TransportInfo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);

  const {
    currentOrder,
    validation,
    calculateValidation,
    availablePrices,
    rollback,
  } = useSelector((state: Store) => state.order);
  const {
    withTransport,
    withMontage,
    variant,
    model,
    distance,
    id,
    category,
    box,
  } = currentOrder;
  const { priceItems, moduleDependantPriceItems } = useSelector(
    (state: Store) => state.price
  );

  const [isTermPickerOpen, setTermPickerOpen] = useState(false);

  useUpdateEffect(() => {
    if (!rollback) {
      dispatch(setWithTransport(null));
      dispatch(setWithMontage(null));
      dispatch(setDistance(""));
    }
  }, [model?.id, variant?.id]);

  useUpdateEffect(() => {
    if (!withTransport && !withMontage) {
      if (id === null) {
        dispatch(setDistance(""));
      } else {
        dispatch(setDistance("0"));
      }
    }
  }, [withTransport, withMontage]);

  const handleChange = (value: string | null) => {
    switch (value) {
      case PICK_UP: {
        dispatch(setWithTransport(false));
        dispatch(setWithMontage(false));
        break;
      }
      case WITH_TRANSPORT: {
        dispatch(setWithTransport(true));
        dispatch(setWithMontage(false));
        break;
      }
      case WITH_MONTAGE: {
        dispatch(setWithTransport(true));
        dispatch(setWithMontage(true));
        dispatch(setBox(true));
        break;
      }
      default: {
      }
    }
  };

  const getOptionLabel = (option: string) => {
    if (option === WITH_MONTAGE && variant) {
      const price =
        availablePrices?.montage[variant.modulesCount] ||
        moduleDependantPriceItems?.find(
          (x) => x.moduleCount === variant?.modulesCount
        )?.montagePrice.value ||
        0;

      return `${t(option)} (${t("__koszt_montazu")}: ${formatPrice(price)} €)`;
    }
    return t(option);
  };

  const AUTOCOMPLETE_COMMON_PROPS = getAutocompleteCommonProps(
    variant,
    t,
    getOptionLabel,
    disableAll
  );

  const getDistanceLabel = () => {
    if (withTransport && withMontage) {
      const price =
        availablePrices?.transportWithMontage ||
        priceItems?.find((x) => x.name === "TRANSPORT_WITH_MONTAGE")?.price
          .value ||
        0;

      return `${t("__odleglosc")} (${formatPrice(price)} €  / km)`;
    }

    if (withTransport && !withMontage) {
      const price =
        availablePrices?.transport ||
        priceItems?.find((x) => x.name === "TRANSPORT_WITHOUT_MONTAGE")?.price
          .value ||
        0;

      return `${t("__odleglosc")} (${formatPrice(price)} €  / km)`;
    }

    return t("__odleglosc");
  };

  const getBoxLabel = () => {
    const price =
      priceItems?.find((x) => x.name === "BOX_MONTAGE")?.price.value || 0;

    return `${t("__zlozenie")} (${formatPrice(price)} €)`;
  };

  const onTermClick = () => {
    if (isAdmin) {
      setTermPickerOpen(true);
    }
  };

  const boxInfoIconContent = (
    <Box sx={styles.infoIcon}>
      <Typography>{t("__zlozenie_tooltip")}</Typography>
    </Box>
  );

  const priceInfoIconContent = (
    <Box sx={styles.infoIcon}>
      <Typography>{t("__cena_nie_uwzglednia")}</Typography>
    </Box>
  );

  return (
    <Section title={t("__montaz_i_transport")}>
      <TermPicker
        open={isTermPickerOpen}
        onClose={() => {
          setTermPickerOpen(false);
        }}
        term={currentOrder.term}
        onChange={(newTerm) => {
          dispatch(setTerm(newTerm));
        }}
      />
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <Autocomplete
            {...AUTOCOMPLETE_COMMON_PROPS}
            options={DELIVERY_OPTIONS}
            value={getValue(withMontage, withTransport)}
            onChange={(event, value) => handleChange(value)}
            renderInput={getRenderInput(
              t("__dostawa"),
              validation,
              "withTransport"
            )}
          />
        </Box>
        <Box sx={styles.fieldWithInfoIcon}>
          <NumericInput
            label={getDistanceLabel()}
            value={distance}
            disabled={
              (!withMontage && !withTransport) || !variant || disableAll
            }
            required={Boolean(withTransport)}
            onChange={(e) => dispatch(setDistance(e.target.value))}
            fullWidth={true}
            size="small"
            variant="outlined"
            error={
              (!!validation && !!validation.distance) ||
              !!calculateValidation.distance
            }
            helperText={
              (!!validation && validation.distance) ||
              calculateValidation.distance
            }
            InputProps={getInputAdornment("km")}
          />
          <InfoIcon
            content={priceInfoIconContent}
            buttonStyles={styles.infoIconButton}
          />
        </Box>
        {category?.box && (
          <Box sx={{ ...styles.field, alignSelf: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={box}
                  onChange={(e) => dispatch(setBox(e.target.checked))}
                  disabled={!variant || disableAll || !!withMontage}
                />
              }
              label={getBoxLabel()}
            />
            <InfoIcon content={boxInfoIconContent} />
          </Box>
        )}
        {(currentOrder.term ||
          (isAdmin && currentOrder.status !== OrderStatus.OFFER)) && (
          <Box sx={styles.field}>
            <TextField
              label={t("__termin_dostawy")}
              value={currentOrder.term?.toLocaleDateString() || ""}
              disabled={disableAll}
              required
              fullWidth
              onClick={onTermClick}
              size="small"
              variant="outlined"
              error={!!validation && !!validation.term}
              helperText={!!validation && validation.term}
            />
          </Box>
        )}
      </Box>
    </Section>
  );
};

export default TransportInfo;
