import { TFunction } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "./md-price-item-edit.types";
import {
  ModuleDependantPriceItem,
  ModuleDependantPriceItemPatchRequest,
  ModuleDependantPriceItemPostRequest,
} from "../../../types/price-types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  return yup.object({
    moduleCount: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(1, invalidValueError),
    brakePrice: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
    montagePrice: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
  });
};

export const mapFormValuesToModuleDependantPriceItemPostRequest = (
  values: FormValues
): ModuleDependantPriceItemPostRequest => ({
  montagePrice: { value: values.montagePrice },
  brakePrice: { value: values.brakePrice },
  moduleCount: values.moduleCount,
});

export const mapFormValuesToModuleDependantPriceItemPatchRequest = (
  values: FormValues
): ModuleDependantPriceItemPatchRequest => ({
  montagePrice: { value: values.montagePrice },
  brakePrice: { value: values.brakePrice },
  moduleCount: values.moduleCount,
});

const mapModuleDependantPriceItemToFormValues = (
  priceItem: ModuleDependantPriceItem
): FormValues => ({
  moduleCount: priceItem.moduleCount,
  brakePrice: priceItem.brakePrice.value,
  montagePrice: priceItem.montagePrice.value,
});

const getDefaultFromValues = (): FormValues => ({
  brakePrice: 0,
  moduleCount: 0,
  montagePrice: 0,
});

export const getInitFormValues = (priceItem: ModuleDependantPriceItem | null) =>
  priceItem
    ? mapModuleDependantPriceItemToFormValues(priceItem)
    : getDefaultFromValues();
