import React, { useEffect, useState } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { editCover, getCovers } from "../../../redux/actions/cover-actions";
import MenuActions, {
  Action,
} from "../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import { Route } from "../../../constants/navigation-constants";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { Cover } from "../../../types/cover-types";
import { Columns } from "../../../components/pagination-table/table-types";
import PaginationTable from "../../../components/pagination-table/pagination-table";
import Box from "@mui/material/Box";
import ConfirmationDialog from "../../../components/confirmation-dialog/confirmation-dialog";
import { Typography } from "@mui/material";
import Section from "../../../components/section/section";
import CoverFilter from "./cover-filter/cover-filter";
import { ELLIPSIS_STYLES } from "../../../components/pagination-table/table-row/table-row";
import { getAllCategories } from "../../../redux/actions/category-actions";
import { getAllProfiles } from "../../../redux/actions/profile-actions";

const tableStyles = {
  minWidth: 770,
  maxWidth: 1200,
  margin: "0 auto",
};

interface CoversListProps extends WithPageLoaderProps {}

const CoversList = ({ finishLoading }: CoversListProps) => {
  const reducer = useSelector((state: Store) => state.cover);
  const { loading, size, sort, filters } = reducer;
  const { allCategories } = useSelector((state: Store) => state.category);
  const { allProfiles } = useSelector((state: Store) => state.profile);
  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const isActive = filters.active;

  const init = async () => {
    await Promise.all([
      dispatch(getAllCategories({})),
      dispatch(getAllProfiles({})),
      dispatch(
        getCovers({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      await dispatch(editCover({ id: pickedId, active: !isActive }));
      dispatch(
        getCovers({ params: { size, page: 0, sort, filters }, reload: true })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.CoverEdit}/${id}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderActionsCell = (cover: Cover) => (
    <MenuActions
      key={`${cover.id}-actions-cell`}
      actions={actions}
      entityId={cover.id}
    />
  );

  const getCategoryName = (id: number) => {
    const category = allCategories?.find((category) => category.id === id);
    return category
      ? category.active
        ? category.label
        : `${category.label} (${t("dezaktywowany")})`
      : "";
  };

  const getProfileName = (id: number) => {
    const profile = allProfiles?.find((category) => category.id === id);
    return profile
      ? profile.active
        ? profile.label
        : `${profile.label} (${t("dezaktywowany")})`
      : "";
  };

  const renderCategoryCell = (cover: Cover) => (
    <Typography key={`${cover.id}-category-cell`} sx={ELLIPSIS_STYLES}>
      {getCategoryName(cover.categoryId)}
    </Typography>
  );

  const renderProfileCell = (cover: Cover) => (
    <Typography key={`${cover.id}-profile-cell`} sx={ELLIPSIS_STYLES}>
      {getProfileName(cover.profileId)}
    </Typography>
  );

  const columns: Columns<Cover> = [
    { id: "label", accessor: "label", flexGrow: 10, name: t("nazwa") },
    {
      id: "category",
      cellRenderer: renderCategoryCell,
      flexGrow: 10,
      name: t("__kategoria"),
    },
    {
      id: "profile",
      cellRenderer: renderProfileCell,
      flexGrow: 10,
      name: t("__profil"),
    },
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const table = (
    <PaginationTable<Cover>
      withPagination={true}
      loading={loading}
      fetcherAction={getCovers}
      reducerName="cover"
      columns={columns}
    />
  );

  const getCoverName = () =>
    reducer.content?.find((color) => color.id === pickedId)?.label;

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("__dezaktywacja_zadaszenia_pytanie")
            : t("__aktywacja_zadaszenia_pytanie")}{" "}
          <strong>({getCoverName()})</strong>
        </Typography>
      </ConfirmationDialog>
      <Section
        title={t("zadaszenia")}
        actions={<CoverFilter />}
        sx={tableStyles}
      >
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(CoversList);
