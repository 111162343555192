import React from "react";
import ConfirmationDialog from "../../../components/confirmation-dialog/confirmation-dialog";
import { useDispatch, useSelector } from "react-redux";
import { acceptPolicy } from "../../../redux/actions/auth-actions";
import { AppDispatch, Store } from "../../../redux/store";
import { useTranslation } from "react-i18next";

interface PolicyDialogProps {
  onClose: () => void;
  isPreview?: boolean;
}

const PolicyDialog = ({ onClose, isPreview }: PolicyDialogProps) => {
  const { loading } = useSelector((state: Store) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const onPrimary = () => {
    dispatch(acceptPolicy());
  };

  const nonPreviewProps = {
    primaryText: t("zaakceptuj"),
    onPrimary: onPrimary,
  };

  return (
    <div>
      <ConfirmationDialog
        open
        title={t("polityka_prywatnosci")}
        secondaryText={t(isPreview ? "zakmnij" : "anuluj")}
        onSecondary={onClose}
        loading={loading === "pending"}
        {...(isPreview ? {} : nonPreviewProps)}
      >
        {t("polityka_prywatnosci_tekst")}
      </ConfirmationDialog>
    </div>
  );
};

export default PolicyDialog;
