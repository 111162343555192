import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Cover } from "../../types/cover-types";
import { Theme, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export type PhotoType =
  | "defaultPhoto"
  | "doorPhoto"
  | "flapPhoto"
  | "secondaryDoorPhoto"
  | "secondaryFlapPhoto";

interface PhotoPreviewProps {
  onClick?: (model: Cover) => void;
  model: Cover;
  hideLabel?: boolean;
  photoType?: PhotoType;
  label?: string;
  blockInteractions?: boolean;
  width?: number;
}

export const getStyles = (
  theme: Theme,
  isMobile: boolean,
  blockInteractions: boolean,
  width?: number
) => ({
  card: {
    minWidth: width ? width : isMobile ? "100%" : 400,
    maxWidth: width ? width : isMobile ? "100%" : 400,
    cursor: blockInteractions ? "cursor" : "pointer",
    "&:hover #overlay": {
      opacity: 0.2,
    },
    "&:hover #preview": {
      transform: blockInteractions ? "" : "scale(1.05)",
    },
  },
  text: {
    fontSize: 16,
    textAlign: "center",
  },
  preview: {
    transition: "transform 300ms",
  },
  overlayContainer: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    inset: 0,
    backgroundColor: theme.palette.grey["700"],
    opacity: 0,
    transition: "opacity 300ms",
  },
});

const PhotoPreview = ({
  onClick,
  model,
  hideLabel,
  photoType = "defaultPhoto",
  label,
  blockInteractions = false,
  width,
}: PhotoPreviewProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const styles = getStyles(theme, isMobile, blockInteractions, width);

  return (
    <Card sx={styles.card} elevation={3} onClick={() => onClick?.(model)}>
      <Box sx={styles.overlayContainer}>
        <CardMedia
          id="preview"
          sx={styles.preview}
          component="img"
          image={`${process.env.REACT_APP_API_PREFIX}/storage/photo/${model[photoType]?.id}`}
        />
        {!blockInteractions && <Box id="overlay" sx={styles.overlay} />}
      </Box>
      {!hideLabel && (
        <CardContent>
          <Typography sx={styles.text}>{label || model.label}</Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default PhotoPreview;
