import React, { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CountrySelect from "../../../../components/country-select/country-select";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { DEFAULT_PARTNER_FILTERS } from "../../../../redux/slices/partner-slice";
import { getPartners } from "../../../../redux/actions/partner-actions";
import { PageChanges } from "../../../../types/common-types";
import { applyChangesToPage } from "../../../../redux/redux-utils";
import Filters from "../../../../components/filters/filters";
import { useNavigate } from "react-router-dom";
import { Route } from "../../../../constants/navigation-constants";
import { useTranslation } from "react-i18next";

const PartnersFilters = () => {
  const partnerReducer = useSelector((state: Store) => state.partner);
  const [name, setName] = useState(partnerReducer.filters.name);
  const [countryId, setCountryId] = useState(partnerReducer.filters.country);
  const [active, setActive] = useState(partnerReducer.filters.active);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchPage = (changes?: PageChanges) => {
    const params = applyChangesToPage(partnerReducer, changes);
    dispatch(getPartners({ params }));
  };

  const onSearch = () => {
    const updatedFilters = {
      name,
      country: countryId,
      active,
    };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onReset = () => {
    setName(DEFAULT_PARTNER_FILTERS.name);
    setCountryId(DEFAULT_PARTNER_FILTERS.country);
    setActive(DEFAULT_PARTNER_FILTERS.active);

    const updatedFilters = {
      name: DEFAULT_PARTNER_FILTERS.name,
      country: DEFAULT_PARTNER_FILTERS.country,
      active: DEFAULT_PARTNER_FILTERS.active,
    };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onNameChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setName(e.target.value);

  const onActiveChange = (event: SelectChangeEvent) => {
    const value = event.target.value === "true";
    setActive(value);
  };

  const onAddClick = () => navigate(Route.PartnerEdit);

  const filters = (
    <>
      <FormControl sx={{ width: 170 }}>
        <InputLabel>{t("status")}</InputLabel>
        <Select
          value={active?.toString()}
          label={t("status")}
          onChange={onActiveChange}
          size="small"
        >
          <MenuItem value="true">{t("aktywny")}</MenuItem>
          <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label={t("nazwa")}
        size="small"
        variant="outlined"
        value={name}
        onChange={onNameChange}
      />
      <CountrySelect
        // @ts-ignore
        countryId={countryId}
        onChange={setCountryId}
      />
    </>
  );

  return (
    <Filters
      filters={filters}
      onReset={onReset}
      onSearch={onSearch}
      onAddClick={onAddClick}
    />
  );
};

export default PartnersFilters;
