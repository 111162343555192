import { TFunction } from "react-i18next";
import * as yup from "yup";
import {
  TranslationPatchRequest,
  TranslationPostRequest,
} from "../../../../types/translation-types";
import {
  Filling,
  FillingPatchRequest,
  FillingPostRequest,
} from "../../../../types/filling-types";
import { Language } from "../../../../types/language-types";
import { FormValues } from "./filling-edit.types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");

  return yup.object({
    polishName: yup.string().required(requiredFieldError),
    englishName: yup.string().required(requiredFieldError),
    germanName: yup.string().required(requiredFieldError),
    swedenName: yup.string().required(requiredFieldError),
    active: yup.boolean(),
  });
};

export const mapFormValuesToFillingPostRequest = (
  values: FormValues
): FillingPostRequest => {
  const labelTranslations: TranslationPostRequest[] = [];

  labelTranslations.push({ langIso: "pl", value: values.polishName });
  labelTranslations.push({ langIso: "en", value: values.englishName });
  labelTranslations.push({ langIso: "de", value: values.germanName });
  labelTranslations.push({ langIso: "sv", value: values.swedenName });

  return { labelTranslations };
};

const findLabelId = (filling: Filling, lang: Language): number =>
  filling.labelTranslations.find((translation) => translation.langIso === lang)!
    .id;

export const mapFormValuesToFillingPatchRequest = (
  values: FormValues,
  filling: Filling
): FillingPatchRequest => {
  const labelTranslations: TranslationPatchRequest[] = [];

  labelTranslations.push(
    {
      id: findLabelId(filling, "pl"),
      langIso: "pl",
      value: values.polishName,
    },
    {
      id: findLabelId(filling, "en"),
      langIso: "en",
      value: values.englishName,
    },
    {
      id: findLabelId(filling, "de"),
      langIso: "de",
      value: values.germanName,
    },
    {
      id: findLabelId(filling, "sv"),
      langIso: "sv",
      value: values.swedenName,
    }
  );

  return {
    id: filling.id,
    active: values.active,
    labelTranslations,
  };
};

const findNameTranslation = (filling: Filling, lang: Language) =>
  filling.labelTranslations.find((translation) => translation.langIso === lang)!
    .value;

const mapFillingToFormValues = (filling: Filling): FormValues => ({
  active: filling.active,
  polishName: findNameTranslation(filling, "pl"),
  germanName: findNameTranslation(filling, "de"),
  englishName: findNameTranslation(filling, "en"),
  swedenName: findNameTranslation(filling, "sv"),
});

const getDefaultFormValues = (): FormValues => ({
  active: true,
  englishName: "",
  germanName: "",
  polishName: "",
  swedenName: "",
});

export const getInitFormValues = (filling: Filling | null): FormValues =>
  filling ? mapFillingToFormValues(filling) : getDefaultFormValues();
