import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SectionTitle from "../../../../components/section-title/section-title";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import ModelSummary from "./model-summary/model-summary";
import VariantPicker from "./variant-picker/variant-picker";
import { getAllVariants } from "../../../../redux/actions/variant-actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import ModelConfig from "./model-config/model-config";
import ClientInfo from "./client-info/client-info";
import DoorsConfig from "./doors-config/doors-config";
import TransportInfo from "./transport-info/transport-info";
import BillingInfo from "./billing-info/billing-info";
import PriceBar from "./price-bar/price-bar";
import { LoadingButton } from "@mui/lab";
import HorizontalDivider from "../../../../components/horizontal-divider/horizontal-divider";
import { Order, OrderStatus } from "../../../../types/order-types";
import TermPicker from "./term-picker/term-picker";
import {
  CurrentOrder,
  DEFAULT_CURRENT_ORDER,
  setCalculateValidation,
  setCurrentOrder,
  setValidation,
} from "../../../../redux/slices/order-slice";
import DeleteOrderDialog from "./delete-order-dialog/delete-order-dialog";
import { useNavigate } from "react-router-dom";
import { Route } from "../../../../constants/navigation-constants";
import CancelOrderDialog from "./cancel-order-dialog/cancel-order-dialog";
import { renderStatusCell } from "../../orders-list/order-list.utils";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {
  resetChanges,
  setOriginalData,
} from "../../../../redux/slices/changes-slice";
import { getStyles } from "./order-form.styles";
import useRole from "../../../../hooks/useRole";
import { isAdminRole } from "../../../../utils/common-utils";
import AdminSection from "./admin-section/admin-section";
import TechnicalProjection from "./technical-projection/technical-projection";
import { setAllVariants } from "../../../../redux/slices/variant-slice";
import DownloadIcon from "@mui/icons-material/Download";
import ExportDialog from "../../../../components/export-dialog/export-dialog";

interface OrderEditProps extends WithPageLoaderProps {
  order: Order | null;
  onBackClick: () => void;
  submitOrder: (args: {
    status?: OrderStatus;
    termToPost?: Date | null;
    callback?: () => void;
  }) => Promise<void>;
}

const OrderForm = ({
  finishLoading,
  onBackClick,
  submitOrder,
  isPageLoading,
  order,
}: OrderEditProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(theme, isMobile);
  const navigate = useNavigate();
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);

  const { currentOrder, entityLoading } = useSelector(
    (state: Store) => state.order
  );
  const { originalData, areUnsavedChanges } = useSelector(
    (state: Store) => state.changes
  );
  const { countries } = useSelector((state: Store) => state.country);
  const isLoading = entityLoading === "pending";

  const [isOrderModalOpen, setOrderModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [term, setTerm] = useState<Date | null>(null);
  const [isExportModalOpen, setExportModalOpen] = useState(false);

  useEffect(() => {
    init();
    return () => {
      dispatch(
        setCurrentOrder({
          currentOrder: {
            ...DEFAULT_CURRENT_ORDER,
            partner: isAdmin ? null : currentOrder.partner,
          },
        })
      );
      dispatch(setValidation(null));
      dispatch(setCalculateValidation({}));
      dispatch(resetChanges());
      dispatch(setAllVariants(null));
    };
  }, []);

  const init = async () => {
    dispatch(setOriginalData({ originalData: currentOrder }));
    dispatch(setValidation(null));
    dispatch(setCalculateValidation({}));

    await dispatch(
      getAllVariants({
        active: true,
        modelId: currentOrder.model!.id,
      })
    );
    finishLoading();
  };

  const handleCloseModal = () => {
    setOrderModalOpen(false);
    setTerm(null);
  };

  const handleOrderClick = () => {
    submitOrder({ status: OrderStatus.WAITING_FOR_ACCEPT, termToPost: term });
    setOrderModalOpen(false);
  };

  const handleDeleteClick = async () => {
    await submitOrder({
      status: OrderStatus.DELETED,
      callback: () => navigate(Route.Orders),
    });
    setDeleteModalOpen(false);
  };

  const handleCancelClick = () => {
    submitOrder({ status: OrderStatus.CANCELED });
    setCancelModalOpen(false);
  };

  const getTitle = () => {
    if (currentOrder.status === OrderStatus.OFFER) {
      if (currentOrder.id !== null) {
        return `${t("__szczegoly_oferty")} ${currentOrder.id}`;
      }
      return t("__nowa_oferta");
    }

    const chip = renderStatusCell(currentOrder.id!, currentOrder.status, t);

    if (currentOrder.id !== null) {
      return (
        <Box sx={styles.actions}>
          {t("__szczegoly_zamowienia")} {currentOrder.id} {chip}
        </Box>
      );
    } else {
      return (
        <Box sx={styles.actions}>
          {t("__nowe_zamowienie")} {chip}
        </Box>
      );
    }
  };

  const getClientCountryName = (order: Order | null) => {
    if (order === null) {
      return "";
    }
    return t(
      countries
        ?.find((c) => c.id === order.client?.address.countryId)
        ?.iso.toLowerCase() || ""
    );
  };

  const getPartnerCountryName = (order: Order | null) => {
    if (order === null) {
      return "";
    }
    return t(
      countries
        ?.find((c) => c.id === order.partner.address.countryId)
        ?.iso.toLowerCase() || ""
    );
  };

  const exportButton =
    currentOrder.id === null ? null : (
      <LoadingButton
        variant="outlined"
        type="button"
        loading={isLoading}
        color="primary"
        loadingPosition="end"
        endIcon={<DownloadIcon />}
        onClick={() => setExportModalOpen(true)}
        disabled={areUnsavedChanges}
      >
        {t("__pobierz")}
      </LoadingButton>
    );

  const partnerManageButtons = (
    <>
      {exportButton}
      {currentOrder.status === OrderStatus.OFFER && (
        <>
          <HorizontalDivider height={4} />
          <LoadingButton
            variant="contained"
            type="button"
            loading={isLoading}
            color="success"
            loadingPosition="end"
            endIcon={<ShoppingCartIcon />}
            onClick={() => setOrderModalOpen(true)}
          >
            {t("__zamow")}
          </LoadingButton>
        </>
      )}
      {currentOrder.id !== null && currentOrder.status === OrderStatus.OFFER && (
        <>
          <HorizontalDivider height={4} />
          <LoadingButton
            variant="outlined"
            type="button"
            loading={isLoading}
            loadingPosition="end"
            color="error"
            endIcon={<DeleteIcon />}
            onClick={() => setDeleteModalOpen(true)}
          >
            {t("__usun")}
          </LoadingButton>
        </>
      )}
      {currentOrder.status === OrderStatus.WAITING_FOR_ACCEPT && (
        <>
          <HorizontalDivider height={4} />
          <LoadingButton
            variant="outlined"
            type="button"
            loading={isLoading}
            loadingPosition="end"
            color="error"
            endIcon={<EventBusyIcon />}
            onClick={() => setCancelModalOpen(true)}
          >
            {t("anuluj")}
          </LoadingButton>
        </>
      )}
    </>
  );

  const onRollbackClick = () => {
    if (originalData) {
      dispatch(
        setCurrentOrder({
          currentOrder: originalData as CurrentOrder,
          rollback: true,
        })
      );
      dispatch(setValidation(null));
    }
  };

  return (
    <Box>
      <TermPicker
        open={isOrderModalOpen}
        onClose={handleCloseModal}
        onOrder={handleOrderClick}
        term={term}
        onChange={setTerm}
      />
      <DeleteOrderDialog
        open={isDeleteModalOpen}
        onCancelClick={() => setDeleteModalOpen(false)}
        deleteCallback={handleDeleteClick}
        id={currentOrder.id === null ? undefined : currentOrder.id}
        loading={isLoading}
      />
      <CancelOrderDialog
        open={isCancelModalOpen}
        onCancelClick={() => setCancelModalOpen(false)}
        onConfirmation={handleCancelClick}
        id={currentOrder.id === null ? undefined : currentOrder.id}
        loading={isLoading}
      />
      <ExportDialog
        open={isExportModalOpen}
        clientCountryName={getClientCountryName(order)}
        partnerCountryName={getPartnerCountryName(order)}
        order={order}
        onFinish={() => setExportModalOpen(false)}
        onClose={() => setExportModalOpen(false)}
      />
      <Box style={styles.container}>
        <SectionTitle
          title={getTitle()}
          onBackClick={onBackClick}
          isLoading={isLoading}
          isEditing={currentOrder.id !== null}
          additionalActions={isAdmin ? exportButton : partnerManageButtons}
          onSubmit={() => submitOrder({})}
          onRollback={onRollbackClick}
        />
        {isAdmin && <AdminSection />}
        <ModelSummary />
        <VariantPicker />
        <ModelConfig />
        <DoorsConfig />
        <TechnicalProjection isPageLoading={isPageLoading} />
        <ClientInfo />
        <TransportInfo />
        <BillingInfo />
        <PriceBar />
      </Box>
    </Box>
  );
};

export default withPageLoader(OrderForm);
