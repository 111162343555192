import React, { useState } from "react";
import MuiInfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/material";
import { prepareSx } from "../../utils/common-utils";

interface InfoIconProps {
  content: TooltipProps["title"];
  placement?: TooltipProps["placement"];
  sx?: SxProps<Theme>;
  buttonStyles?: SxProps<Theme>;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    maxWidth: "none",
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));

const getStyles = () => ({
  button: {
    padding: 0,
  },
});

const InfoIcon = ({
  content,
  placement = "top",
  sx,
  buttonStyles,
}: InfoIconProps) => {
  const styles = getStyles();

  const [open, setOpen] = useState(false);

  return (
    <LightTooltip
      title={content}
      open={open}
      arrow={true}
      placement={placement}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={() => setOpen((prev) => !prev)}
      sx={sx}
    >
      <IconButton sx={[styles.button, ...prepareSx(buttonStyles)]}>
        <MuiInfoIcon color="primary" />
      </IconButton>
    </LightTooltip>
  );
};

export default InfoIcon;
