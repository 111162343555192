import {
  MetaData,
  Point,
  Size,
} from "../../pages/orders-page/order-edit/order-form/technical-projection/technical-projection.types";
import { Theme } from "@mui/material";
import {
  FILLING_WIDTH,
  OFFSET,
  WHEELS_DIFF,
} from "../../pages/orders-page/order-edit/order-form/technical-projection/technical-projection.constants";
import { DoorEnum } from "../../constants/door-constants";

export const drawRails = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData,
  theme: Theme
) => {
  const { totalLength, railWidth, widthExt } = metaData;

  applyRailStyles(ctx, theme);

  drawRail(
    ctx,
    { x: OFFSET, y: OFFSET + widthExt - railWidth },
    { length: totalLength, width: railWidth },
    metaData
  );

  applyDefaultStyles(ctx, theme);
};

export const drawModules = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData
) => {
  const { moduleLength, singleRailWidth, modulesCount, widthExt } = metaData;

  for (let i = 0; i < modulesCount; i++) {
    const x = i * (moduleLength - FILLING_WIDTH) + OFFSET;
    const railOffset = singleRailWidth * (i + 1);
    const y = OFFSET + railOffset;
    const width = widthExt - 2 * railOffset;
    drawModule(
      ctx,
      { x, y: y - singleRailWidth },
      { length: moduleLength, width: width + singleRailWidth }
    );
  }
};

export const drawDoorWithArrow = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData,
  doors: DoorEnum[]
) => {
  const { singleRailWidth, widthExt, moduleLength } = metaData;
  const arrowOffset = 10;
  const doorLength = Math.round((moduleLength - 2 * FILLING_WIDTH) / 2);
  const doorWidth = Math.round((widthExt - singleRailWidth) / 2);

  if (doors.includes(DoorEnum.FrontRight)) {
    drawDoor(ctx, metaData, {
      x: OFFSET + FILLING_WIDTH,
      y: OFFSET + doorWidth,
    });
    drawDoorArrow(
      ctx,
      {
        x: OFFSET + Math.round(doorLength / 2) + arrowOffset + FILLING_WIDTH,
        y: OFFSET + Math.round((3 / 2) * doorWidth),
      },
      {
        x: OFFSET + FILLING_WIDTH + Math.round((3 / 2) * doorLength),
        y: OFFSET + Math.round((3 / 2) * doorWidth),
      }
    );
    return;
  }

  if (doors.includes(DoorEnum.FrontLeft)) {
    drawDoor(ctx, metaData, {
      x: OFFSET + FILLING_WIDTH,
      y: OFFSET,
    });
    drawDoorArrow(
      ctx,
      {
        x: OFFSET + Math.round(doorLength / 2) + arrowOffset + FILLING_WIDTH,
        y: OFFSET + Math.round(doorWidth / 2),
      },
      {
        x: OFFSET + FILLING_WIDTH + Math.round((3 / 2) * doorLength),
        y: OFFSET + Math.round(doorWidth / 2),
      }
    );
    return;
  }

  if (doors.includes(DoorEnum.BackRight)) {
    drawDoor(ctx, metaData, {
      x: OFFSET + Math.round(moduleLength / 2),
      y: OFFSET + doorWidth,
    });
    drawDoorArrow(
      ctx,
      {
        x:
          OFFSET +
          FILLING_WIDTH +
          Math.round((3 / 2) * doorLength) -
          arrowOffset,
        y: OFFSET + Math.round((3 / 2) * doorWidth),
      },
      {
        x: OFFSET + Math.round(doorLength / 2) + FILLING_WIDTH,
        y: OFFSET + Math.round((3 / 2) * doorWidth),
      }
    );
    return;
  }

  if (doors.includes(DoorEnum.BackLeft)) {
    drawDoor(ctx, metaData, {
      x: OFFSET + Math.round(moduleLength / 2),
      y: OFFSET,
    });
    drawDoorArrow(
      ctx,
      {
        x:
          OFFSET +
          FILLING_WIDTH +
          Math.round((3 / 2) * doorLength) -
          arrowOffset,
        y: OFFSET + Math.round(doorWidth / 2),
      },
      {
        x: OFFSET + Math.round(doorLength / 2) + FILLING_WIDTH,
        y: OFFSET + Math.round(doorWidth / 2),
      }
    );
    return;
  }
};

export const drawLengths = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData,
  theme: Theme
) => {
  const { singleRailWidth, length, railWidth } = metaData;
  //@ts-ignore
  const arrowOffset = ctx.innerArrowOffset;

  drawSizeTop(
    ctx,
    { x: OFFSET, y: OFFSET + singleRailWidth },
    { x: OFFSET + length, y: OFFSET + railWidth - singleRailWidth },
    { x: OFFSET, y: OFFSET - arrowOffset },
    { x: OFFSET + length, y: OFFSET - arrowOffset },
    theme
  );
};

export const drawModulesLengths = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData,
  theme: Theme
) => {
  const {
    singleRailWidth,
    widthExt,
    moduleLength,
    modulesCount,
    railExtension,
    length,
  } = metaData;
  //@ts-ignore
  const arrowOffset = ctx.innerArrowOffset;

  for (let i = 0; i < modulesCount; i++) {
    const sourceStart = {
      x:
        i === 0
          ? OFFSET + i * moduleLength
          : OFFSET + i * moduleLength - (i - 1) * FILLING_WIDTH,
      y: OFFSET + widthExt - singleRailWidth * i,
    };
    const sourceEnd = {
      x: OFFSET + (i + 1) * moduleLength - i * FILLING_WIDTH,
      y: OFFSET + widthExt - singleRailWidth * (i + 1),
    };
    const arrowStart = {
      x:
        i === 0
          ? OFFSET + i * moduleLength
          : OFFSET + i * moduleLength - (i - 1) * FILLING_WIDTH,
      y: OFFSET + widthExt + arrowOffset,
    };
    const arrowEnd = {
      x: OFFSET + (i + 1) * moduleLength - i * FILLING_WIDTH,
      y: OFFSET + widthExt + arrowOffset,
    };

    drawSizeBottom(ctx, sourceStart, sourceEnd, arrowStart, arrowEnd, theme);
  }

  if (railExtension) {
    drawSizeBottom(
      ctx,
      { x: OFFSET + length, y: OFFSET + widthExt },
      { x: OFFSET + length + railExtension, y: OFFSET + widthExt },
      { x: OFFSET + length, y: OFFSET + widthExt + arrowOffset },
      {
        x: OFFSET + length + railExtension,
        y: OFFSET + widthExt + arrowOffset,
      },
      theme
    );
  }
};

export const drawWheels = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData
) => {
  const { singleRailWidth, modulesCount } = metaData;

  for (let i = 0; i < modulesCount; i++) {
    ctx.beginPath();
    ctx.rect(
      OFFSET,
      OFFSET + i * singleRailWidth,
      FILLING_WIDTH * 2,
      FILLING_WIDTH
    );
    ctx.fill();
  }
};

export const drawWidths = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData,
  theme: Theme
) => {
  const { railWidth, widthExt } = metaData;
  //@ts-ignore
  const innerArrowOffset = ctx.innerArrowOffset;

  //@ts-ignore
  const outerArrowOffset = ctx.innerArrowOffset * 3;

  drawSizeLeft(
    ctx,
    { x: OFFSET, y: OFFSET },
    { x: OFFSET, y: OFFSET + railWidth - WHEELS_DIFF },
    { x: OFFSET - innerArrowOffset, y: OFFSET },
    { x: OFFSET - innerArrowOffset, y: OFFSET + railWidth - WHEELS_DIFF },
    theme
  );

  drawSizeLeft(
    ctx,
    { x: OFFSET, y: OFFSET + railWidth - WHEELS_DIFF },
    { x: OFFSET, y: OFFSET + widthExt - railWidth },
    { x: OFFSET - innerArrowOffset, y: OFFSET + railWidth - WHEELS_DIFF },
    { x: OFFSET - innerArrowOffset, y: OFFSET + widthExt - railWidth },
    theme
  );

  drawSizeLeft(
    ctx,
    { x: OFFSET, y: OFFSET },
    { x: OFFSET, y: OFFSET + widthExt },
    { x: OFFSET - outerArrowOffset, y: OFFSET },
    { x: OFFSET - outerArrowOffset, y: OFFSET + widthExt },
    theme
  );
};

export const drawRailWidths = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData,
  theme: Theme
) => {
  const { railWidth, totalLength, widthExt } = metaData;
  //@ts-ignore
  const arrowOffset = ctx.innerArrowOffset * 2;

  drawSizeRight(
    ctx,
    { x: OFFSET + totalLength, y: OFFSET + widthExt - railWidth },
    { x: OFFSET + totalLength, y: OFFSET + widthExt },
    { x: OFFSET + totalLength + arrowOffset, y: OFFSET + widthExt - railWidth },
    { x: OFFSET + totalLength + arrowOffset, y: OFFSET + widthExt },
    theme
  );
};

const drawModule = (
  ctx: CanvasRenderingContext2D,
  leftTop: Point,
  size: Size
) => {
  const { x, y } = leftTop;
  const { width, length } = size;

  ctx.beginPath();
  ctx.rect(x, y, length, width);
  ctx.stroke();

  ctx.beginPath();
  ctx.rect(x + FILLING_WIDTH, y, length - FILLING_WIDTH * 2, width);
  ctx.stroke();
};

const drawRail = (
  ctx: CanvasRenderingContext2D,
  topLeft: Point,
  size: Size,
  metaData: MetaData
) => {
  const { x, y } = topLeft;
  const { length, width } = size;
  const { singleRailWidth, modulesCount } = metaData;

  ctx.beginPath();
  ctx.rect(x, y, length, width);
  ctx.fill();
  ctx.stroke();

  ctx.setLineDash([15, 10]);

  for (let i = 0; i < modulesCount; i++) {
    ctx.beginPath();
    ctx.moveTo(x, y + singleRailWidth * (i + 1));
    ctx.lineTo(length + OFFSET, y + singleRailWidth * (i + 1));
    ctx.stroke();
  }

  ctx.setLineDash([]);
};

const drawDoor = (
  ctx: CanvasRenderingContext2D,
  metaData: MetaData,
  topLeft: Point
) => {
  const { widthExt, moduleLength, singleRailWidth } = metaData;
  const doorLength = Math.round((moduleLength - 2 * FILLING_WIDTH) / 2);
  const doorWidth = Math.round((widthExt - singleRailWidth) / 2);

  ctx.beginPath();
  ctx.moveTo(OFFSET + FILLING_WIDTH, OFFSET + doorWidth);
  ctx.lineTo(OFFSET + doorLength * 2 + FILLING_WIDTH, OFFSET + doorWidth);
  ctx.moveTo(topLeft.x, topLeft.y);
  ctx.lineTo(topLeft.x + doorLength, topLeft.y + doorWidth);
  ctx.lineTo(topLeft.x + doorLength, topLeft.y);
  ctx.lineTo(topLeft.x, topLeft.y + doorWidth);
  ctx.lineTo(topLeft.x, topLeft.y);
  ctx.stroke();
};

const drawDoorArrow = (
  ctx: CanvasRenderingContext2D,
  arrowStart: Point,
  arrowEnd: Point
) => {
  //@ts-ignore
  const headLength = ctx.arrowSize * 2;
  const arrowSharpness = 10;
  const dx = arrowEnd.x - arrowStart.x;
  const dy = arrowEnd.y - arrowStart.y;
  const angle = Math.atan2(dy, dx);
  const arrowOffset = arrowStart.x - arrowEnd.x > 0 ? 10 : -10;

  ctx.lineWidth = 3;
  ctx.beginPath();
  ctx.moveTo(arrowStart.x, arrowStart.y);
  ctx.lineTo(arrowEnd.x + arrowOffset, arrowEnd.y);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(arrowEnd.x, arrowEnd.y);
  ctx.lineTo(
    arrowEnd.x - headLength * Math.cos(angle - Math.PI / arrowSharpness),
    arrowEnd.y - headLength * Math.sin(angle - Math.PI / arrowSharpness)
  );
  ctx.lineTo(
    arrowEnd.x - headLength * Math.cos(angle + Math.PI / arrowSharpness),
    arrowEnd.y - headLength * Math.sin(angle + Math.PI / arrowSharpness)
  );
  ctx.lineTo(arrowEnd.x, arrowEnd.y);
  ctx.fill();
  ctx.lineWidth = 1;
};

const drawSizeTop = (
  ctx: CanvasRenderingContext2D,
  sourceStart: Point,
  sourceEnd: Point,
  arrowStart: Point,
  arrowEnd: Point,
  theme: Theme
) => {
  drawSizeArrow(ctx, sourceStart, sourceEnd, arrowStart, arrowEnd, theme);

  ctx.fillText(
    (arrowEnd.x - arrowStart.x).toString(),
    Math.round((arrowStart.x + arrowEnd.x) / 2),
    // @ts-ignore
    arrowStart.y - ctx.textOffset
  );
};

const drawSizeBottom = (
  ctx: CanvasRenderingContext2D,
  sourceStart: Point,
  sourceEnd: Point,
  arrowStart: Point,
  arrowEnd: Point,
  theme: Theme
) => {
  drawSizeArrow(ctx, sourceStart, sourceEnd, arrowStart, arrowEnd, theme);

  ctx.fillText(
    (arrowEnd.x - arrowStart.x).toString(),
    Math.round((arrowStart.x + arrowEnd.x) / 2),
    // @ts-ignore
    arrowStart.y + ctx.textOffset * 2.5
  );
};

const drawSizeLeft = (
  ctx: CanvasRenderingContext2D,
  sourceStart: Point,
  sourceEnd: Point,
  arrowStart: Point,
  arrowEnd: Point,
  theme: Theme
) => {
  drawSizeArrow(ctx, sourceStart, sourceEnd, arrowStart, arrowEnd, theme);

  ctx.save();
  ctx.translate(
    // @ts-ignore
    arrowStart.x - ctx.textOffset,
    arrowStart.y + (arrowEnd.y - arrowStart.y) / 2
  );
  ctx.rotate(-Math.PI / 2);
  ctx.fillText((arrowEnd.y - arrowStart.y).toString(), 0, 0);
  ctx.restore();
};

const drawSizeRight = (
  ctx: CanvasRenderingContext2D,
  sourceStart: Point,
  sourceEnd: Point,
  arrowStart: Point,
  arrowEnd: Point,
  theme: Theme
) => {
  drawSizeArrow(ctx, sourceStart, sourceEnd, arrowStart, arrowEnd, theme);

  ctx.save();
  ctx.translate(
    // @ts-ignore
    arrowStart.x - ctx.textOffset,
    arrowStart.y + Math.round((arrowEnd.y - arrowStart.y) / 2)
  );
  ctx.rotate(-Math.PI / 2);
  ctx.fillText((arrowEnd.y - arrowStart.y).toString(), 0, 0);
  ctx.restore();
};
const drawSizeArrow = (
  ctx: CanvasRenderingContext2D,
  sourceStart: Point,
  sourceEnd: Point,
  arrowStart: Point,
  arrowEnd: Point,
  theme: Theme
) => {
  applySizeStyles(ctx, theme);

  ctx.beginPath();
  ctx.moveTo(sourceStart.x, sourceStart.y);
  ctx.lineTo(arrowStart.x, arrowStart.y);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(sourceEnd.x, sourceEnd.y);
  ctx.lineTo(arrowEnd.x, arrowEnd.y);
  ctx.stroke();

  applyDefaultStyles(ctx, theme);
  drawArrow(ctx, arrowStart, arrowEnd);
};

const drawArrow = (
  ctx: CanvasRenderingContext2D,
  arrowStart: Point,
  arrowEnd: Point
) => {
  //@ts-ignore
  const headLength = ctx.arrowSize;
  const arrowSharpness = 8;
  const dx = arrowEnd.x - arrowStart.x;
  const dy = arrowEnd.y - arrowStart.y;
  const angle = Math.atan2(dy, dx);

  ctx.beginPath();
  ctx.moveTo(arrowStart.x, arrowStart.y);
  ctx.lineTo(arrowEnd.x, arrowEnd.y);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(arrowStart.x, arrowStart.y);
  ctx.lineTo(
    arrowStart.x + headLength * Math.cos(angle - Math.PI / arrowSharpness),
    arrowStart.y + headLength * Math.sin(angle - Math.PI / arrowSharpness)
  );
  ctx.lineTo(
    arrowStart.x + headLength * Math.cos(angle + Math.PI / arrowSharpness),
    arrowStart.y + headLength * Math.sin(angle + Math.PI / arrowSharpness)
  );
  ctx.lineTo(arrowStart.x, arrowStart.y);
  ctx.fill();

  ctx.beginPath();
  ctx.moveTo(arrowEnd.x, arrowEnd.y);
  ctx.lineTo(
    arrowEnd.x - headLength * Math.cos(angle - Math.PI / arrowSharpness),
    arrowEnd.y - headLength * Math.sin(angle - Math.PI / arrowSharpness)
  );
  ctx.lineTo(
    arrowEnd.x - headLength * Math.cos(angle + Math.PI / arrowSharpness),
    arrowEnd.y - headLength * Math.sin(angle + Math.PI / arrowSharpness)
  );
  ctx.lineTo(arrowEnd.x, arrowEnd.y);
  ctx.fill();
};

const applyRailStyles = (ctx: CanvasRenderingContext2D, theme: Theme) => {
  ctx.fillStyle = theme.palette.grey["300"];
  ctx.strokeStyle = theme.palette.grey["400"];
};

const applySizeStyles = (ctx: CanvasRenderingContext2D, theme: Theme) => {
  ctx.fillStyle = theme.palette.grey["500"];
  ctx.strokeStyle = theme.palette.grey["500"];
};

const applyDefaultStyles = (ctx: CanvasRenderingContext2D, theme: Theme) => {
  ctx.fillStyle = theme.palette.common.black;
  ctx.strokeStyle = theme.palette.common.black;
};
