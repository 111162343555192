import React, { useEffect } from "react";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../../hoc/with-page-loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { getPosts } from "../../../../redux/actions/post-actions";
import PaginationTable from "../../../../components/pagination-table/pagination-table";
import { Post } from "../../../../types/post-types";
import Section from "../../../../components/section/section";
import PostItem from "./post-item/post-item";
import Box from "@mui/material/Box";
import { DEFAULT_TABLE_WIDTH } from "../../../../theme/sizes";

const getStyles = () => ({
  container: {
    width: "100%",
    maxWidth: DEFAULT_TABLE_WIDTH,
    minWidth: 250,
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
});

interface PostOverviewListProps extends WithPageLoaderProps {}

const PostOverviewList = ({ finishLoading }: PostOverviewListProps) => {
  const postReducer = useSelector((state: Store) => state.post);
  const { loading, size, sort, filters } = postReducer;
  const dispatch = useDispatch<AppDispatch>();
  const styles = getStyles();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await Promise.all([
      dispatch(
        getPosts({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const renderRow = (post: Post) => (
    <PostItem key={post.id} post={post} loading={loading} />
  );

  const table = (
    <PaginationTable<Post>
      withPagination={true}
      loading={loading}
      fetcherAction={getPosts}
      reducerName="post"
      renderRow={renderRow}
      paginationBackground={true}
    />
  );

  return (
    <Box sx={styles.container}>
      <Section title={t("aktualnosci")} />
      {table}
    </Box>
  );
};

export default withPageLoader(PostOverviewList);
