import { Theme } from "@mui/material";

export const getStyles = (
  theme: Theme,
  isMobile: boolean,
  isTablet: boolean
) => ({
  container: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: isTablet ? "wrap" : "none",
    justifyContent: "space-around",
  },
  fields: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(3),
  },
  tooltipWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  divider: {
    width: "90%",
  },
  infoIcon: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    img: {
      width: 350,
    },
    "& p": { textAlign: "justify", width: 350, fontSize: 14 },
  },
  biggerInfoIcon: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    img: {
      width: 450,
    },
    "& p": { textAlign: "justify", width: 450, fontSize: 14 },
  },
  previews: {
    display: "flex",
    gap: theme.spacing(2),
  },
});
