export enum Colors {
  DrawerBackground = "#283555",
  White = "#fff",
  Black = "#000",
  Transparent = "transparent",
  Body = "#EFF1F5",
  DividerGrey = "#0000001f",
  LoaderBackground = "#ffffffb3",
  DisabledBackgroundGrey = "#00000007",
  DisabledTextGrey = "#00000099",
  Red = "#d32f2f",
  ButtonBackground = "rgba(25, 118, 210, 0.05)",
  ButtonBachgroundHover = "rgba(25, 118, 210, 0.1)",
}
