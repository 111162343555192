import React from "react";
import { Theme, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { toRem } from "../../../../../utils/common-utils";
import { useSelector } from "react-redux";
import { Store } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../../../components/info-icon/info-icon";
import CircularProgress from "@mui/material/CircularProgress";
import { formatPrice } from "../../../../../utils/formatter-utils";

export const getStyles = (
  theme: Theme,
  isMobile: boolean,
  isDrawerOpen: boolean
) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    position: "fixed",
    bottom: 0,
    left: isMobile || !isDrawerOpen ? 57 : 240,
    right: 0,
    padding: theme.spacing(2),
    zIndex: 10,
    borderTop: "solid 1px rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: isMobile ? "flex-end" : "space-around",
    alignItems: "center",
    transition: "left 0.3s",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  value: {
    fontSize: toRem(16),
    fontWeight: 500,
  },
  infoIcon: {
    padding: theme.spacing(1),
    fontSize: 14,
  },
  loader: {
    color: theme.palette.common.black,
  },
});

const PriceBar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 800px)");
  const { isDrawerOpen } = useSelector((state: Store) => state.ui);
  const styles = getStyles(theme, isMobile, isDrawerOpen);

  const { partner, payment } = useSelector(
    (state: Store) => state.order.currentOrder
  );
  const { loading, response } = useSelector(
    (state: Store) => state.order.calculation
  );

  const getDiscount = () => {
    if (partner) {
      return `${(payment?.partnerDiscount ?? partner.discount) * 100}%`;
    }
    return "-";
  };

  const infoIconContent = (
    <Typography sx={styles.infoIcon}>{t("__tooltip_rabat")}</Typography>
  );

  const renderValue = (value?: number) =>
    loading ? (
      <CircularProgress sx={styles.loader} size={24} />
    ) : (
      <Typography sx={styles.value}>
        {value ? `${formatPrice(value)} €` : "-"}
      </Typography>
    );

  return (
    <Box sx={styles.container}>
      {!isMobile && (
        <>
          <Box sx={styles.wrapper}>
            <Typography>{`${t("__cena_przed_rabatem")}:`}</Typography>
            {renderValue(
              response ? response.retailPrice : payment?.retailPrice
            )}
          </Box>
          <Box sx={styles.wrapper}>
            <Typography>{`${t("__przyznany_rabat")}:`}</Typography>
            <Typography sx={styles.value}>{getDiscount()}</Typography>
            <InfoIcon content={infoIconContent} />
          </Box>
        </>
      )}
      <Box sx={styles.wrapper}>
        <Typography>{`${t("__cena_po_rabacie")}:`}</Typography>
        {renderValue(response ? response.partnerPrice : payment?.partnerPrice)}
      </Box>
    </Box>
  );
};

export default PriceBar;
