import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../redux/store";
import Section from "../../../../components/section/section";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Category } from "../../../../types/category-types";
import { Cover } from "../../../../types/cover-types";
import { useTheme } from "@mui/material";
import { getStyles } from "./modal-picker.styles";
import ScrollableContainer from "./scrollable-container";
import PhotoPreview from "../../../../components/photo-preview/photo-preview";
import {
  setCategory,
  setModel,
  setProfile,
  setRailExtension,
} from "../../../../redux/slices/order-slice";
import { Route } from "../../../../constants/navigation-constants";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../../../../components/section-title/section-title";

interface ModerPickerSection {
  category: Category;
  models: Cover[];
}

const ModelPicker = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const categories = useSelector(
    (state: Store) => state.category.allCategories
  );
  const profiles = useSelector((state: Store) => state.profile.allProfiles);
  const models = useSelector((state: Store) => state.cover.allCovers);

  const [sections, setSections] = useState<ModerPickerSection[]>([]);

  useEffect(() => {
    prepareDataToShow();
  }, [categories, profiles, models]);

  const onModelClick = (model: Cover, category: Category) => {
    const profile = profiles?.find((profile) => profile.id === model.profileId);
    dispatch(setCategory(category));
    dispatch(setProfile(profile!));
    dispatch(setModel(model));
    dispatch(setRailExtension(category.box ? "220" : "0"));
  };

  const prepareDataToShow = () => {
    const sectionsToShow: ModerPickerSection[] = [];

    models?.forEach((model) => {
      const foundCategory = categories?.find(
        (category) => category.id === model.categoryId
      );
      const foundProfile = profiles?.find(
        (profile) => profile.id === model.profileId
      );
      if (foundCategory && foundProfile) {
        const foundSectionIndex = sectionsToShow.findIndex(
          (section) => section.category.id === foundCategory.id
        );
        if (foundSectionIndex >= 0) {
          sectionsToShow[foundSectionIndex].models.push(model);
        } else {
          sectionsToShow.push({ category: foundCategory, models: [model] });
        }
      }
    });
    setSections(sectionsToShow);
  };

  const renderModels = (models: Cover[], category: Category) =>
    models.map((model) => (
      <PhotoPreview
        key={model.id}
        model={model}
        onClick={() => onModelClick(model, category)}
      />
    ));

  const sectionNodes = sections.map((section) => (
    <Section title={section.category.label} key={section.category.id}>
      <ScrollableContainer>
        {renderModels(section.models, section.category)}
      </ScrollableContainer>
    </Section>
  ));

  return (
    <Box sx={styles.container}>
      <SectionTitle
        title={t("__wybor_modelu")}
        isLoading={false}
        onBackClick={() => navigate(Route.Orders)}
        isEditing={false}
        onRollback={() => {}}
        hideApiButtons
      />
      {sectionNodes}
    </Box>
  );
};

export default ModelPicker;
