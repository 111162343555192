export const enMainTranslations = {
  konfiguracja: "Configuration",
  zadaszenia: "Pool enclosures",
  aktualnosci: "News",
  zamowienia: "Orders",
  partnerzy: "Partners",
  cennik: "Price list",
  uzytkownicy: "Users",
  niepoprawny_mail: "Please enter correct e-mail address",
  brak_maila: "E-mail address required",
  brak_hasla: "Password required",
  brak_starego_hasla: "Old password required",
  brak_nowego_hasla: "New password required",
  brak_potwierdzenia_hasla: "Password confirmation required",
  login_zacheta: "Enter your e-mail address and password to log in.",
  login: "Log in",
  mail: "E-mail",
  haslo: "Password",
  stare_haslo: "Old password",
  nowe_haslo: "New password",
  potwierdz_haslo: "Confirm password",
  niepoprawne_dane: "Data entry error",
  blad_uwierzytelniania: "Session expired",
  nieodnaleziono: "Resource not found",
  blad_serwera: "Server error",
  wystapil_nieoczekiwany_blad: "An unexpected error occurred",
  nieoczekiwany_blad_tresc:
    "An unexpected error occurred during command processing. If the problem persists after the page is refreshed, contact support providing the error id.",
  identyfikator_bledu: "Error ID",
  zamknij: "Close",
  ups: "Oops... Something went wrong",
  ups_tresc:
    "The page could not be loaded correctly. If the problem persists after the page is refreshed, contact support.",
  zmiana_hasla_zacheta: "Set new account password",
  zapisz: "Save",
  powrot: "Back",
  rozne_hasla: "The passwords do not match",
  haslo_min: "The password must have at least eight characters",
  haslo_max: "The password cannot be longer than 32 characters",
  slabe_haslo:
    "The password must contain a small letter, a capital letter, a digit and a special character",
  polityka_prywatnosci: "Privacy policy",
  zaakceptuj: "Accept",
  anuluj: "Cancel",
  polityka_prywatnosci_tekst: "Privacy policy",
  liczba_wierszy: "Number of rows",
  status: "Status",
  aktywny: "Active",
  dezaktywowany: "Deactivated",
  kraj: "Country",
  dodaj: "Add",
  empty_table: "No data for the provided parameters",
  edytuj: "Edit",
  aktywuj: "Activate",
  dezaktywuj: "Deactivate",
  nazwa: "Name",
  adres: "Address",
  znizka: "Discount",
  potwierdz_operacje: "Confirm operation",
  dezaktywacja_partnera_pytanie: "Do you want to deactivate this partner?",
  aktywacja_partnera_pytanie: "Do you want to activate this partner?",
  edycja_partnera: "Partner edit",
  nowy_partner: "New partner",
  dane_partnera: "Partner details",
  kierunkowy: "Area code",
  numer_telefonu: "Phone number",
  darmowe_przedluzenie_szyn: "Free rail extension",
  dane_adresowe: "Address data",
  ulica: "Street",
  numer_domu: "House number",
  numer_lokalu: "Apartment number",
  kod_pocztowy: "Post code",
  miasto: "City",
  wymagane_pole: "This field is required",
  niepoprawna_wartosc: "Invalid value",
  zapisane_zmiany: "Changes saved",
  dezaktywacja_uzytkwnika_pytanie: "Do you want to deactivate this user?",
  aktywacja_uzytkownika_pytanie: "Do you want to activate this user?",
  rola: "Role",
  ROLE_ADMIN: "Admin",
  ROLE_PARTNER: "Partner",
  brak: "None",
  edycja_usera: "Edit user",
  nowy_user: "New user",
  dane_usera: "User data",
  imie: "Name",
  nazwisko: "Surname",
  partner: "Partner",
  data_dodania: "Date",
  tytul: "Title",
  tresc: "Content",
  dezaktywacja_posta_pytanie: "Do you want to deactivate this notice?",
  aktywacja_posta_pytanie: "Do you want to activate this notice?",
  polski: "Polish",
  angielski: "English",
  niemiecki: "German",
  szwedzki: "Swedish",
  dane_podstawowe: "Basic information",
  nowy_post: "New notice",
  edytcja_posta: "Edit notice",
  kolory: "Colours",
  blokady: "Locks",
  wypelnienia: "Fillings",
  kategorie: "Categories",
  profile: "Profiles",
  edycja_blokady: "Edit lock",
  nowa_blokada: "New lock",
  polska_nazwa: "Polish name",
  angielska_nazwa: "English name",
  niemiecka_nazwa: "German name",
  szwedzka_nazwa: "Swedish name",
  dezaktywacja_blokady_pytanie: "Do you want to deactivate this lock?",
  aktywacja_blokady_pytanie: "Do you want to activate this lock?",
  __edycja_koloru: "Edit colour",
  __nowy_kolor: "New colour",
  __dezaktywacja_koloru_pytanie: "Do you want to deactivate this colour?",
  __aktywacja_koloru_pytanie: "Do you want to activate this colour?",
  __edycja_wypelnienia: "Edit filling",
  __nowe_wypelnienie: "New filling",
  __dezaktywacja_wypelnienia_pytanie: "Do you want to deactivate this filling?",
  __aktywacja_wypelnienia_pytanie: "Do you want to activate this filling?",
  __edycja_profilu: "Edit profile",
  __nowy_profil: "New profile",
  __dezaktywacja_profilu_pytanie: "Do you want to deactivate this profile?",
  __aktywacja_profilu_pytanie: "Do you want to activate this profile?",
  __edycja_kategorii: "Edit category",
  __nowa_kategoria: "New category",
  __dezaktywacja_kategorii_pytanie: "Do you want to deactivate this category?",
  __aktywacja_kategorii_pytanie: "Do you want to activate this category?",
  __cena__przedluzenia_w_profilu: "Price per metre rail extension",
  __dostepne_blokady: "Available locks",
  __wszystkie_blokady: "All locks",
  __brak_opcji: "No options",
  __wybierz_dostepne_blokady: "Select available locks",
  __dostepne_kolory: "Available colours",
  __wszystkie_kolory: "All colours",
  __wybierz_dostepne_kolory: "Select available colours",
  __dostepne_wypelnienia: "Available fillings",
  __wszystkie_wypelnienia: "All fillings",
  __wybierz_dostepne_wypelnienia: "Select available fillings",
  __dostepne_drzwi: "Available doors",
  __wszystkie_drzwi: "All doors",
  __drzwi: "Door",
  __wybierz_dostepne_drzwi: "Select available doors",
  FRONT_MAIN: "Front door",
  BACK_MAIN: "Rear door",
  FRONT_RIGHT: "Front right",
  FRONT_LEFT: "Front left",
  BACK_RIGHT: "Rear right",
  BACK_LEFT: "Rear left",
  __cena: "Price",
  DOOR: "Door",
  __ceny_podstawowe: "Basic prices",
  __edycja_ceny: "Edit price",
  __dodawanie_ceny_moduly: "New price depends on modules",
  __edycja_ceny_moduly: "Edit module-dependent price",
  __liczba_modulow: "Number of modules",
  __cena_blokady: "Lock price",
  __cena_montazu: "Installation price",
  __ceny_moduly: "Prices dependent on module count",
  FRONT: "Front flap",
  BACK: "Rear flap",
  __dostepne_klapy: "Available flaps",
  __wszystkie_klapy: "All flaps",
  __klapy: "Flaps",
  __wybierz_dostepne_klapy: "Select available flaps",
  __edycja_zadaszenia: "Edit enclosure",
  __nowe_zadaszenie: "New enclosure",
  __warianty_zadaszenia: "Enclosure variants",
  __rendery_zadaszenia: "Enclosure renders",
  __pozycja_na_liscie: "Number on the list",
  __kategoria: "Category",
  __profil: "Profile",
  __liczba_szyn: "Number of rails",
  __domyslny: "Default",
  __z_drzwiami: "With a door",
  __z_klapa: "With a flap",
  __kliknij_zdjecie: "Click to select a photo",
  __nowy_wariant: "New variant",
  __edycja_wariantu: "Edit variant",
  __szerokosc_zewnetrzna: "outer width",
  __szerokosc_wewnetrzna: "inner width",
  __szerokosc_zewnetrzna_long: "outer width",
  __szerokosc_wewnetrzna_long: "inner width",
  __dlugosc: "Length",
  __wysokosc: "Height",
  __standardowa_dlugosc: "Standard length",
  __model: "Model",
  __ceny_wariantu: "Variant prices",
  __wszystkie_istniejace: "All existing",
  __wszystkie_z_katerogii: "All available in the category",
  __wszystkie_z_modelu: "All available in the model",
  __brak_dostepnych_wypelnien: "No fillings available",
  __wypelnienie: "Filling",
  __aktywna: "Active",
  __dezaktywowana: "Deactivated",
  __aktywacja_variantu_pytanie: "Do you really want to activate this variant?",
  __dezaktywacja_variantu_pytanie:
    "Do you really want to deactivate this variant?",
  __moduly: "Modules",
  __aktywacja_zadaszenia_pytanie:
    "Do you really want to activate this enclosure?",
  __dezaktywacja_zadaszenia_pytanie:
    "Do you really want to deactivate this enclosure?",
  __niezapisane_zmiany: "Unsaved changes",
  __porzuc_zmiany: "Undo",
  __porzucone_zmiany_pytanie:
    "The form includes unsaved data, do you really want to abandon it?",
  TRANSPORT_WITHOUT_MONTAGE: "Transport without assembly (price per km)",
  TRANSPORT_WITH_MONTAGE: "Transport with assembly (price per km)",
  __wybor_modelu: "Select model",
  __nowa_oferta: "New offer",
  __wybrany_model: "Selected model",
  __wymiary_zadaszenia: "Enclosure dimensions",
  __dlugosci_niestandardowe: "Non-standard lengths",
  __konfiguracja_modelu: "Model configuration",
  __kolor: "Colour",
  __przedluzenie_szyn: "Rail extension",
  __blokada: "Lock",
  __rodzaj_podloza: "Type of base",
  __domyslne_podwyzszenie_scian: "Default wall height extension",
  __podwyzszenie_scian: "Wall height extension",
  __standardowe: "Standard",
  __kluczyk_blokady: "Lock key",
  __podwyzszenie_scian_tooltip:
    "The selection of a wall height extension that is different from the standard does not influence the price. However, it does prevent the selection of a flap for the front and rear walls. if a flap is selected for the front or rear wall, the clearance change option is locked out.",
  __dane_klienta: "Customer data",
  __oferta_dla_klienta: "Offer for the customer",
  __konfiguracja_drzwi: "Door configuration",
  __sciana_przednia: "Front wall",
  __sciana_tylnia: "Rear wall",
  __gora_zadaszenia: "Enclosure top",
  __drzwi_na_dachu_tooltip:
    "The price of the enclosure includes a single door: in the largest module or wall. Subsequent door configurations require additional fees. The models Gravity and Cosmos are unique – they include no doors.",
  __montaz_i_transport: "Assembly and transport",
  __dostawa: "Delivery",
  __obior_osobisty: "Personal pickup",
  __transport_bucovers: "Bucovers transport",
  __montaz_transport_bucovers: "Bucovers transport and assembly",
  __odleglosc: "Distance",
  __szczegoly_platnosci: "Payment details",
  __przyznany_rabat: "Discount provided",
  __tooltip_rabat: "The discount does not cover transport or assembly costs",
  __cena_przed_rabatem: "Bucovers net price before discount",
  __cena_po_rabacie: "Bucovers net price after discount",
  __cena_dla_klienta_przed_rabatem: "Customer net price before discount",
  __rabat_dla_klienta: "Customer discount",
  __cena_dla_klienta_po_rabacie: "Customer net price after discount",
  __wszystkie: "All",
  __oferty: "Offers",
  WAITING_FOR_ACCEPT: "Pending acceptance",
  ACCEPTED: "Accepted",
  DELIVERED: "Delivered",
  CANCELED: "Cancelled",
  DELETED: "Deleted",
  EXPIRED: "Past due",
  __klient: "Customer",
  __numer_oferty: "Number",
  __data_utworzenia: "Date",
  __szczegoly: "Details",
  __zamow: "Order",
  __termin_dostawy: "Preliminary delivery date",
  OFFER: "Offer",
  __szczegoly_oferty: "Details of offer no.",
  __szczegoly_zamowienia: "Details of order no.",
  __nowe_zamowienie: "New order",
  __usun: "Delete",
  __usunac_oferte: "Do you really want to delete offer no.",
  __anulowac_zamowienie: "Do you really want to cancel order no.",
  __tak: "Yes",
  BOX_MONTAGE: "Assembling the box",
  __rzut_zadaszenia: "Enclosure sketch",
  __pobierz: "Download",
  __export_header: "Bucovers - Manufacturer of pool enclosures",
  __oferta_przygotowana_przez: "The offer was prepared by",
  __oferta_wazna: "Offer valid for",
  __dni: "days",
  __dostawca: "Supplier",
  __odbiorca: "Recipient",
  __model_zadaszanie: "Enclosure model",
  __oznaczenie_produkcji: "Production designation",
  __dlugosc_zadaszenia: "Enclosure length",
  __wysokosc_zadaszenia: "Enclosure height",
  __rozstaw_wewnetrzny: "Inner rail spacing",
  __rozstaw_zewnetrzny: "Outer rail spacing",
  __szerokosc_szyn: "Rail width",
  __podwyzszenie_sciany: "Wall height extension",
  __klapa: "Flap",
  __komentarze: "Comments",
  __specyfikacja_partner: "Partner specification",
  __specyfikacja_klient: "Customer specification",
  __cena_netto: "Net price",
  __rabat: "Discount",
  __cena_netto_po_rabacie: "Net price after discount",
  __opcje_dodatkowe: "Additional options",
  __podsumowanie: "Summary",
  __dodatkowe_drzwi: "Additional door",
  __montaz: "Assembly",
  __transport: "Transport",
  __podziekowanie: "Thank you very much for your interest in our offer",
  __potwierdzenie_zamowienia: "Order confirmation by the customer",
  __pieczatka: "Stamp, date and signature",
  __ulica_skrot: "street address",
  __pozycja_szyny: "Rail position",
  RIGHT: "Right",
  LEFT: "Left",
  __tel: "Phone no.",
  __zmiana_statusu: "Change status of order no.",
  __z: "from",
  __na: "to",
  __brak_powiadomien: "No notifications",
  __zmiany: "Changes introduced",
  __box: "Box",
  __zlozenie: "Assembling the enclosure",
  __przedluzenie_tooltip:
    "Box category enclosures include a rail extension of 220 cm. The fee is charged only if the extension exceeds 220 cm.",
  __zlozenie_tooltip:
    "If pool enclosure assembly option is selected, then the enclosure assembly is selected automatically.",
  __globalna_zmiana: "Global price change",
  __zmien: "Change",
  __procentowa_zmiana: "Precentage value of change",
  __powieksz: "Magnification",
  __zmniejsz: "Reduction",
  __zaznacz_wszystkie: "Select all",
  __waluta_klienta: "Customer currency",
  __stawka_vat: "VAT rate",
  __cena_brutto: "Gross customer price",
  EUR: "Euro",
  PLN: "Polish Zloty",
  GBP: "Pound Sterling",
  SEK: "Swedish Krona",
  NOK: "Norwegian Krone",
  USD: "US Dollar",
  CHF: "Swiss Franc",
  CZK: "Czech Koruna",
  HUF: "Forint",
  DKK: "Danish Krone",
  __cena_brutto_rabat: "Gross price after reduction",
  __brak_wymaganych_danych: "Required data not provided",
  __uzupelnij_brakujace_dane:
    "In order to save the form, complete the missing data.",
  __cena_nie_uwzglednia:
    "Price does not include ferry crossings and other special fees.",
  __rzut_blad:
    "The rail extension is too long to generate an enclosure graphic. Choose a rail extension not exceeding 1000 cm.",
  __koszt_montazu: "installation costs",
  __dodane_przez: "Added by",
  __termin_tooltip:
    "The order will be delivered in the week that includes the selected date.",
  __eksport_dla_klienta: "Export for customer",
  __eksport_dla_klienta_bucovers: "Export for Bucovers customer",
  __eksport_bucovers: "Bucovers export",
  __rodzaj_eksportu: "Choose export type",
  __eksport_bucovers_opis:
    "Bucovers export includes comprehensive information concerning the offer for our business partners.",
  __eksport_dla_klienta_opis:
    "Customer export is developed with our business partner customers in mind.",
  __eksport_dla_klienta_bucovers_opis:
    "Bucovers customer export includes a document prepared for Bucovers retail customers.",
};
