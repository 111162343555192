import React, { useEffect } from "react";
import Section from "../../../../../components/section/section";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "../model-config/model-config.styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getCommonProps } from "./client-info.utils";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Client,
  DEFAULT_CLIENT,
  setClient,
  setClientDelivery,
} from "../../../../../redux/slices/order-slice";
import { Address, Phone } from "../../../../../types/partner-types";
import CountrySelect from "../../../../../components/country-select/country-select";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import useDisableAll from "../../../../../hooks/useDisableAll";

const ClientInfo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();

  const { validation, currentOrder } = useSelector(
    (state: Store) => state.order
  );
  const { clientDelivery, model, client } = currentOrder;

  useUpdateEffect(() => {
    resetClient();
  }, [model?.id]);

  useEffect(() => {
    if (!clientDelivery) {
      resetClient();
    }
  }, [clientDelivery]);

  const resetClient = () => {
    dispatch(setClient(DEFAULT_CLIENT));
  };

  const actions = (
    <FormControlLabel
      disabled={disableAll}
      control={
        <Checkbox
          checked={clientDelivery}
          onChange={(value) =>
            dispatch(setClientDelivery(value.target.checked))
          }
        />
      }
      label={t("__oferta_dla_klienta")}
    />
  );

  const COMMON_PROPS = getCommonProps(
    clientDelivery,
    clientDelivery,
    disableAll
  );

  const onChangeBasic = (fieldName: keyof Client, value: string) => {
    dispatch(setClient({ ...client, [fieldName]: value }));
  };

  const onPhoneChange = (fieldName: keyof Phone, value: string) => {
    dispatch(
      setClient({ ...client, phone: { ...client.phone, [fieldName]: value } })
    );
  };

  const onAddressChange = (
    fieldName: keyof Address,
    value: string | number
  ) => {
    dispatch(
      setClient({
        ...client,
        address: { ...client.address, [fieldName]: value },
      })
    );
  };

  const onCountryChange = (countryId: number | null) => {
    const adjustedCountryId = countryId === null ? -1 : countryId;
    onAddressChange("countryId", adjustedCountryId);
  };

  const adjustedCountryId =
    client.address.countryId === -1 ? null : client.address.countryId;

  const getError = (fieldKey: string) => !!validation && !!validation[fieldKey];

  const getErrorMessage = (fieldKey: string) =>
    !!validation && validation[fieldKey];

  return (
    <Section title={t("__dane_klienta")} actions={actions}>
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("nazwa")}
            value={client.name}
            onChange={(e) => onChangeBasic("name", e.target.value)}
            error={getError("client.name")}
            helperText={getErrorMessage("client.name")}
            required
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("mail")}
            value={client.email}
            onChange={(e) => onChangeBasic("email", e.target.value)}
            error={getError("client.email")}
            helperText={getErrorMessage("client.email")}
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("kierunkowy")}
            value={client.phone.prefix}
            onChange={(e) => onPhoneChange("prefix", e.target.value)}
            error={getError("client.phone.prefix")}
            helperText={getErrorMessage("client.phone.prefix")}
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("numer_telefonu")}
            value={client.phone.number}
            onChange={(e) => onPhoneChange("number", e.target.value)}
            error={getError("client.phone.number")}
            helperText={getErrorMessage("client.phone.number")}
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("ulica")}
            value={client.address.street}
            onChange={(e) => onAddressChange("street", e.target.value)}
            error={getError("client.address.street")}
            helperText={getErrorMessage("client.address.street")}
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("numer_domu")}
            value={client.address.houseNumber}
            onChange={(e) => onAddressChange("houseNumber", e.target.value)}
            error={getError("client.address.houseNumber")}
            helperText={getErrorMessage("client.address.houseNumber")}
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("numer_lokalu")}
            value={client.address.flatNumber}
            onChange={(e) => onAddressChange("flatNumber", e.target.value)}
            required={false}
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("kod_pocztowy")}
            value={client.address.zipCode}
            onChange={(e) => onAddressChange("zipCode", e.target.value)}
            error={getError("client.address.zipCode")}
            helperText={getErrorMessage("client.address.zipCode")}
            required
          />
        </Box>
        <Box sx={styles.field}>
          <TextField
            {...COMMON_PROPS}
            label={t("miasto")}
            value={client.address.city}
            onChange={(e) => onAddressChange("city", e.target.value)}
            error={getError("client.address.city")}
            helperText={getErrorMessage("client.address.city")}
            required
          />
        </Box>
        <Box sx={styles.field}>
          <CountrySelect
            countryId={adjustedCountryId}
            onChange={onCountryChange}
            disabled={!clientDelivery || disableAll}
            fullWidth
            required={clientDelivery}
            error={!!validation && !!validation["client.address.countryId"]}
            helperText={!!validation && validation["client.address.countryId"]}
          />
        </Box>
        <Box sx={styles.field} />
        <Box sx={styles.field} />
      </Box>
    </Section>
  );
};

export default ClientInfo;
