export enum DoorEnum {
  FrontMain = "FRONT_MAIN",
  BackMain = "BACK_MAIN",
  FrontRight = "FRONT_RIGHT",
  FrontLeft = "FRONT_LEFT",
  BackRight = "BACK_RIGHT",
  BackLeft = "BACK_LEFT",
}

export interface Door {
  id: DoorEnum;
}

export const DOORS: Door[] = [
  { id: DoorEnum.FrontMain },
  { id: DoorEnum.FrontRight },
  { id: DoorEnum.FrontLeft },
  { id: DoorEnum.BackMain },
  { id: DoorEnum.BackRight },
  { id: DoorEnum.BackLeft },
];
