import { Loading, Pageable } from "../../types/common-types";
import { Profile } from "../../types/profile-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { updateStateWithPageable } from "../redux-utils";
import {
  addProfile,
  editProfile,
  getAllProfiles,
  getProfiles,
} from "../actions/profile-actions";

export interface ProfileState extends Pageable<Profile> {
  loading: Loading;
  allProfiles: Profile[] | null;
}

export const DEFAULT_PROFILE_FILTERS = {
  active: true,
};

export const DEFAULT_PROFILE_SORT = ["label", "id"];

const initialState: ProfileState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_PROFILE_SORT,
  filters: DEFAULT_PROFILE_FILTERS,
  allProfiles: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfiles.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getProfiles.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getProfiles.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addProfile.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addProfile.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addProfile.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editProfile.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editProfile.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editProfile.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getAllProfiles.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllProfiles.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allProfiles = action.payload;
    });
    builder.addCase(getAllProfiles.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default profileSlice.reducer;
