import { Partner } from "../../types/partner-types";
import { Loading, Pageable } from "../../types/common-types";
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import {
  addPartner,
  editPartner,
  getAllPartners,
  getPartners,
} from "../actions/partner-actions";
import { updateStateWithPageable } from "../redux-utils";

export interface PartnerState extends Pageable<Partner> {
  loading: Loading;
  allPartners: Partner[] | null;
}

export const DEFAULT_PARTNER_FILTERS = {
  active: true,
  name: "",
  country: null,
};

export const DEFAULT_PARTNER_SORT = ["name", "id"];

const initialState: PartnerState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_PARTNER_SORT,
  filters: DEFAULT_PARTNER_FILTERS,
  allPartners: null,
};

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPartners.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPartners.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getPartners.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addPartner.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addPartner.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addPartner.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editPartner.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editPartner.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editPartner.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getAllPartners.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllPartners.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.allPartners = action.payload;
    });
    builder.addCase(getAllPartners.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default partnerSlice.reducer;
