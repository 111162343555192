import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "../login-form/login-form.styles";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Store } from "../../../redux/store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getValidationSchema } from "./temp-password-form.utils";
import { TempPasswordFormProps } from "./temp-password-form.types";

const TempPasswordForm = ({ onSubmit, onBackClick }: TempPasswordFormProps) => {
  const { loading } = useSelector((state: Store) => state.auth);
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const styles = getStyles(theme, isTablet);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: getValidationSchema(t),
    onSubmit,
  });

  return (
    <>
      <Typography sx={styles.prompt}>{t("zmiana_hasla_zacheta")}</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={styles.fields}>
          <TextField
            id="newPassword"
            name="newPassword"
            label={t("nowe_haslo")}
            type="password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            label={t("potwierdz_haslo")}
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Box sx={styles.buttons}>
            <LoadingButton
              sx={styles.button}
              startIcon={<ArrowBackIcon />}
              loading={loading === "pending"}
              loadingPosition="start"
              variant="outlined"
              type="button"
              onClick={onBackClick}
            >
              {t("powrot")}
            </LoadingButton>
            <LoadingButton
              sx={styles.button}
              endIcon={<ArrowForwardIcon />}
              loading={loading === "pending"}
              loadingPosition="end"
              variant="contained"
              type="submit"
            >
              {t("zapisz")}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TempPasswordForm;
