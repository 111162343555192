import { Loading, Pageable } from "../../types/common-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { Post } from "../../types/post-types";
import { updateStateWithPageable } from "../redux-utils";
import {
  addPost,
  deactivatePosts,
  editPost,
  getPosts,
  getPostsForPopup,
} from "../actions/post-actions";

export interface PostState extends Pageable<Post> {
  loading: Loading;
  postsForPopup: Post[] | null;
}

export const DEFAULT_POST_FILTERS = {
  active: true,
};

export const DEFAULT_POST_SORT = ["createdDate,desc", "id"];

const initialState: PostState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: DEFAULT_POST_SORT,
  filters: DEFAULT_POST_FILTERS,
  postsForPopup: null,
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPosts.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getPosts.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getPosts.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addPost.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addPost.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addPost.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editPost.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editPost.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editPost.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(getPostsForPopup.fulfilled, (state, action) => {
      state.postsForPopup = action.payload;
    });
    builder.addCase(deactivatePosts.pending, (state) => {
      state.postsForPopup = null;
    });
  },
});

export default postSlice.reducer;
