export enum ClapEnum {
  Front = "FRONT",
  Back = "BACK",
}

export interface Clap {
  id: ClapEnum;
}

export const CLAPS: Clap[] = [{ id: ClapEnum.Front }, { id: ClapEnum.Back }];
