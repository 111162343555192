import { TFunction } from "react-i18next";
import * as yup from "yup";
import { PriceItem, PriceItemPatchRequest } from "../../../types/price-types";
import { FormValues } from "./price-item-edit.types";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  return yup.object({
    name: yup.string().required(requiredFieldError),
    price: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(0, invalidValueError),
  });
};

export const mapFormValuesToPriceItemPatchRequest = (
  values: FormValues,
  priceItem: PriceItem
): PriceItemPatchRequest => ({
  id: priceItem.id,
  price: { value: Number(values.price) },
});

const mapPriceItemToFormValues = (priceItem: PriceItem): FormValues => ({
  price: priceItem.price.value.toString(),
  name: priceItem.name,
});

const getDefaultFormValues = (): FormValues => ({
  name: "",
  price: "",
});

export const getInitialFormValues = (priceItem: PriceItem | null): FormValues =>
  priceItem ? mapPriceItemToFormValues(priceItem) : getDefaultFormValues();
