import { WithId } from "./common-types";
import {
  Translation,
  TranslationPatchRequest,
  TranslationPostRequest,
} from "./translation-types";
import {
  ComponentsAvailability,
  ComponentsAvailabilityPatchRequest,
  ComponentsAvailabilityPostRequest,
} from "./components-availability.types";
import { File } from "./file-types";

export interface Cover extends WithId {
  categoryId: number;
  profileId: number;
  label: string;
  labelTranslations: Translation[];
  componentsAvailability: ComponentsAvailability;
  flapPhoto?: File;
  doorPhoto?: File;
  secondaryFlapPhoto?: File;
  secondaryDoorPhoto?: File;
  defaultPhoto?: File;
  railCount: number;
  active: boolean;
  rank: number;
  defaultWallIncrease: number;
}

export interface CoverPostRequest {
  categoryId: number;
  profileId: number;
  labelTranslations: TranslationPostRequest[];
  componentsAvailability: ComponentsAvailabilityPostRequest;
  flapPhoto?: File;
  doorPhoto?: File;
  secondaryFlapPhoto?: File;
  secondaryDoorPhoto?: File;
  defaultPhoto?: File;
  railCount: number;
  rank: number;
  defaultWallIncrease: number;
}

export interface CoverPatchRequest extends WithId {
  categoryId?: number;
  profileId?: number;
  labelTranslations?: TranslationPatchRequest[];
  componentsAvailability?: ComponentsAvailabilityPatchRequest;
  flapPhoto?: File;
  doorPhoto?: File;
  secondaryFlapPhoto?: File;
  secondaryDoorPhoto?: File;
  defaultPhoto?: File;
  railCount?: number;
  active?: boolean;
  rank?: number;
  defaultWallIncrease?: number;
}

interface secondaryPhotos {
  //secondaryDoorPhoto
  lowWallPhoto?: File;
  //secondaryFlapPhoto
  highWallPhoto?: File;
}

export type CoverDTO = Omit<
  Cover,
  "secondaryFlapPhoto" | "secondaryDoorPhoto"
> &
  secondaryPhotos;

export type CoverPostDTO = Omit<
  CoverPostRequest,
  "secondaryFlapPhoto" | "secondaryDoorPhoto"
> &
  secondaryPhotos;
export type CoverPatchDTO = Omit<
  CoverPatchRequest,
  "secondaryFlapPhoto" | "secondaryDoorPhoto"
> &
  secondaryPhotos;

export const mapCoverDtoToCover = (coverDto: CoverDTO): Cover => {
  const { lowWallPhoto, highWallPhoto, ...coverBase } = coverDto;

  return {
    ...coverBase,
    secondaryDoorPhoto: lowWallPhoto,
    secondaryFlapPhoto: highWallPhoto,
  };
};

export const mapCoverPostRequestToCoverPostDTO = (
  coverPostRequest: CoverPostRequest
): CoverPostDTO => {
  const { secondaryDoorPhoto, secondaryFlapPhoto, ...coverBase } =
    coverPostRequest;

  return {
    ...coverBase,
    lowWallPhoto: secondaryDoorPhoto,
    highWallPhoto: secondaryFlapPhoto,
  };
};

export const mapCoverPatchRequestToCoverPatchDTO = (
  coverPatchRequest: CoverPatchRequest
): CoverPatchDTO => {
  const { secondaryDoorPhoto, secondaryFlapPhoto, ...coverBase } =
    coverPatchRequest;

  return {
    ...coverBase,
    lowWallPhoto: secondaryDoorPhoto,
    highWallPhoto: secondaryFlapPhoto,
  };
};
