import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Drawer, DrawerHeader, getStyles } from "./drawer.styles";
import MenuItem from "./components/menu-item/menu-item";
import logo from "../../assets/images/png/logo.png";
import UserInfo from "./components/user-info";
import Notifications from "./components/notifications/notifications";
import LanguageSelect from "./components/language-select";
import Logout from "./components/logout";
import HorizontalDivider from "../horizontal-divider/horizontal-divider";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { Outlet, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useRole from "../../hooks/useRole";
import { getMenuItems } from "./drawer.utils";
import ErrorBoundary from "../error-boundary/error-boundary";
import { Roles } from "../../constants/roles";
import PostPopup from "../post-popup/post-popup";
import { Route } from "../../constants/navigation-constants";
import UnsavedChangesModal from "../unsaved-changes-modal/unsaved-changes-modal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../redux/store";
import { setDrawerOpen } from "../../redux/slices/ui-slice";
import { isAdminRole } from "../../utils/common-utils";
import useWebSocket from "../../hooks/useWebSocket";

export const APP_BAR_CONTAINER = "app-bar-container";

export default function MiniDrawer() {
  const { isDrawerOpen, isPinned } = useSelector((state: Store) => state.ui);

  const theme = useTheme();
  const styles = getStyles(theme, isPinned);
  const isSmall = useMediaQuery("(max-width: 800px)");
  const userRole = useRole();
  const isAdmin = isAdminRole(userRole);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  useWebSocket();

  const [isUnsavedChangesModalOpen, setUnsavedChangesModalOpen] =
    useState(false);

  useEffect(() => {
    dispatch(setDrawerOpen(!isSmall));
  }, [isSmall]);

  const handleDrawerOpen = () => {
    dispatch(setDrawerOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
  };

  const navigateToMainPage = () => {
    setUnsavedChangesModalOpen(false);
    navigate(Route.Orders);
  };

  const handleLogoClick = () => {
    setUnsavedChangesModalOpen(true);
  };

  return (
    <Box sx={styles.container}>
      <UnsavedChangesModal
        open={isUnsavedChangesModalOpen}
        onCancelClick={() => setUnsavedChangesModalOpen(false)}
        leaveCallback={navigateToMainPage}
      />
      <AppBar position="fixed" open={isDrawerOpen}>
        <Toolbar sx={styles.toolbar}>
          {isDrawerOpen ? (
            <IconButton
              color="primary"
              onClick={handleDrawerClose}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div />
          )}
          <Box sx={styles.userContent}>
            {!isSmall && <UserInfo />}
            <HorizontalDivider />
            {!isAdmin && (
              <>
                <Notifications /> <HorizontalDivider />
              </>
            )}
            <LanguageSelect />
            <HorizontalDivider />
            <Logout />
          </Box>
          <Box id={APP_BAR_CONTAINER} sx={styles.appBarContainer} />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={isDrawerOpen}>
        <DrawerHeader>
          {isDrawerOpen ? (
            <>
              <Typography sx={styles.logoText} onClick={handleLogoClick}>
                BUCOVERS
              </Typography>
              <img
                src={logo}
                width={75}
                alt="logo"
                style={{ cursor: "pointer" }}
                onClick={handleLogoClick}
              />
            </>
          ) : (
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
              edge="start"
              sx={styles.iconButton}
            >
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {getMenuItems(userRole).map((route) => (
            <MenuItem
              key={route.route}
              open={isDrawerOpen}
              text={route.text}
              icon={route.icon}
              route={route.route}
              nestedItems={route.nestedItems}
            />
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={styles.content}>
        {userRole.name === Roles.Partner && <PostPopup />}
        <DrawerHeader />
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
}
