import { Theme } from "@mui/material";

export const getStyles = (theme: Theme) => ({
  menu: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    maxWidth: 400,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "50%",
      left: "50%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateX(-50%) translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  wrapper: {
    maxHeight: 300,
    overflow: "auto",
  },
  menuItem: {
    whiteSpace: "break-spaces",
  },
  item: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  text: {
    fontSize: 14,
    overflowWrap: "break-word",
    width: "100%",
    overflow: "hidden",
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  date: {
    fontSize: 12,
    fontWeight: 500,
  },
  indicator: {
    width: 10,
    minWidth: 10,
    height: 10,
    borderRadius: "50%",
  },
  noNotifications: {
    width: "100%",
    fontSize: 14,
    padding: theme.spacing(2),
  },
  badge: {
    zIndex: 0,
  },
});
