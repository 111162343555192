import { Theme } from "@mui/material";
import loginImg from "../../../assets/images/png/login_img.jpg";
import { Colors } from "../../../theme/colors";
import { toRem } from "../../../utils/common-utils";

export const getStyles = (
  theme: Theme,
  isTablet: boolean,
  isMobile: boolean
) => ({
  form: {
    display: isMobile ? "initial" : "flex",
    width: "80%",
    height: "80vh",
    borderRadius: 5,
    overflow: "hidden",
    boxShadow: theme.shadows[24],
    minHeight: isMobile ? 600 : 300,
  },
  left: {
    position: "relative",
    width: isMobile ? "100%" : "50%",
    height: isMobile ? "50%" : "100%",
    filter: "grayscale(100%)",
    backgroundImage: `url(${loginImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: 300,
  },
  privacyPolicy: {
    position: "absolute",
    bottom: "5%",
    left: "5%",
    color: Colors.White,
    fontSize: toRem(14),
    fontWeight: 300,
    cursor: "pointer",
  },
  media: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: theme.spacing(7),
    inset: 0,
    padding: "10%",
    backgroundColor: "rgba(50,50,50,0.85)",
  },
  logotyp: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  mainText: {
    color: Colors.White,
    fontSize: toRem(50),
    fontWeight: 400,
    lineHeight: 1,
  },
  divider: {
    width: "85%",
    borderColor: Colors.White,
  },
  smallText: {
    textAlign: "right",
    color: Colors.White,
    fontSize: toRem(isTablet ? 20 : 25),
    fontWeight: 100,
    lineHeight: 1,
  },
  mediumText: {
    color: Colors.White,
    fontSize: toRem(isTablet ? 25 : 30),
    fontWeight: 600,
    lineHeight: 1,
  },
  right: {
    width: isMobile ? "100%" : "50%",
    height: isMobile ? "50%" : "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(isTablet ? 2 : 4),
    justifyContent: "center",
    padding: "5%",
    minHeight: 300,
    // overflow: "auto", not sure if needed, uncomment this if there are some problem on small iphones
  },
});
