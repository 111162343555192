import React, { useEffect, useState } from "react";
import Section from "../../../../../components/section/section";
import { useTranslation } from "react-i18next";
import PhotoPreview, {
  PhotoType,
} from "../../../../../components/photo-preview/photo-preview";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import { Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "./doors-config.styles";
import Box from "@mui/material/Box";
import { ClapEnum, CLAPS } from "../../../../../constants/claps-constants";
import { DoorEnum, DOORS } from "../../../../../constants/door-constants";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getAutocompleteCommonProps,
  getRenderInput,
  prepareEntities,
  prepareOptions,
  prepareSideValue,
  prepareTopValue,
} from "./doors-config.utils";
import { InheritedType } from "./doors-config.types";
import { setClaps, setDoors } from "../../../../../redux/slices/order-slice";
import InfoIcon from "../../../../../components/info-icon/info-icon";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import useDisableAll from "../../../../../hooks/useDisableAll";
import { formatPrice } from "../../../../../utils/formatter-utils";
import wallIncreaseImg from "../../../../../assets/images/png/wall-increase.png";
import topDoorsImg from "../../../../../assets/images/png/top-doors.png";

const DoorsConfig = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 1600px)");
  const styles = getStyles(theme, isMobile, isTablet);
  const disableAll = useDisableAll();

  const { currentOrder, availablePrices } = useSelector(
    (state: Store) => state.order
  );
  const { category, model, variant, doorType, clapType, wallIncrease } =
    currentOrder;
  const { priceItems } = useSelector((state: Store) => state.price);

  const [frontOptions, setFrontOptions] = useState<InheritedType[]>([]);
  const [backOptions, setBackOptions] = useState<InheritedType[]>([]);
  const [topOptions, setTopOptions] = useState<DoorEnum[]>([]);

  useEffect(() => {
    if (category && model && variant) {
      const availableDoors = prepareEntities(
        category,
        model,
        variant,
        "doorsInherited",
        "doors",
        DOORS.map((x) => x.id)
      );

      const availableClaps =
        model.defaultWallIncrease === wallIncrease
          ? prepareEntities(
              category,
              model,
              variant,
              "clapsInherited",
              "claps",
              CLAPS.map((x) => x.id)
            )
          : [];

      const [fronts, backs, tops] = prepareOptions(
        // @ts-ignore
        availableDoors,
        availableClaps
      );
      setFrontOptions(fronts);
      setBackOptions(backs);
      setTopOptions(tops);
    }
  }, [variant, wallIncrease]);

  useUpdateEffect(() => {
    reset();
  }, [model?.id, variant?.id, wallIncrease]);

  const reset = () => {
    dispatch(setDoors([]));
    dispatch(setClaps([]));
  };

  if (!model) {
    return null;
  }

  const AUTOCOMPLETE_COMMON_PROPS = getAutocompleteCommonProps(
    variant,
    t,
    wallIncrease,
    disableAll
  );

  const changeTop = (value: DoorEnum | null) => {
    if (value === null) {
      const newValues = doorType.filter(
        (x) =>
          ![
            DoorEnum.FrontRight,
            DoorEnum.BackRight,
            DoorEnum.BackLeft,
            DoorEnum.FrontLeft,
          ].includes(x)
      );
      dispatch(setDoors([...newValues]));
    } else {
      const newValues = doorType.filter(
        (x) =>
          ![
            DoorEnum.FrontRight,
            DoorEnum.BackRight,
            DoorEnum.BackLeft,
            DoorEnum.FrontLeft,
          ].includes(x)
      );
      dispatch(setDoors([...newValues, value]));
    }
  };

  const changeFront = (value: DoorEnum | ClapEnum | null) => {
    let newDoors: DoorEnum[];
    let newClaps: ClapEnum[];

    if (value === DoorEnum.FrontMain) {
      newDoors = [
        ...doorType.filter((x) => x !== DoorEnum.FrontMain),
        DoorEnum.FrontMain,
      ];
      newClaps = clapType.filter((x) => x !== ClapEnum.Front);
    } else if (value === ClapEnum.Front) {
      newDoors = doorType.filter((x) => x !== DoorEnum.FrontMain);
      newClaps = [
        ...clapType.filter((x) => x !== ClapEnum.Front),
        ClapEnum.Front,
      ];
    } else {
      newDoors = doorType.filter((x) => x !== DoorEnum.FrontMain);
      newClaps = clapType.filter((x) => x !== ClapEnum.Front);
    }

    dispatch(setDoors(newDoors));
    dispatch(setClaps(newClaps));
  };

  const changeBack = (value: DoorEnum | ClapEnum | null) => {
    let newDoors: DoorEnum[];
    let newClaps: ClapEnum[];

    if (value === DoorEnum.BackMain) {
      newDoors = [
        ...doorType.filter((x) => x !== DoorEnum.BackMain),
        DoorEnum.BackMain,
      ];
      newClaps = clapType.filter((x) => x !== ClapEnum.Back);
    } else if (value === ClapEnum.Back) {
      newDoors = doorType.filter((x) => x !== DoorEnum.BackMain);
      newClaps = [
        ...clapType.filter((x) => x !== ClapEnum.Back),
        ClapEnum.Back,
      ];
    } else {
      newDoors = doorType.filter((x) => x !== DoorEnum.BackMain);
      newClaps = clapType.filter((x) => x !== ClapEnum.Back);
    }

    dispatch(setDoors(newDoors));
    dispatch(setClaps(newClaps));
  };

  const topInfoIconContent = (
    <Box sx={styles.biggerInfoIcon}>
      <img src={topDoorsImg} />
      <Typography>{t("__drzwi_na_dachu_tooltip")}</Typography>
    </Box>
  );

  const frontInfoIconContent = (
    <Box sx={styles.infoIcon}>
      <img src={wallIncreaseImg} />
      <Typography>{t("__podwyzszenie_scian_tooltip")}</Typography>
    </Box>
  );

  const backInfoIconContent = (
    <Box sx={styles.infoIcon}>
      <img src={wallIncreaseImg} />
      <Typography>{t("__podwyzszenie_scian_tooltip")}</Typography>
    </Box>
  );

  const getFrontPhotoType = (): PhotoType => {
    if (doorType.includes(DoorEnum.FrontMain) && model.doorPhoto) {
      return "doorPhoto";
    }

    if (clapType.includes(ClapEnum.Front) && model.flapPhoto) {
      return "flapPhoto";
    }

    return "defaultPhoto";
  };

  const getBackPhotoType = (): PhotoType => {
    if (doorType.includes(DoorEnum.BackMain)) {
      if (variant?.secondaryHeight && model.secondaryDoorPhoto) {
        return "secondaryDoorPhoto";
      }

      if (model.doorPhoto) {
        return "doorPhoto";
      }
    }

    if (clapType.includes(ClapEnum.Back)) {
      if (variant?.secondaryHeight && model.secondaryFlapPhoto) {
        return "secondaryFlapPhoto";
      }

      if (model.flapPhoto) {
        return "flapPhoto";
      }
    }

    return "defaultPhoto";
  };

  const getLabelWithPrice = (
    option: DoorEnum | ClapEnum,
    isBackWall?: boolean
  ) => {
    let freeOptions: (DoorEnum | ClapEnum)[] = [
      DoorEnum.FrontRight,
      DoorEnum.BackRight,
      DoorEnum.BackLeft,
      DoorEnum.FrontLeft,
    ];

    if (isBackWall) {
      freeOptions = [...freeOptions, DoorEnum.FrontMain, ClapEnum.Front];
    }

    const isPaid =
      doorType.some((x) => freeOptions.includes(x)) ||
      clapType.some((x) => freeOptions.includes(x));

    if (isPaid) {
      const price =
        availablePrices?.door ||
        priceItems?.find((x) => x.name === "DOOR")?.price.value ||
        0;
      return `${t(option)} (${formatPrice(price)} €)`;
    }

    return t(option);
  };

  const getTopOptionLetter = (option: DoorEnum) => {
    switch (option) {
      case DoorEnum.FrontLeft:
        return "A";
      case DoorEnum.FrontRight:
        return "B";
      case DoorEnum.BackRight:
        return "C";
      case DoorEnum.BackLeft:
        return "D";
    }
  };

  return (
    <Section title={t("__konfiguracja_drzwi")}>
      <Box sx={styles.container}>
        <Box sx={styles.previews}>
          <PhotoPreview
            model={model}
            blockInteractions={true}
            label={t("__sciana_przednia")}
            photoType={getFrontPhotoType()}
            width={375}
          />
          <PhotoPreview
            model={model}
            blockInteractions={true}
            label={t("__sciana_tylnia")}
            photoType={getBackPhotoType()}
            width={375}
          />
        </Box>
        <Box sx={styles.fields}>
          <Box sx={styles.tooltipWrapper}>
            <Autocomplete
              {...AUTOCOMPLETE_COMMON_PROPS}
              options={topOptions}
              value={prepareTopValue(doorType)}
              onChange={(event, value) => changeTop(value)}
              renderInput={getRenderInput(t("__gora_zadaszenia"), false)}
              getOptionLabel={(option: DoorEnum) =>
                `${t(option)} (${getTopOptionLetter(option)})`
              }
              renderOption={(props: any, option: DoorEnum) => (
                <li {...props} key={option}>
                  <Typography>{`${t(option)} (${getTopOptionLetter(
                    option
                  )})`}</Typography>
                </li>
              )}
            />
            <InfoIcon content={topInfoIconContent} />
          </Box>
          <Box sx={styles.tooltipWrapper}>
            <Autocomplete
              {...AUTOCOMPLETE_COMMON_PROPS}
              options={frontOptions}
              value={prepareSideValue([...doorType, ...clapType], "front")}
              onChange={(event, value) => changeFront(value)}
              renderInput={getRenderInput(t("__sciana_przednia"), false)}
              getOptionLabel={(option: InheritedType) =>
                getLabelWithPrice(option)
              }
              renderOption={(props: any, option: InheritedType) => (
                <li {...props} key={option}>
                  <Typography>{getLabelWithPrice(option)}</Typography>
                </li>
              )}
            />
            <InfoIcon content={frontInfoIconContent} />
          </Box>
          <Box sx={styles.tooltipWrapper}>
            <Autocomplete
              {...AUTOCOMPLETE_COMMON_PROPS}
              options={backOptions}
              value={prepareSideValue([...doorType, ...clapType], "back")}
              onChange={(event, value) => changeBack(value)}
              renderInput={getRenderInput(t("__sciana_tylnia"), false)}
              getOptionLabel={(option: InheritedType) =>
                getLabelWithPrice(option, true)
              }
              renderOption={(props: any, option: InheritedType) => (
                <li {...props} key={option}>
                  <Typography>{getLabelWithPrice(option, true)}</Typography>
                </li>
              )}
            />
            <InfoIcon content={backInfoIconContent} />
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export default DoorsConfig;
