import { Address } from "../types/partner-types";
import { Address as ClientAddress } from "../redux/slices/order-slice";
import { TFunction } from "i18next";

export const formatAddress = (address: Address, t: TFunction) => {
  const { flatNumber, houseNumber, street, zipCode, city } = address;
  const number = flatNumber ? `${houseNumber}/${flatNumber}` : houseNumber;
  return `${t("__ulica_skrot")} ${street} ${number}, ${zipCode} ${city}`;
};

export const formatClientAddress = (address: ClientAddress, t: TFunction) => {
  const { flatNumber, houseNumber, street, zipCode, city } = address;
  const number = flatNumber ? `${houseNumber}/${flatNumber}` : houseNumber;
  const streetPart = street
    ? `${t("__ulica_skrot")} ${street} ${number}, `
    : "";
  return `${streetPart}${zipCode} ${city}`;
};

export const formatCountry = (address: Address) => {
  return address.countryId;
};

export const formatDiscount = (discount: number) => `${discount * 100}%`;

export const formatDate = (date: string) => new Date(date).toLocaleDateString();

export const formatPrice = (value: number) => {
  return value.toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatInteger = (value: number) => {
  return value.toLocaleString("fr-FR", {
    maximumFractionDigits: 0,
  });
};
