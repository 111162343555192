import { Loading, Pageable } from "../../types/common-types";
import { User } from "../../types/user-types";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { createSlice } from "@reduxjs/toolkit";
import { updateStateWithPageable } from "../redux-utils";
import { addUser, editUser, getUsers } from "../actions/user-actions";

export interface UserState extends Pageable<User> {
  loading: Loading;
}

export const DEFAULT_USER_FILTERS = {
  active: true,
  partnerId: null,
  roleId: "",
};

const initialState: UserState = {
  ...DEFAULT_PAGEABLE,
  loading: "idle",
  sort: ["username"],
  filters: DEFAULT_USER_FILTERS,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload !== null) {
        updateStateWithPageable(state, action.payload);
      }
      state.loading = "succeeded";
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(addUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addUser.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(addUser.rejected, (state) => {
      state.loading = "failed";
    });
    builder.addCase(editUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(editUser.fulfilled, (state) => {
      state.loading = "succeeded";
    });
    builder.addCase(editUser.rejected, (state) => {
      state.loading = "failed";
    });
  },
});

export default userSlice.reducer;
