import { TFunction } from "react-i18next";
import * as yup from "yup";

export const getPasswordSchema = (t: TFunction<"translation">) =>
  yup
    .string()
    .min(8, t("haslo_min"))
    .max(32, t("haslo_max"))
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      t("slabe_haslo")
    );

export const getValidationSchema = (t: TFunction<"translation">) =>
  yup.object({
    newPassword: getPasswordSchema(t).required(t("brak_nowego_hasla")),
    confirmPassword: yup
      .string()
      .required(t("brak_potwierdzenia_hasla"))
      .oneOf([yup.ref("newPassword")], t("rozne_hasla")),
  });
