import { createAsyncThunk } from "@reduxjs/toolkit";
import { PageParams, PageResponse } from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import {
  PatchPostRequest,
  Post,
  PostPostRequest,
} from "../../types/post-types";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";

export const getPosts = createAsyncThunk(
  "post/getPosts",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { post } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, post)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Post>>("/post", {
      params: flatParams,
    });

    return preparePageForAction<Post>(data, params);
  }
);

export const getPost = createAsyncThunk("post/getPost", async (id: string) => {
  const { data } = await axios.get<PageResponse<Post>>(`/post`, {
    params: { id },
  });
  return getFirstElementFromPage<Post>(data);
});

export const getPostsForPopup = createAsyncThunk(
  "post/getPostsForPopup",
  async () => {
    const { data } = await axios.get<Post[]>("/post/popup");
    return data;
  }
);

export const deactivatePosts = createAsyncThunk(
  "post/deactivatePosts",
  async (ids: number[]) => {
    await axios.patch("post/popup/deactivate", { ids });
  }
);

export const addPost = createAsyncThunk(
  "post/addPost",
  async (params: PostPostRequest, thunkAPI) => {
    const { data } = await axios.post<Post>("/post", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editPost = createAsyncThunk(
  "post/editPost",
  async (params: PatchPostRequest, thunkAPI) => {
    const { data } = await axios.patch<Post>("/post", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
