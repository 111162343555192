import React from "react";
import { Post } from "../../../../../types/post-types";
import Section from "../../../../../components/section/section";
import { Theme, Typography, useTheme } from "@mui/material";
import { formatDate } from "../../../../../utils/formatter-utils";
import Box from "@mui/material/Box";
import { Loading } from "../../../../../types/common-types";

interface PostItemProps {
  post: Post;
  loading?: Loading;
}

const getStyles = (theme: Theme, loading?: Loading) => ({
  row: {
    width: "100%",
    margin: theme.spacing(2, 0),
    ".section-title": {
      fontSize: 16,
    },
    opacity: loading === "pending" ? 0.3 : 1,
  },
  text: {
    whiteSpace: "pre-wrap",
  },
  section: {
    ".section-title": {
      maxWidth: "80%",
    },
  },
});

const PostItem = ({ post, loading }: PostItemProps) => {
  const createdDate = <Typography>{formatDate(post.createdDate)}</Typography>;
  const theme = useTheme();
  const styles = getStyles(theme, loading);

  return (
    <Box sx={styles.row}>
      <Section sx={styles.section} title={post.title} actions={createdDate}>
        <Typography sx={styles.text}>{post.label}</Typography>
      </Section>
    </Box>
  );
};

export default PostItem;
