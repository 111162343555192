import { Theme } from "@mui/material";

export const getStyles = (theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column" as const,
    gap: theme.spacing(2),
    width: "100%",
    userSelect: "none",
  },
});
