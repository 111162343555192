import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Filters,
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import {
  Variant,
  VariantPatchRequest,
  VariantPostRequest,
} from "../../types/variant-types";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { DEFAULT_VARIANT_SORT } from "../slices/variant-slice";

export const getVariants = createAsyncThunk(
  "variants/getVariants",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { variant } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, variant)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Variant>>("/variant", {
      params: flatParams,
    });

    return preparePageForAction<Variant>(data, params);
  }
);

export const getAllVariants = createAsyncThunk(
  "variants/getAllVariants",
  async (config: { modelId: number; active?: boolean }) => {
    const filters: Filters = {};

    if (config.active) {
      filters.active = config.active;
      filters.modelId = config.modelId;
    }

    const [, ...sort] = DEFAULT_VARIANT_SORT;

    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort,
      filters,
    };

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Variant>>("/variant", {
      params: flatParams,
    });
    return data.content;
  }
);

export const getVariant = createAsyncThunk(
  "variants/getVariant",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Variant>>(`/variant`, {
      params: { id },
    });
    return getFirstElementFromPage<Variant>(data);
  }
);

export const addVariant = createAsyncThunk(
  "variants/addVariant",
  async (params: VariantPostRequest, thunkAPI) => {
    const { data } = await axios.post<Variant>("/variant", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editVariant = createAsyncThunk(
  "variants/editVariant",
  async (params: VariantPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Variant>("/variant", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
