import React from "react";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import { Store } from "../../../redux/store";
import { toRem } from "../../../utils/common-utils";

const getStyles = (theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  icon: {
    fontSize: toRem(38),
    color: theme.palette.grey["400"],
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  userName: {
    color: theme.palette.grey["600"],
    fontSize: toRem(15),
  },
  partnerName: {
    color: theme.palette.grey["500"],
    fontSize: toRem(13),
  },
});

const UserInfo = () => {
  const { firstname, lastname, username } = useSelector(
    (state: Store) => state.auth.user
  )!;

  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Box sx={styles.container}>
      <AccountCircleIcon sx={styles.icon} />
      <Box sx={styles.info}>
        <Typography
          sx={styles.userName}
        >{`${firstname} ${lastname}`}</Typography>
        <Typography sx={styles.partnerName}>{username}</Typography>
      </Box>
    </Box>
  );
};

export default UserInfo;
