import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Store } from "../../redux/store";
import { Theme, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { ELLIPSIS_STYLES } from "../pagination-table/table-row/table-row";

interface CountryFieldProps {
  countryId: number;
  props?: any;
  component?: string;
}

const getStyles = (theme: Theme) => ({
  container: {
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    "& > img": { mr: 2, flexShrink: 0, boxShadow: theme.shadows[2] },
  },
  text: {
    ...ELLIPSIS_STYLES,
  },
});

const CountryField = ({ countryId, props, component }: CountryFieldProps) => {
  const { countries } = useSelector((state: Store) => state.country);
  const country = countries?.find((country) => country.id === countryId);
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  if (!Boolean(country)) {
    return null;
  }

  return (
    <Box component={component} sx={styles.container} {...props}>
      <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/w20/${country!.iso.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${country!.iso.toLowerCase()}.png 2x`}
        alt=""
      />
      <Typography sx={styles.text}>{t(country!.iso.toLowerCase())}</Typography>
    </Box>
  );
};

export default CountryField;
