import React, { ReactNode } from "react";
import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import HorizontalDivider from "../horizontal-divider/horizontal-divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

interface FiltersProps {
  filters: ReactNode;
  onReset: () => void;
  onSearch: () => void;
  onAddClick: () => void;
}

const getStyles = (theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
});

const Filters = ({ filters, onReset, onSearch, onAddClick }: FiltersProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Button
        variant="contained"
        onClick={onAddClick}
        endIcon={<AddCircleIcon />}
      >
        {t("dodaj")}
      </Button>
      <HorizontalDivider height={5} />
      {filters}
      <HorizontalDivider height={5} />
      <IconButton color="primary" onClick={onSearch}>
        <SearchIcon />
      </IconButton>
      <IconButton color="primary" onClick={onReset}>
        <RestartAltIcon />
      </IconButton>
    </Box>
  );
};

export default Filters;
