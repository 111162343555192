import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { Store } from "../../redux/store";
import { Country } from "../../types/country-types";
import { useTranslation } from "react-i18next";
import { plCountriesTranslations } from "../../translations/pl/pl-countries-translations";
import CountryField from "./country-field";

interface CountrySelectProps {
  countryId: number | null;
  onChange: (countryId: number | null) => void;
  error?: boolean;
  helperText?: string | false;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const CountrySelect = ({
  countryId,
  onChange,
  error,
  helperText,
  fullWidth,
  disabled,
  required,
}: CountrySelectProps) => {
  const { countries } = useSelector((state: Store) => state.country);
  const { t } = useTranslation();

  const translatedOptions =
    countries === null
      ? []
      : countries
          .filter((country) =>
            // @ts-ignore
            Boolean(plCountriesTranslations[country.iso.toLowerCase()])
          )
          .map((country) => ({
            ...country,
            name: t(country.iso.toLowerCase()),
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

  const onCountryChange = (country: Country | null) =>
    onChange(country?.id || null);

  const getCountryById = (countryId: number | null) => {
    if (countryId === null) return null;
    return translatedOptions.find((country) => country.id === countryId)!;
  };

  return (
    <Autocomplete
      sx={{ width: fullWidth ? "100%" : 200 }}
      size="small"
      options={translatedOptions}
      autoHighlight
      fullWidth
      disabled={disabled}
      noOptionsText={t("__brak_opcji")}
      value={getCountryById(countryId)}
      onChange={(event: any, newValue: Country | null) => {
        onCountryChange(newValue);
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <CountryField
          key={option.id}
          countryId={option.id}
          props={props}
          component="li"
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          required={required}
          label={t("kraj")}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
