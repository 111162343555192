import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Filters,
  PageParams,
  PageRequest,
  PageResponse,
} from "../../types/common-types";
import { Store } from "../store";
import {
  flatPageParams,
  getFirstElementFromPage,
  isPageUpToDate,
  preparePageForAction,
} from "../redux-utils";
import axios from "axios";
import {
  Filling,
  FillingPatchRequest,
  FillingPostRequest,
} from "../../types/filling-types";
import { setAlert } from "../slices/alert-slice";
import i18next from "../../i18n";
import { DEFAULT_PAGEABLE } from "../../constants/common";
import { DEFAULT_BRAKE_SORT } from "../slices/brake-slice";

export const getFillings = createAsyncThunk(
  "filling/getFillings",
  async ({ params, reload }: PageParams, thunkAPI) => {
    const { filling } = thunkAPI.getState() as Store;

    if (!reload && isPageUpToDate(params, filling)) {
      return null;
    }

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Filling>>("/filling", {
      params: flatParams,
    });

    return preparePageForAction<Filling>(data, params);
  }
);

export const getAllFillings = createAsyncThunk(
  "fillings/getAllFillings",
  async (config: { active?: boolean }) => {
    const filters: Filters = {};

    if (config.active) {
      filters.active = config.active;
    }

    const params: PageRequest = {
      page: DEFAULT_PAGEABLE.page,
      size: 1000000,
      sort: DEFAULT_BRAKE_SORT,
      filters,
    };

    const flatParams = flatPageParams(params);

    const { data } = await axios.get<PageResponse<Filling>>("/filling", {
      params: flatParams,
    });
    return data.content;
  }
);

export const getFilling = createAsyncThunk(
  "filling/getFilling",
  async (id: string) => {
    const { data } = await axios.get<PageResponse<Filling>>(`/filling`, {
      params: { id },
    });
    return getFirstElementFromPage<Filling>(data);
  }
);

export const addFilling = createAsyncThunk(
  "filling/addFilling",
  async (params: FillingPostRequest, thunkAPI) => {
    const { data } = await axios.post<Filling>("/filling", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);

export const editFilling = createAsyncThunk(
  "filling/editFilling",
  async (params: FillingPatchRequest, thunkAPI) => {
    const { data } = await axios.patch<Filling>("/filling", params);
    thunkAPI.dispatch(setAlert({ message: i18next.t("zapisane_zmiany") }));
    return data;
  }
);
