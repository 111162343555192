import { WithId } from "./common-types";

export interface Price {
  value: number;
}

export interface PriceItem extends WithId {
  name: string;
  price: Price;
}

export interface PriceItemPatchRequest extends WithId {
  price?: Price;
}

export interface ModuleDependantPriceItemGetResponse {
  moduleCount: number;
  brakePrice: Price;
  montagePrice: Price;
}

export type ModuleDependantPriceItem = WithId &
  ModuleDependantPriceItemGetResponse;

export type ModuleDependantPriceItemPostRequest =
  ModuleDependantPriceItemGetResponse;

export type ModuleDependantPriceItemPatchRequest =
  Partial<ModuleDependantPriceItemGetResponse> & { moduleCount: number };

export interface VariantPrice {
  fillingId: number;
  price: number;
  active: boolean;
}

export interface GlobalPriceUpdateRequest {
  priceMultiplier: number;
  modelIds: number[];
}

export interface Currency {
  code: string;
  symbol: string;
}

export const DEFAULT_CURRENCY: Currency = { code: "EUR", symbol: "€" };

export const CURRENCIES: Currency[] = [
  DEFAULT_CURRENCY,
  { code: "PLN", symbol: "zł" },
  { code: "GBP", symbol: "£" },
  { code: "SEK", symbol: "kr" },
  { code: "NOK", symbol: "kr" },
  { code: "USD", symbol: "$" },
  { code: "CHF", symbol: "CHF" },
  { code: "CZK", symbol: "Kč" },
  { code: "HUF", symbol: "Ft" },
  { code: "DKK", symbol: "kr" },
];
