import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UiState {
  isDrawerOpen: boolean;
  isPinned: boolean;
}

const initialState: UiState = {
  isDrawerOpen: true,
  isPinned: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
    setPinned(state, action: PayloadAction<boolean>) {
      state.isPinned = action.payload;
    },
  },
});

export default uiSlice.reducer;
export const { setDrawerOpen, setPinned } = uiSlice.actions;
