import { TFunction } from "react-i18next";
import * as yup from "yup";
import {
  mapClapEnumsToClaps,
  mapDoorEnumsToDoors,
  mapFormValuesToComponentsAvailability,
} from "../../config-pages/category-page/category-edit/category-edit.utils";
import { FormValues, FormValuesWithPrices } from "./varaint-edit.types";
import {
  Variant,
  VariantPatchRequest,
  VariantPostRequest,
} from "../../../types/variant-types";
import { VariantPrice } from "../../../types/price-types";
import { VariantPriceRow } from "./variant-prices/variant-prices";

export const getValidationSchema = (t: TFunction<"translation">) => {
  const requiredFieldError = t("wymagane_pole");
  const invalidValueError = t("niepoprawna_wartosc");

  return yup.object({
    active: yup.boolean(),
    modulesCount: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(1, invalidValueError),
    widthExt: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(1, invalidValueError),
    widthInt: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(1, invalidValueError),
    length: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(1, invalidValueError),
    height: yup
      .number()
      .typeError(invalidValueError)
      .required(requiredFieldError)
      .min(1, invalidValueError),
    secondaryHeight: yup.number().typeError(invalidValueError),
    standardLength: yup.boolean(),
    modelId: yup.string().required(requiredFieldError),
  });
};

const mapPriceRowToPrice = (row: VariantPriceRow): VariantPrice => ({
  active: row.active,
  price: Number(row.price),
  fillingId: row.fillingId,
});

export const mapFormValuesToVariantPostRequest = (
  values: FormValuesWithPrices
): VariantPostRequest => {
  return {
    height: Number(values.height),
    secondaryHeight: values.secondaryHeight
      ? Number(values.secondaryHeight)
      : undefined,
    length: Number(values.length),
    modelId: Number(values.modelId),
    modulesCount: Number(values.modulesCount),
    price: values.prices.map(mapPriceRowToPrice),
    standardLength: values.standardLength,
    widthExt: Number(values.widthExt),
    widthInt: Number(values.widthInt),
    componentsAvailability: mapFormValuesToComponentsAvailability(values),
  };
};

export const mapFormValuesToVariantPatchRequest = (
  values: FormValuesWithPrices,
  variant: Variant
): VariantPatchRequest => {
  return {
    widthExt: Number(values.widthExt),
    standardLength: values.standardLength,
    price: values.prices.map(mapPriceRowToPrice),
    modulesCount: Number(values.modulesCount),
    modelId: Number(values.modelId),
    length: Number(values.length),
    height: Number(values.height),
    secondaryHeight: values.secondaryHeight
      ? Number(values.secondaryHeight)
      : undefined,
    id: variant.id,
    active: values.active,
    componentsAvailability: {
      ...mapFormValuesToComponentsAvailability(values),
      id: variant.componentsAvailability.id,
    },
    widthInt: Number(values.widthInt),
  };
};

const mapVariantToFormValues = (variant: Variant): FormValues => ({
  widthInt: variant.widthInt.toString(),
  height: variant.height.toString(),
  length: variant.length.toString(),
  modelId: variant.modelId.toString(),
  modulesCount: variant.modulesCount.toString(),
  standardLength: variant.standardLength,
  widthExt: variant.widthExt.toString(),
  active: variant.active,
  fillings: variant.componentsAvailability.fillings,
  doors: mapDoorEnumsToDoors(variant.componentsAvailability.doors),
  claps: mapClapEnumsToClaps(variant.componentsAvailability.claps),
  colors: variant.componentsAvailability.colors,
  brakes: variant.componentsAvailability.brakes,
  clapsInherited: variant.componentsAvailability.clapsInherited,
  doorsInherited: variant.componentsAvailability.doorsInherited,
  brakesInherited: variant.componentsAvailability.brakesInherited,
  colorsInherited: variant.componentsAvailability.colorsInherited,
  fillingsInherited: variant.componentsAvailability.fillingsInherited,
  secondaryHeight: variant.secondaryHeight?.toString() || "",
});

const getDefaultFormValues = (): FormValues => ({
  colorsInherited: true,
  brakesInherited: true,
  doorsInherited: true,
  clapsInherited: true,
  brakes: [],
  colors: [],
  doors: [],
  claps: [],
  fillings: [],
  active: true,
  widthExt: "",
  standardLength: true,
  modulesCount: "",
  modelId: "",
  length: "",
  height: "",
  secondaryHeight: "",
  widthInt: "",
  fillingsInherited: true,
});

export const getInitFormValues = (variant: Variant | null): FormValues =>
  variant ? mapVariantToFormValues(variant) : getDefaultFormValues();
