import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageChanges } from "../../../../../types/common-types";
import { applyChangesToPage } from "../../../../../redux/redux-utils";
import { getProfiles } from "../../../../../redux/actions/profile-actions";
import { DEFAULT_PROFILE_FILTERS } from "../../../../../redux/slices/profile-slice";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Route } from "../../../../../constants/navigation-constants";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Filters from "../../../../../components/filters/filters";

const ProfileFilter = () => {
  const reducer = useSelector((state: Store) => state.profile);
  const [active, setActive] = useState(reducer.filters.active);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchPage = (changes?: PageChanges) => {
    const params = applyChangesToPage(reducer, changes);
    dispatch(getProfiles({ params }));
  };

  const onSearch = () => {
    const updatedFilters = { active };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onReset = () => {
    setActive(DEFAULT_PROFILE_FILTERS.active);

    const updatedFilters = {
      active: DEFAULT_PROFILE_FILTERS.active,
    };
    fetchPage({ page: 0, filters: updatedFilters });
  };

  const onActiveChange = (event: SelectChangeEvent) => {
    const value = event.target.value === "true";
    setActive(value);
  };

  const onAddClick = () => navigate(Route.ProfileEdit);

  const filters = (
    <>
      <FormControl sx={{ width: 170 }}>
        <InputLabel>{t("status")}</InputLabel>
        <Select
          value={active?.toString()}
          label={t("status")}
          onChange={onActiveChange}
          size="small"
        >
          <MenuItem value="true">{t("aktywny")}</MenuItem>
          <MenuItem value="false">{t("dezaktywowany")}</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  return (
    <Filters
      filters={filters}
      onReset={onReset}
      onSearch={onSearch}
      onAddClick={onAddClick}
    />
  );
};

export default ProfileFilter;
