import {
  OrderPatchRequest,
  OrderPostRequest,
  OrderStatus,
} from "../../../../types/order-types";
import { CurrentOrder } from "../../../../redux/slices/order-slice";
import { CalculationResponse } from "../../../../types/calculation-types";
import { format } from "date-fns";

export const createOrderPostRequest = (
  currentOrder: CurrentOrder,
  status: OrderStatus,
  calculation?: CalculationResponse,
  term?: Date
): OrderPostRequest => {
  const { clientPrice } = currentOrder;
  const { isDetailedPrice, detailedClientPrice } = clientPrice;

  return {
    brakeId: currentOrder.brake!.id,
    brakeKey: currentOrder.brakeKey,
    clapType: currentOrder.clapType,
    client: currentOrder.clientDelivery ? currentOrder.client : undefined,
    clientPrice:
      currentOrder.clientPrice.final === ""
        ? undefined
        : Number(currentOrder.clientPrice.final),
    colorId: currentOrder.color!.id,
    distance: Number(currentOrder.distance),
    doorType: currentOrder.doorType,
    fillingId: currentOrder.filling!.id,
    floorBase: currentOrder.floorBase,
    height: currentOrder.variant!.height,
    secondaryHeight: currentOrder.variant!.secondaryHeight,
    length: currentOrder.variant!.length,
    modulesCount: currentOrder.variant!.modulesCount,
    partnerId: currentOrder.partner!.id,
    price: calculation?.partnerPrice || 0,
    railLength: Number(currentOrder.railExtension),
    status: status,
    term: term ? format(term, "yyyy-MM-dd") : undefined,
    variantId: currentOrder.variant!.id,
    wallIncrease: Number(currentOrder.wallIncrease),
    widthExt: currentOrder.variant!.widthExt,
    widthInt: currentOrder.variant!.widthInt,
    withMontage: Boolean(currentOrder.withMontage),
    withTransport: Boolean(currentOrder.withTransport),
    clientDiscount:
      currentOrder.clientPrice.discount === ""
        ? undefined
        : Number(currentOrder.clientPrice.discount) / 100,
    railPosition:
      currentOrder.model?.railCount === 1
        ? currentOrder.railPosition!
        : undefined,
    box: currentOrder.category?.box ? !currentOrder.box : undefined,
    clientCurrency: currentOrder.clientPrice.currency.code,
    clientVat: Number(currentOrder.clientPrice.vatRate) / 100,
    detailedPrices: isDetailedPrice,
    boxMontageClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.boxMontage.price)
      : undefined,
    boxMontageClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.boxMontage.discount) / 100
      : undefined,
    brakeClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.brake.price)
      : undefined,
    brakeClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.brake.discount) / 100
      : undefined,
    doorClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.door.price)
      : undefined,
    doorClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.door.discount) / 100
      : undefined,
    montageClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.montage.price)
      : undefined,
    montageClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.montage.discount) / 100
      : undefined,
    railExtensionClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.railExtension.price)
      : undefined,
    railExtensionClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.railExtension.discount) / 100
      : undefined,
    transportClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.transport.price)
      : undefined,
    transportClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.transport.discount) / 100
      : undefined,
    variantClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.variant.price)
      : undefined,
    variantClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.variant.discount) / 100
      : undefined,
  };
};

export const createOrderPatchRequest = (
  currentOrder: CurrentOrder,
  status: OrderStatus,
  partnerPrice?: number,
  term?: Date
): OrderPatchRequest => {
  const { clientPrice } = currentOrder;
  const { isDetailedPrice, detailedClientPrice } = clientPrice;

  return {
    id: currentOrder.id!,
    brakeId: currentOrder.brake!.id,
    brakeKey: currentOrder.brakeKey,
    clapType: currentOrder.clapType,
    client: currentOrder.clientDelivery ? currentOrder.client : undefined,
    clientPrice:
      currentOrder.clientPrice.final === ""
        ? undefined
        : Number(currentOrder.clientPrice.final),
    colorId: currentOrder.color!.id,
    distance: Number(currentOrder.distance),
    doorType: currentOrder.doorType,
    fillingId: currentOrder.filling!.id,
    floorBase: currentOrder.floorBase,
    height: currentOrder.variant!.height,
    secondaryHeight: currentOrder.variant!.secondaryHeight,
    length: currentOrder.variant!.length,
    modulesCount: currentOrder.variant!.modulesCount,
    partnerId: currentOrder.partner!.id,
    price: partnerPrice || currentOrder.payment?.partnerPrice || 0,
    railLength: Number(currentOrder.railExtension),
    status: status,
    term: term ? format(term, "yyyy-MM-dd") : undefined,
    variantId: currentOrder.variant!.id,
    wallIncrease: Number(currentOrder.wallIncrease),
    widthExt: currentOrder.variant!.widthExt,
    widthInt: currentOrder.variant!.widthInt,
    withMontage: Boolean(currentOrder.withMontage),
    withTransport: Boolean(currentOrder.withTransport),
    clientDiscount:
      currentOrder.clientPrice.discount === ""
        ? undefined
        : Number(currentOrder.clientPrice.discount) / 100,
    railPosition:
      currentOrder.model?.railCount === 1
        ? currentOrder.railPosition!
        : undefined,
    box: currentOrder.category?.box ? !currentOrder.box : undefined,
    clientCurrency: currentOrder.clientPrice.currency.code,
    clientVat: Number(currentOrder.clientPrice.vatRate) / 100,
    detailedPrices: isDetailedPrice,
    boxMontageClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.boxMontage.price)
      : undefined,
    boxMontageClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.boxMontage.discount) / 100
      : undefined,
    brakeClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.brake.price)
      : undefined,
    brakeClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.brake.discount) / 100
      : undefined,
    doorClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.door.price)
      : undefined,
    doorClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.door.discount) / 100
      : undefined,
    montageClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.montage.price)
      : undefined,
    montageClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.montage.discount) / 100
      : undefined,
    railExtensionClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.railExtension.price)
      : undefined,
    railExtensionClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.railExtension.discount) / 100
      : undefined,
    transportClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.transport.price)
      : undefined,
    transportClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.transport.discount) / 100
      : undefined,
    variantClientPrice: isDetailedPrice
      ? Number(detailedClientPrice.variant.price)
      : undefined,
    variantClientPriceDiscount: isDetailedPrice
      ? Number(detailedClientPrice.variant.discount) / 100
      : undefined,
  };
};
