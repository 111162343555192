import React from "react";
import useRole from "../../../hooks/useRole";
import { Roles } from "../../../constants/roles";
import { Navigate } from "react-router-dom";
import { Route } from "../../../constants/navigation-constants";

interface ProtectedRouteProps {
  children: JSX.Element;
  roles: Roles[];
}

const ProtectedRoute = ({ children, roles }: ProtectedRouteProps) => {
  const userRole = useRole();

  const authorized = roles.some((role) => role === userRole.name);

  return authorized ? children : <Navigate to={Route.Layout} />;
};

export default ProtectedRoute;
