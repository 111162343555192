import React, { ReactNode, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { getStyles } from "./login-page.styles";
import { LoginFormValues, TempPasswordFormValues } from "./login-page.types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  login,
  logout,
  whoAmI,
} from "../../redux/actions/auth-actions";
import { AppDispatch, Store } from "../../redux/store";
import { Navigate, useLocation } from "react-router-dom";
import { Route } from "../../constants/navigation-constants";
import Loader from "../../components/loader/loader";
import LoginForm from "./login-form/login-form";
import FormContainer from "./form-container/form-container";
import TempPasswordForm from "./temp-password-form/temp-password-form";
import PolicyDialog from "./policy-dialog/policy-dialog";

const LoginPage = () => {
  const { user, didAutoLogin } = useSelector((state: Store) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 650px)");
  const styles = getStyles(theme, isMobile);
  const location = useLocation();
  const state = location.state as { from?: string };

  useEffect(() => {
    dispatch(whoAmI());
  }, []);

  const onLoginSubmit = ({ email, password }: LoginFormValues) => {
    dispatch(login({ email, password }));
  };

  const onTempPasswordSubmit = (values: TempPasswordFormValues) => {
    dispatch(
      changePassword({
        password: values.newPassword,
      })
    );
  };

  const onCancel = () => {
    dispatch(logout());
  };

  const redirect = <Navigate to={state?.from || Route.Layout} />;

  const loader = <Loader sx={styles.loader} />;

  const loginForm = (
    <FormContainer>
      <LoginForm onSubmit={onLoginSubmit} />
    </FormContainer>
  );

  const tempPasswordForm = (
    <FormContainer>
      <TempPasswordForm
        onSubmit={onTempPasswordSubmit}
        onBackClick={onCancel}
      />
    </FormContainer>
  );

  const policyDialog = <PolicyDialog onClose={onCancel} />;

  const getContent = (children: ReactNode) => (
    <Box sx={styles.container}>{children}</Box>
  );

  if (user) {
    if (user.tempPassword) {
      return getContent(tempPasswordForm);
    }

    if (!user.termsAgreement) {
      return getContent(policyDialog);
    }

    return redirect;
  }

  return getContent(didAutoLogin ? loginForm : loader);
};

export default LoginPage;
