import React, { ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import logo from "../../../assets/images/png/logo_with_text_white.png";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "./form-container.styles";
import PolicyDialog from "../policy-dialog/policy-dialog";
import { useTranslation } from "react-i18next";

interface FormContainerProps {
  children: ReactNode;
}

const FormContainer = ({ children }: FormContainerProps) => {
  const [isPolicyOpen, setPolicyOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const isMobile = useMediaQuery("(max-width: 650px)");
  const styles = getStyles(theme, isTablet, isMobile);

  const onPolicyClose = () => setPolicyOpen(false);

  const onPolicyClick = () => setPolicyOpen(true);

  return (
    <Box sx={styles.form}>
      {isPolicyOpen && <PolicyDialog onClose={onPolicyClose} isPreview />}
      <Box sx={styles.left}>
        <Box sx={styles.media}>
          <Box sx={styles.logotyp}>
            <img src={logo} width={isTablet ? "70%" : "40%"} alt="logo" />
          </Box>
          <Divider sx={styles.divider} />
          <Box>
            <Typography sx={styles.smallText}>for</Typography>
            <Typography sx={styles.mediumText}>Business</Typography>
          </Box>
        </Box>
        <Typography sx={styles.privacyPolicy} onClick={onPolicyClick}>
          {t("polityka_prywatnosci")}
        </Typography>
      </Box>
      <Box sx={styles.right}>{children}</Box>
    </Box>
  );
};

export default FormContainer;
