import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ModuleDependantPriceItem } from "../../../types/price-types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import useFetchEntity, { FetchEntityArgs } from "../../../hooks/useFetchEntity";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { getStyles } from "../../partners-page/partner-edit/partner-edit.styles";
import { Route } from "../../../constants/navigation-constants";
import { FormValues } from "./md-price-item-edit.types";
import {
  getInitFormValues,
  getValidationSchema,
  mapFormValuesToModuleDependantPriceItemPatchRequest,
  mapFormValuesToModuleDependantPriceItemPostRequest,
} from "./md-price-item-edit.utils";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Section from "../../../components/section/section";
import {
  addModuleDependantPriceItem,
  editModuleDependantPriceItem,
  getModuleDependantPriceItem,
} from "../../../redux/actions/price-actions";
import {
  applyChanges,
  resetChanges,
  setOriginalData,
} from "../../../redux/slices/changes-slice";
import SectionTitle from "../../../components/section-title/section-title";
import { preparePatchEntity } from "../../../redux/redux-utils";
import { getInputAdornment } from "../../../utils/common-utils";
import NumericInput from "../../../components/numeric-input/numeric-input";

interface ModuleDependantPriceItemEditProps extends WithPageLoaderProps {}

const ModuleDependantPriceItemEdit = ({
  finishLoading,
}: ModuleDependantPriceItemEditProps) => {
  const { id } = useParams<{ id?: string }>();
  const [edited, setEdited] = useState<ModuleDependantPriceItem | null>(null);
  const { moduleDependantPriceItems, loading } = useSelector(
    (state: Store) => state.price
  );
  const { originalData } = useSelector((state: Store) => state.changes);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchEntity } = useFetchEntity();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const styles = getStyles(theme, isMobile);
  const isLoading = loading === "pending";

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const fetchArgs: FetchEntityArgs<ModuleDependantPriceItem> = {
      callback: setEdited,
      collection: moduleDependantPriceItems,
      fallbackRoute: Route.Prices,
      fetcherAction: getModuleDependantPriceItem,
      id,
    };
    await Promise.all([fetchEntity<ModuleDependantPriceItem>(fetchArgs)]);
    finishLoading();
  };

  const onSubmit = async (values: FormValues) => {
    const saveFn = edited ? submitEdit : submitAdd;
    const saved = await saveFn(values);
    if (saved) {
      setEdited(saved);
    }
  };

  const submitAdd = async (values: FormValues) => {
    const priceItem =
      mapFormValuesToModuleDependantPriceItemPostRequest(values);
    const { payload } = await dispatch(addModuleDependantPriceItem(priceItem));
    return payload as ModuleDependantPriceItem;
  };

  const submitEdit = async (values: FormValues) => {
    const priceItem =
      mapFormValuesToModuleDependantPriceItemPatchRequest(values);
    const originalEntity = mapFormValuesToModuleDependantPriceItemPatchRequest(
      originalData as FormValues
    );
    const adjustedEntity = preparePatchEntity(priceItem, originalEntity);
    const { payload } = await dispatch(
      editModuleDependantPriceItem({
        ...adjustedEntity,
        moduleCount: priceItem.moduleCount,
      })
    );
    return payload as ModuleDependantPriceItem;
  };

  const formik = useFormik({
    initialValues: getInitFormValues(edited),
    validationSchema: getValidationSchema(t),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const originalData = getInitFormValues(edited);
    dispatch(setOriginalData({ originalData }));

    return () => {
      dispatch(resetChanges());
    };
  }, [edited]);

  useEffect(() => {
    dispatch(applyChanges({ data: formik.values }));
  }, [formik.values]);

  const onBackClick = () => navigate(Route.Prices);

  const onRollbackClick = () => {
    formik.setValues(getInitFormValues(edited));
  };

  return (
    <form style={styles.container}>
      <SectionTitle
        title={
          edited ? t("__edycja_ceny_moduly") : t("__dodawanie_ceny_moduly")
        }
        onBackClick={onBackClick}
        isLoading={isLoading}
        isEditing={Boolean(edited)}
        onRollback={onRollbackClick}
        onSubmit={formik.handleSubmit}
      />
      <Section title={t("dane_podstawowe")}>
        <Box sx={styles.fields}>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="moduleCount"
              name="moduleCount"
              fullWidth
              label={t("__liczba_modulow")}
              size="small"
              disabled={Boolean(edited)}
              value={formik.values.moduleCount}
              onChange={formik.handleChange}
              error={
                formik.touched.moduleCount && Boolean(formik.errors.moduleCount)
              }
              helperText={
                formik.touched.moduleCount && formik.errors.moduleCount
              }
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="brakePrice"
              name="brakePrice"
              fullWidth
              label={t("__cena_blokady")}
              size="small"
              value={formik.values.brakePrice}
              onChange={formik.handleChange}
              error={
                formik.touched.brakePrice && Boolean(formik.errors.brakePrice)
              }
              helperText={formik.touched.brakePrice && formik.errors.brakePrice}
              InputProps={getInputAdornment("€")}
              isMoney
            />
          </Box>
          <Box sx={styles.field}>
            <NumericInput
              variant="outlined"
              id="montagePrice"
              name="montagePrice"
              fullWidth
              label={t("__cena_montazu")}
              size="small"
              value={formik.values.montagePrice}
              onChange={formik.handleChange}
              error={
                formik.touched.montagePrice &&
                Boolean(formik.errors.montagePrice)
              }
              helperText={
                formik.touched.montagePrice && formik.errors.montagePrice
              }
              InputProps={getInputAdornment("€")}
              isMoney
            />
          </Box>
          <Box sx={styles.field} />
        </Box>
      </Section>
    </form>
  );
};

export default withPageLoader(ModuleDependantPriceItemEdit);
