import React, { useEffect, useState } from "react";
import Section from "../../../../../components/section/section";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../../../redux/store";
import {
  AUTOCOMPLETE_COMMON_PROPS,
  getRenderInput,
} from "./variant-picker.utils";
import {
  getInputAdornment,
  getUnique,
} from "../../../../../utils/common-utils";
import { COMMON_PROPS } from "../model-summary/model-summary";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getStyles } from "./variant-picker.styles";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { setVariant } from "../../../../../redux/slices/order-slice";
import useUpdateEffect from "../../../../../hooks/useUpdateEffect";
import useDisableAll from "../../../../../hooks/useDisableAll";
import NumericInput from "../../../../../components/numeric-input/numeric-input";

const VariantPicker = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 650px)");
  const styles = getStyles(theme, isMobile);
  const disableAll = useDisableAll();

  const { allVariants } = useSelector((state: Store) => state.variant);
  const { currentOrder, validation, rollback } = useSelector(
    (state: Store) => state.order
  );
  const { model, variant, partner } = currentOrder;

  const [isStandard, setStandard] = useState(true);
  const [lengths, setLengths] = useState<number[]>([]);
  const [widthInts, setWidthInts] = useState<number[]>([]);
  const [length, setLength] = useState<number | null>(variant?.length || null);
  const [widthInt, setWidthInt] = useState<number | null>(
    variant?.widthInt || null
  );
  const [widthExt, setWidthExt] = useState<string>(
    variant?.widthExt.toString() || ""
  );
  const [height, setHeight] = useState<string>(
    variant?.height.toString() || ""
  );
  const [secondaryHeight, setSecondaryHeight] = useState<string>(
    variant?.secondaryHeight?.toString() || ""
  );
  const [wasRollback, setWasRollback] = useState(false);

  useEffect(() => {
    prepareLengths();
  }, [allVariants]);

  useEffect(() => {
    if (model === null) {
      dispatch(setVariant(null));
    }
  }, [model]);

  useUpdateEffect(() => {
    prepareLengths();
    if (!wasRollback) {
      setLength(null);
      reset();
    }
  }, [isStandard]);

  useUpdateEffect(() => {
    prepareWidthInts();
    if (!wasRollback) {
      reset();
    }
  }, [length]);

  useUpdateEffect(() => {
    if (!wasRollback) {
      changeVariant();
    }
  }, [widthInt]);

  useEffect(() => {
    if (rollback && variant) {
      setWasRollback(true);
      setLength(variant.length);
      setWidthInt(variant.widthInt);
      setWidthExt(variant.widthExt.toString());
      setHeight(variant.height.toString());
      setSecondaryHeight(variant.secondaryHeight?.toString() || "");
      setStandard(variant.standardLength);
      setTimeout(() => {
        setWasRollback(false);
      }, 1000);
    }
  }, [rollback]);

  const reset = () => {
    setWidthInt(null);
    setWidthExt("");
    setHeight("");
    setSecondaryHeight("");
    dispatch(setVariant(null));
  };

  const prepareLengths = () => {
    if (allVariants) {
      setLengths(
        allVariants
          .filter((variant) => variant.price.some((price) => price.active))
          .filter((variant) => variant.standardLength === isStandard)
          .map((variant) => variant.length)
          .filter(getUnique)
      );
    }
  };

  const prepareWidthInts = () => {
    if (allVariants) {
      setWidthInts(
        allVariants
          .filter((variant) => variant.price.some((price) => price.active))
          .filter((variant) => variant.length === length)
          .map((variant) => variant.widthInt)
          .filter(getUnique)
      );
    }
  };

  const changeVariant = () => {
    if (allVariants) {
      const pickedVariant = allVariants.find(
        (variant) => variant.length === length && variant.widthInt === widthInt
      );
      if (pickedVariant && pickedVariant.id !== variant?.id) {
        dispatch(setVariant(pickedVariant));
        setWidthExt(pickedVariant.widthExt.toString());
        setHeight(pickedVariant.height.toString());
        setSecondaryHeight(pickedVariant.secondaryHeight?.toString() || "");
      }
    }
  };

  const actions = (
    <FormControlLabel
      disabled={disableAll}
      control={
        <Checkbox
          checked={!isStandard}
          onChange={(value) => setStandard(!value.target.checked)}
        />
      }
      label={t("__dlugosci_niestandardowe")}
    />
  );

  return (
    <Section title={t("__wymiary_zadaszenia")}>
      <Box sx={styles.fields}>
        <Box sx={styles.field}>
          <Autocomplete
            {...AUTOCOMPLETE_COMMON_PROPS}
            noOptionsText={t("__brak_opcji")}
            options={lengths}
            value={length}
            disabled={disableAll || !partner}
            onChange={(event, value) => setLength(value)}
            renderInput={getRenderInput(
              t("__dlugosc"),
              validation,
              length,
              true,
              "cm"
            )}
          />
        </Box>
        <Box sx={styles.field}>
          <Autocomplete
            {...AUTOCOMPLETE_COMMON_PROPS}
            noOptionsText={t("__brak_opcji")}
            options={widthInts}
            value={widthInt}
            disabled={!length || disableAll}
            onChange={(event, value) => setWidthInt(value)}
            renderInput={getRenderInput(
              t("__szerokosc_wewnetrzna_long"),
              validation,
              widthInt,
              true,
              "cm"
            )}
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__szerokosc_zewnetrzna_long")}
            value={widthExt}
            disabled={true}
            error={!!validation && !!validation.variant && widthExt === ""}
            helperText={!!validation && widthExt === "" && validation.variant}
            InputProps={getInputAdornment("cm")}
          />
        </Box>
        <Box sx={styles.field}>
          <NumericInput
            {...COMMON_PROPS}
            label={t("__wysokosc")}
            value={height}
            disabled={true}
            error={!!validation && !!validation.variant && height === ""}
            helperText={!!validation && height === "" && validation.variant}
            InputProps={getInputAdornment("cm")}
          />
        </Box>
        {secondaryHeight && (
          <>
            <Box sx={styles.field}>
              <NumericInput
                {...COMMON_PROPS}
                label={t("__druga_wysokosc")}
                value={secondaryHeight}
                disabled={true}
                InputProps={getInputAdornment("cm")}
              />
            </Box>
            <Box sx={styles.field} />
          </>
        )}
      </Box>
    </Section>
  );
};

export default VariantPicker;
