import { DoorEnum } from "../../../../../constants/door-constants";
import { ClapEnum } from "../../../../../constants/claps-constants";
import { Category } from "../../../../../types/category-types";
import { Cover } from "../../../../../types/cover-types";
import { Variant } from "../../../../../types/variant-types";
import {
  InheritedCollection,
  InheritedField,
  InheritedType,
  Place,
} from "./doors-config.types";
import { TFunction } from "i18next";
import { Typography } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";

export const prepareOptions = (
  availableDoors: DoorEnum[],
  availableClaps: ClapEnum[]
): [InheritedType[], InheritedType[], DoorEnum[]] => {
  const fronts: InheritedType[] = [ClapEnum.Front, DoorEnum.FrontMain].filter(
    (x) => [...availableDoors, ...availableClaps].includes(x)
  );

  const backs: InheritedType[] = [ClapEnum.Back, DoorEnum.BackMain].filter(
    (x) => [...availableDoors, ...availableClaps].includes(x)
  );

  const tops: DoorEnum[] = [
    DoorEnum.FrontLeft,
    DoorEnum.FrontRight,
    DoorEnum.BackRight,
    DoorEnum.BackLeft,
  ].filter((x) => availableDoors.includes(x));

  return [fronts, backs, tops];
};

export const prepareEntities = (
  category: Category,
  model: Cover,
  variant: Variant,
  inheritedField: InheritedField,
  inheritedCollection: InheritedCollection,
  allCollection: InheritedType[] | null
) => {
  let preparedEntities: InheritedType[];

  if (variant.componentsAvailability[inheritedField]) {
    if (model.componentsAvailability[inheritedField]) {
      if (category.componentsAvailability[inheritedField]) {
        preparedEntities = allCollection ? [...allCollection] : [];
      } else {
        preparedEntities = category.componentsAvailability[inheritedCollection];
      }
    } else {
      preparedEntities = model.componentsAvailability[inheritedCollection];
    }
  } else {
    preparedEntities = variant.componentsAvailability[inheritedCollection];
  }
  return preparedEntities;
};

export const getAutocompleteCommonProps = (
  variant: Variant | null,
  t: TFunction,
  wallIncrease: number | null,
  disableAll: boolean
) => ({
  size: "small" as const,
  autoHighlight: true,
  fullWidth: true,
  noOptionsText: t("__brak_opcji"),
  disabled: !variant || wallIncrease === null || disableAll,
  getOptionLabel: (option: InheritedType) => t(option),
  isOptionEqualToValue: (option: InheritedType, value: InheritedType) =>
    option === value,
  renderOption: (props: any, option: InheritedType) => (
    <li {...props} key={option}>
      <Typography>{`${t(option)}`}</Typography>
    </li>
  ),
});

export const prepareTopValue = (wallStuff: DoorEnum[]) => {
  const value = wallStuff.find((x) =>
    [
      DoorEnum.FrontRight,
      DoorEnum.BackRight,
      DoorEnum.BackLeft,
      DoorEnum.FrontLeft,
    ].includes(x)
  );

  return value || null;
};

export const prepareSideValue = (wallStuff: InheritedType[], place: Place) => {
  let value: InheritedType | undefined;

  if (place === "front") {
    value = wallStuff.find((x) =>
      [DoorEnum.FrontMain, ClapEnum.Front].includes(x)
    );
  }

  if (place === "back") {
    value = wallStuff.find((x) =>
      [DoorEnum.BackMain, ClapEnum.Back].includes(x)
    );
  }

  return value || null;
};

export const getRenderInput =
  (label: string, required = true) =>
  (params: any) =>
    (
      <TextField
        {...params}
        label={label}
        required={required}
        inputProps={{
          ...params.inputProps,
          autoComplete: "off",
        }}
      />
    );
